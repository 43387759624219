import React from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import type { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { DepositFrequencyHelper } from '../../../../models';
import { BudgetPlanActionMode, budgetPlanActionModeSelector } from '../../../../state';
import { DEFAULT_TIME_FORMAT } from '../../../../utils/constants';
import { intl } from '../../../../utils/intl';
import { NumberInput, NumberInputChangeHandler, SelectionMenu, SelectionMenuOptionMetadata, Value } from '../../../../wmv-components';
import { budgetPlanFormInputKeys, formTranslationKey } from '../helpers';
import { budgetPlanFormInputStyles } from '../styles';

import { BudgetPlanDetailsFormValues } from './BudgetPlanDetailsView';
import { BudgetPlanInputContainer } from './BudgetPlanInputContainer';
import { BudgetSpendConstraintsInput } from './BudgetSpendConstraintsInput';

export const formInputTranslationKey = `${formTranslationKey}.input`;

export const BudgetPlanFormInputs = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { setFieldValue, handleBlur, handleChange, values, errors, touched, isSubmitting } =
    useFormikContext<BudgetPlanDetailsFormValues>();

  const planActionMode = useSelector(budgetPlanActionModeSelector);

  if (!values.amountPerTripSpendConstraintExists && values.amountPerTripSpendConstraint) {
    // TODO: Fix this later as to how we can deal with promise without swallowing exception
    setFieldValue(budgetPlanFormInputKeys.amountPerTripSpendConstraint, null);
  }

  const viewMode = planActionMode === BudgetPlanActionMode.View;
  const maxNameLengthHelperText = intl.formatMessage({ id: 'form.validation.maxChars' }, { maxChars: 35 });
  return (
    <Stack spacing={3}>
      <BudgetPlanInputContainer translationGroupKeyPrefix="name" required>
        <TextField
          name={budgetPlanFormInputKeys.name}
          value={values.name}
          error={!!errors.name && touched.name}
          helperText={!!errors.name && touched.name ? errors.name : maxNameLengthHelperText}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          sx={budgetPlanFormInputStyles}
          label={formatMessage({ id: `${formInputTranslationKey}.name.label` })}
          placeholder={formatMessage({ id: `${formInputTranslationKey}.name.placeholder` })}
          disabled={viewMode || isSubmitting}
        />
      </BudgetPlanInputContainer>
      <BudgetPlanInputContainer translationGroupKeyPrefix="depositFrequency">
        <TextField
          name={budgetPlanFormInputKeys.depositFrequency}
          value={DepositFrequencyHelper.metadata(values.depositFrequency).displayText}
          error={!!errors.depositFrequency && touched.depositFrequency}
          helperText={!!errors.depositFrequency && touched.depositFrequency ? errors.depositFrequency : null}
          label={formatMessage({ id: `${formInputTranslationKey}.depositFrequency.label` })}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled
          sx={budgetPlanFormInputStyles}
          size="small"
        />
      </BudgetPlanInputContainer>
      <BudgetPlanInputContainer translationGroupKeyPrefix="depositDayOfMonthAndTime">
        <Box>
          <Box display="flex" gap={1} width="300px">
            <SelectionMenu
              value={values.depositDayOfMonth}
              options={daysOfMonthSelectionMenuOptions}
              name={budgetPlanFormInputKeys.depositDayOfMonth}
              label={formatMessage({ id: `${formInputTranslationKey}.depositDayOfMonth.label` })}
              disabled={viewMode || isSubmitting}
              onChange={handleDepositDayChange}
              sx={{ width: '100%' }}
              required={false}
              hasError={!!errors.depositDayOfMonth && touched.depositDayOfMonth}
              helperText={!!errors.depositDayOfMonth && touched.depositDayOfMonth ? errors.depositDayOfMonth : null}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                ampm={false}
                disabled={viewMode || isSubmitting}
                value={values.depositTime}
                timeSteps={{ minutes: 1 }}
                onChange={handleDepositTimeChange}
                label={formatMessage({ id: `${formInputTranslationKey}.depositTime.label` })}
                format={DEFAULT_TIME_FORMAT}
                slotProps={{
                  textField: {
                    required: true,
                    onBlur: handleBlur,
                    name: budgetPlanFormInputKeys.depositTime,
                    error: !!errors.depositTime && touched.depositTime,
                    helperText: !!errors.depositTime && touched.depositTime ? errors.depositTime : null,
                    size: 'small',
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          {values.depositDayOfMonth && values.depositTime && dayjs(values.depositTime).isValid() && (
            <Typography variant="caption" color={theme.palette.baseLight.base60} maxWidth="400px" component={'p'} marginTop={0.5}>
              <FormattedMessage
                id={`${formInputTranslationKey}.depositDayOfMonthAndTime.nextDepositDescription`}
                values={{
                  startDate: dayjs().set('date', values.depositDayOfMonth).formatAsDateString(),
                  isStartTimeBeginningOfDay: values.depositTime.isBeginningOfDay(),
                  startTime: <b>{values.depositTime.formatAsTimeWithoutSeconds()}</b>,
                  endDate: dayjs().set('date', values.depositDayOfMonth).plusMonths(1).formatAsDateString(),
                  endTime: values.depositTime.formatAsTimeWithoutSeconds(),
                  depositDayOfMonth: <b>{values.depositDayOfMonth}</b>,
                  // @ts-ignore
                  bold: (str) => <b>{str}</b>,
                }}
              />
            </Typography>
          )}
        </Box>
      </BudgetPlanInputContainer>
      <BudgetPlanInputContainer translationGroupKeyPrefix="depositAmount" required>
        <NumberInput
          name={budgetPlanFormInputKeys.depositAmount}
          value={values.depositAmount!}
          onChange={handleDepositAmountChange}
          inputExtraProps={{ onBlur: handleBlur }}
          label={formatMessage({ id: `${formInputTranslationKey}.depositAmount.label` })}
          dataTestId="newPausedMinutesFee"
          disabled={viewMode || isSubmitting}
          error={!!errors.depositAmount && touched.depositAmount}
          helperText={!!errors.depositAmount && touched.depositAmount ? errors.depositAmount : null}
          placeholder={formatMessage({ id: `common.numberPlaceholder` }, { value: 100 })}
          sx={budgetPlanFormInputStyles}
          size="small"
          formatAsI18nNumber
        />
      </BudgetPlanInputContainer>
      <BudgetSpendConstraintsInput />
    </Stack>
  );

  async function handleDepositTimeChange(time: Dayjs | null) {
    await setFieldValue(budgetPlanFormInputKeys.depositTime, time!);
  }

  async function handleDepositDayChange(e: SelectChangeEvent<Value>) {
    await setFieldValue(budgetPlanFormInputKeys.depositDayOfMonth, e.target.value);
  }

  async function handleDepositAmountChange(...[{ name, value }]: Parameters<NumberInputChangeHandler>) {
    await setFieldValue(name, value);
  }
};

const daysOfMonthSelectionMenuOptions: SelectionMenuOptionMetadata[] = [];
for (let i = 1; i < 29; i++) {
  daysOfMonthSelectionMenuOptions.push({
    value: i,
    label: <b>{i.toString()}</b>,
  });
}
