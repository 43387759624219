import React, { useState } from 'react';

import { Avatar, Box, Button, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import mockProfileImage from '../../assets/map-illustrations/user-mock-image.png';
import CustomIcon from '../../utils/CustomIcon';

import { ImageHeaderStyle, InputModalStyle } from './AccountInformationStyles';

function AccountInformationUserIcon() {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [selectedImage, setSelectedImage] = useState(mockProfileImage);
  const [inputModalOpen, setInputModalOpen] = useState(false);

  const handleDeleteImage = () => {
    setSelectedImage(mockProfileImage);
  };
  const handleOpenInput = () => {
    setInputModalOpen(true);
  };
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    //ToDo - implement logic for uploading an image to the user db
    setSelectedImage(mockProfileImage);
    setInputModalOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="bodyLargeBold">{formatMessage({ id: 'account.profile.user' })}</Typography>
      <Box sx={ImageHeaderStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Avatar alt="userImage" src={selectedImage} sx={{ width: '130px', height: '130px' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              paddingTop: '40px',
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="contained" color="secondary" onClick={() => handleOpenInput()}>
              <Typography variant="bodySmallBold">{formatMessage({ id: 'account.image.upload' })}</Typography>
            </Button>
            <Button variant="contained" color="tertiary" onClick={() => handleDeleteImage()}>
              <Typography variant="bodySmallBold">{formatMessage({ id: 'account.image.delete' })}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      {inputModalOpen ? (
        <div>
          <Modal open={inputModalOpen}>
            <Box sx={InputModalStyle}>
              <input type="file" style={{ width: '350px' }} onChange={(event) => handleImageUpload(event)} />
              <Button
                type="submit"
                sx={{
                  position: 'absolute',
                  top: '4%',
                  right: '2.5%',
                  minWidth: '0px',
                  padding: '0px',
                }}
                onClick={() => setInputModalOpen(false)}
              >
                {CustomIcon('ModalCloseIcon', theme.palette.dark.main, '24px')}
              </Button>
            </Box>
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default AccountInformationUserIcon;
