import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import { ReactComponent as EditInfoIcon } from '../../../assets/icons/outline/editInfo.svg';
import { ReactComponent as SaveInfoIcon } from '../../../assets/icons/outline/saveInfo.svg';
import { ProgressAwareButton } from '../../../wmv-components';

import { ZoneManagementActionState } from './ZoneManagement';
import { formDeleteButtonStyle } from './ZoneRulesForm.styles';

interface ZoneRuleFormActionButtonProps {
  isSubmitting: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  zoneManagementActionState: ZoneManagementActionState;
}

export const ZoneRuleFormActionButtons = ({
  isSubmitting,
  onEditClick,
  onDeleteClick,
  onCancelClick,
  zoneManagementActionState,
}: ZoneRuleFormActionButtonProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const viewZoneInformationScreen = zoneManagementActionState === ZoneManagementActionState.ViewZoneInformationScreen;
  const editZoneInformationScreen = zoneManagementActionState === ZoneManagementActionState.EditZoneInformationScreen;
  const newZoneInformationScreen = zoneManagementActionState === ZoneManagementActionState.NewZoneInformationScreen;

  return (
    <Box display="flex" gap={1} mt={4} height={theme.spacing(5.4)}>
      {viewZoneInformationScreen ? (
        <Button variant="outlined" sx={formDeleteButtonStyle} onClick={onDeleteClick} disabled={isSubmitting}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'actions.delete' })}</Typography>
        </Button>
      ) : (
        <Button variant="outlined" sx={formDeleteButtonStyle} onClick={onCancelClick} disabled={isSubmitting}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'actions.cancel' })}</Typography>
        </Button>
      )}
      {viewZoneInformationScreen && (
        <Button onClick={onEditClick} sx={{ textTransform: 'unset' }} variant="contained" color="primary" type="button" fullWidth>
          <EditInfoIcon fill={theme.palette.baseLight.base0} />
          <Typography variant="bodyMediumBold" ml={0.5}>
            {formatMessage({ id: 'actions.editZone' })}
          </Typography>
        </Button>
      )}
      {editZoneInformationScreen && (
        <ProgressAwareButton loading={isSubmitting} startIcon={<SaveInfoIcon />} loadingButtonProps={{ sx: { flex: 1 } }}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'actions.saveChanges' })}</Typography>
        </ProgressAwareButton>
      )}
      {newZoneInformationScreen && (
        <ProgressAwareButton loading={isSubmitting} startIcon={<SaveInfoIcon />} loadingButtonProps={{ sx: { flex: 1 } }}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'actions.setUpZone' })}</Typography>
        </ProgressAwareButton>
      )}
    </Box>
  );
};
