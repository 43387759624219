import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import type { Dayjs } from 'dayjs';
import type { FormikProps } from 'formik';
import { Form, withFormik } from 'formik';
import type { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';

import { ErrorMessage } from '../../../components/FormFields/ErrorMessage';
import { DayOfWeek, VehicleType, ZoneType } from '../../../models';
import { saveZoneErrorSelector } from '../../../state/zones-management';

import { zoneRulesFormValidationSchema } from './validation-schemas';
import { ZoneDateRangeTimeWindowAndDaysOfWeekFormGroup } from './ZoneDateRangeTimeWindowAndDaysOfWeekFormGroup';
import { ZoneManagementActionState } from './ZoneManagement';
import { ZoneRuleFormActionButtons } from './ZoneRuleFormActionButtons';
import { zoneRuleFormStyles } from './ZoneRulesForm.styles';
import { ZoneTypeAndNameFormGroup } from './ZoneTypeAndNameFormGroup';
import { ZoneVehicleTypesFormField } from './ZoneVehicleTypesFormField';

const ZoneRulesFormWithFormik = (props: ZoneRulesFormOwnProps & FormikProps<ZoneRulesFormValues>) => {
  const theme = useTheme();

  const saveZoneError = useSelector(saveZoneErrorSelector);
  const { values, intl, handleSubmit, onDelete, onCancel, isSubmitting, onZoneTypeChange, onZoneEditClick, zoneManagementActionState } =
    props;
  const { formatMessage } = intl;

  useEffect(() => {
    onZoneTypeChange(values.type);
  }, [values.type, onZoneTypeChange]);

  const readOnlyZoneRuleForm = zoneManagementActionState === ZoneManagementActionState.ViewZoneInformationScreen || isSubmitting;
  return (
    <Form onSubmit={handleSubmit} style={zoneRuleFormStyles(theme)}>
      <Box position="relative">
        <Stack spacing={3} divider={<Divider flexItem color={theme.palette.baseLight.base8} />}>
          <ZoneTypeAndNameFormGroup name={values.name} type={values.type} readOnlyZoneRuleForm={readOnlyZoneRuleForm} />
          <ZoneDateRangeTimeWindowAndDaysOfWeekFormGroup
            selectedDaysOfWeek={values.daysOfWeek}
            startDate={values.startDate}
            endDate={values.endDate}
            startTime={values.startTime}
            endTime={values.endTime}
            indefiniteZone={values.indefiniteZone}
            representsFullDay={values.representsFullDay}
            readOnlyZoneRuleForm={readOnlyZoneRuleForm}
            zoneManagementActionState={zoneManagementActionState}
          />
          <ZoneVehicleTypesFormField selectedVehicleTypes={values.applicableVehicleTypes} readOnlyZoneRuleForm={readOnlyZoneRuleForm} />
        </Stack>
      </Box>
      <Box>
        <ZoneRuleFormActionButtons
          isSubmitting={isSubmitting}
          onCancelClick={onCancel}
          onEditClick={onZoneEditClick}
          onDeleteClick={onDelete}
          zoneManagementActionState={zoneManagementActionState}
        />
        {!!saveZoneError && <ErrorMessage error={formatMessage({ id: 'systemStatus.error.caption' })} />}
      </Box>
    </Form>
  );
};

export const ZoneRulesForm = withFormik<ZoneRulesFormOwnProps, ZoneRulesFormValues>({
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => ({
    [ZoneRuleFormFields.type]: initialValues.type,
    [ZoneRuleFormFields.name]: initialValues.name,
    [ZoneRuleFormFields.startDate]: initialValues.startDate,
    [ZoneRuleFormFields.endDate]: initialValues.endDate,
    [ZoneRuleFormFields.indefiniteZone]: initialValues.indefiniteZone,
    [ZoneRuleFormFields.startTime]: initialValues.startTime,
    [ZoneRuleFormFields.endTime]: initialValues.endTime,
    [ZoneRuleFormFields.representsFullDay]: initialValues.representsFullDay,
    [ZoneRuleFormFields.daysOfWeek]: initialValues.daysOfWeek,
    [ZoneRuleFormFields.applicableVehicleTypes]: initialValues.applicableVehicleTypes,
  }),
  validationSchema: zoneRulesFormValidationSchema,
  handleSubmit: async (values, { props: { onSubmit }, setSubmitting }) => {
    try {
      setSubmitting(true);
      await onSubmit(values);
    } finally {
      setSubmitting(false);
    }
  },
  displayName: 'ZoneRulesForm',
})(ZoneRulesFormWithFormik);

export const ZoneRuleFormFields = {
  type: 'type',
  name: 'name',
  startDate: 'startDate',
  endDate: 'endDate',
  indefiniteZone: 'indefiniteZone',
  startTime: 'startTime',
  endTime: 'endTime',
  representsFullDay: 'representsFullDay',
  daysOfWeek: 'daysOfWeek',
  applicableVehicleTypes: 'applicableVehicleTypes',
} as const;

export interface ZoneRulesFormValues {
  type: ZoneType;
  name: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  indefiniteZone: boolean;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  representsFullDay: boolean;
  daysOfWeek: Set<DayOfWeek>;
  applicableVehicleTypes: VehicleType[];
}

export interface ZoneRulesFormOwnProps {
  onSubmit(values: ZoneRulesFormValues): void;
  onDelete(): void;
  onCancel(): void;
  onZoneTypeChange(zoneType: ZoneType): void;
  intl: IntlShape;
  initialValues: ZoneRulesFormValues;
  onZoneEditClick: () => void;
  zoneManagementActionState: ZoneManagementActionState;
}
