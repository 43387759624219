import { createContext, Dispatch, ReactNode, useReducer, useContext } from 'react';

import { LoadingStatus } from '../../../../models';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { ApiStatusService } from '../../../../services/ui';

const initialBudgetMembershipListState: BudgetPlanMembershipsListState = {
  membershipsListApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
  memberships: [],
  error: '',
  selectedMembershipId: null,
  linkModalOpen: false,
  searchString: '',
};

const BudgetPlanMembershipsListStateContext = createContext<BudgetPlanMembershipsListState>(initialBudgetMembershipListState);
const BudgetPlanMembershipsListStateDispatchContext = createContext<Dispatch<BudgetPlanMembershipsListAction> | null>(null);

export function BudgetPlanMembershipsListStateProvider({ children }: BudgetPlanMembershipsListStateProviderProps) {
  const [budgetPlanMembershipsListState, dispatch] = useReducer(BudgetPlanMembershipsListReducer, initialBudgetMembershipListState);

  return (
    <BudgetPlanMembershipsListStateContext.Provider value={budgetPlanMembershipsListState!}>
      <BudgetPlanMembershipsListStateDispatchContext.Provider value={dispatch}>
        {children}
      </BudgetPlanMembershipsListStateDispatchContext.Provider>
    </BudgetPlanMembershipsListStateContext.Provider>
  );
}

export function useBudgetPlanMembershipsListState() {
  return useContext(BudgetPlanMembershipsListStateContext)!;
}

export function useBudgetPlanMembershipsListDispatch() {
  return useContext(BudgetPlanMembershipsListStateDispatchContext)!;
}

export function useSelectedMembershipId() {
  const membershipsListState = useBudgetPlanMembershipsListState();
  return membershipsListState.selectedMembershipId;
}

function BudgetPlanMembershipsListReducer(
  budgetMembershipListState: BudgetPlanMembershipsListState,
  action: BudgetPlanMembershipsListAction,
): BudgetPlanMembershipsListState {
  switch (action.type) {
    case BudgetPlanMembershipsListActionType.MembershipsListFetchStart:
      return {
        ...budgetMembershipListState,
        membershipsListApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
        memberships: [],
      };
    case BudgetPlanMembershipsListActionType.MembershipsListFetchSuccess:
      return {
        ...budgetMembershipListState,
        membershipsListApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
        memberships: action.payload,
      };
    case BudgetPlanMembershipsListActionType.MembershipsListFetchFailure:
      return {
        ...budgetMembershipListState,
        membershipsListApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
        memberships: [],
      };
    case BudgetPlanMembershipsListActionType.UpdateMembershipsList:
      return {
        ...budgetMembershipListState,
        memberships: action.payload,
      };
    case BudgetPlanMembershipsListActionType.UpdateSelectedMembership:
      return {
        ...budgetMembershipListState,
        selectedMembershipId: action.payload,
      };
    case BudgetPlanMembershipsListActionType.OpenLinkMembershipModal:
      return {
        ...budgetMembershipListState,
        linkModalOpen: true,
      };
    case BudgetPlanMembershipsListActionType.CloseLinkMembershipModal:
      return {
        ...budgetMembershipListState,
        linkModalOpen: false,
      };
    case BudgetPlanMembershipsListActionType.UpdateSearchString:
      return {
        ...budgetMembershipListState,
        searchString: action.payload,
      };
    default:
      return budgetMembershipListState;
  }
}

interface BudgetPlanMembershipsListState {
  membershipsListApiStatus: ApiStatusService;
  memberships: BudgetPlanMembershipDto[];
  error: string;
  selectedMembershipId: number | null;
  linkModalOpen: boolean;
  searchString: string;
}

interface BudgetPlanMembershipsListStateProviderProps {
  children: ReactNode;
}

export enum BudgetPlanMembershipsListActionType {
  MembershipsListFetchStart = 'MEMBERSHIPS_LIST_FETCH_START',
  MembershipsListFetchSuccess = 'MEMBERSHIPS_LIST_FETCH_SUCCESS',
  MembershipsListFetchFailure = 'MEMBERSHIPS_LIST_FETCH_FAILURE',
  UpdateMembershipsList = 'UPDATE_MEMBERSHIPS_LIST',
  OpenLinkMembershipModal = 'OPEN_LINK_MEMBERSHIP_MODAL',
  CloseLinkMembershipModal = 'CLOSE_LINK_MEMBERSHIP_MODAL',
  UpdateSelectedMembership = 'UPDATE_SELECTED_MEMBERSHIP',
  UpdateSearchString = 'UPDATE_SEARCH_STRING',
}

export type BudgetPlanMembershipsListAction =
  | { type: BudgetPlanMembershipsListActionType.MembershipsListFetchStart }
  | { type: BudgetPlanMembershipsListActionType.MembershipsListFetchSuccess; payload: BudgetPlanMembershipDto[] }
  | { type: BudgetPlanMembershipsListActionType.MembershipsListFetchFailure }
  | { type: BudgetPlanMembershipsListActionType.UpdateMembershipsList; payload: BudgetPlanMembershipDto[] }
  | { type: BudgetPlanMembershipsListActionType.OpenLinkMembershipModal }
  | { type: BudgetPlanMembershipsListActionType.CloseLinkMembershipModal }
  | { type: BudgetPlanMembershipsListActionType.UpdateSelectedMembership; payload: number | null }
  | { type: BudgetPlanMembershipsListActionType.UpdateSearchString; payload: string };
