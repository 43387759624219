import { useState } from 'react';

import { Box, Divider, IconButton, Modal } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { CustomerInformationTab, CustomerInformationTabHelper, CustomerDetailsType } from '../../models';
import CustomIcon from '../../utils/CustomIcon';
import { WmvTabs, WmvTabPanel } from '../../wmv-components';
import Placeholder from '../Placeholder/Placeholder';

import { CustomerBudgetInformation } from './CustomerBudgetInformation';
import CustomerPersonalDetails from './CustomerPersonalDetails';
import { customerInfoTabPanelStyles, dividerStyles, separtorStyles, sidebarHeaderStyles, tabPanelContainerStyles } from './styles';
import UserLicenceInformation from './UserLicenceInformation';
import { UserNameAndId } from './UserNameAndId';
import UserTrips from './UserTrips';

function CustomerInformationModal({ isOpen, onClose, userInfo }: { isOpen: boolean; onClose: () => void; userInfo: CustomerDetailsType }) {
  const [openCustomerModal] = useState(isOpen);
  const theme = useTheme();
  const [child, setChildUserDetails] = useState<CustomerDetailsType | null>(null);

  const [activeTab, setActiveTab] = useState<CustomerInformationTab>(CustomerInformationTab.CustomerDetails);

  return (
    <Modal open={openCustomerModal} aria-labelledby="modal-title">
      <CustomerModal>
        <Box sx={sidebarHeaderStyles}>
          <UserNameAndId
            firstName={child ? child.firstName : userInfo.firstName}
            lastName={child ? child.lastName : userInfo.lastName}
            subjectClaim={userInfo.subjectClaim}
          />
          <Divider orientation="horizontal" flexItem sx={dividerStyles}></Divider>
          <WmvTabs<CustomerInformationTab>
            options={CustomerInformationTabHelper.allLabelAndValues()}
            activeTab={activeTab}
            onChange={handleCustomerInfoTabChange}
            orientation="vertical"
          ></WmvTabs>
        </Box>

        <Box sx={separtorStyles}></Box>

        <Box sx={tabPanelContainerStyles}>
          <WmvTabPanel<CustomerInformationTab>
            activeTab={activeTab}
            currentTab={CustomerInformationTab.CustomerDetails}
            sx={customerInfoTabPanelStyles}
          >
            <CustomerPersonalDetails subjectClaim={userInfo.subjectClaim} onUpdateUserDetails={handleUpdateUserDetails} />
          </WmvTabPanel>

          <WmvTabPanel<CustomerInformationTab>
            activeTab={activeTab}
            currentTab={CustomerInformationTab.TripHistory}
            sx={customerInfoTabPanelStyles}
          >
            <UserTrips userId={userInfo.subjectClaim} />
          </WmvTabPanel>

          <WmvTabPanel<CustomerInformationTab>
            activeTab={activeTab}
            currentTab={CustomerInformationTab.Promotions}
            sx={customerInfoTabPanelStyles}
          >
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Placeholder />
            </Box>
          </WmvTabPanel>

          <WmvTabPanel<CustomerInformationTab>
            activeTab={activeTab}
            currentTab={CustomerInformationTab.Budget}
            sx={customerInfoTabPanelStyles}
          >
            <CustomerBudgetInformation />
          </WmvTabPanel>

          <WmvTabPanel<CustomerInformationTab> activeTab={activeTab} currentTab={CustomerInformationTab.Tickets} sx={{ height: '100%' }}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Placeholder />
            </Box>
          </WmvTabPanel>

          <WmvTabPanel<CustomerInformationTab>
            activeTab={activeTab}
            currentTab={CustomerInformationTab.DriverLicence}
            sx={customerInfoTabPanelStyles}
          >
            <UserLicenceInformation subjectClaim={userInfo.subjectClaim} />
          </WmvTabPanel>
        </Box>

        <CloseButton aria-label="close" onClick={() => onClose()} disableRipple>
          {CustomIcon('CloseIcon', theme.palette.white.main, '32px')}
        </CloseButton>
      </CustomerModal>
    </Modal>
  );

  function handleUpdateUserDetails(updatedUserDetails: CustomerDetailsType) {
    setChildUserDetails(updatedUserDetails);
  }

  function handleCustomerInfoTabChange(tab: CustomerInformationTab) {
    setActiveTab(tab);
  }
}

const CustomerModal = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  width: '70%',
  height: '90%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: theme.palette.tertiary.main,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '-40px',
  right: '-40px',
}));

export default CustomerInformationModal;
