import { useCallback, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../components/LoadingAndErrorWithRetryAndNoResults';
import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { VehiclePricingDto } from '../../../services/vehicle-fee-pricing';
import { ReactTable } from '../../../wmv-components';

import { useVehiclePricingDispatch, useVehiclePricingState, VehiclePricingActionType } from './contexts';
import { VehiclePricingDetailsActionMode } from './helpers';
import { useFetchVehiclePricingList, useMakeVehiclePricingTableColumns } from './hooks';
import { customRowHoverStyle } from './styles';

export const VehiclePricingTableWithLoadingOrNoContentMessage = () => {
  const vehiclePricingTableColumns = useMakeVehiclePricingTableColumns();
  const vehiclePricingState = useVehiclePricingState();
  const vehiclePricingDispatch = useVehiclePricingDispatch()!;
  const fetchVehicleFeePricingListAndUpdateState = useFetchVehiclePricingList();

  useEffect(() => {
    (async () => {
      await fetchVehicleFeePricingListAndUpdateState();
    })();
  }, [fetchVehicleFeePricingListAndUpdateState]);

  const handleTableRowClick = useCallback(
    function handleTableRowClick(vehicleFeePricing: VehiclePricingDto) {
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
        payload: vehicleFeePricing.id,
      });
      if (vehicleFeePricing.isStateChangeInProgress()) {
        vehiclePricingDispatch({
          type: VehiclePricingActionType.UpdateActionMode,
          payload: VehiclePricingDetailsActionMode.ViewInProgressPricing,
        });
      }
      if (vehicleFeePricing.isStateChangeFailed()) {
        vehiclePricingDispatch({
          type: VehiclePricingActionType.UpdateActionMode,
          payload: VehiclePricingDetailsActionMode.ViewFailedPricing,
        });
      }
      if (vehicleFeePricing.isStateChangeSuccessful()) {
        vehiclePricingDispatch({
          type: VehiclePricingActionType.UpdateActionMode,
          payload: VehiclePricingDetailsActionMode.ViewActivePricing,
        });
      }
    },
    [vehiclePricingDispatch],
  );

  if (isEmpty(vehiclePricingState.list)) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        loading={vehiclePricingState.listStatus.isPending()}
        noResults={vehiclePricingState.listStatus.isSucceeded()}
        error={vehiclePricingState.listStatus.isFailed()}
        onRetry={fetchVehicleFeePricingListAndUpdateState}
        baseTranslationKey="vehicleFeePricingList"
        style={{ height: 'calc(100vh - 172px)', backgroundColor: theme.palette.tertiary.main }}
      />
    );
  }
  return (
    <ReactTable
      columns={vehiclePricingTableColumns}
      data={vehiclePricingState.list}
      onRowClickHandler={handleTableRowClick}
      tableContainerStyle={{ height: `100%` }}
      customRowHoverStyle={customRowHoverStyle}
    />
  );
};
