import { Box, Stack, TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { NumberInput, NumberInputChangeHandler } from '../../../wmv-components';

import { useIsViewingActivePricingDetails } from './contexts';
import { disabledVehiclePricingInputStyles } from './styles';

export function VehiclePricingInputSection({
  activeValue,
  activeFieldName,
  newValue,
  newValueFieldName,
  onChange,
  error = false,
  helperText = '',
  disabled = false,
  formatInputComponent = null,
  sectionTitle,
}: VehiclePricingInputProps) {
  const isViewingActivePricingDetails = useIsViewingActivePricingDetails();
  const { formatMessage, formatNumber } = useIntl();

  return (
    <Box width="100%">
      <Typography variant="bodyMediumBold" component="div" mb={1}>
        {sectionTitle}
        <Typography component="span" variant="bodyMedium" ml={1}>
          (EUR)
        </Typography>
      </Typography>
      <Box mt={2}>
        {isViewingActivePricingDetails ? (
          <TextField
            value={formatNumber(activeValue)}
            label={formatMessage({ id: 'common.currentValue' })}
            name={activeFieldName}
            sx={disabledVehiclePricingInputStyles}
            InputProps={{
              inputComponent: formatInputComponent as any,
            }}
            fullWidth
            disabled
          />
        ) : (
          <Stack spacing={3} direction="row">
            <TextField
              value={formatNumber(activeValue)}
              label={formatMessage({ id: 'common.currentValue' })}
              name={activeFieldName}
              sx={disabledVehiclePricingInputStyles}
              InputProps={{
                inputComponent: formatInputComponent as any,
              }}
              fullWidth
              disabled
            />
            <NumberInput
              name={newValueFieldName}
              value={newValue}
              onChange={onChange}
              label={formatMessage({ id: 'common.enterNewValue' })}
              dataTestId="newPausedMinutesFee"
              disabled={disabled}
              error={error}
              helperText={helperText}
              formatInputComponent={formatInputComponent}
              formatAsCents
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
}

interface VehiclePricingInputProps {
  activeValue: number;
  activeFieldName: string;
  newValue: number;
  newValueFieldName: string;
  onChange: NumberInputChangeHandler;
  error?: boolean;
  helperText?: string | null;
  disabled?: boolean;
  sectionTitle: string;
  formatInputComponent?: any;
}
