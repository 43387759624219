import { useState, useEffect } from 'react';

import { UserTripDetailsResponse } from '../../models';
import { getUserTripDetails } from '../../utils/queries';

interface UseTripDetailsProps {
  tripId: BigInt;
}

export function useUserTripDetails({ tripId }: UseTripDetailsProps) {
  const [tripDetailsData, setTripDetailsData] = useState<UserTripDetailsResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchTripDetails = async () => {
    setLoading(true);
    setError(false);
    setTripDetailsData(null);
    try {
      const data = await getUserTripDetails(tripId);
      if (tripId && data.id) {
        setTripDetailsData(data);
      } else {
        setTripDetailsData(null);
      }
    } catch (err) {
      setError(true);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchTripDetails();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  return { tripDetailsData, loading, error, refetch: fetchTripDetails };
}
