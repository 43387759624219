import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import trim from 'lodash/trim';

import { ZoneResponse, ZoneRequest, ZoneStatus } from '../../models';
import {
  deleteZone as deleteZoneApi,
  updateZone as updateZoneApi,
  createZone,
  fetchZonesByStatus,
  fetchAllZones,
} from '../../utils/queries';

import { AddZoneRequestPayload, DeleteError, UpdateZoneActionPayload, ZoneConfig, ZoneServerBadRequestResponseBody } from './models';

export const resetErrorAction = createAction<'updateZone' | 'createZone' | 'deleteZone'>('zones/resetError');

const getZoneRequestPayload = (zoneConfig: ZoneConfig, geoJson: GeoJSON.Feature): ZoneRequest => {
  const { name, type, indefiniteZone, startDate, endDate, startTime, endTime, daysOfWeek, applicableVehicleTypes } = zoneConfig;
  return {
    name: trim(name),
    type,
    representsMobilityStation: false, //FIXME: This will come from form input once we will have UI
    activeOn: {
      dateRange: {
        openEnded: indefiniteZone,
        startInclusive: startDate!.formatAsISODateString(),
        endInclusive: endDate?.formatAsISODateString(),
      },
      timeWindow: {
        startInclusive: startTime!.formatAsTimeWithoutSeconds(),
        endExclusive: endTime!.formatAsTimeWithoutSeconds(),
      },
      daysOfWeek: [...daysOfWeek],
    },
    applicableVehicleTypes,
    geoJson: {
      type: geoJson.type,
      properties: geoJson.properties,
      geometry: geoJson.geometry,
    },
  };
};

export const createZoneAsyncThunkAction = createAsyncThunk<
  ZoneResponse,
  AddZoneRequestPayload,
  {
    rejectValue: ZoneServerBadRequestResponseBody | null;
  }
>('zones/create', async ({ config, geoJson }, { dispatch, rejectWithValue }) => {
  try {
    const zone = getZoneRequestPayload(config, geoJson);
    const resp = await createZone(zone);
    return resp;
  } catch (err: any) {
    console.error(err);
    let error: AxiosError<ZoneServerBadRequestResponseBody> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updateZoneAsyncThunkAction = createAsyncThunk<
  ZoneResponse,
  UpdateZoneActionPayload,
  {
    rejectValue: ZoneServerBadRequestResponseBody | null;
  }
>('zones/update', async ({ zoneId, config, geoJson }, { dispatch, rejectWithValue }) => {
  try {
    const zone = getZoneRequestPayload(config, geoJson);
    return await updateZoneApi(zoneId, zone);
  } catch (err: any) {
    console.error(err);
    let error: AxiosError<ZoneServerBadRequestResponseBody> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteZoneAsyncThunkAction = createAsyncThunk<
  ZoneResponse,
  number,
  {
    rejectValue: DeleteError | null;
  }
>('zones/delete', async (zoneId, { dispatch, rejectWithValue }) => {
  try {
    return await deleteZoneApi(zoneId);
  } catch (err: any) {
    console.error(err);
    let error: AxiosError<DeleteError> = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getAllZonesAsyncThunkAction = createAsyncThunk<ZoneResponse[], void>('zones/getAll', async (status) => {
  try {
    return (await fetchAllZones()) || [];
  } catch (err) {
    console.error(err);
    // TODO We cant eat exceptions. Throw them so appropriate handler would deal with it
    return [];
  }
});

export const getPublishedZonesAsyncThunkAction = createAsyncThunk<ZoneResponse[], void, { rejectValue: any }>(
  'zones/getPublishedZones',
  async (_, { rejectWithValue }) => {
    try {
      return (await fetchZonesByStatus(ZoneStatus.Published)) || [];
    } catch (err: any) {
      console.error(err);
      let error: AxiosError<any> = err;
      if (!error.response) {
        throw err;
      }
      // TODO We cant eat exceptions. Throw them so appropriate handler would deal with it
      return rejectWithValue(error.response.data);
    }
  },
);
