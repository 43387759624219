import { EnumWithStringValue } from '../../../utils/EnumUtils';
import { intl } from '../../../utils/intl';

export enum DepositFrequency {
  Monthly = 'MONTHLY',
}

interface DepositFrequencyUnderivedMetadata {
  displayText: string;
}

export namespace DepositFrequencyHelper {
  const { formatMessage } = intl;
  const depositFrequencyUnderivedMetadata = new Map<DepositFrequency, DepositFrequencyUnderivedMetadata>([
    [DepositFrequency.Monthly, { displayText: formatMessage({ id: 'budgetPlan.depositFrequency.monthly' }) }],
  ]);

  export function metadata(depositFrequency: DepositFrequency): DepositFrequencyUnderivedMetadata {
    const underivedMetadata = depositFrequencyUnderivedMetadata.get(depositFrequency);
    if (!underivedMetadata) {
      throw Error(`
            Specified Deposit Frequency: ${toString(depositFrequency)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(depositFrequency: DepositFrequency): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(DepositFrequency, depositFrequency);
  }
}
