import { Fragment } from 'react';

import { Grid, Typography, Divider, MenuList, MenuItem, useTheme } from '@mui/material';

import { ListItemConfig } from '../../models';

import {
  dividerStyles,
  tabsContainerStyles,
  tabsHeaderStyles,
  tabsHeaderSubtitleStyles,
  tabsListStyles,
  tabItemStyles,
} from './Tabs.styles';

export const Tabs = ({ items, active, title, subTitle, onChange, disabledItems = [] }: TabsProps) => {
  const theme = useTheme();

  return (
    <Grid container item direction="column" sx={tabsContainerStyles}>
      {title && (
        <Fragment>
          <Grid container direction="row" sx={tabsHeaderStyles}>
            <Grid container direction="column" sx={{ px: 2 }}>
              <Typography variant="bodyLargeBold" color={theme.palette.dark.main} sx={{ ws: 'nowrap' }}>
                {title}
              </Typography>
              {subTitle && (
                <Typography variant="bodyMedium" color={theme.palette.dark.shade76} sx={tabsHeaderSubtitleStyles}>
                  {subTitle}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider orientation="horizontal" flexItem sx={dividerStyles}></Divider>
        </Fragment>
      )}

      <MenuList sx={tabsListStyles}>
        {items.map((item, index) => (
          <MenuItem
            disableRipple
            sx={tabItemStyles}
            key={index}
            selected={item.value === active}
            onClick={() => onChange(item.value)}
            disabled={disabledItems.includes(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Grid>
  );
};

export interface TabsProps {
  items: ListItemConfig<any>[]; // TODO: please remove this any
  disabledItems?: any[];
  active: any;
  onChange: (item: any) => void;
  title?: string;
  subTitle?: string;
}
