import { memo, useEffect, useState } from 'react';

import { isArray } from '@apollo/client/utilities';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Button, Popover, Typography } from '@mui/material';
import type { PopoverOrigin } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import type { Dayjs } from 'dayjs';
import PopupState, { bindPopover, bindToggle } from 'material-ui-popup-state';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import { useIntl } from 'react-intl';

import { TimeWindow } from '../../models';
import { PredicatePopoverAnchorButton } from '../../pages/Zones/ZonePlanningMode';
import {
  DEFAULT_TIME_FORMAT,
  END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
  SECONDS_IN_A_MINUTE,
  START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
} from '../../utils/constants';
import { StyledSlider } from '../StyledSlider';
import { TranslatedText } from '../TranslatedText';

import { timePickerStyles } from './styles';

const defaultTimeWindow: TimeWindow = {
  startSecondsFromBeginningOfDay: START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
  endSecondsFromBeginningOfDay: END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
};

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const anchorOrigin: PopoverOrigin = {
  vertical: -10,
  horizontal: 'left',
};

interface TimeWindowPickerProps {
  placeholderTranslationKey?: string;
  initialValue: TimeWindow;
  onChange: (value: TimeWindow) => void;
}

export const TimeWindowPicker = memo(
  ({ placeholderTranslationKey = 'input.timeWindow.placeholder', initialValue, onChange }: TimeWindowPickerProps) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [timeWindow, setTimeWindow] = useState<TimeWindow>(initialValue || defaultTimeWindow);

    useEffect(() => {
      setTimeWindow(initialValue);
    }, [initialValue]);

    const { startSecondsFromBeginningOfDay, endSecondsFromBeginningOfDay } = initialValue;
    const startTimeFormatted = startSecondsFromBeginningOfDay.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutSeconds();
    const endTimeFormatted = endSecondsFromBeginningOfDay.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutSeconds();

    const resetTime = () => {
      setTimeWindow(defaultTimeWindow);
    };

    const handleStartTimeChange = (value: Dayjs | null) => {
      setTimeWindow((prevTime) => ({
        ...prevTime,
        startSecondsFromBeginningOfDay: value?.secondsFromBeginningOfDay()!,
      }));
    };

    const handleEndTimeChange = (value: Dayjs | null) => {
      setTimeWindow((prevTime) => ({
        ...prevTime,
        endSecondsFromBeginningOfDay: value?.secondsFromBeginningOfDay()!,
      }));
    };

    const handleOutsideClick = (popupState: PopupStateProps) => {
      const startNotChanged = timeWindow.startSecondsFromBeginningOfDay === initialValue.startSecondsFromBeginningOfDay;
      const endNotChanged = timeWindow.endSecondsFromBeginningOfDay === initialValue.endSecondsFromBeginningOfDay;
      if (startNotChanged && endNotChanged) {
        popupState.setOpen(false);
      } else {
        popupState.setOpen(true);
      }
    };

    let popupToggleButtonLabel: string;
    if (initialValue) {
      popupToggleButtonLabel = `${startTimeFormatted} – ${endTimeFormatted.asDayjsTime().formatAsTimeWithoutSeconds()}`;
    } else {
      popupToggleButtonLabel = formatMessage({ id: placeholderTranslationKey });
    }

    return (
      <Box display="flex" gap={1} alignItems="center">
        <PopupState variant="popover">
          {(popupState) => (
            <>
              <PredicatePopoverAnchorButton
                endIcon={popupState.isOpen ? <AccessTimeOutlinedIcon color="secondary" /> : <AccessTimeOutlinedIcon color="action" />}
                label={popupToggleButtonLabel}
                labelFontVariant="bodySmall"
                {...bindToggle(popupState)}
              />
              <Popover anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} {...bindPopover(popupState)}>
                <ClickAwayListener onClickAway={() => handleOutsideClick(popupState)}>
                  <Box sx={{ minWidth: '500px', p: 2 }}>
                    <Box>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          bgcolor={theme.palette.baseLight.base4}
                          p={1}
                          borderRadius={2}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <TranslatedText translationKey="planningPreview.timeRangeSelection.title" />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                value={timeWindow?.startSecondsFromBeginningOfDay.parseSecondsSinceBeginningOfDay()}
                                maxTime={timeWindow?.endSecondsFromBeginningOfDay.parseSecondsSinceBeginningOfDay()}
                                disableOpenPicker
                                sx={timePickerStyles}
                                ampm={false}
                                onChange={handleStartTimeChange}
                                format={DEFAULT_TIME_FORMAT}
                              />
                              <TimePicker
                                value={timeWindow?.endSecondsFromBeginningOfDay.parseSecondsSinceBeginningOfDay()}
                                minTime={timeWindow?.startSecondsFromBeginningOfDay.parseSecondsSinceBeginningOfDay()}
                                disableOpenPicker
                                sx={timePickerStyles}
                                ampm={false}
                                onChange={handleEndTimeChange}
                                format={DEFAULT_TIME_FORMAT}
                              />
                            </LocalizationProvider>
                            <Typography> {formatMessage({ id: 'common.dash' })} </Typography>
                          </Box>
                          <Box>
                            <Button size="small" variant="outlined" color="primary" disableRipple disableElevation onClick={resetTime}>
                              <TranslatedText variant="bodyMedium" translationKey="planningPreview.timeRangeSelection.resetMessage" />
                            </Button>
                          </Box>
                        </Box>
                        <Box px={1}>
                          <StyledSlider
                            value={[timeWindow.startSecondsFromBeginningOfDay, timeWindow.endSecondsFromBeginningOfDay]}
                            name="interval"
                            step={SECONDS_IN_A_MINUTE}
                            min={0}
                            max={END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY}
                            marks
                            onChange={(e: any, value: any) => {
                              const [start, end] = isArray(value) ? value : [0, value];
                              setTimeWindow({
                                startSecondsFromBeginningOfDay: start,
                                endSecondsFromBeginningOfDay: end,
                              });
                            }}
                          />
                          <Box px={1} display="flex" justifyContent="space-between">
                            <Typography>{formatMessage({ id: 'planningMode.timeSlider.startOfDay' })}</Typography>
                            <Typography>{formatMessage({ id: 'planningMode.timeSlider.midOfDay' })}</Typography>
                            <Typography>{formatMessage({ id: 'planningMode.timeSlider.endOfDay' })}</Typography>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" gap={1} pt={2}>
                          <Button
                            size="medium"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setTimeWindow(initialValue);
                              popupState.close();
                            }}
                          >
                            {formatMessage({ id: 'actions.cancel' })}
                          </Button>
                          <Button
                            disabled={timeWindow.endSecondsFromBeginningOfDay < timeWindow.startSecondsFromBeginningOfDay}
                            size="medium"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              onChange(timeWindow);
                              popupState.close();
                            }}
                          >
                            {formatMessage({ id: 'actions.apply' })}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Popover>
            </>
          )}
        </PopupState>
      </Box>
    );
  },
);

TimeWindowPicker.displayName = 'TimeRangePicker';
