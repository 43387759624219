import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import { LoadingStatus } from '../../../../models';
import { ApiStatusService } from '../../../../services/ui';
import { VehiclePricingDto } from '../../../../services/vehicle-fee-pricing';
import { VehiclePricingDetailsActionMode } from '../helpers';

const vehiclePricingInitialState: VehiclePricingState = {
  list: [],
  listStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
  selectedFeePricingId: null,
  actionMode: VehiclePricingDetailsActionMode.ViewActivePricing,
};

const VehicleFeePricingContext = createContext<VehiclePricingState>(vehiclePricingInitialState);
const VehicleFeePricingDispatchContext = createContext<Dispatch<VehiclePricingAction> | null>(null);

export function VehiclePricingStateProvider({ children }: VehiclePricingStateProviderProps) {
  const [vehicleFeePricingState, dispatch] = useReducer(vehiclePricingReducer, vehiclePricingInitialState);

  return (
    <VehicleFeePricingContext.Provider value={vehicleFeePricingState!}>
      <VehicleFeePricingDispatchContext.Provider value={dispatch}>{children}</VehicleFeePricingDispatchContext.Provider>
    </VehicleFeePricingContext.Provider>
  );
}

export function vehiclePricingReducer(vehicleFeePricingState: VehiclePricingState, action: VehiclePricingAction) {
  switch (action.type) {
    case VehiclePricingActionType.VehiclePricingListFetchStart:
      return {
        ...vehicleFeePricingState,
        listStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
        list: [],
      };
    case VehiclePricingActionType.VehiclePricingListFetchSuccess:
      return {
        ...vehicleFeePricingState,
        listStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
        list: action.payload,
      };
    case VehiclePricingActionType.VehiclePricingListFetchFailure:
      return {
        ...vehicleFeePricingState,
        listStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
      };
    case VehiclePricingActionType.UpdateSelectedVehicleFeePricingId:
      return {
        ...vehicleFeePricingState,
        selectedFeePricingId: action.payload,
      };
    case VehiclePricingActionType.UpdateActionMode:
      return {
        ...vehicleFeePricingState,
        actionMode: action.payload,
      };
    default:
      return vehiclePricingInitialState;
  }
}

export function useVehiclePricingState() {
  return useContext(VehicleFeePricingContext);
}

export function useVehiclePricingDispatch() {
  return useContext(VehicleFeePricingDispatchContext);
}

export function useSelectedVehiclePricingPricingId(): number | null {
  const vehiclePricingState = useVehiclePricingState();
  return vehiclePricingState.selectedFeePricingId;
}

export function useIsViewingActivePricingDetails() {
  const vehiclePricingState = useVehiclePricingState();
  return vehiclePricingState.actionMode === VehiclePricingDetailsActionMode.ViewActivePricing;
}

export function useIsEditingActivePricingDetails() {
  const vehiclePricingState = useVehiclePricingState();
  return vehiclePricingState.actionMode === VehiclePricingDetailsActionMode.EditActivePricing;
}

export function useIsViewingFailedPricingDetails() {
  const vehiclePricingState = useVehiclePricingState();
  return vehiclePricingState.actionMode === VehiclePricingDetailsActionMode.ViewFailedPricing;
}

export function useIsViewingInProgressPricingDetails() {
  const vehiclePricingState = useVehiclePricingState();
  return vehiclePricingState.actionMode === VehiclePricingDetailsActionMode.ViewInProgressPricing;
}

interface VehiclePricingStateProviderProps {
  children: ReactNode;
}

interface VehiclePricingState {
  list: Array<VehiclePricingDto>;
  listStatus: ApiStatusService;
  selectedFeePricingId: number | null;
  actionMode: VehiclePricingDetailsActionMode;
}

export enum VehiclePricingActionType {
  VehiclePricingListFetchStart = 'VEHICLE_PRICING_LIST_FETCH_START',
  VehiclePricingListFetchSuccess = 'VEHICLE_PRICING_LIST_FETCH_SUCCESS',
  VehiclePricingListFetchFailure = 'VEHICLE_PRICING_LIST_FETCH_FAILURE',
  UpdateSelectedVehicleFeePricingId = 'UPDATE_SELECTED_VEHICLE_FEE_PRICING_ID',
  UpdateActionMode = 'UPDATE_ACTION_MODE',
}

export type VehiclePricingAction =
  | {
      type: VehiclePricingActionType.VehiclePricingListFetchStart;
    }
  | {
      type: VehiclePricingActionType.VehiclePricingListFetchSuccess;
      payload: VehiclePricingDto[];
    }
  | {
      type: VehiclePricingActionType.VehiclePricingListFetchFailure;
    }
  | {
      type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId;
      payload: number | null;
    }
  | {
      type: VehiclePricingActionType.UpdateActionMode;
      payload: VehiclePricingDetailsActionMode;
    };
