export const routes = {
  login: '/login', // this page doesn't exist anymore, but its still a logical redirect
  // and might also be used again in future
  dashboard: '/dashboard',
  communication: '/communication',
  zones: '/zones',
  incidents: '/incidents',
  analytics: '/analytics',
  operator: '/operator',
  user: '/user',
  support: '/support',
  maas: '/maas',
  notifications: '/notifications',

  customerDatabase: '/maas/customer-database',
  serviceManagement: '/maas/service-management',
  productManagement: '/maas/product-management',
  billingStatements: '/maas/billing-statements',

  budgetPlan: '/operator/budget-plan',
  fleetManagement: '/operator/fleet-management',
  vehicleFeePricing: '/operator/vehicle-fee-pricing',
};

export const menus = [
  {
    name: 'menu.dashboard',
    route: routes.dashboard,
  },
  { name: 'menu.zones', route: routes.zones },
  {
    name: 'menu.maas',
    route: routes.maas,
    subMenus: [
      { name: 'menu.submenu.customerDatabase', route: routes.customerDatabase },
      { name: 'menu.submenu.serviceManagement', route: routes.serviceManagement },
      { name: 'menu.submenu.productManagement', route: routes.productManagement },
      { name: 'menu.submenu.billingStatements', route: routes.billingStatements },
    ],
  },
  { name: 'menu.analytics', route: routes.analytics },
  {
    name: 'menu.operator',
    route: routes.operator,
    subMenus: [
      { name: 'menu.submenu.operator.fleetManagement', route: routes.fleetManagement },
      { name: 'menu.submenu.operator.budgetPlan', route: routes.budgetPlan },
      { name: 'common.vehicleFeePricing', route: routes.vehicleFeePricing },
    ],
  },
  { name: 'menu.notifications', route: routes.notifications },
];

export default routes;
