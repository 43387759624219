import { LoadingStatus } from '../../models';
import { BudgetPlanSummaryCumDetailsResponse, UpdateBudgetPlanStatusRequest, UpdateBudgetPlanRequest } from '../../models/budget-plan';

export interface BudgetPlanApiError {
  title: string;
  status: number;
  detail: string;
}

export interface BudgetPlanApiLoadingStatus {
  listPlans: LoadingStatus;
  addPlan: LoadingStatus;
  updatePlan: LoadingStatus;
  transitionFailureRetry: LoadingStatus;
}

export interface BudgetPlanApiErrorState {
  listPlans: BudgetPlanApiError | null;
  addPlan: BudgetPlanApiError | null;
  updatePlan: BudgetPlanApiError | null;
  transitionFailureRetry: BudgetPlanApiError | null;
}

export type BudgetPlanReduxActionPayloadType = 'listPlans' | 'addPlan' | 'updatePlan' | 'transitionFailureRetry';

export interface BudgetPlanReduxState {
  entities: BudgetPlanSummaryCumDetailsResponse[];
  apiStatus: BudgetPlanApiLoadingStatus;
  apiError: BudgetPlanApiErrorState;
  ui: {
    planActionMode: BudgetPlanActionMode;
  };
}

export enum BudgetPlanActionMode {
  Idle = 'idle',
  Add = 'add',
  View = 'view',
  Edit = 'edit',
}

export interface UpdateBudgetPlanActionPayload {
  id: number;
  payload: UpdateBudgetPlanRequest;
}

export interface UpdateBudgetPlanStatusActionPayload {
  id: number;
  payload: UpdateBudgetPlanStatusRequest;
}
