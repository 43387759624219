import { useMemo } from 'react';

import dayjs from 'dayjs';

import { DayOfWeekHelper, ModeType, VehicleType, ZoneType } from '../models';
import { Mode, Zone, ZonePreviewPredicates } from '../services/zone';
import {
  END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
  MAX_DEFAULT_DATE,
  MIN_DEFAULT_DATE,
  START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
} from '../utils/constants';

export const useFilterZonesByPredicates = (zones: Zone[], predicates: UseFilterZonesByPredicates): Zone[] => {
  const { vehicleTypes, zoneTypes } = predicates;

  const filteredZones = useMemo(() => {
    const zonePreviewPredicates = new ZonePreviewPredicates(
      new Mode(ModeType.WideInterval),
      new Set(zoneTypes),
      MIN_DEFAULT_DATE.asDayjsDate(),
      MAX_DEFAULT_DATE.asDayjsDate(),
      START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
      END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
      DayOfWeekHelper.allDaysOfWeek(),
      new Set(vehicleTypes),
    );
    const publishedZonesFromNowOnwards = zonePreviewPredicates.provideApplicableZones(dayjs.now().toEpochMillis(), zones);
    return publishedZonesFromNowOnwards;
  }, [vehicleTypes, zoneTypes, zones]);

  return filteredZones;
};

interface UseFilterZonesByPredicates {
  vehicleTypes: VehicleType[];
  zoneTypes: ZoneType[];
}
