import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import MultipleSelectField from '../../components/FormFields/MultipleSelectField';
import MapFiltersAndZoneTypeInfoControls from '../../components/Map/ZoneControls';
import { MapTableView } from '../../components/MapTableView';
import {
  useApplicableVehiclesForFilters,
  useEvaluateZonesVisibilityWithTheTime,
  useFilterZonesByPredicates,
  useMapFilterChange,
} from '../../hooks';
import { FloatingButtonType, TableColumnType, graphqlVehicleTypeToVehicleType } from '../../models';
import { AppDispatch, loadingVehiclesErrorSelector } from '../../state';
import { filterProfilesSelector, selectedFilterProfileSelector } from '../../state';
import { allVehiclesSelector, loadingVehiclesSelector } from '../../state';
import { getPublishedZonesAsyncThunkAction, publishedZonesSelector } from '../../state/zones-management';
import { HEADER_TABLE_GAP_HEIGHT, TABLE_HEIGHT, TABLE_HEIGHT_NO_SECONDARY, analyticsTableAllColumns } from '../../utils/constants';
import { intl } from '../../utils/intl';
import { DataTable, FloatingBox, SideDrawer, WmvButtonGroup } from '../../wmv-components';

import TableFilters from './TableFilters';

const switchButtonConfig = [
  {
    text: intl.formatMessage({ id: 'map.mapMode' }),
    switchModeButtonId: FloatingButtonType.ViewMode,
  },
  {
    text: intl.formatMessage({ id: 'map.listMode' }),
    switchModeButtonId: FloatingButtonType.ListMode,
  },
];

const Analytics = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [columns, setColumns] = useState<TableColumnType[]>([]);
  const [activeView, setActiveView] = useState(FloatingButtonType.ViewMode);

  const publishedZones = useSelector(publishedZonesSelector);
  const filterProfiles = useSelector(filterProfilesSelector);
  const selectedFilterProfile = useSelector(selectedFilterProfileSelector);

  const loadingVehicles = useSelector(loadingVehiclesSelector);
  const loadingVehiclesError = useSelector(loadingVehiclesErrorSelector);
  const allVehicles = useSelector(allVehiclesSelector);

  useEffect(() => {
    dispatch(getPublishedZonesAsyncThunkAction());
  }, [dispatch]);

  const zonesApplicableAtCurrentTime = useEvaluateZonesVisibilityWithTheTime(publishedZones);
  const { filters, anyFilterApplied, handleFilterChange, resetFilters } = useMapFilterChange();
  const filteredVehicles = useApplicableVehiclesForFilters(allVehicles, filters);
  const filteredZones = useFilterZonesByPredicates(zonesApplicableAtCurrentTime, {
    zoneTypes: filters.zoneTypes,
    vehicleTypes: filters.vehicleTypes.map((graphqlVehicleType) => graphqlVehicleTypeToVehicleType.get(graphqlVehicleType)!),
  });

  useEffect(() => {
    const filterProfile = filterProfiles.find((fp) => fp.filterName === selectedFilterProfile);

    if (filterProfile) {
      const newColumns = filterProfile.visibleColumns;
      sortAndUpdateColumns(newColumns);
    }
  }, [filterProfiles, selectedFilterProfile]);

  const handleOpenDrawer = (v: boolean) => {
    setOpenDrawer(v);
  };

  const getPreFilter = (columnAccessor: string) => {
    const columnFilter = filterProfiles.find((fp) => fp.filterName === selectedFilterProfile)?.columnFilters;
    if (columnFilter && columnFilter.length > 0) {
      const preFilterValue = columnFilter.find((cf) => cf.column.accessor === columnAccessor);
      if (preFilterValue) {
        return preFilterValue.value;
      }
    }
    return undefined;
  };

  const sortAndUpdateColumns = (newColumns: TableColumnType[]) => {
    // sort Columns based on id.
    const newA = [...newColumns].sort((a, b) => a.id - b.id);
    setColumns(newA);
  };

  const handleSwitchModeButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, switchModeButtonId: string) => {
    setActiveView(switchModeButtonId as FloatingButtonType);
  };

  const isViewMode = activeView === FloatingButtonType.ViewMode;
  return (
    <Box>
      <FloatingBox boxStyling={{ top: '104px' }}>
        <WmvButtonGroup config={switchButtonConfig} activeButtonId={activeView} onClick={handleSwitchModeButtonClick} />
        {isViewMode && (
          <MapFiltersAndZoneTypeInfoControls filters={filters} isAnyFilterApplied={anyFilterApplied} onFilterChange={handleFilterChange} />
        )}
      </FloatingBox>

      {!isViewMode ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              height: HEADER_TABLE_GAP_HEIGHT,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: theme.spacing(2),
            }}
          >
            <MultipleSelectField
              value={columns}
              options={analyticsTableAllColumns}
              outlineLabel={intl.formatMessage({ id: 'analytics.filter.columns.showHide' })}
              setValue={sortAndUpdateColumns}
            />
          </Box>
          <Box>
            <DataTable
              getPreFilter={getPreFilter}
              filterChangeDependency={[filterProfiles, selectedFilterProfile]}
              data={allVehicles}
              columns={columns}
              loading={loadingVehicles}
              rowCount={20}
              tableContainerStyle={{
                height: TABLE_HEIGHT,
                width: `calc(100vw - (${openDrawer ? '480px' : '100px'}))`,
                overflow: 'auto',
              }}
            />
            <SideDrawer open={openDrawer}>
              <TableFilters open={openDrawer} handleOpenDrawer={handleOpenDrawer} style={{ height: TABLE_HEIGHT }} />
            </SideDrawer>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: TABLE_HEIGHT_NO_SECONDARY }}>
          <MapTableView
            zones={filteredZones}
            vehicles={filteredVehicles}
            filters={filters}
            isAnyFilterApplied={anyFilterApplied}
            onFiltersReset={resetFilters}
            loadingVehicles={loadingVehicles}
            loadingVehiclesError={loadingVehiclesError}
          />
        </Box>
      )}
    </Box>
  );
};

export default Analytics;
