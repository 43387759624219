import { useState } from 'react';

import { CustomerDetailsType, SearchUsersQueryParams } from '../../../models';
import { searchUsers } from '../../../utils/queries';

import { CustomerDetailsForm } from './CustomerDetailsForm';
import { CustomerDetailsTable } from './CustomerDetailsTable';

export const CustomerDetailsView = () => {
  const [customerDetails, setCustomerDetails] = useState<CustomerDetailsType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [lastSearchParameters, setLastSearchParameters] = useState<SearchUsersQueryParams | null>(null);

  const onSearch = async (searchParameters: SearchUsersQueryParams) => {
    setLoading(true);
    setError(false);
    setNoSearchResults(false);
    const trimmedSearchParameters = {
      firstName: searchParameters.firstName.trim(),
      lastName: searchParameters.lastName.trim(),
      birthDate: searchParameters.birthDate,
    };
    setLastSearchParameters(trimmedSearchParameters);
    setCustomerDetails([]);

    await searchUsers(trimmedSearchParameters)
      .then((res: CustomerDetailsType[]) => {
        if (res && res.length > 0) {
          setCustomerDetails(res);
        }
        if (res.length === 0) {
          setNoSearchResults(true);
        }
      })
      .catch((error) => {
        console.log('API call failed with error:', error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CustomerDetailsForm onSearch={onSearch} loading={loading} />
      <CustomerDetailsTable
        customerDetails={customerDetails}
        loading={loading}
        error={error}
        onRetry={onSearch}
        lastSearchParameters={lastSearchParameters}
        noSearchResults={noSearchResults}
      />
    </>
  );
};
