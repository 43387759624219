import { useEffect, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

export interface TablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const TablePagination = ({ count, page, rowsPerPage, onPageChange, style, children }: TablePaginationProps) => {
  const [pageButtons, setPageButtons] = useState<number[]>([]);
  useEffect(() => {
    const pbs = range(0, Math.floor(count / rowsPerPage), 1);
    setPageButtons(pbs);
  }, [count, rowsPerPage, page]);

  useEffect(() => {
    const lastPageButtonValue = pageButtons[pageButtons.length - 1];
    // console.log(page, lastPageButtonValue);
    if (page > lastPageButtonValue) {
      onPageChange(null, lastPageButtonValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageButtons]);

  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 24px',
        height: 50,
        position: 'static',
        bottom: 0,
        ...style,
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs="auto">
          <Button
            style={{ width: 96, height: 32 }}
            variant="outlined"
            disabled={page === 0}
            onClick={(e) => onPageChange(e, page - 1)}
            startIcon={<ChevronLeftIcon />}
          >
            {formatMessage({ id: 'tablePagination.button.back' })}
          </Button>
        </Grid>
        {pageButtons
          .filter((pb) => [-1, 0, 1, 2].includes(pb - page))
          .map((pb) => (
            <Grid item xs="auto" key={pb}>
              <div
                style={{
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 20,
                  height: 32,
                  backgroundColor: pb === page ? theme.palette.secondary.main : theme.palette.dark.shade4,
                }}
                onClick={(e) => onPageChange(e, pb)}
              >
                {pb}
              </div>
            </Grid>
          ))}
        <Grid item xs="auto">
          <Button
            style={{ width: 96, height: 32 }}
            variant="outlined"
            disabled={page === pageButtons.length - 1}
            onClick={(e) => onPageChange(e, page + 1)}
            endIcon={<ChevronRightIcon />}
          >
            {formatMessage({ id: 'tablePagination.button.next' })}
          </Button>
        </Grid>
        <Grid item xs={true}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default TablePagination;
