import React, { ReactNode, ReactElement } from 'react';

import { FormControl, Select, MenuItem, InputLabel, Box } from '@mui/material';
import type { InputBasePropsSizeOverrides } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import type { SxProps } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import find from 'lodash/find';

const SelectionMenuOption = ({ option, startIcon = null }: SelectionMenuOptionProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {startIcon && <ListItemIcon sx={{ minWidth: 'unset !important', mr: 0.5 }}>{startIcon}</ListItemIcon>}
      <ListItemText sx={{ m: 0 }}>{option.label}</ListItemText>
    </Box>
  );
};

export const SelectionMenu = ({
  size = 'small',
  options,
  onChange,
  value = '',
  label,
  required = true,
  disabled,
  name,
  sx,
  renderValue,
  hasError = false,
  helperText = '',
}: SelectionMenuProps) => {
  return (
    <FormControl sx={sx} error={hasError}>
      <InputLabel size="small" id="menu-label" required>
        {label}
      </InputLabel>
      <Select
        fullWidth
        size={size}
        labelId="menu-label"
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        required={required}
        disabled={disabled}
        renderValue={(value: Value) => {
          const selectedOption = find(options, (option) => option.value.toString() === value.toString())!;
          return renderValue ? (
            renderValue(selectedOption.value)
          ) : (
            <Box display="flex" flexDirection="row" alignItems="center">
              {selectedOption.startIcon && (
                <ListItemIcon sx={{ minWidth: 'unset !important', mr: 0.5 }}>{selectedOption.startIcon}</ListItemIcon>
              )}
              <ListItemText sx={{ m: 0 }}>{selectedOption.label}</ListItemText>
            </Box>
          );
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <SelectionMenuOption option={option} startIcon={option?.startIcon} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export type Value = string | number;
export interface SelectionMenuProps {
  value?: string | number;
  label: string | ReactNode;
  options: SelectionMenuOptionMetadata[];
  onChange: (e: SelectChangeEvent<Value>) => void;
  renderValue?: (selected: Value) => ReactNode;
  required?: boolean;
  disabled?: boolean;
  name: string;
  size?: OverridableStringUnion<'small' | 'medium', InputBasePropsSizeOverrides>;
  sx?: SxProps;
  helperText?: ReactNode;
  hasError?: boolean;
}

export interface SelectionMenuOptionMetadata {
  value: Value;
  label: string | ReactNode;
  startIcon?: ReactNode | ReactElement;
}

export interface SelectionMenuOptionProps {
  option: SelectionMenuOptionMetadata;
  startIcon: ReactNode | ReactElement;
}
