import type { Dayjs, PluginFunc } from 'dayjs';

declare module 'dayjs' {
  interface Dayjs {
    isSameTime(time: Dayjs): boolean;
    isBeforeTime(time: Dayjs): boolean;
    isAfterTime(time: Dayjs): boolean;
    isSameOrBeforeTime(time: Dayjs): boolean;
    isSameOrAfterTime(time: Dayjs): boolean;
  }
}

const timeUtilsPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.isSameTime = function (time: Dayjs): boolean {
    return this.secondsFromBeginningOfDay() === time.secondsFromBeginningOfDay();
  };

  dayjsClass.prototype.isBeforeTime = function (time: Dayjs): boolean {
    return this.secondsFromBeginningOfDay() < time.secondsFromBeginningOfDay();
  };

  dayjsClass.prototype.isAfterTime = function (time: Dayjs): boolean {
    return this.secondsFromBeginningOfDay() > time.secondsFromBeginningOfDay();
  };

  dayjsClass.prototype.isSameOrBeforeTime = function (time: Dayjs): boolean {
    return this.isSameTime(time) || this.isBeforeTime(time);
  };

  dayjsClass.prototype.isSameOrAfterTime = function (time: Dayjs): boolean {
    return this.isSameTime(time) || this.isAfterTime(time);
  };
};

export default timeUtilsPlugin;
