import * as React from 'react';
import { useState } from 'react';

import { ReactComponent as UnlinkIcon } from '../../../../assets/icons/outline/unlink.svg';
import { intl } from '../../../../utils/intl';
import { ReactTable } from '../../../../wmv-components';

import { SlidingConfirmationButton, SlidingConfirmationIconButtonClickPosition } from './SlidingConfirmationButton';
import { tableContainerStyles, unsetBgStyles } from './styles';

export function LinkedMembersList({ memberships }: { memberships: any[] }) {
  return (
    <ReactTable
      data={memberships}
      columns={rows}
      noBorder={true}
      customRowHoverStyle={unsetBgStyles}
      tableContainerStyle={tableContainerStyles}
      sorted={false}
    />
  );
}

const rows = [
  {
    Header: intl.formatMessage({ id: `common.name` }),
    accessor: 'name',
  },
  {
    Header: intl.formatMessage({ id: `common.activationDate` }),
    accessor: 'activationDate',
  },
  {
    Header: '',
    accessor: 'final',
    Cell: ({ row: { original: budgetInfo } }: RowData) => {
      const [toggleUnlinkingButton, setToggleUnlinkingButton] = useState<boolean>(false);

      const handleToggleUnlinkButton = (clickPosition: SlidingConfirmationIconButtonClickPosition) => {
        if (clickPosition === 'iconClick') setToggleUnlinkingButton((prev) => !prev);
        if (clickPosition === 'outsideClick') setToggleUnlinkingButton(false);
      };

      const handleUnlinkingConfirm = () => {
        console.log('Unlinking', budgetInfo.name);
        // Handle Unlinking here
      };
      return (
        <SlidingConfirmationButton
          toggleButton={toggleUnlinkingButton}
          onButtonClick={handleUnlinkingConfirm}
          onIconClick={handleToggleUnlinkButton}
          loading={false}
          confirmationButtonProps={{ variant: 'alert', sx: { mx: 1 } }}
          confirmationButtonText={'common.unlinkMember'.i18nText()}
          icon={<UnlinkIcon />}
          toolTipTitle={'common.removeFromSBA'.i18nText()}
        />
      );
    },
  },
];

interface RowData {
  row: {
    original: any; //TODO: Replace this with actual model interface while implementing contracts
  };
}
