import type { Theme } from '@mui/material/styles';

import { themeOptions } from '../../../init-setup/ThemeOptions';

// PageHeading styles
export const pageHeadingStyles = (theme: Theme) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
});

export const pricingDetailsContentStyles = (theme: Theme) => ({
  width: '680px',
  minHeight: '400px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: 2,
});

export const disabledVehiclePricingInputStyles = (theme: Theme) => ({
  '.MuiInputBase-input': { backgroundColor: theme.palette.baseLight.base4, textAlign: 'center', fontWeight: 'bold' },
});

export const customRowHoverStyle = { backgroundColor: themeOptions.palette.secondary.tint, cursor: 'pointer' };
