import 'react-datepicker/dist/react-datepicker.css';

import { Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import hybridMap from '../../../../../assets/images/hybridMap.svg';
import satelliteMap from '../../../../../assets/images/sateliteMap.svg';
import streetMap from '../../../../../assets/images/streetMap.svg';
import { themeOptions as theme } from '../../../../../init-setup/ThemeOptions';

interface ViewButtonProps {
  handleOverlay: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
  input: string;
  backgroundImage: string;
}

const ViewButton = (props: ViewButtonProps) => {
  const { handleOverlay, backgroundImage, selected, input } = props;
  return (
    <Button
      key="Overlay"
      onClick={handleOverlay}
      disableRipple
      sx={{
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        backgroundImage: `url(${backgroundImage})`,
        opacity: selected ? '1' : '0.5',
        margin: '2.5px 0px',
        padding: '0px',
        border: '2px solid white',
        '&:hover': {
          border: `2px solid ${theme.palette.secondary.dark}`,
          opacity: '1',
        },
        height: '63px',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '0px 0px 6px 6px',
          textAlign: 'left',
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          display: 'flex',
          height: '20px',
        }}
      >
        <Typography
          sx={{
            padding: '2px 2px',
            fontWeight: selected ? 'bold' : 'normal',
          }}
          variant="caption"
          color={selected ? theme.palette.secondary.dark : theme.palette.hexBlack.shade32}
        >
          {input}
        </Typography>
      </div>
    </Button>
  );
};

export interface SetViewComponentProps {
  selectedView: string;
  setSelectedView: (view: string) => void;
}

const SetView = (props: SetViewComponentProps) => {
  const { selectedView, setSelectedView } = props;

  const handleButtonSelect = (selectedView: string) => {
    setSelectedView(selectedView);
  };

  const { formatMessage } = useIntl();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '72px',
          height: '212px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.street' })}
          backgroundImage={streetMap}
          selected={selectedView === 'Street'}
          handleOverlay={() => handleButtonSelect('Street')}
        />
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.satellite' })}
          backgroundImage={satelliteMap}
          selected={selectedView === 'Satellite'}
          handleOverlay={() => handleButtonSelect('Satellite')}
        />
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.hybrid' })}
          backgroundImage={hybridMap}
          selected={selectedView === 'Hybrid'}
          handleOverlay={() => handleButtonSelect('Hybrid')}
        />
      </div>
    </>
  );
};
export default SetView;
