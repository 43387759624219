import { createIntl, createIntlCache } from 'react-intl';

import germanTranslations from '../configs/translations/de.json';
import englishTranslations from '../configs/translations/en.json';
import { AvailableLanguage } from '../state/common';

// TODO: This will break locale switch, because we are calling this outside react context, needs to be fixed.
const cache = createIntlCache();
const locale = localStorage.getItem('language') || AvailableLanguage.EN;

export const intl = createIntl(
  {
    locale: locale,
    messages: locale === AvailableLanguage.EN ? englishTranslations : germanTranslations,
  },
  cache,
);
