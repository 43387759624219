import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';

import GeoJsonZones from '../../components/Map/GeoJsonZones';
import VehicleStateLegendAndResetFilterBottomBar from '../../components/Map/VehicleStateLegendAndResetFilterBottomBar';
import WikimoveMap from '../../components/Map/WikimoveMap';
import MapFiltersAndZoneTypeInfoControls from '../../components/Map/ZoneControls';
import { MapFilters } from '../../components/Map/ZoneControls/MapFilters';
import VehiclesPopupDataTable from '../../components/MapTableView/ZonesPopupDataTable';
import { useFilterZonesByPredicates, useApplicableVehiclesForFilters, useEvaluateZonesVisibilityWithTheTime } from '../../hooks';
import { graphqlVehicleTypeToVehicleType } from '../../models';
import { AppDispatch, loadingVehiclesSelector, allVehiclesSelector, loadingVehiclesErrorSelector } from '../../state';
import { LiveVehicle } from '../../state/common/vehicle.model';
import { publishedZonesSelector, getPublishedZonesAsyncThunkAction } from '../../state/zones-management';
import {
  analyticsTableAllColumns,
  TABLE_FOOTER_HEIGHT,
  VIEW_PANNING_MODE_VIEW_HEIGHT,
  VIEW_PLANNING_MODE_TABLE_HEIGHT,
} from '../../utils/constants';
import { FloatingBox } from '../../wmv-components';

const ViewModeLayout = ({ filters, onFilterChange, anyFilterApplied, resetFilters }: ViewModeLayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null | undefined>();
  const [showVehicleTable, setShowVehicleTable] = useState(false);
  const [selectedByShiftAndDragVehicles, setSelectedByShiftAndDragVehicles] = useState<LiveVehicle[]>([]);

  const loadingVehiclesError = useSelector(loadingVehiclesErrorSelector);
  const loadingVehicles: boolean = useSelector(loadingVehiclesSelector);
  const allVehicles = useSelector(allVehiclesSelector);
  const filteredVehicles = useApplicableVehiclesForFilters(allVehicles, filters);

  const publishedZones = useSelector(publishedZonesSelector);
  const zonesApplicableAtCurrentTime = useEvaluateZonesVisibilityWithTheTime(publishedZones);
  const filteredZones = useFilterZonesByPredicates(zonesApplicableAtCurrentTime, {
    zoneTypes: filters.zoneTypes,
    vehicleTypes: filters.vehicleTypes.map((graphqlVehicleType) => graphqlVehicleTypeToVehicleType.get(graphqlVehicleType)!),
  });

  useEffect(() => {
    dispatch(getPublishedZonesAsyncThunkAction());
  }, [dispatch]);

  const vehicleTableHeight = useMemo(() => {
    let availableHeight = showVehicleTable
      ? VIEW_PANNING_MODE_VIEW_HEIGHT
      : `calc(${VIEW_PLANNING_MODE_TABLE_HEIGHT} - ${TABLE_FOOTER_HEIGHT})`;

    return `calc(100vh - ${availableHeight})`;
  }, [showVehicleTable]);

  useEffect(() => {
    if (!showVehicleTable && selectedByShiftAndDragVehicles.length > 0) {
      setShowVehicleTable(true);
    }
    if (showVehicleTable && selectedByShiftAndDragVehicles.length < 1) {
      setShowVehicleTable(false);
    }
  }, [selectedByShiftAndDragVehicles, showVehicleTable]);

  return (
    <Box height={vehicleTableHeight}>
      <WikimoveMap openMarkerId={selectedVehicleId} setPopupTableData={setSelectedByShiftAndDragVehicles} vehicles={filteredVehicles}>
        <FloatingBox boxStyling={{ top: theme.spacing(9) }}>
          <MapFiltersAndZoneTypeInfoControls filters={filters} isAnyFilterApplied={anyFilterApplied} onFilterChange={onFilterChange} />
        </FloatingBox>
        <GeoJsonZones zones={filteredZones} />
      </WikimoveMap>
      <Box>
        {showVehicleTable && (
          <VehiclesPopupDataTable
            loading={loadingVehicles}
            data={selectedByShiftAndDragVehicles}
            columns={analyticsTableAllColumns}
            onClose={() => {
              setShowVehicleTable(false);
              setSelectedVehicleId(null);
              setSelectedByShiftAndDragVehicles([]);
            }}
            onRowClickHandler={setSelectedVehicleId}
          />
        )}
        <VehicleStateLegendAndResetFilterBottomBar
          filters={filters}
          isAnyFilterApplied={anyFilterApplied}
          onFiltersReset={resetFilters}
          loadingVehiclesError={loadingVehiclesError}
          loadingVehicles={loadingVehicles}
        />
      </Box>
    </Box>
  );
};

interface ViewModeLayoutProps {
  onFilterChange: (values: MapFilters) => void;
  filters: MapFilters;
  anyFilterApplied: boolean;
  resetFilters: () => void;
}

export default ViewModeLayout;
