import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../components/LoadingAndErrorWithRetryAndNoResults';
import { BudgetPlanTab, LoadingStatus } from '../../../models';
import { BudgetPlanDto } from '../../../services/budget-plan';
import {
  AppDispatch,
  BudgetPlanActionMode,
  budgetPlanApiErrorSelector,
  BudgetPlanApiErrorState,
  BudgetPlanApiLoadingStatus,
  budgetPlanApiStatusSelector,
  budgetPlansSelector,
  setBudgetPlanActionModeAction,
} from '../../../state';
import { ReactTable } from '../../../wmv-components';

import { BudgetPlanActionType, useBudgetPlanDispatch } from './contexts';
import { useMakeBudgetPlanDetailsTableColumns } from './hooks/useMakeBudgetPlanDetailsTableColumns';

export const BudgetPlansTableWithLoadingOrNoContentMessages = ({
  fetchBudgetPlans,
}: BudgetPlansTableWithLoadingOrNoContentMessagesProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const budgetPlanDispatch = useBudgetPlanDispatch();
  const budgetPlans: BudgetPlanDto[] = useSelector(budgetPlansSelector);
  const budgetPlanDetailsTableColumns = useMakeBudgetPlanDetailsTableColumns(handleStatusChangeCellClick);
  const budgetPlanApiStatus: BudgetPlanApiLoadingStatus = useSelector(budgetPlanApiStatusSelector);
  const budgetPlanApiError: BudgetPlanApiErrorState = useSelector(budgetPlanApiErrorSelector);

  function handleStatusChangeCellClick(budgetPlan: BudgetPlanDto) {
    budgetPlanDispatch({
      type: BudgetPlanActionType.UpdateSelectedFailedPlanId,
      payload: budgetPlan.id,
    });
  }

  const handleBudgetPlanRowClick = (budgetPlan: BudgetPlanDto) => {
    budgetPlanDispatch({
      type: BudgetPlanActionType.UpdateSelectedBudgetPlanId,
      payload: budgetPlan.id,
    });
    budgetPlanDispatch({
      type: BudgetPlanActionType.UpdateBudgetPlanFormModalVisibility,
      payload: true,
    });
    budgetPlanDispatch({
      type: BudgetPlanActionType.UpdateActiveTab,
      payload: BudgetPlanTab.PlanDetails,
    });
    dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.View));
  };

  const showLoadingOrNoContentOrErrorMessage =
    isEmpty(budgetPlans) || budgetPlanApiStatus['listPlans'] === LoadingStatus.Pending || !!budgetPlanApiError['listPlans'];
  return showLoadingOrNoContentOrErrorMessage ? (
    <LoadingAndErrorWithRetryAndNoResults
      error={!!budgetPlanApiError['listPlans']}
      loading={budgetPlanApiStatus['listPlans'] === LoadingStatus.Pending}
      onRetry={fetchBudgetPlans}
      baseTranslationKey="budgetPlan"
      noResults={isEmpty(budgetPlans)}
      style={{ height: 'calc(100vh - 172px)' }}
    />
  ) : (
    <ReactTable
      columns={budgetPlanDetailsTableColumns}
      data={budgetPlans}
      onRowClickHandler={handleBudgetPlanRowClick}
      tableContainerStyle={{ height: 'calc(100vh - 172px)' }}
      customRowHoverStyle={{ backgroundColor: palette.secondary.tint, cursor: 'pointer' }}
    />
  );
};

interface BudgetPlansTableWithLoadingOrNoContentMessagesProps {
  fetchBudgetPlans: () => Promise<void>;
}
