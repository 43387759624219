import { Dayjs } from 'dayjs';

import { BudgetPlanMembership } from '../../models/budget-plan';

import { BudgetPlanMembershipActiveStateDto } from './BudgetPlanMembershipActiveStateDto';
import { BudgetPlanMembershipLastAttemptedStateTransitionDto } from './BudgetPlanMembershipLastAttemptedStateTransitionDto';

export class BudgetPlanMembershipDto {
  private readonly membership: BudgetPlanMembership;
  id: number;
  version: number;
  firstName: string;
  lastName: string;
  email: string;
  activeState: BudgetPlanMembershipActiveStateDto;
  lastAttemptedStateTransition: BudgetPlanMembershipLastAttemptedStateTransitionDto;
  activationDate?: Dayjs;

  constructor(budgetPlanMembership: BudgetPlanMembership) {
    this.membership = budgetPlanMembership;
    this.version = budgetPlanMembership.version;
    this.id = budgetPlanMembership.id;
    this.firstName = budgetPlanMembership.firstName;
    this.lastName = budgetPlanMembership.lastName;
    this.email = budgetPlanMembership.email;
    this.activeState = new BudgetPlanMembershipActiveStateDto(budgetPlanMembership.activeState);
    /* prettier-ignore */
    this.lastAttemptedStateTransition = new BudgetPlanMembershipLastAttemptedStateTransitionDto(budgetPlanMembership.lastAttemptedStateTransition);
    this.activationDate = budgetPlanMembership.activeState.subscribedAtEpochMillis?.parseEpochMillis();
  }

  getMembership() {
    return this.membership;
  }

  name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  isActive(): boolean {
    return (
      (this.isLinked() && this.isLastAttemptedStateTransitionSuccessful()) ||
      (this.isLinked() && this.lastAttemptedStateTransition.isFailedAttemptAcknowledged())
    );
  }

  isInactive(): boolean {
    return (
      (this.isUnlinked() && this.isLastAttemptedStateTransitionSuccessful()) ||
      (this.isUnlinked() && this.lastAttemptedStateTransition.isFailedAttemptAcknowledged())
    );
  }

  isLinked(): boolean {
    return this.activeState.isSubscribed;
  }

  isUnlinked(): boolean {
    return this.activeState.isNotSubscribed;
  }

  isLastAttemptedStateTransitionInProgress(): boolean {
    return this.lastAttemptedStateTransition.isInProgress();
  }

  isLastAttemptedStateTransitionSuccessful(): boolean {
    return this.lastAttemptedStateTransition.isSuccessful();
  }

  isLastAttemptedStateTransitionFailed(): boolean {
    return this.lastAttemptedStateTransition.isFailed();
  }

  isLinkingInProgress(): boolean {
    return this.isLastAttemptedStateTransitionInProgress() && this.lastAttemptedStateTransition.toSubscribed();
  }

  isUnlinkingInProgress(): boolean {
    return this.isLastAttemptedStateTransitionInProgress() && this.lastAttemptedStateTransition.toUnSubscribed();
  }

  isLinkingFailed(): boolean {
    return this.isLastAttemptedStateTransitionFailed() && this.lastAttemptedStateTransition.toSubscribed();
  }

  isUnlinkingFailed(): boolean {
    return this.isLastAttemptedStateTransitionFailed() && this.lastAttemptedStateTransition.toUnSubscribed();
  }

  isExistingMembership(): boolean {
    return this.isActive() || this.isInactive() || this.isUnlinkingInProgress() || this.isUnlinkingFailed();
  }

  isStatusTransitionPending(): boolean {
    return this.isLinkingInProgress() || this.isUnlinkingInProgress();
  }
}
