import { useCallback } from 'react';

import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { fetchBudgetPlanMembershipSummaries } from '../../../../utils/queries';
import { BudgetPlanMembershipsListActionType, useBudgetPlanMembershipsListDispatch } from '../contexts';

export function useGetBudgetPlanMemberships(): GetBudgetPlanMembershipsHookReturnValue {
  const membershipsListDispatch = useBudgetPlanMembershipsListDispatch();

  return useCallback(
    async (planId: number) => {
      try {
        membershipsListDispatch({ type: BudgetPlanMembershipsListActionType.MembershipsListFetchStart });
        const membershipSummaryResponses = (await fetchBudgetPlanMembershipSummaries(planId!)) || [];
        const membershipDtoList = membershipSummaryResponses.map((member) => new BudgetPlanMembershipDto(member));
        membershipsListDispatch({ type: BudgetPlanMembershipsListActionType.MembershipsListFetchSuccess, payload: membershipDtoList });
      } catch (e) {
        membershipsListDispatch({ type: BudgetPlanMembershipsListActionType.MembershipsListFetchFailure });
      }
    },
    [membershipsListDispatch],
  );
}

export type GetBudgetPlanMembershipsHookReturnValue = (planId: number) => Promise<void>;
