import dayjs from 'dayjs';

import { ZoneType } from '../../../models';
import { DayOfWeekHelper } from '../../../models';

import { ZoneRuleFormFields, ZoneRulesFormValues } from './ZoneRulesForm';

export const ACTIVE_ZONE_LAYER_STROKE_WIDTH = 6;

export const zoneRuleInitialValues: ZoneRulesFormValues = {
  [ZoneRuleFormFields.type]: ZoneType.STOP, // as its assumed that this is the most frequently created zone.
  [ZoneRuleFormFields.name]: '',
  [ZoneRuleFormFields.startDate]: null,
  [ZoneRuleFormFields.endDate]: null,
  [ZoneRuleFormFields.indefiniteZone]: false,
  [ZoneRuleFormFields.startTime]: dayjs().toBeginningOfDay(),
  [ZoneRuleFormFields.endTime]: dayjs().toEndOfDay(),
  [ZoneRuleFormFields.representsFullDay]: true,
  [ZoneRuleFormFields.daysOfWeek]: DayOfWeekHelper.allDaysOfWeek(),
  [ZoneRuleFormFields.applicableVehicleTypes]: [],
};
