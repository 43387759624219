import { Grid, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import {
  ZoneType,
  ZoneTypeHelper,
  GraphqlVehicleType,
  MspTypeHelper,
  InfrastructureTypeHelper,
  GraphqlVehicleUsageStatus,
  GraphqlVehicleUsageStatusHelper,
  MspType,
  InfrastructureType,
  GraphqlVehicleTypeHelper,
} from '../../../models';
import { SaveButton } from '../../../wmv-components';
import CustomSelectField from '../../FormFields/CustomSelectField';

const MapEntitiesFilters = ({ handleClose, onFilterChange, filters }: MapEntitiesFiltersProps) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const validationFn = () => {
    return {};
  };

  const applyVehicleFilter = (values: MapFilters) => {
    onFilterChange(values);
    handleClose();
  };

  return (
    <Formik initialValues={filters!} validate={validationFn} onSubmit={applyVehicleFilter} enableReinitialize>
      {({ isValid, isValidating }) => (
        <Form>
          <Grid container style={{ width: 275 }} rowSpacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="subtitle2">{formatMessage({ id: 'zoneControls.filters.title' })}</Typography>
            </Grid>
            {getFilters(intl).map((filter) => (
              <Grid key={filter.name} item xs={12}>
                <Field name={filter.name} as={CustomSelectField} options={filter.options} outlineLabel={filter.label} multiple />
                <div
                  style={{
                    marginTop: 5,
                    color: 'red',
                    fontSize: 12,
                    height: 18,
                  }}
                >
                  <ErrorMessage name={filter.name} />
                </div>
              </Grid>
            ))}
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <SaveButton disabled={isValidating || !isValid} type="submit" variant="contained" disabledElevation>
                <Typography variant="subtitle2">{formatMessage({ id: 'zoneControls.filters.apply' })}</Typography>
              </SaveButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const getFilters = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.vehicleType' }),
    options: GraphqlVehicleTypeHelper.valuesWithLabel,
    name: 'vehicleTypes',
  },
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.vehicleStatus' }),
    options: GraphqlVehicleUsageStatusHelper.valuesWithLabel,
    name: 'vehicleStatuses',
  },
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.provider' }),
    options: MspTypeHelper.valuesWithLabel,
    name: 'mspTypes',
  },
  {
    label: ZoneTypeHelper.menuConfig.label,
    options: ZoneTypeHelper.menuConfig.options,
    name: 'zoneTypes',
  },
  {
    label: intl.formatMessage({ id: 'zoneControls.filters.infrastructure' }),
    options: InfrastructureTypeHelper.valuesWithLabel,
    name: 'infrastructureTypes',
  },
];

export interface MapEntitiesFiltersProps {
  handleClose: () => void;
  onFilterChange: (values: MapFilters) => void;
  filters: MapFilters;
}

export interface MapFilters {
  vehicleTypes: GraphqlVehicleType[];
  vehicleStatuses: GraphqlVehicleUsageStatus[];
  mspTypes: MspType[];
  zoneTypes: ZoneType[];
  infrastructureTypes: InfrastructureType[];
}

export default MapEntitiesFilters;
