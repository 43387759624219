import { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';

import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { fetchBudgetPlanMembershipDetails } from '../../../../utils/queries';
import { MembershipDetailsActionType, useMembershipDetailsDispatch } from '../contexts';

export function useGetMembershipDetails(): UseGetMembershipDetailsReturn {
  const membershipDetailsDispatch = useMembershipDetailsDispatch();

  return useCallback(
    async (planId: number, membershipId: number) => {
      try {
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.MembershipDetailsFetchStart,
        });
        const resp = await fetchBudgetPlanMembershipDetails(planId, membershipId);
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.MembershipDetailsFetchSuccess,
          payload: isEmpty(resp) ? null : new BudgetPlanMembershipDto(resp),
        });
      } catch (err) {
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.MembershipDetailsFetchFailure,
        });
      }
    },
    [membershipDetailsDispatch],
  );
}

type UseGetMembershipDetailsReturn = (planId: number, membershipId: number) => Promise<void>;
