import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ReactComponent as PlusIcon } from '../../../assets/icons/fill/plus.svg';
import { BudgetPlanTab } from '../../../models';
import { AppDispatch, BudgetPlanActionMode, setBudgetPlanActionModeAction } from '../../../state';
import { InfoIconWithTooltip, TranslatedText } from '../../../wmv-components';

import { BudgetPlanActionType, useBudgetPlanDispatch } from './contexts';
import { headerSectionContainerStyles } from './styles';

export const HeadingSection = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { palette } = theme;

  const dispatch = useDispatch<AppDispatch>();
  const selectedPlanDispatch = useBudgetPlanDispatch();
  const budgetPlanDispatch = useBudgetPlanDispatch();

  return (
    <Box component="section" sx={headerSectionContainerStyles}>
      <Box display="flex" alignItems="center">
        <TranslatedText variant="bodyMediumBold" translationKey="operator.budgetPlan.heading" sx={{ mr: 0.5 }} />
        <InfoIconWithTooltip title={formatMessage({ id: 'operator.budgetPlan.heading.tooltipDescription' })} placement="top-end" />
      </Box>
      <Button variant="contained" onClick={handleAddBudgetPlanClick}>
        <PlusIcon fill={palette.white.main} />
        <TranslatedText variant="bodyMediumBold" translationKey="operator.budgetPlan.addBudgetPlan" sx={{ pl: 0.5 }} />
      </Button>
    </Box>
  );

  function handleAddBudgetPlanClick() {
    budgetPlanDispatch({
      type: BudgetPlanActionType.UpdateActiveTab,
      payload: BudgetPlanTab.PlanDetails,
    });
    selectedPlanDispatch({
      type: BudgetPlanActionType.UpdateBudgetPlanFormModalVisibility,
      payload: true,
    });
    dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.Add));
  }
};
