import { memo, useCallback, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { intl } from '../../../../utils/intl';
import { ReactTable } from '../../../../wmv-components';
import { BudgetPlanMembershipsListActionType, useBudgetPlanMembershipsListDispatch, useBudgetPlanMembershipsListState } from '../contexts';

import { MembershipStatus } from './MembershipStatus';

export const BudgetPlanMembershipTable = memo(({ budgetPlanMemberships }: BudgetPlanMembershipTableProps) => {
  const { palette } = useTheme();
  const membershipsListDispatch = useBudgetPlanMembershipsListDispatch();
  const membershipsListState = useBudgetPlanMembershipsListState();
  const searchStringToLowercase = membershipsListState.searchString.toLowerCase();

  const filteredMemberships = useMemo(() => {
    const trimmedSearchString = trim(searchStringToLowercase);
    if (searchStringToLowercase.length < 2 || isEmpty(trimmedSearchString)) {
      return budgetPlanMemberships;
    }

    return budgetPlanMemberships.filter(
      ({ firstName, lastName, email }) =>
        firstName.toLowerCase().includes(searchStringToLowercase) ||
        lastName.toLowerCase().includes(searchStringToLowercase) ||
        email.toLowerCase().includes(searchStringToLowercase),
    );
  }, [budgetPlanMemberships, searchStringToLowercase]);

  const handleMembershipRowClick = useCallback(
    (membership: BudgetPlanMembershipDto) => {
      membershipsListDispatch({
        type: BudgetPlanMembershipsListActionType.UpdateSelectedMembership,
        payload: membership.id,
      });
    },
    [membershipsListDispatch],
  );

  return (
    <Box py={3} height="100%" sx={{ overflowY: 'hidden' }}>
      <Typography variant="bodyMediumBold" mb={1} component="h3">
        {intl.formatMessage({ id: 'budgetPlanMemberships.table.title' })}
      </Typography>
      <ReactTable
        data={filteredMemberships}
        columns={BudgetPlanMembershipTableColumnSupplier()}
        tableContainerStyle={{ height: `100%` }}
        onRowClickHandler={handleMembershipRowClick}
        customRowHoverStyle={{ backgroundColor: palette.secondary.tint, cursor: 'pointer' }}
      />
    </Box>
  );
});
BudgetPlanMembershipTable.displayName = 'BudgetPlanMembershipTable';

// TODO: Cells text need to be reusable, because same way we are displaying text in maximum cases
export const BudgetPlanMembershipTableColumnSupplier = () => {
  const theme = useTheme();

  return [
    {
      Header: intl.formatMessage({ id: `common.firstName` }),
      accessor: 'firstName',
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.firstName}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.lastName` }),
      accessor: 'lastName',
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.lastName}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.email` }),
      accessor: 'email',
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.email}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.activationDate` }),
      accessor: 'activeState.subscribedAtEpochMillis',
      Cell: ({ row: { original: planMembership } }: RowData) => (
        <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
          {planMembership.activeState.isSubscribed
            ? planMembership.activeState.subscribedAtEpochMillis!.parseEpochMillis().formatAsDateString()
            : intl.formatMessage({ id: 'common.na' })}
        </Typography>
      ),
    },
    {
      Header: intl.formatMessage({ id: `common.lastStatusUpdate` }),
      accessor: 'lastAttemptedStateTransition.status',
      Cell: ({ row: { original: planMembership } }: RowData) => {
        return (
          <Box display="flex" alignItems="center" px={1}>
            <MembershipStatus membership={planMembership} sx={{ pr: 0.5 }} />
            {planMembership.isLastAttemptedStateTransitionFailed() && (
              <Typography variant="bodyMedium" color={theme.palette.alert.main} component="span">
                - {intl.formatMessage({ id: 'common.clickToRetry' })}
              </Typography>
            )}
          </Box>
        );
      },
    },
  ];
};

interface BudgetPlanMembershipTableProps {
  budgetPlanMemberships: BudgetPlanMembershipDto[];
}

interface RowData {
  row: {
    original: BudgetPlanMembershipDto;
  };
}
