import { useState } from 'react';

import { Button, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/fill/plus.svg';
import { TranslatedText } from '../../../../wmv-components';
import { BudgetPlanMembershipsListActionType, useBudgetPlanMembershipsListDispatch } from '../contexts';

import { SearchInput } from './SearchInput';

export const SearchExistingMemberOrLinkNewMember = () => {
  const { palette } = useTheme();
  const { formatMessage } = useIntl();
  const [searchString, setSearchString] = useState<string>('');

  const membershipsListDispatch = useBudgetPlanMembershipsListDispatch();

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={3}>
      <SearchInput
        value={searchString}
        onChange={handleSearchStringChange}
        variant="outlined"
        label={formatMessage({ id: 'common.emailAddress' })}
        placeholder={formatMessage({ id: 'common.searchUser' })}
        size="small"
        sx={{ flex: 1 }}
        showSearchIcon={isEmpty(searchString)}
        showClearIcon={!isEmpty(searchString)}
      />
      <TranslatedText translationKey="common.orSeprator" variant="bodyMedium" color={palette.baseLight.base76} sx={{ mx: 3 }} />
      <Button variant="contained" color="primary" size="large" onClick={handleLinkNewMembershipClick}>
        <PlusIcon fill={palette.white.main} />
        <TranslatedText translationKey="common.linkNewUser" variant="bodyMediumBold" />
      </Button>
    </Box>
  );

  function handleLinkNewMembershipClick() {
    membershipsListDispatch({
      type: BudgetPlanMembershipsListActionType.OpenLinkMembershipModal,
    });
  }

  function handleSearchStringChange(searchStr: string) {
    setSearchString(searchStr);
    membershipsListDispatch({
      type: BudgetPlanMembershipsListActionType.UpdateSearchString,
      payload: searchStr,
    });
  }
};
