import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { useBudgetPlanMembershipsListState, useSelectedBudgetPlanId } from '../contexts';
import { useGetBudgetPlanMemberships } from '../hooks/useGetBudgetPlanMemberships';

import { BudgetPlanMembershipTable } from './BudgetPlanMembershipTable';

export function BudgetPlanMembershipsListWithLoadingOrNoContentMessages() {
  const getBudgetPlanMemberships = useGetBudgetPlanMemberships();

  const membershipsListState = useBudgetPlanMembershipsListState();
  const selectedPlanId = useSelectedBudgetPlanId();

  const { memberships = [], membershipsListApiStatus } = membershipsListState;

  const activeOrInTransitionMembers = useMemo(() => memberships!.filter((member) => !member.isInactive()), [memberships]);
  if (activeOrInTransitionMembers.length) {
    return <BudgetPlanMembershipTable budgetPlanMemberships={activeOrInTransitionMembers} />;
  }

  return (
    <LoadingAndErrorWithRetryAndNoResults
      error={membershipsListApiStatus.isFailed()}
      loading={membershipsListApiStatus.isPending()}
      noResults={isEmpty(activeOrInTransitionMembers) && membershipsListApiStatus.isSucceeded()}
      onRetry={async () => {
        await getBudgetPlanMemberships(selectedPlanId!);
      }}
      baseTranslationKey={'budgetPlanMemberships'}
      style={{ backgroundColor: 'transparent' }}
    />
  );
}
