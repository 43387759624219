import { BudgetPlanTab } from '../../../models';

import { BudgetPlanDetailsView } from './BudgetPlanDetails';
import { BudgetPlanMembershipsView } from './BudgetPlanMemberships';
import { BudgetPlanModalCloseHandler } from './BudgetPlanModal';
import { BudgetPlanMembershipsListStateProvider } from './contexts';

export const BudgetPlanPopupTabContent = ({ activeTab, onBudgetPlanModalClose }: TabPanelContentProps) => {
  if (activeTab === BudgetPlanTab.PlanDetails) {
    return <BudgetPlanDetailsView onBudgetPlanModalClose={onBudgetPlanModalClose} />;
  }

  if (activeTab === BudgetPlanTab.Members) {
    return (
      <BudgetPlanMembershipsListStateProvider>
        <BudgetPlanMembershipsView />
      </BudgetPlanMembershipsListStateProvider>
    );
  }
};

interface TabPanelContentProps {
  activeTab: BudgetPlanTab;
  onBudgetPlanModalClose: BudgetPlanModalCloseHandler;
}
