import type { Dayjs } from 'dayjs';

import { ModeType } from '../ui';

import { DayOfWeek } from './DayOfWeek';
import { GraphqlVehicleType } from './GraphqlVehicleType';
import { VehicleType } from './VehicleType';
import { ZoneType } from './ZoneType';

export type ColumnFilterType = {
  column: TableColumnType;
  valueType: ListItemConfig<string>; //This gets selected based on the column type.
  operator: ListItemConfig<string>;
  value: string;
};

export type TableColumnType = {
  id: number;
  Header: string;
  accessor: string;
  filter?: string;
  label: string;
  value: string;
  color?: string;
};

export type FilterProfileType = {
  filterName: string;
  visibleColumns: TableColumnType[];
  columnFilters: ColumnFilterType[];
  favorite: boolean;
};

export interface SearchUsersQueryParams {
  firstName: string;
  lastName: string;
  birthDate: string;
}

export interface GetTripsQueryParams {
  vehicleId: string;
  fromInclusiveEpochMillis: number;
  toInclusiveEpochMillis: number;
}

export interface Address {
  houseNumber: string;
  street: string;
  extension: string;
  city: string;
  postalCode: string;
  countryCode: string;
}

export interface DrivingLicense {
  id: string;
  issuedOn: string;
  expiresOn: string;
  countryCode: string;
  placeOfIssuingAuthority: string;
  classes: string[];
}
export interface CustomerDetailsType {
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phoneNumber: string;
  address: Address | null;
  drivingLicense: DrivingLicense | null;
  registrationToken: string;
  acceptedWikimoveTerms: boolean;
  createdAt: number;
  updatedAt: number;
  subjectClaim: string;
}

export interface UserTripFieldValue {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  latestDate: string;
  latestTime: string;
}

export interface UserTripDetailsActivityLog {
  event: string;
  terminalState: boolean;
  timestampEpochMillis: number;
  updatedBy: UpdatedBy;
}

export interface Billing {
  amountMultiplier: number;
  amount: number;
  currency: string;
  amountBreakdown: AmountBreakdown[];
  durationBreakdown: DurationBreakdown[];
}

export interface AmountBreakdown {
  amount: number;
  type: string;
  subType?: string;
  metadata?: Metadata;
}

interface Metadata {
  discountCode: string;
}

export interface DurationBreakdown {
  startEpochMillis: number;
  actualDurationSeconds: number;
  billableDurationSeconds: number;
  type: DurationBreakdownType;
}

export enum DurationBreakdownType {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
}

interface UpdatedBy {
  id: string;
  fullname: string;
}
export interface UserTripDetailsOld {
  id: number;
  active: boolean;
  qrCode: string;
  providerType: string;
  vehicleType: VehicleType;
  totalDistanceMeters: number;
  activityLog: UserTripDetailsActivityLog[];
  billing?: Billing;
}

interface ShortutMetadata {
  id: string;
  name: string;
  msp: string;
  vehicle_types: string[];
  providerType?: string;
}

interface Shortcut {
  type: string;
  metadata?: ShortutMetadata;
}
export interface DashboardShortcut {
  category: string;
  shortcuts: Shortcut[];
}

export interface Shortcuts {
  title: string;
  type: string;
  description: string;
  url: string;
}

export interface SupportCard {
  type: string;
  title: string;
  img?: string;
  description: string;
  url: string;
}

export interface Category {
  name: string;
  shortcuts: Shortcuts[];
}

export interface ZoneDateRangeResponse {
  openEnded: boolean;
  startInclusive: string;
  endInclusive?: string;
}

export interface ZoneTimeWindowResponse {
  startInclusive: string;
  endExclusive: string;
}

export interface ActiveOnPredicate {
  dateRange: ZoneDateRangeResponse;
  timeWindow: ZoneTimeWindowResponse;
  daysOfWeek?: DayOfWeek[];
}

export enum ZoneStatus {
  Published = 'PUBLISHED',
  Draft = 'DRAFT',
}

export interface ZonePublishMetadata {
  publishedAtEpochMillis: number;
  approvedBy: string;
  draftedBy: string;
}

export interface ZoneResponse {
  id: number;
  version: number;
  name: string;
  type: ZoneType;
  representsMobilityStation: boolean;
  activeOn: ActiveOnPredicate;
  applicableVehicleTypes: VehicleType[];
  geoJson: GeoJSON.Feature;
  status: ZoneStatus;
  publishMetadata: ZonePublishMetadata;
  createdAtEpochMillis: number;
  updatedAtEpochMillis: number;
}

export interface ZoneRequest {
  name: string;
  type: ZoneType;
  representsMobilityStation: boolean;
  activeOn: ActiveOnPredicate;
  applicableVehicleTypes: VehicleType[];
  geoJson: GeoJSON.Feature;
}

export interface LawEnforcementTripSummaryResponse {
  id: number;
  active: boolean;
  qrCode: string;
  providerType: string;
  vehicleType: string;
  startedAt: number;
  lastUpdatedAt: number;
  user: {
    subjectClaim: string;
    firstName: string;
    lastName: string;
    address: Address;
  };
}

export interface VehicleDetailsType {
  vehicleId: string;
  systemId: string;
  qrCodeId: string | null;
  name: string | null;
  lat: number;
  lon: number;
  isReserved: boolean;
  isDisabled: boolean;
  vehicleTypeId: string;
  lastReported: string;
  currentRangeMeters: number;
  currentFuelPercent: number | null;
  stationId: string | null;
  homeStationId: string | null;
  pricingPlanId: string;
  vehicleEquipments: string | null;
  availableUntil: string | null;
  stableVehicleId: string;
  volatileVehicleId: string;
  mspName: string;
  formFactor: string;
  maxRangeMeters: number;
  propulsionType: string;
  usageStatus: string | null;
  seats: string | null;
  __typename: string;
}

export interface VehicleTripType {
  id: BigInt;
  providerType: string;
  totalDurationSeconds: number;
  totalDistanceMeters: number;
  startedAt: number;
  finishedAt: number;
}

export const graphqlVehicleTypeToVehicleType = new Map<GraphqlVehicleType, VehicleType>([
  [GraphqlVehicleType.ELECTRIC_MOPED, VehicleType.ElectricMoped],
  [GraphqlVehicleType.ELECTRIC_BIKE, VehicleType.ElectricBike],
  [GraphqlVehicleType.CAR, VehicleType.Car],
  [GraphqlVehicleType.BICYCLE, VehicleType.Bicycle],
  [GraphqlVehicleType.CARGO_BICYCLE, VehicleType.CargoBicycle],
  [GraphqlVehicleType.SCOOTER, VehicleType.Scooter],
  [GraphqlVehicleType.SCOOTER_STANDING, VehicleType.ScooterStanding],
  [GraphqlVehicleType.SCOOTER_SEATED, VehicleType.ScooterSeated],
  [GraphqlVehicleType.OTHER, VehicleType.Other],
]);

export interface DropDownOption {
  label: string;
  value: string;
  color?: string;
}

export interface FormikFieldProps {
  field: any;
  form: any;
  name: string;
  outlineLabel: string;
  options: DropDownOption[];
  width?: string;
  value?: DropDownOption;
  disabled?: boolean;
  defaultValue?: DropDownOption;
}

export enum ApiRequestStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

// TODO Is kept as orientation for now - will be removed upon switching to new tables
export interface TableColumn {
  id: number;
  Header: string;
  filter: string;
  label: string;
  value: string;
  depth: number;
  width: number;
  minWidth: number;
  maxWidth: number;
  sortType: string;
  sortDescFirst: boolean;
  canResize: boolean;
  originalWidth: number;
  isVisible: boolean;
  totalVisibleHeaderCount: number;
  totalLeft: number;
  totalMinWidth: number;
  totalWidth: number;
  totalMaxWidth: number;
  totalFlexWidth: number;
  canFilter: boolean;
  filterValue: string[];
  preFilteredRows: FilteredRow[];
  filteredRows: FilteredRow[];
  canSort: boolean;
  isSorted: boolean;
  isSortedDesc: boolean | undefined;
  sortedIndex: number;
  render: (type: string | React.ComponentType<any>, userProps?: object) => React.ReactNode;
  getHeaderProps: (userProps?: Record<string, any>) => Record<string, any>;
  getSortByToggleProps: (userProps?: Record<string, any>) => Record<string, any>;
  setFilter: (filterValue: string | string[] | undefined) => void;
}

export interface FilteredRow {
  allCells: FilteredRowCell[];
  cells: FilteredRowCell[];
  depth: number;
  getRowProps: (userProps?: Record<string, any>) => Record<string, any>;
  id: string;
  index: number;
  original: VehicleDetailsType;
  subRows: [];
  originalSubRows: [];
  values: { [key: string]: string | null | undefined };
}

export interface FilteredRowCell {
  column: any;
  getCellProps: (userProps?: Record<string, any>) => Record<string, any>;
  render: (type: string | React.ComponentType<any>, userProps?: object) => React.ReactNode;
  value: string | null | undefined;
}

export interface FilterValueType<T> {
  label: string;
  value: T;
  icon?: string;
  type?: GraphqlVehicleType;
}

export interface TimeWindow {
  startSecondsFromBeginningOfDay: number;
  endSecondsFromBeginningOfDay: number;
}

export interface DateRangeAndDaysOfWeek {
  startDate: Dayjs;
  endDate: Dayjs;
  daysOfWeek: Set<DayOfWeek>;
}

export interface PreviewPredicates {
  modeType: ModeType;
  timeRange: TimeWindow;
  dateRangeAndDaysOfWeek: DateRangeAndDaysOfWeek;
}

// TODO: Separate API level concerns from page level concerns
//  API status: loading, success, failed
//  Page status: idle, loading, success, failed
export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum LookupDurationWindow {
  SixHours = 'account.tripHistory.6h',
  TwelveHours = 'account.tripHistory.12h',
  TwentyFourHours = 'account.tripHistory.24h',
  Custom = 'account.tripHistory.custom',
}

export interface ListItemConfig<T> {
  value: T;
  label: string;
}

export enum Unit {
  Minutes = 'MINUTES',
}

export enum PaymentStatus {
  Paid = 'PAID',
  NotPaid = 'NOT_PAID',
}

export enum PaymentCardType {
  Card = 'CARD',
}

export enum PaymentCardBrand {
  Visa = 'VISA',
}

export type NO_CONTENT_RESPONSE = '';
