import { createContext, Dispatch, ReactNode, useReducer, useContext } from 'react';

import { LoadingStatus } from '../../../../models';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { BudgetPlanMembershipSearchDto } from '../../../../services/budget-plan-memberships-search';
import { ApiStatusService } from '../../../../services/ui';
import { HelperTextType } from '../../../../wmv-components/HelperText';

const initialSearchBudgetPlanMembershipState: SearchBudgetPlanMembershipState = {
  searchMembershipApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
  searchedMembership: null,
  error: '',
  searchedMembershipEmail: '',
  linkMembership: {
    messageType: 'error',
    message: '',
    status: ApiStatusService.getInstance(LoadingStatus.Idle),
    membership: null,
  },
};

const SearchBudgetPlanMembershipContext = createContext<SearchBudgetPlanMembershipState>(initialSearchBudgetPlanMembershipState);
const SearchBudgetPlanDispatchContext = createContext<Dispatch<SearchBudgetPlanMembershipAction> | null>(null);

export function SearchBudgetPlanMembershipProvider({ children }: SearchBudgetPlanMembershipProviderProps) {
  const [searchBudgetPlanMembershipState, dispatch] = useReducer(searchBudgetPlanMembershipReducer, initialSearchBudgetPlanMembershipState);

  return (
    <SearchBudgetPlanDispatchContext.Provider value={dispatch!}>
      <SearchBudgetPlanMembershipContext.Provider value={searchBudgetPlanMembershipState}>
        {children}
      </SearchBudgetPlanMembershipContext.Provider>
    </SearchBudgetPlanDispatchContext.Provider>
  );
}

export function useSearchBudgetPlanMembership() {
  return useContext(SearchBudgetPlanMembershipContext)!;
}

export function useSearchBudgetPlanMembershipDispatch() {
  return useContext(SearchBudgetPlanDispatchContext)!;
}

function searchBudgetPlanMembershipReducer(
  searchBudgetPlanMembershipState: SearchBudgetPlanMembershipState,
  action: SearchBudgetPlanMembershipAction,
): SearchBudgetPlanMembershipState {
  switch (action.type) {
    case SearchBudgetPlanMembershipActionType.SearchStart:
      return {
        ...searchBudgetPlanMembershipState,
        searchMembershipApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
        searchedMembership: null,
        linkMembership: {
          ...searchBudgetPlanMembershipState.linkMembership,
          message: '',
        },
      };
    case SearchBudgetPlanMembershipActionType.SearchSuccess:
      return {
        ...searchBudgetPlanMembershipState,
        searchMembershipApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
        searchedMembership: action.payload,
      };
    case SearchBudgetPlanMembershipActionType.SearchFailure:
      return {
        ...searchBudgetPlanMembershipState,
        searchMembershipApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
        searchedMembership: null,
      };
    case SearchBudgetPlanMembershipActionType.UpdateSearchedEmail:
      return {
        ...searchBudgetPlanMembershipState,
        searchedMembershipEmail: action.payload,
      };

    case SearchBudgetPlanMembershipActionType.LinkingUserStart:
      return {
        ...searchBudgetPlanMembershipState,
        linkMembership: {
          ...searchBudgetPlanMembershipState.linkMembership,
          status: ApiStatusService.getInstance(LoadingStatus.Pending),
          message: '',
        },
      };
    case SearchBudgetPlanMembershipActionType.LinkingUserSuccess:
      return {
        ...searchBudgetPlanMembershipState,
        linkMembership: {
          ...searchBudgetPlanMembershipState.linkMembership,
          status: ApiStatusService.getInstance(LoadingStatus.Succeeded),
          membership: action.payload.membership,
          message: action.payload.message,
          messageType: action.payload.messageType,
        },
      };
    case SearchBudgetPlanMembershipActionType.LinkingUserFailure:
      return {
        ...searchBudgetPlanMembershipState,
        linkMembership: {
          ...searchBudgetPlanMembershipState.linkMembership,
          status: ApiStatusService.getInstance(LoadingStatus.Failed),
          message: action.payload,
          messageType: 'error',
        },
      };

    case SearchBudgetPlanMembershipActionType.ResetSearchMembership:
      return initialSearchBudgetPlanMembershipState;
    default:
      return initialSearchBudgetPlanMembershipState;
  }
}

interface SearchBudgetPlanMembershipProviderProps {
  children: ReactNode;
}

export interface SearchBudgetPlanMembershipState {
  searchMembershipApiStatus: ApiStatusService;
  searchedMembership: BudgetPlanMembershipSearchDto | null;
  error: string;
  searchedMembershipEmail: string;
  linkMembership: LinkMembershipState;
}

export enum SearchBudgetPlanMembershipActionType {
  SearchStart = 'SEARCH_START',
  SearchSuccess = 'SEARCH_SUCCESS',
  SearchFailure = 'SEARCH_FAILURE',
  UpdateSearchedEmail = 'UPDATE_SEARCHED_EMAIL',
  ResetSearchMembership = 'RESET_SEARCH_MEMBERSHIP',
  LinkingUserStart = 'LINKING_USER_START',
  LinkingUserSuccess = 'LINKING_USER_SUCCESS',
  LinkingUserFailure = 'LINKING_USER_FAILURE',
}

export type SearchBudgetPlanMembershipAction =
  | { type: SearchBudgetPlanMembershipActionType.SearchStart }
  | { type: SearchBudgetPlanMembershipActionType.SearchSuccess; payload: BudgetPlanMembershipSearchDto | null }
  | { type: SearchBudgetPlanMembershipActionType.SearchFailure }
  | { type: SearchBudgetPlanMembershipActionType.UpdateSearchedEmail; payload: string }
  | { type: SearchBudgetPlanMembershipActionType.ResetSearchMembership }
  | { type: SearchBudgetPlanMembershipActionType.LinkingUserStart }
  | {
      type: SearchBudgetPlanMembershipActionType.LinkingUserSuccess;
      payload: LinkingUserSuccessActionPayload;
    }
  | {
      type: SearchBudgetPlanMembershipActionType.LinkingUserFailure;
      payload: string;
    };

interface LinkingUserSuccessActionPayload {
  membership: BudgetPlanMembershipDto;
  message: string;
  messageType: HelperTextType;
}
interface LinkMembershipState {
  messageType: HelperTextType;
  message: string;
  status: ApiStatusService;
  membership: null | BudgetPlanMembershipDto;
}
