import { useEffect, useState } from 'react';

import { MapFilters } from '../components/Map/ZoneControls/MapFilters';
import { MspType } from '../models';
import { LiveVehicle } from '../state/common/vehicle.model';

export const useApplicableVehiclesForFilters: UseApplicableVehiclesForFilters = (vehicles, filters) => {
  const [filteredVehicles, setFilteredVehicles] = useState<LiveVehicle[]>([]);

  useEffect(() => {
    const { mspTypes, vehicleTypes, vehicleStatuses } = filters;
    const applicableVehicles = vehicles
      .filter((vehicle) => vehicleStatuses.includes(vehicle.usageStatus))
      .filter(({ mspName }) => mspTypes.includes(mspName as MspType))
      .filter(({ formFactor }) => vehicleTypes.map((vehicleType) => vehicleType?.toLowerCase()).includes(formFactor.toLowerCase()));

    setFilteredVehicles(applicableVehicles);
  }, [vehicles, filters]);

  return filteredVehicles;
};

type UseApplicableVehiclesForFilters = (vehicles: LiveVehicle[], filters: MapFilters) => LiveVehicle[];
