import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { sidebarContentStyles } from './styles';

export function UserNameAndId({ firstName, lastName, subjectClaim }: UserNameAndIdProps) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box sx={sidebarContentStyles}>
      <Typography variant="bodyLargeBold" color={theme.palette.dark.main}>
        {lastName}, {firstName}
      </Typography>
      <Grid container direction="row">
        <Typography variant="bodySmallBold" color={theme.palette.dark.main} sx={{ paddingRight: '5px' }}>
          {' '}
          {formatMessage({ id: 'customerInformationModal.customerId' })}
        </Typography>
        <Typography variant="bodySmall" color={theme.palette.dark.shade76}>
          {subjectClaim ? subjectClaim : 'no id'}
        </Typography>
      </Grid>
    </Box>
  );
}

interface UserNameAndIdProps {
  firstName: string;
  lastName: string;
  subjectClaim?: string;
}
