import { useMemo } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';

import { DEFAULT_DATETIME_FORMAT_DAYJS } from '../../utils/constants';

import { getValidationSchema } from './DateTimeRangeSearchForm.helpers';
import { errorTextStyles } from './DateTimeRangeSearchForm.styles';
import { SearchAndResetButtonContainer } from './SearchAndResetButtonContainer';

export interface DateTimeRangeSearchFormProps {
  onSearch: (formValues: StartAndEndDateTimeRangeValues) => void;
  loading?: boolean;
  startDateTimeTranslationKey?: string;
  endDateTimeTranslationKey?: string;
  startDateStyles?: SxProps;
  endDateStyles?: SxProps;
}

export interface StartAndEndDateTimeRangeValues {
  startDateTime: Dayjs | null;
  endDateTime: Dayjs | null;
}

export const DateTimeRangeSearchForm = (props: DateTimeRangeSearchFormProps) => {
  const { onSearch, loading = false, startDateTimeTranslationKey, endDateTimeTranslationKey, startDateStyles, endDateStyles } = props;
  const intl = useIntl();
  const theme = useTheme();
  const { formatMessage } = intl;
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xxl));

  const validationSchema = useMemo(() => getValidationSchema(intl), [intl]);

  const formik = useFormik<StartAndEndDateTimeRangeValues>({
    initialValues: {
      startDateTime: null,
      endDateTime: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values: StartAndEndDateTimeRangeValues) => {
      onSearch(values);
    },
  });

  const handleStartDateTimeChange = (startDateTime: Dayjs | null) => {
    formik.setFieldValue('startDateTime', startDateTime);
  };

  const handleEndDateTimeChange = (endDateTime: Dayjs | null) => {
    formik.setFieldValue('endDateTime', endDateTime);
  };

  const handleEndDateTimePickerPopupOpen = () => {
    if (!formik.values.endDateTime) {
      const defaultEndDateTime = dayjs().endOf('day');
      formik.setFieldValue('endDateTime', defaultEndDateTime);
    }
  };

  const handleStartDateTimePickerPopupOpen = () => {
    if (!formik.values.startDateTime) {
      // TODO: replace it with our custom dayjs toBegingingOfDay plugin once (MR-223 have this plugin) => dayjs().toBegingingOfDay()
      const defaultStartDateTime = dayjs().startOf('day');
      formik.setFieldValue('startDateTime', defaultStartDateTime);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={!isTablet ? 3 : 1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl variant="standard" error={!!formik.errors.startDateTime} sx={{ position: 'relative', flex: 1 }}>
            <DateTimePicker
              disableFuture
              label={formatMessage({ id: startDateTimeTranslationKey || 'common.start' })}
              value={formik.values.startDateTime}
              format={DEFAULT_DATETIME_FORMAT_DAYJS}
              onOpen={handleStartDateTimePickerPopupOpen}
              onChange={handleStartDateTimeChange}
              timeSteps={{ minutes: 1 }}
              ampm={false}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={startDateStyles}
            />
            <FormHelperText id="startDateTime" sx={errorTextStyles}>
              {formik.errors.startDateTime}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" error={!!formik.errors.endDateTime} sx={{ position: 'relative', flex: 1 }}>
            <DateTimePicker
              label={formatMessage({ id: endDateTimeTranslationKey || 'common.end' })}
              value={formik.values.endDateTime}
              disabled={!formik.values.startDateTime}
              format={DEFAULT_DATETIME_FORMAT_DAYJS}
              onOpen={handleEndDateTimePickerPopupOpen}
              onChange={handleEndDateTimeChange}
              timeSteps={{ minutes: 1 }}
              ampm={false}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={endDateStyles}
            />
            <FormHelperText id="endDateTime" sx={errorTextStyles}>
              {formik.errors.endDateTime}
            </FormHelperText>
          </FormControl>
        </LocalizationProvider>

        <SearchAndResetButtonContainer isValid={formik.isValid} loading={loading} dirty={formik.dirty} />
      </Box>
    </form>
  );
};
