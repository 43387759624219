import React from 'react';

import { BudgetPlanLayout } from './BudgetPlanLayout';
import { BudgetPlanProvider } from './contexts/BudgetPlan';

const BudgetPlan = () => {
  return (
    <BudgetPlanProvider>
      <BudgetPlanLayout />
    </BudgetPlanProvider>
  );
};

export default BudgetPlan;
