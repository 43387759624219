import { Fragment, ReactNode } from 'react';

import { TextField } from '@mui/material';
import type { TextFieldProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import type { SxProps } from '@mui/material/styles';

import { ReactComponent as CancelOutlinedIcon } from '../../../../assets/icons/outline/closeCircle.svg';
import { ReactComponent as SearchOutlinedIcon } from '../../../../assets/icons/outline/search.svg';

export const SearchInput = ({
  variant = 'outlined',
  label,
  placeholder,
  onChange,
  onSearchClick,
  value,
  size = 'medium',
  sx,
  textFieldProps,
  hasError = false,
  helperText = null,
  showSearchIcon = true,
  showClearIcon = false,
}: SearchInputProps) => {
  return (
    <TextField
      color="primary"
      variant={variant}
      placeholder={placeholder}
      label={label}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      size={size}
      sx={{ ...sx, pr: 0 }}
      helperText={helperText}
      error={hasError}
      InputProps={{
        endAdornment: (
          <Fragment>
            {value && showClearIcon && (
              <IconButton onClick={() => onChange('')} sx={{ mr: '-8px' }}>
                <CancelOutlinedIcon />
              </IconButton>
            )}
            {showSearchIcon && (
              <IconButton
                onClick={() => {
                  if (onSearchClick) onSearchClick(value);
                }}
                sx={{ mr: '-8px' }}
              >
                <SearchOutlinedIcon />
              </IconButton>
            )}
          </Fragment>
        ),
      }}
      {...textFieldProps}
    />
  );
};

interface SearchInputProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  onSearchClick?: (searchString: string) => void;
  sx?: SxProps;
  label: TextFieldProps['label'];
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
  textFieldProps?: TextFieldProps;
  hasError?: boolean;
  helperText?: ReactNode;
  showSearchIcon?: boolean;
  showClearIcon?: boolean;
}
