import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

import { TranslatedText } from '../../../../wmv-components';
import { useSearchBudgetPlanMembership } from '../contexts';

import { MembershipDetailsRow } from './MembershipDetailsRow';

export const SearchedBudgetPlanMembershipUserDetails = () => {
  const theme = useTheme();
  const searchMembershipState = useSearchBudgetPlanMembership();
  const { searchedMembership } = searchMembershipState;
  const { firstName, lastName, email } = searchedMembership!.details;

  return (
    <Box mb={2}>
      <TranslatedText translationKey="budgetPlanMembershipDetails.title" variant="bodyMediumBold" color={theme.palette.baseLight.main} />
      <Box display="flex" flexDirection="column" gap={1} mt={2}>
        <MembershipDetailsRow title={firstName!} labelTranslationKey="common.firstName" />
        <MembershipDetailsRow title={lastName!} labelTranslationKey="common.lastName" />
        <MembershipDetailsRow title={email!} labelTranslationKey="common.emailAddress" />
      </Box>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};
