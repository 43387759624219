import { forwardRef, ReactNode, useCallback } from 'react';

import { enqueueSnackbar, useSnackbar, SnackbarContent, SnackbarProvider } from 'notistack';
import type { CustomContentProps } from 'notistack';

import { ALERT_HIDE_DURATION, MAX_ALLOWED_ALERTS } from '../utils/constants';

import { Alert, SnackbarAlertType } from './SnackbarAlert';

declare module 'notistack' {
  interface VariantOverrides {
    wmvDefault: true;
  }
  interface OptionsObject {
    type: SnackbarAlertType;
  }
}

export const WmvEnqueuedSnackbar = forwardRef<HTMLDivElement, WmvEnqueuedSnackbarProps>(
  ({ id, message, type, variant, ...extraProps }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} {...extraProps}>
        <Alert onClose={handleDismiss} severity={variant} sx={{ width: '400px' }}>
          {message}
        </Alert>
      </SnackbarContent>
    );
  },
);
WmvEnqueuedSnackbar.displayName = 'WmvEnqueuedSnackbar';

export function EnqueuedSnackbarProvider({ children }: { children: ReactNode }) {
  return (
    <SnackbarProvider
      Components={{
        success: WmvEnqueuedSnackbar,
        error: WmvEnqueuedSnackbar,
      }}
      maxSnack={MAX_ALLOWED_ALERTS}
      autoHideDuration={ALERT_HIDE_DURATION}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
}

export function enqueuedSnackbar(message: string, variant: SnackbarAlertType) {
  enqueueSnackbar(message, {
    variant: variant,
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    type: variant,
  });
}

interface WmvEnqueuedSnackbarProps extends CustomContentProps {
  variant: SnackbarAlertType;
}
