import axios from 'axios';
import { JSONParse } from 'json-with-bigint';
import isEmpty from 'lodash/isEmpty';

import { toggleAccessForbiddenAlertAction } from '../state';
import store from '../state/store';

import UserService from './UserService';

const currentLanguage = localStorage.getItem('language');

const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': `${currentLanguage}`,
  },
};

const axiosInstance = axios.create(config);

// Default JSON.parse will transform the huge number which is greater than Number.MAX_SAFE_INTEGER to some random number
// JSON.parse('{ "value" : 9223372036854775807}') will result to { value: 9223372036854776000 } is an issue
// This fix bypasses that issue by using bigint to string bigint -> string hack
// see json-with-bigint library documentation for more info
axiosInstance.defaults.transformResponse = [
  (data) => {
    if (typeof data === 'string') {
      if (isEmpty(data)) {
        return data;
      }

      try {
        data = JSONParse(data);
      } catch (error) {
        throw Error(`Axios - Error parsing response JSON data - ${JSON.stringify(error)}`);
      }
    }
    return data;
  },
];

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = UserService.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 403) {
        store.dispatch(toggleAccessForbiddenAlertAction(true));
      }
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          UserService.invalidateToken();
          await UserService.refreshToken(5);
          const refreshedToken = UserService.getToken();
          originalConfig.headers['Authorization'] = `Bearer ${refreshedToken}`;
          return axiosInstance(originalConfig);
        } catch (e) {
          console.log('Error occured while refresh Token');
        }
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
