import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import CustomIcon from '../../utils/CustomIcon';

import { DropDownBoxStyle } from './AccountInformationStyles';

function AccountInformationSidebar() {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box sx={DropDownBoxStyle}>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.dark.shade8}`,
          display: 'flex',
          justifyContent: 'flexStart',
          width: '100%',
          paddingLeft: theme.spacing(2),
        }}
      >
        {' '}
        <Typography variant="h3">{formatMessage({ id: 'account.main.title' })}</Typography>{' '}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: theme.spacing(3),
          alignItems: 'center',
        }}
      >
        <Box color={theme.palette.dark.shade76} sx={{ display: 'flex', justifyContent: 'center', gap: theme.spacing(2) }}>
          {' '}
          {CustomIcon('ProfilUserIcon', theme.palette.dark.shade76)}
          <Typography variant="bodyLargeBold">{formatMessage({ id: 'account.main.user' })}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: theme.spacing(1),
            paddingLeft: '20%',
          }}
        >
          <Typography color={theme.palette.secondary.main} variant="bodyLargeBold">
            {formatMessage({ id: 'account.profile.user' })}
          </Typography>
          <Typography variant="bodyLargeBold">{formatMessage({ id: 'account.profile.sec' })}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: theme.spacing(3),
          alignItems: 'center',
        }}
      >
        <Box color={theme.palette.dark.shade76} sx={{ display: 'flex', justifyContent: 'center', gap: '17px' }}>
          {' '}
          {CustomIcon('ProfilOrgaIcon', theme.palette.dark.shade76)}
          <Typography variant="bodyLargeBold">{formatMessage({ id: 'account.main.organisation' })}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: theme.spacing(1),
            paddingLeft: '20%',
          }}
        >
          <Typography variant="bodyLargeBold">{formatMessage({ id: 'account.organisation.general' })}</Typography>
          <Typography variant="bodyLargeBold">{formatMessage({ id: 'account.organisation.users' })}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default AccountInformationSidebar;
