import { useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Dictionary } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { BudgetPlanDto } from '../../../services/budget-plan';
import {
  AppDispatch,
  budgetPlansSelector,
  getBudgetPlanAction,
  listBudgetPlansAsyncThunkAction,
  planIdToBudgetPlanSelector,
  updateBudgetPlanStatusAsyncThunkAction,
} from '../../../state';

import { RetryBudgetStatusModal } from './BudgetPlanDetails/RetryBudgetStatusModal';
import { BudgetPlanModal } from './BudgetPlanModal';
import { BudgetPlansTableWithLoadingOrNoContentMessages } from './BudgetPlansTableWithLoadingOrNoContentMessages';
import { BudgetPlanActionType, useBudgetPlanDispatch, useBudgetPlanState } from './contexts';
import { HeadingSection } from './HeadingSection';
import { pollActionCreator } from './hooks/usePolling';

export const BudgetPlanLayout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const selectedPlanState = useBudgetPlanState();
  const selectedPlanDispatch = useBudgetPlanDispatch();

  const budgetPlans: BudgetPlanDto[] = useSelector(budgetPlansSelector);
  const planIdToBudgetPlan: Dictionary<BudgetPlanDto> = useSelector(planIdToBudgetPlanSelector);

  useEffect(() => {
    // TODO: All these should not be here
    let polledActions: { start: any; stop: any }[] = [];
    if (budgetPlans.length && !selectedPlanState.budgetPlanModalVisible) {
      for (let i = 0; i < budgetPlans.length; i++) {
        if (budgetPlans[i].lastChangeAttempt.isTransitionInProgress) {
          const pollingAction = pollActionCreator(getBudgetPlanAction, budgetPlans[i].id, 5000);
          pollingAction.start(dispatch);
          polledActions.push(pollingAction);
        }
      }
    }

    return () => {
      polledActions.forEach((polledAction) => polledAction.stop());
    };
  }, [budgetPlans, dispatch, selectedPlanState.budgetPlanModalVisible]);

  const fetchBudgetPlans = useCallback(async () => {
    // TODO: See if we can remove empty object `{}`
    await dispatch(listBudgetPlansAsyncThunkAction({}));
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await fetchBudgetPlans();
    })();
  }, [fetchBudgetPlans]);

  return (
    <Box component="main">
      <HeadingSection />
      <BudgetPlansTableWithLoadingOrNoContentMessages fetchBudgetPlans={fetchBudgetPlans} />
      <BudgetPlanModal />
      {selectedPlanState.selectedFailedPlanId && (
        <RetryBudgetStatusModal
          open={!!selectedPlanState.selectedFailedPlanId}
          onClose={() => resetFailedPlanIdToNull()}
          planName={planIdToBudgetPlan[selectedPlanState.selectedFailedPlanId!].activeState.name}
          onRetry={handleTransitionFailedPlanStatusUpdateRetry}
        />
      )}
    </Box>
  );

  function resetFailedPlanIdToNull() {
    selectedPlanDispatch({
      type: BudgetPlanActionType.UpdateSelectedFailedPlanId,
      payload: null,
    });
  }

  async function handleTransitionFailedPlanStatusUpdateRetry() {
    const budgetPlan = planIdToBudgetPlan[selectedPlanState.selectedFailedPlanId!];
    await dispatch(
      updateBudgetPlanStatusAsyncThunkAction({
        id: selectedPlanState.selectedFailedPlanId!,
        payload: {
          version: budgetPlan.version,
          status: budgetPlan.lastChangeAttempt.transitionTo!,
        },
      }),
    ).unwrap();
    resetFailedPlanIdToNull();
  }
};
