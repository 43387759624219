import React, { Fragment } from 'react';

import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { NumberFormatBase } from 'react-number-format';
import type { NumericFormatProps } from 'react-number-format';

import { useSelectedVehiclePricingDetails } from './contexts';
import { vehiclePricingFormFieldName } from './helpers';
import { VehiclePricingFormValues } from './VehiclePricingDetailsForm';
import { VehiclePricingInputSection } from './VehiclePricingInputSection';

export function VehicleFeePricingInputs() {
  const { formatMessage } = useIntl();
  const selectedVehiclePricingDetails = useSelectedVehiclePricingDetails();
  const isVehiclePricingTransitionInProgress = selectedVehiclePricingDetails?.isStateChangeInProgress();
  const {
    values: {
      activeActiveMinutesFee,
      newActiveMinutesFee,
      activeActivationFee,
      newActivationFee,
      activePausedMinutesFee,
      newPausedMinutesFee,
    },
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<VehiclePricingFormValues>();

  return (
    <Fragment>
      <VehiclePricingInputSection
        activeValue={activeActivationFee!}
        activeFieldName={vehiclePricingFormFieldName.activeActivationFee}
        newValue={newActivationFee!}
        newValueFieldName={vehiclePricingFormFieldName.newActivationFee}
        onChange={handleVehiclePricingChange}
        disabled={isVehiclePricingTransitionInProgress}
        error={!!errors.newActivationFee && touched.newActivationFee}
        helperText={!!errors.newActivationFee && touched.newActivationFee ? errors.newActivationFee : null}
        sectionTitle={formatMessage({ id: 'common.activationFee' })}
        formatInputComponent={NumericFormatCustom}
      />
      <VehiclePricingInputSection
        activeValue={activeActiveMinutesFee!}
        activeFieldName={vehiclePricingFormFieldName.activeActiveMinutesFee}
        newValue={newActiveMinutesFee!}
        newValueFieldName={vehiclePricingFormFieldName.newActiveMinutesFee}
        onChange={handleVehiclePricingChange}
        disabled={isVehiclePricingTransitionInProgress}
        error={!!errors.newActiveMinutesFee && touched.newActiveMinutesFee}
        helperText={!!errors.newActiveMinutesFee && touched.newActiveMinutesFee ? errors.newActiveMinutesFee : null}
        sectionTitle={formatMessage({ id: 'common.activeMinutes' })}
        formatInputComponent={NumericFormatCustom}
      />
      <VehiclePricingInputSection
        activeValue={activePausedMinutesFee!}
        activeFieldName={vehiclePricingFormFieldName.activePausedMinutesFee}
        newValue={newPausedMinutesFee!}
        newValueFieldName={vehiclePricingFormFieldName.newPausedMinutesFee}
        onChange={handleVehiclePricingChange}
        disabled={isVehiclePricingTransitionInProgress}
        error={!!errors.newPausedMinutesFee && touched.newPausedMinutesFee}
        helperText={!!errors.newPausedMinutesFee && touched.newPausedMinutesFee ? errors.newPausedMinutesFee : null}
        sectionTitle={formatMessage({ id: 'common.pausedMinutes' })}
        formatInputComponent={NumericFormatCustom}
      />
    </Fragment>
  );

  async function handleVehiclePricingChange({ name, value }: { name: string; value: number }) {
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
  }
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, prefix, suffix, value, allowEmptyFormatting = true, ...other } = props;

  function currencyFormatter(value: any) {
    if (allowEmptyFormatting && isEmpty(value)) return prefix + suffix;
    return Number(value).toCents({ signDisplay: 'never' }).replace('€', '').trim();
  }

  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={value}
      format={currencyFormatter}
    />
  );
});

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix: string;
  suffix: string;
  value: number;
  allowEmptyFormatting?: boolean;
}
