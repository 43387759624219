import { useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../components/LoadingAndErrorWithRetryAndNoResults';
import MapFiltersAndZoneTypeInfoControls from '../../../components/Map/ZoneControls';
import { MapTableView } from '../../../components/MapTableView';
import {
  useApplicableVehiclesForFilters,
  useEvaluateZonesVisibilityWithTheTime,
  useFilterZonesByPredicates,
  useMapFilterChange,
} from '../../../hooks';
import { FloatingButtonType, graphqlVehicleTypeToVehicleType } from '../../../models';
import { allVehiclesSelector, loadingVehiclesErrorSelector, loadingVehiclesSelector } from '../../../state';
import { HEADER_HEIGHT, HEADER_TABLE_GAP_HEIGHT, TABLE_HEIGHT_NO_SECONDARY } from '../../../utils/constants';
import { FloatingBox, NonClickableBreadCrumb, ReactTable, WmvButtonGroup } from '../../../wmv-components';
import { switchButtonConfig } from '../helpers';

import useFetchMspVehicles from './hooks/useFetchMspVehicles';
import useFetchPublishedZones from './hooks/useFetchPublishedZones';
import OperatorColumns from './OperatorColumns';

const Operator = () => {
  const location = useLocation();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const queryParams = new URLSearchParams(location.search);
  const listViewQueryParam = queryParams.get('openListView');
  const initialMapViewType = listViewQueryParam === 'true' ? FloatingButtonType.ListMode : FloatingButtonType.MapMode;

  const [openMarkerId, setOpenMarkerId] = useState<string | null | undefined>(null);
  const [processingStatuses, setProcessingStatuses] = useState<{ [key: string]: boolean }>({});
  const [activeView, setActiveView] = useState(initialMapViewType);
  //Assumption: There will be periodic checks for aggregated Vehicles in the future - This is a mock simulating that some status changes will not be successful
  const [failedStatuses, setFailedStatuses] = useState<{ [key: string]: boolean }>({});

  const { allMspVehicles: dataVehicles, loading: loadingMspVehicle, error: errorMspVehicle, getAggregatedVehicles } = useFetchMspVehicles();
  const { allPublishedZones: publishedZones, getPublishedZones } = useFetchPublishedZones();

  const zonesApplicableAtCurrentTime = useEvaluateZonesVisibilityWithTheTime(publishedZones);
  const { filters, anyFilterApplied, handleFilterChange, resetFilters } = useMapFilterChange();

  const loadingVehiclesError = useSelector(loadingVehiclesErrorSelector);
  const loadingVehicles: boolean = useSelector(loadingVehiclesSelector);
  const allVehicles = useSelector(allVehiclesSelector);

  const filteredVehicles = useApplicableVehiclesForFilters(allVehicles, filters);
  const filteredZones = useFilterZonesByPredicates(zonesApplicableAtCurrentTime, {
    zoneTypes: filters.zoneTypes,
    vehicleTypes: filters.vehicleTypes.map((graphqlVehicleType) => graphqlVehicleTypeToVehicleType.get(graphqlVehicleType)!),
  });

  const columns = OperatorColumns({
    processingStatuses,
    setProcessingStatuses,
    failedStatuses,
    setFailedStatuses,
    onVehicleLocationClick: handleVehicleLocationClick,
  });

  function handleVehicleLocationClick(markerId: string) {
    setOpenMarkerId(markerId);
    setActiveView(FloatingButtonType.MapMode);
  }

  const handleSwitchModeButtonClick = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, switchModeButtonId: string) => {
    setActiveView(switchModeButtonId as FloatingButtonType);
  };

  const fetchAggregateVehiclesAndPublishedZones = async () => {
    await Promise.all([getAggregatedVehicles(), getPublishedZones()]);
  };

  const breadCrumbTexts = [formatMessage({ id: 'menu.support' }), formatMessage({ id: 'support.fleetManagement' })];
  const isMapMode = activeView === FloatingButtonType.MapMode;
  const isSupporterMode = listViewQueryParam === 'true';
  return (
    <>
      {loadingMspVehicle || errorMspVehicle || !dataVehicles.length ? (
        <LoadingAndErrorWithRetryAndNoResults
          loading={loadingMspVehicle}
          error={errorMspVehicle}
          baseTranslationKey="operator"
          onRetry={fetchAggregateVehiclesAndPublishedZones}
          style={{ height: `calc(100vh - ${HEADER_HEIGHT})` }}
          noResults={!dataVehicles.length}
        />
      ) : (
        <Box sx={{ backgroundColor: theme.palette.dark.shade3 }}>
          {isSupporterMode && <NonClickableBreadCrumb values={breadCrumbTexts} />}
          <FloatingBox boxStyling={{ top: isSupporterMode ? '156px' : '104px' }}>
            <WmvButtonGroup config={switchButtonConfig} activeButtonId={activeView} onClick={handleSwitchModeButtonClick} />
            {isMapMode && (
              <MapFiltersAndZoneTypeInfoControls
                filters={filters}
                isAnyFilterApplied={anyFilterApplied}
                onFilterChange={handleFilterChange}
              />
            )}
          </FloatingBox>

          {!isMapMode ? (
            <Box sx={{ paddingTop: HEADER_TABLE_GAP_HEIGHT }}>
              {dataVehicles.length > 0 && (
                <ReactTable
                  loading={loadingMspVehicle}
                  columns={[...columns]}
                  tableContainerStyle={{
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px',
                    backgroundColor: theme.palette.white.main,
                  }}
                  customRowHoverStyle={{ backgroundColor: theme.palette.secondary.tint }}
                  displayLastDifferent={true}
                  data={dataVehicles}
                />
              )}
            </Box>
          ) : (
            <Box sx={{ height: TABLE_HEIGHT_NO_SECONDARY }}>
              <MapTableView
                zones={filteredZones}
                vehicles={filteredVehicles}
                filters={filters}
                isAnyFilterApplied={anyFilterApplied}
                onFiltersReset={resetFilters}
                loadingVehicles={loadingVehicles}
                loadingVehiclesError={loadingVehiclesError}
                defaultMarkerId={openMarkerId}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Operator;
