import type { MouseEvent } from 'react';

import ButtonGroup from '@mui/material/ButtonGroup';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { btnGroupSx, StyledButton } from './WmvButtonGroup.styles';

export const WmvButtonGroup = ({ config, activeButtonId, onClick }: WmvButtonGroupProps) => {
  const { palette } = useTheme();

  return (
    <ButtonGroup variant="outlined" sx={btnGroupSx}>
      {config.map((config: ButtonGroupItemConfig) => {
        const isActive = activeButtonId === config.switchModeButtonId;

        return (
          <StyledButton
            key={config.switchModeButtonId}
            onClick={(e) => onClick(e, config.switchModeButtonId)}
            isActive={isActive}
            variant="contained"
          >
            <Typography
              variant={isActive ? 'bodyMediumBold' : 'bodyMedium'}
              color={isActive ? palette.baseLight.main : palette.baseLight.base76}
            >
              {config.text}
            </Typography>
          </StyledButton>
        );
      })}
    </ButtonGroup>
  );
};

interface WmvButtonGroupProps {
  config: ButtonGroupItemConfig[];
  activeButtonId: string;
  onClick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, switchModeButtonId: string) => void;
}

export interface ButtonGroupItemConfig {
  text: string;
  switchModeButtonId: string;
}
