import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface WidgetProps {
  img: string;
  title: string;
  description: string;
  url: string;
}

const SupportCard = ({ img, title, description, url }: WidgetProps) => {
  const theme = useTheme();

  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <Card
      sx={{
        flex: '1 0 30%',
        minWidth: '300px',
        minHeight: '260px',
        maxHeight: '260px',
        maxWidth: '300px',
        borderRadius: '8px',
        boxShadow: theme.shadowOptions.belowMedium,
        '&:hover': {
          boxShadow: theme.shadowOptions.belowLarge,
          backgroundColor: 'none',
        },
      }}
      onClick={handleClick}
    >
      <CardActionArea>
        <CardMedia component="img" height="140" image={img} alt={title} />
        <CardContent>
          <Typography sx={{ margin: 0 }} gutterBottom variant="bodyLargeBold" component="div">
            {title}
          </Typography>
          <Typography variant="bodyMedium" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SupportCard;
