import { ReactElement, ReactNode } from 'react';

import { IconButton, Modal as MuiModal } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import CustomIcon from '../../../utils/CustomIcon';

export const Modal = ({ open, onClose, children, bgColor, disableClose = false }: ModalProps) => {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <MuiModal open={open || disableClose} onClose={(e, reason) => onClose(reason)}>
      <Box sx={modalContentStyles(theme, bgColor)}>
        {children}
        <Box sx={closeIconButtonStyles}>
          <IconButton onClick={() => onClose('closeIconClick')} disabled={disableClose}>
            {CustomIcon('CloseIcon', palette.white.main, '32px')}
          </IconButton>
        </Box>
      </Box>
    </MuiModal>
  );
};

const ModalContent = ({ children, sx }: ModalContentProps) => {
  return <Box sx={sx}>{children}</Box>;
};

const modalContentStyles = (theme: Theme, bgColor?: string) => ({
  position: 'absolute',
  minHeight: '400px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: `${theme.spacing(2)}`,
  borderRadius: '8px',
  backgroundColor: bgColor || theme.palette.baseLight.base4,
  display: 'flex',
  flexDirection: 'column',
});

const closeIconButtonStyles = (theme: Theme) => ({
  position: 'absolute',
  top: '-40px',
  right: '-40px',
});

interface ModalProps {
  open: boolean;
  onClose: (closeReason: 'backdropClick' | 'escapeKeyDown' | 'closeIconClick') => void;
  children: ReactElement;
  disableClose?: boolean;
  bgColor?: string;
}

interface ModalContentProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

Modal.Content = ModalContent;
