import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../reducers';

const commonState = (state: RootState) => state.common;

export const allVehiclesSelector = createSelector(
  (state: RootState) => commonState(state),
  (commonState) => {
    return commonState.allVehicles;
  },
);

export const loadingVehiclesSelector = (state: RootState) => state.common.loadingVehiclesData;
export const loadingVehiclesErrorSelector = (state: RootState) => state.common.loadingVehiclesError;
export const changingLanguageSelector = (state: RootState) => state.common.language;

export const accessForbiddenAlertVisibleSelector = (state: RootState) => state.common.accessForbiddenAlertVisible;
