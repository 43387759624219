import type { ChangeEvent, ForwardRefExoticComponent, ReactNode } from 'react';

import { FormControl } from '@mui/material';
import type { CheckboxProps } from '@mui/material/Checkbox';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import type { SxProps, Theme } from '@mui/material/styles';

// TODO: See if we can generify the value type
export interface CheckboxMetadata {
  name?: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  color?: CheckboxProps['color'];
}
export interface CheckboxComponentProps extends CheckboxMetadata {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: CheckboxProps['size'];
  checked: boolean;
  hasError?: boolean;
  helperText?: string;
}

export interface CheckboxGroupComponentProps {
  options: CheckboxMetadata[];
  size?: CheckboxProps['size'];
  disabled?: boolean;
  checkedValues: (string | number)[];
  onChange: (values: (string | number)[]) => void;
  sx?: SxProps<Theme>;
  color?: CheckboxProps['color'];
  hasError?: boolean;
  helperText?: ReactNode;
}

const InternalCheckboxComponent = ({
  checked,
  onChange,
  name,
  value,
  size = 'small',
  label,
  disabled = false,
  color = 'secondary',
}: CheckboxComponentProps) => {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox checked={checked} onChange={onChange} name={name} size={size} value={value} disabled={disabled} color={color} />
      }
      label={label}
    />
  );
};

const CheckboxGroupComponent = ({
  options,
  size = 'small',
  disabled,
  checkedValues,
  onChange,
  sx,
  color = 'secondary',
  hasError,
  helperText,
}: CheckboxGroupComponentProps) => {
  return (
    <FormControl error={hasError}>
      <FormGroup sx={sx}>
        {options.map((option: CheckboxMetadata, index: number) => {
          const { name, value, label, disabled: checkboxDisabled = false } = option;

          const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            const index = checkedValues.indexOf(value);
            if (index > -1) {
              checkedValues = checkedValues.toSpliced(index, 1);
            } else {
              checkedValues = [...checkedValues, value];
            }
            onChange(checkedValues);
          };

          return (
            <InternalCheckboxComponent
              checked={checkedValues.includes(value)}
              disabled={checkboxDisabled || disabled}
              onChange={handleChange}
              key={index}
              name={name}
              value={value}
              size={size}
              color={color}
              label={label}
            />
          );
        })}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

type CompoundedCheckboxComponent = ForwardRefExoticComponent<CheckboxComponentProps> & {
  Group: typeof CheckboxGroupComponent;
};

export const Checkbox = InternalCheckboxComponent as CompoundedCheckboxComponent;
Checkbox.Group = CheckboxGroupComponent;
