import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { Column, Row } from 'react-table';

import CustomIcon from '../../../utils/CustomIcon';
import { StartAndEndDateTimeRangeValues, ReactTable } from '../../../wmv-components';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';

export interface LawEnforcementTripSearchResult {
  startDateFormatted: string;
  startTimeFormatted: string;
  endDateFormatted: string;
  endTimeFormatted: string;
  vehicleType: string;
  qrCodeId: string;
  firstName: string;
  lastName: string;
  streetName: string;
  houseNumber: string;
}

interface LawEnforcementTripSearchResultsTableProps {
  searchResults: LawEnforcementTripSearchResult[];
  loading: boolean;
  error: boolean;
  onRetry: (formValues: StartAndEndDateTimeRangeValues) => void;
  lastSearchFormValues: StartAndEndDateTimeRangeValues | null;
  noSearchResults: boolean;
}

const getFormattedColumns = (formatMessage: any): Column<LawEnforcementTripSearchResult>[] => [
  {
    Header: formatMessage({ id: 'maas.vehicleTrip.details.startDate' }),
    accessor: 'startDateFormatted',
  },
  {
    Header: formatMessage({ id: 'maas.vehicleTrip.details.startTime' }),
    accessor: 'startTimeFormatted',
  },
  {
    Header: formatMessage({ id: 'maas.vehicleTrip.details.endDate' }),
    accessor: 'endDateFormatted',
  },
  {
    Header: formatMessage({ id: 'maas.vehicleTrip.details.endTime' }),
    accessor: 'endTimeFormatted',
  },
  {
    Header: formatMessage({ id: 'maas.vehicleTrip.details.vehicleType' }),
    accessor: 'vehicleType',
    Cell: ({ row }: { row: Row<LawEnforcementTripSearchResult> }) => {
      const vehicleTypeIcons = {
        electric_bike: CustomIcon('ebikeTooltipIcon', '', '22px'),
        electric_moped: CustomIcon('mopedTooltipIcon', '', '22px'),
        car: CustomIcon('carTooltipIcon', '', '22px'),
        cargo_bicycle: CustomIcon('cargobikeTooltipIcon', '', '22px'),
        electric_scooter: CustomIcon('scooterTooltipIcon', '', '22px'),
      };
      const IconComponent = vehicleTypeIcons[row.original.vehicleType.toLocaleLowerCase() as keyof typeof vehicleTypeIcons] || null;
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '4px',
            padding: '4px',
          }}
        >
          {IconComponent}
          <Typography variant="bodySmall" px={1}>
            {formatMessage({ id: `mspVehicleType.${row.original.vehicleType.toLowerCase()}` })}
          </Typography>
        </Box>
      );
    },
  },
  { Header: formatMessage({ id: 'maas.vehicleTrip.details.qrCodeId' }), accessor: 'qrCodeId' },
  { Header: formatMessage({ id: 'maas.customer.details.firstName' }), accessor: 'firstName' },
  { Header: formatMessage({ id: 'maas.customer.details.lastName' }), accessor: 'lastName' },
  { Header: formatMessage({ id: 'maas.customer.details.streetName' }), accessor: 'streetName' },
];

export const LawEnforcementTripSearchResultsTable = (props: LawEnforcementTripSearchResultsTableProps) => {
  const { searchResults, loading, error, onRetry, lastSearchFormValues, noSearchResults } = props;
  const { formatMessage } = useIntl();
  const { palette } = useTheme();

  const columns = useMemo(() => getFormattedColumns(formatMessage), [formatMessage]);

  return (
    <>
      {searchResults.length > 0 ? (
        <ReactTable
          columns={columns}
          data={searchResults}
          tableContainerStyle={{
            height: `calc(100vh - (194px))`,
            backgroundColor: palette.white.main,
          }}
          loading={loading}
        />
      ) : (
        // 280px depends on the preceding elements; needed to center the text vertically
        <LoadingAndErrorWithRetryAndNoResults
          style={{ minHeight: `calc(100vh - (192px))`, backgroundColor: palette.white.main }}
          error={error}
          loading={loading}
          onRetry={() => {
            if (lastSearchFormValues) onRetry(lastSearchFormValues);
          }}
          baseTranslationKey="lawEnforcementTripSearch"
          noResults={noSearchResults}
        />
      )}
    </>
  );
};
