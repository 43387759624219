import type { Theme } from '@mui/material/styles';

export const headerSectionContainerStyles = (theme: Theme) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

// Create Budget form Modal
export const modalContentContainerStyles = (theme: Theme) => ({
  position: 'absolute',
  width: '80%',
  height: '90%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  borderRadius: '8px',
  backgroundColor: theme.palette.baseLight.base4,
  display: 'flex',
  gap: theme.spacing(2),
});

export const contentBodyStyles = (theme: Theme) => ({
  padding: theme.spacing(2),
  height: '100%',
  backgroundColor: theme.palette.white.main,
  borderRadius: '8px',
  flex: 1,
  overflow: 'auto',
});

export const contentHeadingStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginBottom: theme.spacing(5),
});

export const closeIconButtonStyles = (theme: Theme) => ({
  position: 'absolute',
  top: '-40px',
  right: '-40px',
});

export const inputContainerStyles = {
  display: 'flex',
  gap: '16px',
};

export const inputTitleAndDescriptionContainerStyles = {
  width: '350px',
  maxWidth: '350px',
};

export const vehicleTypesCheckboxGroupStyles = (theme: Theme) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  justifyItems: 'stretch',
  rowGap: theme.spacing(2),
  flex: 1,
  marginTop: theme.spacing(4),
});

export const budgetPlanFormInputStyles = (theme: Theme) => ({
  width: '300px',
});

export const actionButtonStyles = (theme: Theme) => ({
  marginLeft: 1,
  width: '120px',
});

// Table Cell
export const statusIndicatorCircleStyles = (theme: Theme, color: string) => ({
  height: '14px',
  width: '14px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: theme.spacing(1),
});

export const statusContainerStyles = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
});
