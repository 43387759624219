import type { CSSProperties } from 'react';

import { FormControl, MenuItem, Checkbox, InputLabel, OutlinedInput } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { useFormikContext } from 'formik';

import { ListItemConfig } from '../../models';
import { intl } from '../../utils/intl';

const allOptionConfig = {
  value: 'all',
  label: intl.formatMessage({ id: 'common.all' }),
};

const CustomSelectField = ({ field, form, ...props }: CustomSelectFieldProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { outlineLabel, multiple, value = [], name, options, style } = props;

  const handleChange = async (event: SelectChangeEvent<MenuItemValueType[]>) => {
    const newSelectedValues = event.target.value as MenuItemValueType[];
    const isAllExistsInSelectedValues = newSelectedValues.includes(allOptionConfig.value);
    if (isAllExistsInSelectedValues) {
      const isAllAlreadySelected = newSelectedValues.length === options.length + 1;
      const allValues = options.map((option) => option.value);
      const selectedValues = isAllAlreadySelected ? [] : allValues;
      await setFieldValue(name, selectedValues);
      return;
    }
    await setFieldValue(name, newSelectedValues);
  };

  const getRenderValue = (selected: MenuItemValueType[]) => {
    if (!multiple) return selected[0];
    if (!value) return '';
    if (value.length === options.length) return allOptionConfig.label;
    return options
      .filter((option) => option.value !== allOptionConfig.value && selected.includes(option.value))
      .map((op) => op.label)
      .join(', ');
  };

  return (
    <FormControl sx={{ width: 275, ...style }}>
      <InputLabel size="small" id={outlineLabel}>
        {outlineLabel}
      </InputLabel>
      <Select
        multiple={multiple}
        fullWidth
        size="small"
        labelId={outlineLabel}
        id={outlineLabel}
        value={value}
        label={name}
        onChange={handleChange}
        onBlur={async () => {
          await setFieldTouched(name, true);
        }}
        sx={{
          '& fieldset': { top: 0 },
        }}
        renderValue={getRenderValue}
        input={<OutlinedInput label={outlineLabel} />}
      >
        <MenuItem key="012" value={allOptionConfig.value}>
          <ListItemIcon>
            <Checkbox checked={value.length === options.length} indeterminate={value.length > 0 && value.length < options.length} />
          </ListItemIcon>
          <ListItemText>{allOptionConfig.label}</ListItemText>
        </MenuItem>
        {options.map((option: ListItemConfig<MenuItemValueType>, index: number) => (
          <MenuItem key={index} value={option.value}>
            <ListItemIcon>
              <Checkbox checked={value.includes(option.value)} />
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export type MenuItemValueType = string | number | readonly string[] | undefined;

export interface CustomSelectFieldProps {
  field: string;
  form: any;
  name: string;
  outlineLabel: string;
  options: ListItemConfig<MenuItemValueType>[];
  width?: string;
  value?: MenuItemValueType[];
  disabled?: boolean;
  defaultValue?: MenuItemValueType;
  multiple?: boolean;
  style: CSSProperties;
}

export default CustomSelectField;
