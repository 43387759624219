import type { IntlShape } from 'react-intl';

import { Category, SupportCard, TableColumnType } from '../models';

import { intl } from './intl';

export const HEADER_HEIGHT = '88px';
export const TABLE_FOOTER_HEIGHT = '55px';
export const HEADER_TABLE_GAP_HEIGHT = '80px';
export const SUPPORT_SUBHEADER_HEIGHT = '52px';
export const TABLE_HEIGHT = `calc(100vh - (${HEADER_HEIGHT} + ${HEADER_TABLE_GAP_HEIGHT} + ${TABLE_FOOTER_HEIGHT}))`;
export const TABLE_HEIGHT_NO_SECONDARY = `calc(100vh - (${HEADER_HEIGHT} + ${TABLE_FOOTER_HEIGHT}))`;
export const VIEW_PANNING_MODE_VIEW_HEIGHT = '400px';
export const VIEW_PLANNING_MODE_TABLE_HEIGHT = '200px';

export const analyticsTableAllColumns = [
  {
    id: 0,
    Header: intl.formatMessage({ id: 'analytics.table.column.id' }),
    accessor: 'qrCodeId', // accessor is the "key" in the data
    filter: 'text',
    label: intl.formatMessage({ id: 'analytics.table.column.id' }),
    value: 'qrCodeId',
  },
  {
    id: 1,
    Header: intl.formatMessage({ id: 'analytics.table.column.vehicleId' }),
    accessor: 'vehicleId', // accessor is the "key" in the data
    filter: 'text',
    label: intl.formatMessage({ id: 'analytics.table.column.vehicleId' }),
    value: 'vehicleId',
  },
  {
    id: 2,
    Header: intl.formatMessage({ id: 'analytics.table.column.formFactor1' }),
    accessor: 'formFactor1',
    filter: 'text',
    label: intl.formatMessage({ id: 'analytics.table.column.formFactor1' }),
    value: 'formFactor1',
  },
  {
    id: 3,
    Header: intl.formatMessage({ id: 'analytics.table.column.mspName' }),
    accessor: 'mspName',
    filter: 'select',
    label: intl.formatMessage({ id: 'analytics.table.column.mspName' }),
    value: 'mspName',
  },
  {
    id: 4,
    Header: intl.formatMessage({ id: 'analytics.table.column.formFactor' }),
    accessor: 'formFactor',
    filter: 'select',
    label: intl.formatMessage({ id: 'analytics.table.column.formFactor' }),
    value: 'formFactor',
  },
  {
    id: 5,
    Header: intl.formatMessage({ id: 'analytics.table.column.last_event_type' }),
    accessor: 'last_event_type',
    filter: 'select',
    label: intl.formatMessage({ id: 'analytics.table.column.last_event_type' }),
    value: 'last_event_type',
  },
  {
    id: 6,
    Header: intl.formatMessage({ id: 'analytics.table.column.usageStatus' }),
    accessor: 'usageStatus',
    filter: 'text',
    label: intl.formatMessage({ id: 'analytics.table.column.usageStatus' }),
    value: 'usageStatus',
  },
];

export const valueTypeOptions = [
  {
    label: intl.formatMessage({ id: 'analytics.filter.value.text' }),
    value: 'text',
  },
  {
    label: intl.formatMessage({ id: 'analytics.filter.value.number' }),
    value: 'number',
  },
];

export const operatorOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: 'operator.filters.query.is' }),
    value: 'is',
  },
  {
    label: intl.formatMessage({ id: 'operator.filters.query.is-not' }),
    value: 'is-not',
  },
  {
    label: intl.formatMessage({ id: 'operator.filters.query.starts-with' }),
    value: 'starts-with',
  },
  {
    label: intl.formatMessage({ id: 'operator.filters.query.starts-with-not' }),
    value: 'starts-with-not',
  },
  {
    label: intl.formatMessage({ id: 'operator.filters.query.contains' }),
    value: 'contains',
  },
];

export const phoneNumberCountryCodeAndLabels = [
  {
    label: '+49',
    value: '+49',
  },
  {
    label: '+48',
    value: '+48',
  },
  {
    label: '+41',
    value: '+41',
  },
];

export const MOCK_FILTERPROFILES = [
  {
    filterName: 'default',
    visibleColumns: analyticsTableAllColumns.filter((col) => col.id < 7),
    columnFilters: [],
    favorite: false,
  },
  {
    filterName: 'Filter Set 1',
    visibleColumns: analyticsTableAllColumns.filter((col: TableColumnType) => col.id < 6),
    columnFilters: [
      ...analyticsTableAllColumns
        .filter((col) => col.accessor !== 'mspName')
        .map((col) => ({
          column: col,
          valueType: {
            label: 'Text',
            value: 'text',
          },
          operator: {
            label: 'Is...',
            value: 'is',
          },
          value: '',
        })),
      {
        column: analyticsTableAllColumns[3],
        valueType: {
          label: 'Text',
          value: 'text',
        },
        operator: {
          label: 'Is...',
          value: 'is',
        },
        value: 'STWB Bamberg',
      },
    ],
    favorite: true,
  },
  {
    filterName: 'Filter Set 2',
    visibleColumns: analyticsTableAllColumns.filter((col: TableColumnType) => col.id < 6),
    columnFilters: analyticsTableAllColumns.map((col) => ({
      column: col,
      valueType: {
        label: 'Text',
        value: 'text',
      },
      operator: {
        label: 'Is...',
        value: 'is',
      },
      value: '',
    })),
    favorite: true,
  },
  {
    filterName: 'Filter Set 3',
    visibleColumns: analyticsTableAllColumns.filter((col: TableColumnType) => col.id < 6),
    columnFilters: [
      ...analyticsTableAllColumns
        .filter((col) => col.accessor !== 'mspName')
        .map((col) => ({
          column: col,
          valueType: {
            label: 'Text',
            value: 'text',
          },
          operator: {
            label: 'Is...',
            value: 'is',
          },
          value: '',
        })),
      {
        column: analyticsTableAllColumns[3],
        valueType: {
          label: 'Text',
          value: 'text',
        },
        operator: {
          label: 'Is...',
          value: 'is',
        },
        value: 'meiaudo',
      },
    ],
    favorite: false,
  },
];

export const MOCK_userData = [
  {
    userId: 1,
    firstName: 'Max',
    lastName: 'Mustermann',
    department: 'Finance',
    role: 'Controller',
    login: 'max.mustermann@mail.com',
    phone: '01739943393',
    password: 'topSecret',
  },
];

export const categoryShortcutsMapping = (intl: IntlShape): { [key: string]: Category } => ({
  user_management: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.userManagement' }),
    shortcuts: [
      {
        type: 'user_add_profile',
        title: intl.formatMessage({ id: 'home.shortcuts.user_management.user_add_profile.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.user_management.user_add_profile.description' }),
        url: '/user-management/add-user',
      },
      {
        type: 'user_add_admin_profile',
        title: intl.formatMessage({ id: 'home.shortcuts.user_management.user_add_admin_profile.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.user_management.user_add_admin_profile.description' }),
        url: '/user-management/add-admin',
      },
      {
        type: 'user_support_requests',
        title: intl.formatMessage({ id: 'home.shortcuts.user_management.user_support_requests.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.user_management.user_support_requests.description' }),
        url: '/user-management/support-requests',
      },
    ],
  },
  analytics: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.analytics' }),
    shortcuts: [
      {
        type: 'vehicles_filter_list_instance',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.vehicles_filter_list_instance.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.vehicles_filter_list_instance.description' }),
        url: '/analytics',
      },
      {
        type: 'vehicles_filter_list_instance',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.vehicles_filter_list_instanceEscooter.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.vehicles_filter_list_instanceEscooter.description' }),
        url: '/analytics',
      },
      {
        type: 'vehicles_add_new_filter',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.vehicles_add_new_filter.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.vehicles_add_new_filter.description' }),
        url: '/analytics',
      },
      {
        type: 'traffic_heatmap',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.traffic_heatmap.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.traffic_heatmap.description' }),
        url: '/analytics',
      },
      {
        type: 'origin_destination_map',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.origin_destination_map.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.origin_destination_map.description' }),
        url: '/analytics',
      },
      {
        type: 'co2_emission_savings',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.co2_emission_savings.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.co2_emission_savings.description' }),
        url: '/analytics',
      },
      {
        type: 'mobility_mode_utilisation',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.mobility_mode_utilisation.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.mobility_mode_utilisation.description' }),
        url: '/analytics',
      },
      {
        type: 'data_export',
        title: intl.formatMessage({ id: 'home.shortcuts.analytics.data_export.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.analytics.data_export.description' }),
        url: '/analytics',
      },
    ],
  },
  maas_management: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.maasManagement' }),
    shortcuts: [
      {
        type: 'maas_gamification',
        title: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_gamification.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_gamification.description' }),
        url: '/maas-management/gamification',
      },
      {
        type: 'maas_promotion',
        title: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_promotion.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_promotion.description' }),
        url: '/maas-management/promotion',
      },
      {
        type: 'maas_customers',
        title: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_customers.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_customers.description' }),
        url: '/maas/customerDatabase',
      },
      {
        type: 'maas_reports',
        title: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_reports.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_reports.description' }),
        url: '/maas',
      },
      {
        type: 'maas_service_and_tickets',
        title: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_service_and_tickets.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_service_and_tickets.description' }),
        url: '/maas',
      },
      {
        type: 'maas_manage_msp_contracts',
        title: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_manage_msp_contracts.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.maas_management.maas_manage_msp_contracts.description' }),
        url: '/maas',
      },
    ],
  },
  customer_communication: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.communication' }),
    shortcuts: [
      {
        type: 'support_latest_requests',
        title: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_latest_requests.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_latest_requests.description' }),
        url: '/communication/support-requests',
      },
      {
        type: 'support_latest_responses',
        title: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_latest_responses.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_latest_responses.description' }),
        url: '/communication/support-responses',
      },
      {
        type: 'support_push_notifications',
        title: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_push_notifications.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_push_notifications.description' }),
        url: '/communication/push-notifications',
      },
      {
        type: 'support_chat_with_team_members',
        title: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_chat_with_team_members.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.customer_communication.support_chat_with_team_members.description' }),
        url: '/communication/chat',
      },
    ],
  },
  city_traffic_management: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.trafficManagement' }),
    shortcuts: [
      {
        type: 'city_traffic_disruptions',
        title: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_traffic_disruptions.title' }),

        description: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_traffic_disruptions.description' }),
        url: '/traffic-management/disruptions',
      },
      {
        type: 'city_fleet_management',
        title: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_fleet_management.title' }),

        description: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_fleet_management.description' }),
        url: '/traffic-management/fleet-management',
      },
      {
        type: 'city_active_zones',
        title: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_active_zones.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_active_zones.description' }),
        url: '/traffic-management/active-zones',
      },
      {
        type: 'city_planned_zones',
        title: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_planned_zones.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_planned_zones.description' }),
        url: '/traffic-management/planned-zones',
      },
      {
        type: 'city_create_or_schedule_new_zone',
        title: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_create_or_schedule_new_zone.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.city_traffic_management.city_create_or_schedule_new_zone.description' }),
        url: ' /traffic-management/active-zones',
      },
    ],
  },
  mobility_budget: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.mobilityBudget' }),
    shortcuts: [
      {
        type: 'mobility_budget_overall_summary',
        title: intl.formatMessage({ id: 'home.shortcuts.mobility_budget.mobility_budget_overall_summary.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.mobility_budget.mobility_budget_manage_budgets.description' }),
        url: '/mobility-budget/overview',
      },
      {
        type: 'mobility_budget_manage_support_tickets',
        title: intl.formatMessage({ id: 'home.shortcuts.mobility_budget.mobility_budget_manage_support_tickets.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.mobility_budget.mobility_budget_manage_support_tickets.description' }),
        url: '/mobility-budget/support-tickets',
      },
      {
        type: 'mobility_budget_manage_budgets',
        title: intl.formatMessage({ id: 'home.shortcuts.mobility_budget.mobility_budget_manage_budgets.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.mobility_budget.mobility_budget_manage_budgets.description' }),
        url: '/mobility-budget/budgets',
      },
    ],
  },
  msp_billing: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.mspBilling' }),
    shortcuts: [
      {
        type: 'msp_payments_overview',
        title: intl.formatMessage({ id: 'home.shortcuts.msp_billing.msp_payments_overview.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.msp_billing.msp_payments_overview.description' }),
        url: '/maas/billingStatements',
      },
      {
        type: 'msp_payments_overdue',
        title: intl.formatMessage({ id: 'home.shortcuts.msp_billing.msp_payments_overdue.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.msp_billing.msp_payments_overdue.description' }),
        url: '/maas/payments',
      },
    ],
  },
  operator: {
    name: intl.formatMessage({ id: 'home.shortcuts.sections.operator' }),
    shortcuts: [
      {
        type: 'operator_new_floating_zone',
        title: intl.formatMessage({ id: 'home.shortcuts.operator.operator_new_floating_zone.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.operator.operator_new_floating_zone.description' }),
        url: '/operator/floating-zones',
      },
      {
        type: 'operator_fleet_overview',
        title: intl.formatMessage({ id: 'home.shortcuts.operator.operator_fleet_overview.title' }),
        description: intl.formatMessage({ id: 'home.shortcuts.operator.operator_fleet_overview.description' }),
        url: '/operator/fleet-overview',
      },
    ],
  },
});

export const supportCardsData = (intl: IntlShape): SupportCard[] => [
  {
    type: 'supporting_documents',
    title: intl.formatMessage({ id: 'supportCards.supporting_documents.title' }),
    img: 'SupportingDocuments',
    description: intl.formatMessage({ id: 'supportCards.supporting_documents.description' }),
    url: '/*',
  },
  {
    type: 'video_tutorials',
    title: intl.formatMessage({ id: 'supportCards.video_tutorials.title' }),
    img: 'VideoTutorials',
    description: intl.formatMessage({ id: 'supportCards.video_tutorials.description' }),
    url: '/*',
  },
  {
    type: 'faq',
    title: intl.formatMessage({ id: 'supportCards.faq.title' }),
    img: 'FAQ',
    description: intl.formatMessage({ id: 'supportCards.faq.description' }),
    url: '/*',
  },
  {
    type: 'troubleshooting_guides',
    title: intl.formatMessage({ id: 'supportCards.troubleshooting_guides.title' }),
    img: 'TroubleShootingGuides',
    description: intl.formatMessage({ id: 'supportCards.troubleshooting_guides.description' }),
    url: '/*',
  },
  {
    type: 'changelog',
    title: intl.formatMessage({ id: 'supportCards.changelog.title' }),
    img: 'Changelog',
    description: intl.formatMessage({ id: 'supportCards.changelog.description' }),
    url: '/*',
  },
  {
    type: 'contact_customer_support',
    title: intl.formatMessage({ id: 'supportCards.contact_customer_support.title' }),
    img: 'ContactCustomerSupport',
    description: intl.formatMessage({ id: 'supportCards.contact_customer_support.description' }),
    url: '/*',
  },
];

export const licenseClasses: { value: string; label: string }[] = [
  {
    value: 'germany_class_a',
    label: 'A',
  },
  {
    value: 'germany_class_a1',
    label: 'A1',
  },
  {
    value: 'germany_class_b',
    label: 'B',
  },
  {
    value: 'germany_class_a2',

    label: 'A2',
  },
  {
    value: 'germany_class_am',
    label: 'AM',
  },
];

export const countryCodesIso3611 = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
];

export const defaultAddress = {
  houseNumber: '24B',
  street: 'UpdatedStreet',
  extension: 'UpdatedAddressExtension',
  city: 'UpdatedCity',
  postalCode: '12345',
  countryCode: 'DE',
};

export const ISO_DATE_FORMAT_DAYJS = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT_DAYJS = 'DD.MM.YYYY';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss';
export const DEFAULT_12HR_TIME_FORMAT = 'hh:mm';
export const DEFAULT_12HR_TIME_FORMAT_WITH_SECONDS = 'hh:mm:ss';
export const DEFAULT_DATETIME_FORMAT_DAYJS = `${DEFAULT_DATE_FORMAT_DAYJS} ${DEFAULT_TIME_FORMAT}`;
export const DEFAULT_CURRENCY = '€';

export const START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY = 0;
export const END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY = 24 /* hours */ * 60 /* minutes */ * 60 /* seconds */ - 1;
export const SECONDS_IN_AN_HOUR = 60 /* minutes */ * 60; /* seconds */
export const SECONDS_IN_A_MINUTE = 60;

export const START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY = 0;
export const END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY = 24 /* hours */ * 60 /* minutes */ - 1;

export const END_OF_DAY_TIME = '24:00';
export const START_OF_DAY_TIME = '00:00';

export const MIN_DEFAULT_DATE = '1900-01-01';
export const MAX_DEFAULT_DATE = '9999-12-31';

export const ALERT_HIDE_DURATION = 6000;
export const MAX_ALLOWED_ALERTS = 3;

export const BAMBERG_COORDINATES = [49.89873, 10.90067];

export const DEFAULT_NAME_MAX_CHAR_LENGTH = 250;
export const NAME_35_CHARS_LENGTH = 35;
export const MIN_DAYS_IN_A_MONTH = 28;

// TODO: Move this to enum
export const confirmationRadioButtonConfig = [
  {
    label: intl.formatMessage({ id: 'common.yes' }),
    value: true,
  },
  {
    label: intl.formatMessage({ id: 'common.no' }),
    value: false,
  },
];
