import { useState, useEffect } from 'react';

import { UserTrip } from '../../../models';
import { getUserTripsList } from '../../../utils/queries';

interface UseAllTripsProps {
  userId: string;
}

function useFetchAllTrips({ userId }: UseAllTripsProps) {
  const [allTrips, setAllTrips] = useState<UserTrip[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadUserTrips = async (userId: string) => {
    try {
      setError(false);
      setLoading(true);
      setAllTrips([]);
      const userTrips = await getUserTripsList(userId);
      setAllTrips(userTrips || []);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await loadUserTrips(userId);
    })();
  }, [userId]);

  return { allTrips, loading, error, refetch: loadUserTrips, setAllTrips };
}

export default useFetchAllTrips;
