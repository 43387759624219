import { useState } from 'react';

import { Button, ButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as OverlayFilledIcon } from '../../../../../assets/icons/fill/overlayFilled.svg';
import { ReactComponent as OverlayOutlinedIcon } from '../../../../../assets/icons/outline/overlay.svg';
import CustomPopover from '../../../../CustomPopover';

import SetView from './SetView';

interface CustomLayersViewComponentProps {
  selectedView: string;
  setSelectedView: (value: string) => void;
}

interface OverlayButtonProps {
  handleOverlay: (event: React.MouseEvent<HTMLElement>) => void;
  isSelected: boolean;
}

const OverlayButton = ({ handleOverlay, isSelected }: OverlayButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      key="Overlay"
      onClick={handleOverlay}
      disableRipple
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'white',
        border: 0,
        '&:hover': {
          backgroundColor: theme.palette.secondary.tint,
          border: 'none',
        },
        width: '58px',
        height: '58px',
      }}
    >
      {isSelected ? <OverlayFilledIcon /> : <OverlayOutlinedIcon />}
    </Button>
  );
};

const CustomLayersViewComponent = ({ selectedView, setSelectedView }: CustomLayersViewComponentProps) => {
  const [showViewLayer, setShowViewLayer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const toggleAnchorElement = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleOverlay = (event: React.MouseEvent<HTMLElement>) => {
    setShowViewLayer((prevState) => !prevState);
    toggleAnchorElement(event);

    setIsSelected(!isSelected);
  };

  const theme = useTheme();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '16px 24px 12px 24px',
        }}
      >
        <ButtonGroup
          orientation="vertical"
          variant="outlined"
          aria-label="outlined primary button group"
          sx={{ boxShadow: theme.shadowOptions.belowMedium, zIndex: '1' }}
        >
          <OverlayButton handleOverlay={handleOverlay} isSelected={isSelected} />
        </ButtonGroup>
      </div>
      {showViewLayer && (
        <CustomPopover
          open={showViewLayer}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: -10, vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          handleClose={handleOverlay}
          style={{ padding: '0px' }}
        >
          <SetView selectedView={selectedView} setSelectedView={setSelectedView} />
        </CustomPopover>
      )}
    </>
  );
};

export default CustomLayersViewComponent;
