import camelCase from 'lodash/camelCase';

import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum InfrastructureType {
  MobilityHub = 'MOBILITY_HUB',
  ChargingStation = 'CHARGING_STATION',
}

export namespace InfrastructureTypeHelper {
  export function allTypes() {
    return EnumWithStringValue.enumToValues(InfrastructureType);
  }

  export const valuesWithLabel = Object.entries(InfrastructureType).map(([key, value]) => ({
    value: value,
    label: intl.formatMessage({ id: `filter.infrastructure.${camelCase(key)}` }),
  }));
}
