import { useIntl } from 'react-intl';

import { enqueuedSnackbar } from '../../../../components/EnqueuedSnackbar';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';

export const useSnackbarWhileTransition = () => {
  const { formatMessage: fm } = useIntl();

  function snackbarOnTransitionRetryApiSuccess(membership: BudgetPlanMembershipDto) {
    const { email } = membership;
    if (membership.isActive()) enqueuedSnackbar(fm({ id: 'common.linkedSuccessfully' }, { user: email }), 'success');
    if (membership.isInactive()) enqueuedSnackbar(fm({ id: 'common.unlinkedSuccessfully' }, { user: email }), 'success');
    if (membership.isUnlinkingInProgress()) enqueuedSnackbar(fm({ id: 'common.unlinkingInProgress' }, { user: email }), 'success');
    if (membership.isUnlinkingFailed()) enqueuedSnackbar(fm({ id: 'common.failedToUnlink' }, { user: email }), 'error');
    if (membership.isLinkingInProgress()) enqueuedSnackbar(fm({ id: 'common.linkingInProgress' }, { user: email }), 'success');
    if (membership.isLinkingFailed()) enqueuedSnackbar(fm({ id: 'common.failedToLink' }, { user: email }), 'error');
  }

  function snackbarOnDiscardFailedTransitionApiSuccess(membership: BudgetPlanMembershipDto) {
    if (membership!.isLinkingFailed()) enqueuedSnackbar(fm({ id: 'common.discardLinkingSuccess' }, { user: membership!.email }), 'success');
    if (membership!.isUnlinkingFailed())
      enqueuedSnackbar(fm({ id: 'common.discardUnlinkingSuccess' }, { user: membership!.email }), 'success');
  }

  return {
    snackbarOnTransitionRetryApiSuccess,
    snackbarOnDiscardFailedTransitionApiSuccess,
  };
};
