import dayjs from 'dayjs';
import { array, date, object, ref } from 'yup';

import { validateName } from '../../../../utils/ValidationSchemas';
import { ZoneRuleFormFields, ZoneRulesFormOwnProps } from '../ZoneRulesForm';

export const zoneRulesFormValidationSchema = ({ intl }: ZoneRulesFormOwnProps) => {
  const { formatMessage } = intl;
  return object().shape({
    [ZoneRuleFormFields.name]: validateName(intl),
    [ZoneRuleFormFields.startDate]: date()
      .typeError(formatMessage({ id: 'form.validation.invalidDate' }))
      .min(dayjs().toBeginningOfDay().toDate(), formatMessage({ id: 'form.validation.errorNoPastDate' }))
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
    [ZoneRuleFormFields.endDate]: date().when(ZoneRuleFormFields.indefiniteZone, {
      is: false,
      then: () =>
        date()
          .typeError(formatMessage({ id: 'form.validation.invalidDate' }))
          .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
          .test('end-date-same-or-after-start-date', formatMessage({ id: 'form.validation.shouldBeSameOrAfter' }), function (endDate) {
            const startDate = this.parent[ZoneRuleFormFields.startDate];
            return dayjs(endDate).isSameOrAfter(startDate);
          }),
      otherwise: () => date().nullable().optional(),
    }),
    [ZoneRuleFormFields.startTime]: date()
      .typeError(formatMessage({ id: 'form.validation.invalidTime' }))
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
    [ZoneRuleFormFields.endTime]: date()
      .typeError(formatMessage({ id: 'form.validation.invalidTime' }))
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
      .min(ref(ZoneRuleFormFields.startTime), formatMessage({ id: 'form.validation.errorEndTimeBeforeStartTime' })),
    [ZoneRuleFormFields.daysOfWeek]: array()
      .transform((daysOfWeek) => [...daysOfWeek])
      .min(1, formatMessage({ id: 'form.validation.requiredDaysOfWeek' }))
      .required(formatMessage({ id: 'form.validation.requiredDaysOfWeek' })),
    [ZoneRuleFormFields.applicableVehicleTypes]: array()
      .min(1, formatMessage({ id: 'form.validation.requiredVehicleType' }))
      .required(formatMessage({ id: 'form.validation.requiredVehicleType' })),
  });
};
