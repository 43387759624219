import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { VehicleTripType } from '../../../../models';
import { formatDistanceFromMeters } from '../../../../utils/DateMethods';
import { ReactTable } from '../../../../wmv-components';
import UserTripHistory from '../../../UserTripHistory/UserTripDetails';

function VehicleInfoReactTable({ loading, filteredTrips }: { loading: boolean; filteredTrips: VehicleTripType[] }) {
  const { formatMessage } = useIntl();

  const columns = [
    {
      Header: formatMessage({ id: 'informationTable.header.tripId' }),
      accessor: 'id',
      Cell: ({ row }: any) => (
        <Typography variant="bodySmall" px={1}>
          {row.original.id.toString()}
        </Typography>
      ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.startEpochMillis' }),
      accessor: 'startedAt',
      Cell: ({ row }: any) => (
        <Typography variant="bodySmall">{row.original.startedAt.parseEpochMillis().formatAsDateTimeString()}</Typography>
      ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.endEpochMillis' }),
      accessor: 'finishedAt',
      Cell: ({ row }: any) =>
        row.original.active ? (
          <Typography sx={{ marginLeft: '2px' }} variant="bodySmall">
            --
          </Typography>
        ) : (
          <Typography variant="bodySmall">{row.original.finishedAt.parseEpochMillis().formatAsDateTimeString()}</Typography>
        ),
    },
    {
      Header: formatMessage({ id: 'informationTable.header.actualDurationSeconds' }),
      accessor: 'totalDurationSeconds',
      Cell: ({ row }: any) =>
        row.original.active ? (
          <Typography variant="bodySmall">{formatMessage({ id: 'common.dash' })}</Typography>
        ) : (
          <Typography variant="bodySmall" px={1}>
            {row.original.totalDurationSeconds.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}
          </Typography>
        ),
    },

    {
      Header: formatMessage({ id: 'informationTable.header.totalDistanceMeters' }),
      accessor: 'totalDistanceMeters',
      Cell: ({ row }: any) =>
        row.original.active ? (
          <Typography variant="bodySmall">{formatMessage({ id: 'common.dash' })}</Typography>
        ) : (
          <Typography variant="bodySmall" px={1}>
            {formatDistanceFromMeters(row.original.totalDistanceMeters)}
          </Typography>
        ),
    },
  ];

  const EXPANDER_COLUMN = {
    id: 'expander',
    Header: () => <></>,
    Cell: ({ row }: any) => {
      return (
        <Box
          {...row.getToggleRowExpandedProps({})}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {row.isExpanded ? (
            <ExpandLessOutlinedIcon sx={{ fontSize: 16, color: '#009881' }} />
          ) : (
            <ExpandMoreOutlinedIcon sx={{ fontSize: 16, color: '#009881' }} />
          )}
        </Box>
      );
    },
    width: '25px',
  };

  return (
    <>
      <ReactTable
        loading={loading}
        columns={[...columns, EXPANDER_COLUMN]}
        tableContainerStyle={{
          border: '1px solid #f0f0f0',
          borderRadius: '8px',
        }}
        renderDetailsComponent={({ row }: { row: VehicleTripType }) => {
          const detailsQueryParams = {
            id: row.id,
            startedAt: row.startedAt,
            finishedAt: row.finishedAt,
          };
          return <UserTripHistory trip={detailsQueryParams} enableForceStop={false} />;
        }}
        data={filteredTrips}
        pagination={false}
        sorted={false}
      />
    </>
  );
}

export default VehicleInfoReactTable;
