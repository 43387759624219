import { useEffect } from 'react';

import Box from '@mui/material/Box';

import { EnqueuedSnackbarProvider } from '../../../../components/EnqueuedSnackbar';
import { MembershipDetailsProvider, SearchBudgetPlanMembershipProvider, useSelectedBudgetPlanId } from '../contexts';
import { usePollInProgressTransitionStateMemberships } from '../hooks';
import { useGetBudgetPlanMemberships } from '../hooks/useGetBudgetPlanMemberships';

import { BudgetPlanMembershipsListWithLoadingOrNoContentMessages } from './BudgetPlanMembershipsListWithLoadingOrNoContentMessages';
import { MembershipDetailsModal } from './MembershipDetailsModal';
import { SearchAndLinkNewBudgetPlanMembershipModal } from './SearchAndLinkNewBudgetPlanMembershipModal';
import { SearchExistingMemberOrLinkNewMember } from './SearchExistingMemberOrLinkNewMember';

export const BudgetPlanMembershipsView = () => {
  const selectedPlanId = useSelectedBudgetPlanId();
  const getBudgetPlanMemberships = useGetBudgetPlanMemberships();

  useEffect(() => {
    (async () => {
      if (selectedPlanId) {
        await getBudgetPlanMemberships(selectedPlanId);
      }
    })();
  }, [getBudgetPlanMemberships, selectedPlanId]);

  usePollInProgressTransitionStateMemberships();

  return (
    <EnqueuedSnackbarProvider>
      <Box height="100%" display="flex" flexDirection="column">
        <SearchExistingMemberOrLinkNewMember />
        <BudgetPlanMembershipsListWithLoadingOrNoContentMessages />
        <SearchBudgetPlanMembershipProvider>
          <SearchAndLinkNewBudgetPlanMembershipModal />
        </SearchBudgetPlanMembershipProvider>
        <MembershipDetailsProvider>
          <MembershipDetailsModal />
        </MembershipDetailsProvider>
      </Box>
    </EnqueuedSnackbarProvider>
  );
};
