import { useEffect } from 'react';

import { Stack, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { VehicleTypeHelper } from '../../../models';
import { VehiclePricingDto } from '../../../services/vehicle-fee-pricing';
import { AppDispatch } from '../../../state';
import { showAlertAction } from '../../../state/app';
import { discardFailedVehiclePricingStatusTransition, updateVehicleFeePricing } from '../../../utils/queries';
import HelperText from '../../../wmv-components/HelperText';

import {
  SelectedVehicleFeePricingActionType,
  useSelectedVehiclePricingDispatch,
  useSelectedVehiclePricingState,
  useIsViewingActivePricingDetails,
  useIsViewingFailedPricingDetails,
  useIsViewingInProgressPricingDetails,
  useVehiclePricingDispatch,
  VehiclePricingActionType,
  useSelectedVehiclePricingDetails,
} from './contexts';
import { makeUpdateVehiclePricingRequestPayload, VehiclePricingDetailsActionMode } from './helpers';
import { useFetchVehiclePricingList, useVehiclePricingFormInitialValues } from './hooks';
import { useVehiclePricingStateTransitionHelperText } from './hooks/useVehiclePricingStateTransitionHelperText';
import { vehiclePricingFormValidationSchema } from './validation-schemas';
import { VehicleFeePricingInputs } from './VehicleFeePricingInputs';
import { VehiclePricingFormTitleWithActionButtons } from './VehiclePricingFormTitleWithActionButtons';

export function VehiclePricingDetailsForm() {
  const { formatMessage } = useIntl();

  const reduxDispatch = useDispatch<AppDispatch>();
  const fetchVehicleFeePricingListAndUpdateState = useFetchVehiclePricingList();
  const vehiclePricingFormInitialValues = useVehiclePricingFormInitialValues();
  const selectedPricingDispatch = useSelectedVehiclePricingDispatch()!;
  const selectedVehiclePricingDetails = useSelectedVehiclePricingDetails();
  const vehiclePricingDispatch = useVehiclePricingDispatch()!;
  const { updatePricingApiStatus } = useSelectedVehiclePricingState();
  const isViewingActivePricingDetails = useIsViewingActivePricingDetails();
  const isViewingFailedPricingDetails = useIsViewingFailedPricingDetails();
  const isViewingInProgressPricingDetails = useIsViewingInProgressPricingDetails();
  const {
    helperText,
    helperTextType,
    setHelperTextToEmpty,
    setHelperTextForFailedTransition,
    setHelperTextForInProgressTransition,
    setHelperTextForFailedRequest,
  } = useVehiclePricingStateTransitionHelperText();

  useEffect(() => {
    if (isViewingActivePricingDetails) setHelperTextToEmpty();
    if (isViewingFailedPricingDetails) setHelperTextForFailedTransition();
    if (isViewingInProgressPricingDetails) setHelperTextForInProgressTransition();
  }, [
    isViewingActivePricingDetails,
    isViewingFailedPricingDetails,
    isViewingInProgressPricingDetails,
    setHelperTextToEmpty,
    setHelperTextForFailedTransition,
    setHelperTextForInProgressTransition,
  ]);

  return (
    <Formik
      initialValues={vehiclePricingFormInitialValues}
      validationSchema={vehiclePricingFormValidationSchema}
      onSubmit={handleVehiclePricingDetailsSubmit}
      enableReinitialize
    >
      {(props) => (
        <Form>
          <VehiclePricingFormTitleWithActionButtons onDiscardFailedStatusTransitionSubmit={handleDiscardFailedStatusTransitionSubmit} />
          <Box display="flex" justifyContent="flex-end" width="100%">
            <HelperText
              value={helperText}
              type={helperTextType}
              showCheckIcon={isViewingInProgressPricingDetails}
              showAttentionIcon={isViewingFailedPricingDetails || updatePricingApiStatus.isFailed()}
              sx={{ justifyContent: 'flex-end', maxWidth: '380px' }}
            />
          </Box>
          <Stack gap={2} mt={3}>
            <VehicleFeePricingInputs />
          </Stack>
        </Form>
      )}
    </Formik>
  );

  async function handleDiscardFailedStatusTransitionSubmit() {
    try {
      setHelperTextToEmpty();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionStart,
      });
      await discardFailedVehiclePricingStatusTransition(
        selectedVehiclePricingDetails?.msp.subjectClaim!,
        selectedVehiclePricingDetails?.modelId!,
      );
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionSuccess,
      });
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
        payload: null,
      });
      reduxDispatch(
        showAlertAction({
          type: 'success',
          message: formatMessage(
            { id: 'vehicleFeePricing.successfullyUpdated' },
            {
              vehicleType: VehicleTypeHelper.metadata(selectedVehiclePricingDetails!.vehicleType).displayText,
              vehicleModel: selectedVehiclePricingDetails!.modelName,
            },
          ),
        }),
      );
    } catch (e) {
      setHelperTextForFailedRequest();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionFailure,
      });
    }
  }

  async function handleVehiclePricingDetailsSubmit(values: VehiclePricingFormValues) {
    try {
      setHelperTextToEmpty();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingStart,
      });
      const request = makeUpdateVehiclePricingRequestPayload(values, selectedVehiclePricingDetails!.version);
      const resp = await updateVehicleFeePricing(
        request,
        selectedVehiclePricingDetails?.msp.subjectClaim!,
        selectedVehiclePricingDetails?.modelId!,
      );
      const newPricingDto = new VehiclePricingDto(resp);
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingSuccess,
        payload: newPricingDto,
      });
      await updateHelperTextAndActionMode(newPricingDto);
    } catch (e) {
      setHelperTextForFailedRequest();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingFailure,
      });
    }
  }

  async function updateHelperTextAndActionMode(vehiclePricing: VehiclePricingDto) {
    if (vehiclePricing.isStateChangeInProgress()) {
      setHelperTextForInProgressTransition();
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateActionMode,
        payload: VehiclePricingDetailsActionMode.ViewInProgressPricing,
      });
    }
    if (vehiclePricing.isStateChangeSuccessful()) {
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
        payload: null,
      });
      reduxDispatch(
        showAlertAction({
          type: 'success',
          message: formatMessage(
            { id: 'vehicleFeePricing.successfullyUpdated' },
            {
              vehicleType: VehicleTypeHelper.metadata(vehiclePricing.vehicleType).displayText,
              vehicleModel: vehiclePricing.modelName,
            },
          ),
        }),
      );
      await fetchVehicleFeePricingListAndUpdateState();
    }
    if (vehiclePricing.isStateChangeFailed()) {
      setHelperTextForFailedTransition();
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateActionMode,
        payload: VehiclePricingDetailsActionMode.ViewFailedPricing,
      });
    }
  }
}

export interface VehiclePricingFormValues {
  activeActivationFee: number | null;
  activeActiveMinutesFee: number | null;
  activePausedMinutesFee: number | null;
  newActivationFee: number | null;
  newActiveMinutesFee: number | null;
  newPausedMinutesFee: number | null;
}
