import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useIntl } from 'react-intl';

export const SearchAndResetButtonContainer = ({ isValid, dirty, loading }: { isValid: boolean; dirty: boolean; loading: boolean }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xxl));

  return (
    <Stack gap={theme.spacing(1)} direction="row" useFlexGap flexWrap="wrap" alignSelf="baseline" my={0.5}>
      <Button type="submit" disabled={!isValid || !dirty || loading} variant="contained" size={!isTablet ? 'large' : 'medium'}>
        <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customerDatabase.search' })}</Typography>
      </Button>
      <Button type="reset" disabled={!dirty || loading} variant="text" size={!isTablet ? 'large' : 'medium'}>
        <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customerDatabase.reset' })}</Typography>
      </Button>
    </Stack>
  );
};
