import { useCallback, useEffect } from 'react';

import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { fetchBudgetPlanMembershipDetails } from '../../../../utils/queries';
import { pollActionCreator } from '../BudgetPlanMemberships/usePolling';
import {
  BudgetPlanMembershipsListActionType,
  useBudgetPlanMembershipsListDispatch,
  useBudgetPlanMembershipsListState,
  useSelectedBudgetPlanId,
} from '../contexts';

import { useGetBudgetPlanMemberships } from './useGetBudgetPlanMemberships';
import { useSnackbarWhileTransition } from './useSnackbarWhileTransition';

let polledActions: { start: any; stop: any; intervalId: ReturnType<typeof setTimeout> }[] = [];

export function usePollInProgressTransitionStateMemberships() {
  const membershipsListDispatch = useBudgetPlanMembershipsListDispatch();
  const membershipsListState = useBudgetPlanMembershipsListState();
  const selectedPlanId = useSelectedBudgetPlanId();

  const { memberships = [] } = membershipsListState;
  const getBudgetPlanMemberships = useGetBudgetPlanMemberships();
  const { snackbarOnTransitionRetryApiSuccess } = useSnackbarWhileTransition();

  useEffect(() => {
    (async () => {
      if (selectedPlanId) {
        await getBudgetPlanMemberships(selectedPlanId);
      }
    })();
  }, [getBudgetPlanMemberships, selectedPlanId]);

  // TODO: Simplify the enqueuing of snackbars while doing
  const updateInProgressTransitionOfBudgetPlanMembership = useCallback(
    (membershipId: number) => async () => {
      try {
        const updatedMembership = await fetchBudgetPlanMembershipDetails(selectedPlanId!, membershipId);
        const updatedMembershipDto = new BudgetPlanMembershipDto(updatedMembership);
        if (!updatedMembershipDto.isLastAttemptedStateTransitionInProgress()) {
          const newMemberships: BudgetPlanMembershipDto[] = memberships!.map((membershipDto) => {
            if (membershipDto.id === updatedMembershipDto.id) return updatedMembershipDto;
            return membershipDto;
          });
          snackbarOnTransitionRetryApiSuccess(updatedMembershipDto);
          membershipsListDispatch({ type: BudgetPlanMembershipsListActionType.UpdateMembershipsList, payload: newMemberships });
        }
      } catch {
        console.log('Failed to fetch Budget plan membership with id', membershipId);
      }
    },
    [memberships, selectedPlanId, membershipsListDispatch, snackbarOnTransitionRetryApiSuccess],
  );

  useEffect(() => {
    if (memberships.length) {
      for (let i = 0; i < memberships.length; i++) {
        if (memberships[i].lastAttemptedStateTransition.isInProgress()) {
          const pollingAction = pollActionCreator(5000);
          const polledActionId = pollingAction.start(updateInProgressTransitionOfBudgetPlanMembership(memberships[i].id));
          polledActions.push({ ...pollingAction, intervalId: polledActionId });
        }
      }
    }
    return () => {
      polledActions.forEach((polledAction) => polledAction.stop(polledAction.intervalId));
      polledActions = [];
    };
  }, [memberships, updateInProgressTransitionOfBudgetPlanMembership]);
}
