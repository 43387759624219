import { SvgIcon } from '@mui/material';

import { ReactComponent as PlusIconFilled } from '../assets/icons/fill/AddButtonFilledBlack.svg';
import { ReactComponent as AttentionIconFilled } from '../assets/icons/fill/attention.svg';
import { ReactComponent as MinusIconFilled } from '../assets/icons/fill/DeleteButtonFilledBlack.svg';
import { ReactComponent as DeselectedIcon } from '../assets/icons/fill/deselected.svg';
import { ReactComponent as EditIcon } from '../assets/icons/fill/editTextFieldIcon.svg';
import { ReactComponent as InfrastructuresIcon } from '../assets/icons/fill/infrastructure.svg';
import { ReactComponent as LinkOut } from '../assets/icons/fill/linkOut.svg';
import { ReactComponent as PlusIconOutlined } from '../assets/icons/outline/AddIconBorder.svg';
import { ReactComponent as AscendingIcon } from '../assets/icons/outline/ascending.svg';
import { ReactComponent as AttentionIcon } from '../assets/icons/outline/attention.svg';
import { ReactComponent as BatteryHalfIcon } from '../assets/icons/outline/batteryHalf.svg';
import { ReactComponent as BikeTooltipIcon } from '../assets/icons/outline/bikeIconMapTooltip.svg';
import { ReactComponent as ebikeTooltipIcon } from '../assets/icons/outline/bikeIconMapTooltip.svg';
import { ReactComponent as CargoBikeTooltipIcon } from '../assets/icons/outline/cargobikeIconMapTooltip.svg';
import { ReactComponent as cargobikeTooltipIcon } from '../assets/icons/outline/cargobikeIconMapTooltip.svg';
import { ReactComponent as CarTooltipIcon } from '../assets/icons/outline/carIconMapTooltip.svg';
import { ReactComponent as carTooltipIcon } from '../assets/icons/outline/carIconMapTooltip.svg';
import { ReactComponent as ChevronDown } from '../assets/icons/outline/chevronDown.svg';
import { ReactComponent as ChevronDownIcon } from '../assets/icons/outline/chevronDown.svg';
import { ReactComponent as ChevronLeft } from '../assets/icons/outline/chevronLeft.svg';
import { ReactComponent as ChevronRight } from '../assets/icons/outline/chevronRight.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/outline/closeCircle.svg';
import { ReactComponent as CurrentLocationIcon } from '../assets/icons/outline/currentLocation.svg';
import { ReactComponent as MinusIconOutlined } from '../assets/icons/outline/DeleteIconBorder.svg';
import { ReactComponent as DescendingIcon } from '../assets/icons/outline/descending.svg';
import { ReactComponent as DateIcon } from '../assets/icons/outline/detailedModalDateIcon.svg';
import { ReactComponent as DurationIcon } from '../assets/icons/outline/detailedModalDurationIcon.svg';
import { ReactComponent as DoubleChevronDown } from '../assets/icons/outline/doubleChevronDown.svg';
import { ReactComponent as DoubleChevronUp } from '../assets/icons/outline/doubleChevronUp.svg';
import { ReactComponent as DropdownIcon } from '../assets/icons/outline/dropdownSelect.svg';
import { ReactComponent as Globe } from '../assets/icons/outline/globe.svg';
import { ReactComponent as Info } from '../assets/icons/outline/info.svg';
import { ReactComponent as TextIcon } from '../assets/icons/outline/inputText.svg';
import { ReactComponent as LockAdornmentIcon } from '../assets/icons/outline/lockOutlineIcon.svg';
import { ReactComponent as Logout } from '../assets/icons/outline/logout.svg';
import { ReactComponent as MailAdornmentIcon } from '../assets/icons/outline/mailOutlineIcon.svg';
import { ReactComponent as ModalCloseIcon } from '../assets/icons/outline/modalCloseIcon.svg';
import { ReactComponent as MopedTooltipIcon } from '../assets/icons/outline/mopedIconMapTooltip.svg';
import { ReactComponent as mopedTooltipIcon } from '../assets/icons/outline/mopedIconMapTooltip.svg';
import { ReactComponent as NumbersIcon } from '../assets/icons/outline/number.svg';
import { ReactComponent as PastSearchIcon } from '../assets/icons/outline/pastSearch.svg';
import { ReactComponent as PhoneAdornmentIcon } from '../assets/icons/outline/phoneOutlineIcon.svg';
import { ReactComponent as ProfilOrgaIcon } from '../assets/icons/outline/profileModalOrganisation.svg';
import { ReactComponent as ProfilUserIcon } from '../assets/icons/outline/profileModalUser.svg';
import { ReactComponent as ProvidersIcon } from '../assets/icons/outline/providers.svg';
import { ReactComponent as PublicTransportIcon } from '../assets/icons/outline/publicTransport.svg';
import { ReactComponent as RefreshIcon } from '../assets/icons/outline/refreshOutlineIcon.svg';
import { ReactComponent as RemoveTextIcon } from '../assets/icons/outline/removeText.svg';
import { ReactComponent as ScooterTooltipIcon } from '../assets/icons/outline/scooterIconMapTooltip.svg';
import { ReactComponent as scooterTooltipIcon } from '../assets/icons/outline/scooterIconMapTooltip.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/outline/search.svg';
import { ReactComponent as Support } from '../assets/icons/outline/support.svg';
import { ReactComponent as User } from '../assets/icons/outline/user.svg';
import { ReactComponent as VehicleStatusIcon } from '../assets/icons/outline/vehicle-status.svg';
import { ReactComponent as ZonesIcon } from '../assets/icons/outline/zones.svg';

export const availableIcons = {
  PublicTransportIcon,
  RefreshIcon,
  ProvidersIcon,
  ZonesIcon,
  InfrastructuresIcon,
  VehicleStatusIcon,
  ChevronDown,
  Globe,
  Logout,
  Support,
  User,
  LinkOut,
  ChevronLeft,
  DescendingIcon,
  AscendingIcon,
  DeselectedIcon,
  Info,
  NumbersIcon,
  ChevronDownIcon,
  CloseIcon,
  TextIcon,
  PastSearchIcon,
  SearchIcon,
  EditIcon,
  LockAdornmentIcon,
  MailAdornmentIcon,
  ModalCloseIcon,
  PhoneAdornmentIcon,
  ProfilOrgaIcon,
  ProfilUserIcon,
  DateIcon,
  DurationIcon,
  DropdownIcon,
  RemoveTextIcon,
  BikeTooltipIcon,
  CargoBikeTooltipIcon,
  CarTooltipIcon,
  MopedTooltipIcon,
  ScooterTooltipIcon,
  AttentionIcon,
  AttentionIconFilled,
  DoubleChevronDown,
  DoubleChevronUp,
  BatteryHalfIcon,
  CurrentLocationIcon,
  ChevronRight,
  ebikeTooltipIcon,
  cargobikeTooltipIcon,
  carTooltipIcon,
  mopedTooltipIcon,
  scooterTooltipIcon,
  PlusIconOutlined,
  MinusIconOutlined,
  PlusIconFilled,
  MinusIconFilled,
};

const CustomIcon = (icon: IconType, color?: string, size?: string | number) => {
  return <SvgIcon sx={{ color: color, height: size, width: size }} component={availableIcons[icon]} />;
};

export type IconType = keyof typeof availableIcons;

export default CustomIcon;
