export const regexPatterns = {
  onlyLetters: /^[\p{Letter}]+$/u,
  lettersWithSpace: /^[\p{Letter}\s]+$/u,

  onlyDigits: /^\d+$/,
  lettersWithDigitsAndSpace: /^[\p{Letter}0-9\s]+$/u,

  postalCode: /^(?=\S)[\d\p{L} /-]{1,10}(?<=\S)$/u,
  dialCode: /^\+?[0-9]{1,7}$/, // Regular expression to allow only numbers and + as the first character
  name: /^(?=\S)[ \p{L}'-]{0,250}(?<=\S)/u,
  streetName: /^(?!\s)[\p{L}][\d\p{L} .'/-]+(?!\s)$/u,
  houseNumber: /^(?=\S)[1-9]\d*[ –—/-]?[a-zA-Z]{0,2}(?:[ ]?[–—/-][ ]?[1-9]\d*[ –—/-]?[a-zA-Z]{0,2})?(?<=\S)$/u,
  address: /^(?=\S)[\d\p{L} './,:;(){}[\]-]{0,250}(?<=\S)$/u,
  cityName: /^(?!\s)[\p{L} './,:;()-]{0,250}(?!\s)$/u,

  numerberOnly: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
};
