import dayjs from 'dayjs';
import type { IntlShape } from 'react-intl';

import { DayOfWeekHelper, FloatingButtonType, FloatingButtonHelper, ModeType } from '../../models';
import { END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY, START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY } from '../../utils/constants';
import { ButtonGroupItemConfig } from '../../wmv-components';

export const getSwitchButtonConfig = (intl: IntlShape): ButtonGroupItemConfig[] => {
  const { formatMessage } = intl;
  return [
    {
      text: formatMessage({
        id: FloatingButtonHelper.previewModeTranslationKey(FloatingButtonType.ViewMode),
      }),
      switchModeButtonId: FloatingButtonType.ViewMode,
    },
    {
      text: formatMessage({
        id: FloatingButtonHelper.previewModeTranslationKey(FloatingButtonType.PlanningMode),
      }),
      switchModeButtonId: FloatingButtonType.PlanningMode,
    },
  ];
};

export const defaultPreviewPredicates = {
  modeType: ModeType.WideInterval,
  timeRange: {
    startSecondsFromBeginningOfDay: START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
    endSecondsFromBeginningOfDay: END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
  },
  dateRangeAndDaysOfWeek: {
    startDate: dayjs(),
    endDate: dayjs().plusMonths(1),
    daysOfWeek: DayOfWeekHelper.allDaysOfWeek(),
  },
};
