import { useTheme } from '@mui/material/styles';

import {
  useSelectedVehiclePricingPricingId,
  useVehiclePricingDispatch,
  VehiclePricingActionType,
  SelectedVehicleFeePricingActionType,
  useSelectedVehiclePricingDispatch,
  useUpdatePricingApiStatus,
  useDiscardFailedTransitionApiStatus,
} from './contexts';
import { useFetchVehiclePricingList } from './hooks';
import { Modal } from './Modal';
import { pricingDetailsContentStyles } from './styles';
import { VehiclePricingDetailsForm } from './VehiclePricingDetailsForm';

export function VehiclePricingDetailsModal() {
  const { palette } = useTheme();

  const vehiclePricingDispatch = useVehiclePricingDispatch();
  const selectedVehiclePricingId = useSelectedVehiclePricingPricingId();
  const selectedPricingDispatch = useSelectedVehiclePricingDispatch()!;

  const updatePricingApiStatus = useUpdatePricingApiStatus();
  const discardFailedTransitionApiStatus = useDiscardFailedTransitionApiStatus();
  const fetchVehicleFeePricingListAndUpdateState = useFetchVehiclePricingList();

  return (
    <Modal
      open={!!selectedVehiclePricingId}
      onClose={handlePricingModalClose}
      disableClose={updatePricingApiStatus.isPending() || discardFailedTransitionApiStatus.isPending()}
      bgColor={palette.white.main}
    >
      <Modal.Content sx={pricingDetailsContentStyles}>
        <VehiclePricingDetailsForm />
      </Modal.Content>
    </Modal>
  );

  function handlePricingModalClose() {
    vehiclePricingDispatch!({
      type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
      payload: null,
    });
    if (updatePricingApiStatus.isSucceeded() || discardFailedTransitionApiStatus.isSucceeded()) {
      fetchVehicleFeePricingListAndUpdateState();
    }
    selectedPricingDispatch({
      type: SelectedVehicleFeePricingActionType.ResetToInitialState,
    });
  }
}
