import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import SupportCard from '../../components/Cards/SupportCard';
import routes from '../../routes';
import { supportCardsData } from '../../utils/constants';
import { illustrations } from '../../utils/CustomIllustrations';

const SupportContainer = styled('div')(({ theme }) => ({
  '&& .MuiCardActionArea-focusHighlight': {
    opacity: '0 !important',
  },
  color: theme.palette.baseLight.base76,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  height: 'calc(100vh - 88px)',
  overflow: 'auto',
  padding: '30px 200px',
}));

const CardList = styled('ul')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 0,
  alignItems: 'flex-start',
  margin: '16px 0px',
  flexWrap: 'wrap',
  gap: '30px',
}));

const Support: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const theme = useTheme();
  const navigate = useNavigate();

  const handleFleetManagementClick = () => {
    const query = `openListView=${encodeURIComponent('true')}&highlightActiveRoute=${encodeURIComponent('false')}`;
    navigate(`${routes.operator}?${query}`);
  };

  return (
    <SupportContainer>
      <Typography variant="h2">{formatMessage({ id: 'support.greeting' })}</Typography>
      <Typography variant="bodyLarge">{formatMessage({ id: 'support.subHeadline' })}</Typography>
      <CardList>
        {supportCardsData(intl).map((card) => (
          <SupportCard
            key={card.type}
            img={card.img ? illustrations[card.img] : ''}
            title={card.title}
            description={card.description}
            url={card.url}
          />
        ))}
      </CardList>
      <Box
        sx={{
          width: '100%',
          margin: '32px 0px',
          borderBottom: `2px solid ${theme.palette.baseLight.base32}`,
        }}
      >
        <Typography
          variant="bodyLargeBold"
          sx={{
            color: theme.palette.baseLight.base60,
          }}
        >
          {formatMessage({ id: 'pages.support.title' })}
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.baseLight.base4,
          width: '320px',
          height: '64px',
          color: theme.palette.baseDark.main,
          '&:hover': {
            color: theme.palette.baseLight.base0,
          },
        }}
        onClick={handleFleetManagementClick}
      >
        <Typography variant="bodyLargeBold">{formatMessage({ id: 'support.fleetManagement' })}</Typography>
      </Button>
    </SupportContainer>
  );
};

export default Support;
