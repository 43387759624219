import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import CustomIcon from '../../utils/CustomIcon';

interface InfoIconWithTooltipProps {
  title: ReactNode;
  placement?: TooltipProps['placement'];
  arrow?: TooltipProps['arrow'];
  tooltipProps?: TooltipProps;
  iconSize?: number;
  iconColor?: string;
}

export const InfoIconWithTooltip = ({
  placement = 'bottom',
  arrow = true,
  title,
  tooltipProps,
  iconSize = 16,
  iconColor = 'initial',
}: InfoIconWithTooltipProps) => {
  if (!title) {
    return null;
  }

  if (isString(title) || isNumber(title)) {
    title = <Typography variant="bodyMedium">{title}</Typography>;
  }

  return (
    <Tooltip
      placement={placement}
      arrow={arrow}
      {...tooltipProps}
      title={
        <Box py={1} px={1}>
          {title}
        </Box>
      }
    >
      {CustomIcon('Info', iconColor, iconSize)}
    </Tooltip>
  );
};
