import isEmpty from 'lodash/isEmpty';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { useSearchBudgetPlanMembership } from '../contexts';

import { searchBudgetPlanMemberFormKeys } from './helpers';
import { SearchBudgetPlanMembershipHandler } from './SearchAndLinkNewBudgetPlanMembershipModal';
import { SearchedMembershipDetailsAndLinkNewUserButton } from './SearchedMembershipDetailsAndLinkNewUserButton';

export const SearchedMembershipDetailsWithLoadingOrNoContentMessages = ({
  onSearchBudgetPlanMembershipClick,
}: SearchedMembershipDetailsWithLoadingAndNoContentMessagesProps) => {
  const searchMembershipState = useSearchBudgetPlanMembership();

  const { searchMembershipApiStatus } = searchMembershipState;

  if (isEmpty(searchMembershipState.searchedMembership)) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        error={searchMembershipApiStatus.isFailed()}
        loading={searchMembershipApiStatus.isPending()}
        noResults={isEmpty(searchMembershipState.searchedMembership) && searchMembershipApiStatus.isSucceeded()}
        onRetry={handleSearchMembershipRetry}
        baseTranslationKey="searchBudgetPlanMembership"
        style={{ height: '100%', backgroundColor: 'transparent' }}
      />
    );
  }

  return <SearchedMembershipDetailsAndLinkNewUserButton />;

  function handleSearchMembershipRetry() {
    onSearchBudgetPlanMembershipClick({
      [searchBudgetPlanMemberFormKeys.memberEmail]: searchMembershipState.searchedMembershipEmail,
    });
  }
};

interface SearchedMembershipDetailsWithLoadingAndNoContentMessagesProps {
  onSearchBudgetPlanMembershipClick: SearchBudgetPlanMembershipHandler;
}
