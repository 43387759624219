import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import { BudgetPlanApiLoadingStatus, RootState } from '..';
import { LoadingStatus } from '../../models';
import { BudgetPlanDto } from '../../services/budget-plan';

import { BudgetPlanReduxState } from './models';

type BudgetPlansStateSelector = (state: RootState) => BudgetPlanReduxState;
export const budgetPlanStateSelector: BudgetPlansStateSelector = (state: RootState) => state.budgetPlan;

export const budgetPlanApiStatusSelector = createSelector(
  budgetPlanStateSelector,
  (reduxState: BudgetPlanReduxState) => reduxState.apiStatus,
);

export const budgetPlansSelector = createSelector(budgetPlanStateSelector, (budgetPlans: BudgetPlanReduxState) => {
  if (isEmpty(budgetPlans.entities)) {
    return [];
  }
  return budgetPlans.entities.map((budgetPlan) => new BudgetPlanDto(budgetPlan));
});

export const planIdToBudgetPlanSelector = createSelector(budgetPlansSelector, (budgetPlans: BudgetPlanDto[]) => {
  return keyBy(budgetPlans, 'id');
});

export const budgetPlanApiErrorSelector = createSelector(
  budgetPlanStateSelector,
  (budgetPlan: BudgetPlanReduxState) => budgetPlan.apiError,
);

export const budgetPlansByIdSelector = createSelector(budgetPlansSelector, (budgetPlans) => {
  return keyBy(budgetPlans, 'id');
});

export const budgetPlanActionModeSelector = createSelector(
  budgetPlanStateSelector,
  (budgetPlans: BudgetPlanReduxState) => budgetPlans.ui.planActionMode,
);

export const budgetPlanListLoadingOrErrorOrNoResultsSelector = createSelector(
  budgetPlanApiStatusSelector,
  budgetPlansSelector,
  (budgetPlanApiStatus: BudgetPlanApiLoadingStatus, budgetPlans: BudgetPlanDto[]) => {
    return (
      budgetPlanApiStatus['listPlans'] === LoadingStatus.Failed ||
      budgetPlanApiStatus['listPlans'] === LoadingStatus.Pending ||
      !budgetPlans.length
    );
  },
);
