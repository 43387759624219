import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line import/named
import { Box, Button, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
// eslint-disable-next-line import/named
import { Field, Formik, FormikProps } from 'formik';
// eslint-disable-next-line import/named
import { IntlShape, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { Address, CustomerDetailsType, DrivingLicense } from '../../../models';
import { countryCodesIso3611, DEFAULT_DATE_FORMAT_DAYJS, phoneNumberCountryCodeAndLabels } from '../../../utils/constants';
import { localDateTime } from '../../../utils/DateMethods';
import { getCustomerDetails, putCustomerDetails } from '../../../utils/queries';
import {
  validateAddressExtension,
  validateCity,
  validateDateOfBirthDate,
  validateHouseNumber,
  validateMobileNumber,
  validateName,
  validatePostalCode,
  validateStreetName,
} from '../../../utils/ValidationSchemas';
import CustomTextField from '../../FormFields/CustomTextField';
import FormikErrorMessage from '../../FormFields/ErrorMessage';
import FormikSelectField from '../../FormFields/FormikSelectField';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';

interface CustomerDetailsTypeForForm extends Address {
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phoneNumberCountryCode: string;
  phoneNumberWithoutCountryCode: string;
  drivingLicense: DrivingLicense | null;
}

const initialCustomerDetails: CustomerDetailsTypeForForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumberCountryCode: '+49',
  phoneNumberWithoutCountryCode: '',
  birthDate: localDateTime(5).toISOString().slice(0, -8),
  street: '',
  houseNumber: '',
  postalCode: '',
  city: '',
  extension: '',
  countryCode: '',
  drivingLicense: null,
};

// useCallback?
const generateValidationSchema = (intl: IntlShape) => {
  return Yup.object().shape({
    firstName: validateName(intl),
    lastName: validateName(intl),
    birthDate: validateDateOfBirthDate(intl),
    phoneNumberWithoutCountryCode: validateMobileNumber(intl),
    street: validateStreetName(intl),
    houseNumber: validateHouseNumber(intl),
    extension: validateAddressExtension(intl),
    postalCode: validatePostalCode(intl),
    city: validateCity(intl),
  });
};

export interface CustomerDetailsProps {
  subjectClaim: string;
  onUpdateUserDetails: (updatedUserDetails: CustomerDetailsType) => void;
}

function CustomerPersonalDetails({ subjectClaim, onUpdateUserDetails }: CustomerDetailsProps) {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [customerDetails, setCustomerDetails] = useState<CustomerDetailsTypeForForm>(initialCustomerDetails);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [originalValues, setOriginalValues] = useState<CustomerDetailsType | null>(null);

  const fetchCustomerDetails = async (subjectClaim: string) => {
    setLoading(true);
    setError(false);
    try {
      const userData = await getCustomerDetails(subjectClaim);
      const { phoneNumber, birthDate, address } = userData;

      const { street, houseNumber, extension, postalCode, city, countryCode } = address || {};
      const phoneNumberParts = phoneNumber.split(/\s+/);
      const phoneNumberCountryCode = phoneNumberParts[0];
      // Older phone numbers might be having spaces in them such as +43 123 456 789
      // To show those numbers properly, we are merging all space delimited segments after country code i.e 1st space
      const phoneNumberWithoutCountryCode = phoneNumberParts
        .slice(1)
        .map((phoneNumberPart) => phoneNumberPart)
        .join('');

      const updatedUserData = {
        ...userData,
        phoneNumberCountryCode,
        phoneNumberWithoutCountryCode,
        birthDate,
        street: street || '',
        houseNumber: houseNumber || '',
        extension: extension || '',
        postalCode: postalCode || '',
        city: city || '',
        countryCode: countryCode || '',
      };
      setCustomerDetails(updatedUserData);
      setOriginalValues(userData);

      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerDetails(subjectClaim);
  }, [subjectClaim]);

  const intl = useIntl();
  const { formatMessage } = intl;

  const updateCustomerDetails = async (updatedCustomerDetails: CustomerDetailsTypeForForm) => {
    setLoading(true);
    setError(false);

    const {
      phoneNumberWithoutCountryCode,
      phoneNumberCountryCode,
      street,
      houseNumber,
      extension,
      postalCode,
      city,
      countryCode,
      firstName,
      lastName,
      birthDate,
      drivingLicense,
    } = updatedCustomerDetails;
    const fullPhoneNumber = `${phoneNumberCountryCode} ${phoneNumberWithoutCountryCode}`;
    const address = { street, houseNumber, extension, postalCode, city, countryCode };

    const payload = {
      firstName,
      lastName,
      birthDate,
      phoneNumber: fullPhoneNumber,
      address,
      drivingLicense,
    };

    try {
      await putCustomerDetails(payload, subjectClaim);
      originalValues && onUpdateUserDetails({ ...originalValues, ...updatedCustomerDetails, ...payload });
      setIsInEditMode(false);
      setLoading(false);
    } catch (error) {
      // TODO: error handling
      setLoading(false);
    }
  };

  const formikRef = useRef<FormikProps<CustomerDetailsTypeForForm>>(null);

  const handleCancelRequest = () => {
    formikRef.current?.resetForm();
    setIsInEditMode(false);
  };

  return loading || error ? (
    <LoadingAndErrorWithRetryAndNoResults
      style={{ height: '100%', backgroundColor: theme.palette.white.main }}
      error={error}
      loading={loading}
      onRetry={() => {
        !isInEditMode && fetchCustomerDetails(subjectClaim);
      }}
      baseTranslationKey={'customerDetails'}
    />
  ) : (
    <Formik
      initialValues={customerDetails}
      validationSchema={generateValidationSchema(intl)}
      enableReinitialize
      innerRef={formikRef}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(v) => {}}
    >
      {({ isValid, isValidating, values, handleChange, handleBlur }) => (
        <Grid container={true} component="form" direction="row">
          <Grid container={true} direction="row" justifyContent="space-between">
            <Typography variant="bodySmallBold" color={theme.palette.dark.main}>
              {formatMessage({ id: 'maas.customer.details.personalInfo' })}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              disabled={isInEditMode}
              onClick={(e) => {
                setIsInEditMode(true);
              }}
              color="primary"
              style={{ minWidth: '90px' }}
            >
              <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.edit' })}</Typography>
            </Button>
          </Grid>

          <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
            <Grid item xs={5} sx={{ marginRight: '30px' }}>
              <Typography
                component="label"
                htmlFor="firstName"
                variant="caption"
                color={theme.palette.dark.shade60}
                sx={{ marginBottom: '4px' }}
              >
                {formatMessage({ id: 'maas.customer.details.firstName' })}
              </Typography>
              <Field as={CustomTextField} name="firstName" disabled={!isInEditMode} />
              <FormikErrorMessage name="firstName" />
            </Grid>
            <Grid item xs={5}>
              <Typography
                component="label"
                htmlFor="lastName"
                variant="caption"
                color={theme.palette.dark.shade60}
                sx={{ marginBottom: '4px' }}
              >
                {formatMessage({ id: 'maas.customer.details.lastName' })}
              </Typography>
              <Field as={CustomTextField} name="lastName" disabled={!isInEditMode} />
              <FormikErrorMessage name="lastName" />
            </Grid>
          </Grid>

          <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
            <Grid item xs={5} sx={{ marginRight: '30px' }}>
              <Typography
                component="label"
                htmlFor="birthDate"
                variant="caption"
                color={theme.palette.dark.shade60}
                sx={{ marginBottom: '4px' }}
              >
                {formatMessage({ id: 'maas.customer.details.birthdate' })}
              </Typography>
              <Field
                type="date"
                name="birthDate"
                component={CustomTextField}
                value={values.birthDate}
                disabled={!isInEditMode}
                format={DEFAULT_DATE_FORMAT_DAYJS}
              />
              <FormikErrorMessage name="birthDate" />
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: '20px' }}>
            <Typography variant="bodySmallBold" color={theme.palette.dark.main}>
              {formatMessage({ id: 'maas.customer.details.contactInformation' })}
            </Typography>
            <Grid container direction="row" alignItems="flex-start" sx={{ marginTop: '20px', display: 'flex' }}>
              <Grid item container xs={5} sx={{ marginRight: '30px', display: 'flex', flexDirection: 'column' }}>
                <Typography
                  component="label"
                  htmlFor="mobilephone"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px', display: 'block' }}
                >
                  {formatMessage({ id: 'maas.customer.details.mobilePhone' })}
                </Typography>
                <Grid item sx={{ display: 'flex' }}>
                  <FormControl
                    size="small"
                    sx={{
                      display: 'flex',
                      marginRight: '10px',
                      minWidth: '80px',
                    }}
                  >
                    <Field
                      as={Select}
                      name="phoneNumberCountryCode"
                      labelId="phoneNumberCountryCode-label"
                      id="phoneNumberCountryCode"
                      value={values.phoneNumberCountryCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isInEditMode}
                    >
                      {phoneNumberCountryCodeAndLabels.map((op) => (
                        <MenuItem key={op.value} value={op.value}>
                          {op.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <Field as={CustomTextField} name="phoneNumberWithoutCountryCode" disabled={!isInEditMode} />
                </Grid>
                <FormikErrorMessage name="phoneNumberWithoutCountryCode" />
              </Grid>
              <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  component="label"
                  htmlFor="email"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px' }}
                >
                  {formatMessage({ id: 'maas.customer.details.email' })}
                </Typography>
                <Field as={CustomTextField} id="email" name="email" value={customerDetails.email} disabled />
                <FormikErrorMessage name="email" />
              </Grid>
            </Grid>

            <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
              <Grid item xs={5} sx={{ marginRight: '30px' }}>
                <Typography
                  component="label"
                  htmlFor="street"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px' }}
                >
                  {formatMessage({ id: 'maas.customer.details.streetName' })}
                </Typography>
                <Field as={CustomTextField} name="street" disabled={!isInEditMode} />
                <FormikErrorMessage name="street" />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component="label"
                  htmlFor="houseNumber"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px' }}
                >
                  {formatMessage({ id: 'maas.customer.details.houseNumber' })}
                </Typography>
                <Field as={CustomTextField} name="houseNumber" disabled={!isInEditMode} />
                <FormikErrorMessage name="houseNumber" />
              </Grid>
            </Grid>

            <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
              <Grid item xs={5} sx={{ marginRight: '30px' }}>
                <Typography
                  component="label"
                  htmlFor="extension"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px' }}
                >
                  {formatMessage({ id: 'maas.customer.details.addressExtension' })}
                </Typography>
                <Field as={CustomTextField} name="extension" disabled={!isInEditMode} />
                <FormikErrorMessage name="extension" />
              </Grid>
            </Grid>

            <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
              <Grid item xs={5} sx={{ marginRight: '30px' }}>
                <Typography
                  component="label"
                  htmlFor="postalCode"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px' }}
                >
                  {formatMessage({ id: 'maas.customer.details.postCode' })}
                </Typography>
                <Field as={CustomTextField} name="postalCode" disabled={!isInEditMode} />
                <FormikErrorMessage name="postalCode" />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  component="label"
                  htmlFor="city"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px' }}
                >
                  {formatMessage({ id: 'maas.customer.details.city' })}
                </Typography>
                <Field as={CustomTextField} name="city" disabled={!isInEditMode} />
                <FormikErrorMessage name="city" />
              </Grid>
            </Grid>
            <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
              <Grid item xs={5} sx={{ marginRight: '30px' }}>
                <Typography
                  component="label"
                  htmlFor="countryCode"
                  variant="caption"
                  color={theme.palette.dark.shade60}
                  sx={{ marginBottom: '4px' }}
                >
                  {formatMessage({ id: 'maas.customer.dl.issuingCountry' })}
                </Typography>
                <Field as={FormikSelectField} options={countryCodesIso3611} name="countryCode" disabled={!isInEditMode} />
                <FormikErrorMessage name="countryCode" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                float: 'right',
                height: '32px',
                marginTop: '30px',
              }}
            >
              <Button variant="outlined" disabled={!isInEditMode} size="small" color="primary" onClick={handleCancelRequest}>
                <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.cancel' })}</Typography>
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={isValidating || !isValid || !isInEditMode}
                onClick={(e) => {
                  e.preventDefault();
                  updateCustomerDetails(values);
                }}
                sx={{
                  marginLeft: '24px',
                }}
                color="primary"
              >
                <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.update' })}</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default CustomerPersonalDetails;
