import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { ConfirmationModal } from '../../../../wmv-components';
import {
  MembershipDetailsActionType,
  useSelectedBudgetPlanDetails,
  useMembershipDetailsState,
  useMembershipDetailsDispatch,
} from '../contexts';

import { MembershipDetailsRow } from './MembershipDetailsRow';

export const UnlinkMembershipConfirmationModal = ({ onUnlinkConfirmClick }: UnlinkMembershipConfirmationModalProps) => {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();
  const membershipDetailsDispatch = useMembershipDetailsDispatch();
  const membershipDetailsState = useMembershipDetailsState();
  const selectedBudgetPlanDetails = useSelectedBudgetPlanDetails();
  const { membership, unlinkingApiStatus } = membershipDetailsState;

  return (
    <ConfirmationModal
      open={membershipDetailsState.showUnlinkConfirmationModal}
      title={fm({ id: 'common.unlinkingUser' })}
      handleClose={closeUnlinkConfirmationModal}
      handleConfirm={onUnlinkConfirmClick}
      loading={unlinkingApiStatus.isPending()}
      confirmBtnColor="error"
      error={unlinkingApiStatus.isFailed() ? fm({ id: 'common.submitFailed' }) : null}
    >
      <Box>
        <MembershipDetailsRow
          title={selectedBudgetPlanDetails?.activeState.name}
          titleVariant="bodyMediumBold"
          labelTranslationKey="common.budget"
          applyLabelStyles={false}
        />
        <MembershipDetailsRow
          title={membership?.name()}
          titleVariant="bodyMediumBold"
          labelTranslationKey="common.user"
          applyLabelStyles={false}
        />
        <Typography typography="bodyMedium" color={palette.baseLight.base60} mt={2}>
          {fm({ id: 'budgetPlanMembership.unlinkConfirmation' })}
        </Typography>
      </Box>
    </ConfirmationModal>
  );

  function closeUnlinkConfirmationModal() {
    membershipDetailsDispatch({
      type: MembershipDetailsActionType.CloseUnlinkConfirmationModal,
    });
  }
};

interface UnlinkMembershipConfirmationModalProps {
  onUnlinkConfirmClick: () => Promise<void>;
}
