import type { Theme } from '@mui/material/styles';

export const DropDownBoxStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  gap: theme.spacing(3),
  width: '20%',
  maxWidth: theme.spacing(27),
  minWidth: theme.spacing(20.5),
  height: '100%',
  borderRight: `2px solid ${theme.palette.dark.shade8}`,
  overflow: 'auto',
});

export const InputModalStyle = (theme: Theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.white.main,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  isolation: 'isolate',
  borderRadius: theme.spacing(1),
  width: '500px',
  height: '400px',
});

export const ImageHeaderStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'flex-end',
  borderBottom: `1px solid ${theme.palette.dark.shade8}`,
  paddingBottom: theme.spacing(1),
});

export const RootBoxStyle = (theme: Theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.white.main,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '24px 24px 24px 0px',
  gap: theme.spacing(1),
  isolation: 'isolate',
  borderRadius: theme.spacing(1),
  width: '95%',
  height: '90%',
  maxWidth: '1600px',
  maxHeight: '1200px',
  minWidth: '790px',
});

export const ContentBoxStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '0px 4px 0px 3vh',
  gap: theme.spacing(4),
  width: '82%',
  height: '100%',
  overflow: 'auto',
  scrollbarWidth: 'thin',
});

export const ContentInnerBoxStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '24px 0px 0px',
  gap: theme.spacing(2),
  width: '100%',
});

export const AboutBoxStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'strech',
  gap: theme.spacing(1),
  width: '100%',
  paddingTop: theme.spacing(2),
});

export const CommonSectionStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
});

export const CommonRowStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
});

export const CommonTextFieldStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.dark.shade8,
  width: theme.spacing(33),
});
