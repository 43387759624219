import Box from '@mui/material/Box';

import { VehiclePricingStateProvider, SelectedVehiclePricingStateProvider } from './contexts';
import { PageHeading } from './PageHeading';
import { VehiclePricingDetailsModal } from './VehiclePricingDetailsModal';
import { VehiclePricingTableWithLoadingOrNoContentMessage } from './VehiclePricingTableWithLoadingOrNoContentMessage';

export const VehiclePricingLayout = () => {
  return (
    <VehiclePricingStateProvider>
      <Box>
        <PageHeading />
        <VehiclePricingTableWithLoadingOrNoContentMessage />
        <SelectedVehiclePricingStateProvider>
          <VehiclePricingDetailsModal />
        </SelectedVehiclePricingStateProvider>
      </Box>
    </VehiclePricingStateProvider>
  );
};
