import { EnumWithStringValue } from '../../../utils/EnumUtils';
import { intl } from '../../../utils/intl';

export enum DepositUnit {
  Minutes = 'MINUTES',
}

interface DepositUnitUnderivedMetadata {
  displayText: string;
}

export namespace DepositUnitHelper {
  const { formatMessage } = intl;
  const depositUnitUnderivedMetadata = new Map<DepositUnit, DepositUnitUnderivedMetadata>([
    [DepositUnit.Minutes, { displayText: formatMessage({ id: 'budgetPlan.depositUnit.minutes' }) }],
  ]);

  export function metadata(depositUnit: DepositUnit): DepositUnitUnderivedMetadata {
    const underivedMetadata = depositUnitUnderivedMetadata.get(depositUnit);
    if (!underivedMetadata) {
      throw Error(`
            Specified Deposit Unit: ${toString(depositUnit)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(depositUnit: DepositUnit): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(DepositUnit, depositUnit);
  }
}
