import type { Theme } from '@mui/material/styles';

export const tabsContainerStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.baseLight.base0,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  gap: theme.spacing(2),
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(1),
});

export const tabsHeaderStyles = (theme: Theme) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  flexWrap: 'nowrap',
  mt: 2,
});

export const tabsHeaderSubtitleStyles = (theme: Theme) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const dividerStyles = (theme: Theme) => ({
  borderColor: theme.palette.dark.shade8,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
});

export const tabsListStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  p: 0,
});

export const tabItemStyles = (theme: Theme) => ({
  paddingTop: '0',
  paddingBottom: '0',
  paddingLeft: '32px',
  paddingRight: '32px',
  color: theme.palette.dark.shade60,
  '&:hover': {
    backgroundColor: theme.palette.dark.shade4,
    color: theme.palette.secondary.dark,
  },
  '&.Mui-selected': {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.dark.shade4,
  },
  ...theme.typography.bodyLargeBold,
});
