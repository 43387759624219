import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';

import { BudgetPlanMembershipSearchDto } from '../../../../services/budget-plan-memberships-search';
import { searchMembership } from '../../../../utils/queries';
import { TranslatedText } from '../../../../wmv-components';
import {
  BudgetPlanMembershipsListActionType,
  SearchBudgetPlanMembershipActionType,
  useBudgetPlanMembershipsListDispatch,
  useBudgetPlanMembershipsListState,
  useSearchBudgetPlanMembership,
  useSearchBudgetPlanMembershipDispatch,
  useSelectedBudgetPlanId,
} from '../contexts';

import { Modal } from './Modal';
import { SearchBudgetPlanMemberFormWithFormik } from './SearchBudgetPlanMemberForm';
import { SearchedMembershipDetailsWithLoadingOrNoContentMessages } from './SearchedMembershipDetailsWithLoadingOrNoContentMessages';
import { searchMembershipContentStyles } from './styles';

export const SearchAndLinkNewBudgetPlanMembershipModal = () => {
  const theme = useTheme();
  const searchMembershipDispatch = useSearchBudgetPlanMembershipDispatch();
  const membershipsListState = useBudgetPlanMembershipsListState();
  const membershipsListDispatch = useBudgetPlanMembershipsListDispatch();
  const selectedPlanId = useSelectedBudgetPlanId();
  const searchMembershipState = useSearchBudgetPlanMembership();
  const {
    linkMembership: { status: linkingApiStatus },
    searchMembershipApiStatus,
  } = searchMembershipState;

  return (
    <Modal
      open={membershipsListState.linkModalOpen}
      onClose={handleLinkNewMembershipModalClose}
      closeable={!searchMembershipApiStatus.isPending() || !linkingApiStatus.isPending()}
      bgColor={theme.palette.baseLight.base0}
    >
      <Modal.Content sx={searchMembershipContentStyles}>
        <TranslatedText translationKey="common.linkNewUser" variant="bodyLargeBold" />
        <SearchBudgetPlanMemberFormWithFormik onSearchBudgetPlanMembershipClick={handleSearchBudgetPlanMembershipClick} />
        <SearchedMembershipDetailsWithLoadingOrNoContentMessages
          onSearchBudgetPlanMembershipClick={handleSearchBudgetPlanMembershipClick}
        />
      </Modal.Content>
    </Modal>
  );

  async function handleSearchBudgetPlanMembershipClick(values: SearchMemberFormValues) {
    try {
      searchMembershipDispatch({
        type: SearchBudgetPlanMembershipActionType.UpdateSearchedEmail,
        payload: values.memberEmail,
      });
      searchMembershipDispatch({
        type: SearchBudgetPlanMembershipActionType.SearchStart,
      });
      const resp = await searchMembership('a@a.com', selectedPlanId || 1);
      searchMembershipDispatch({
        type: SearchBudgetPlanMembershipActionType.SearchSuccess,
        payload: isEmpty(resp) ? null : new BudgetPlanMembershipSearchDto(resp[0]),
      });
    } catch (err) {
      searchMembershipDispatch({
        type: SearchBudgetPlanMembershipActionType.SearchFailure,
      });
    }
  }

  async function handleLinkNewMembershipModalClose() {
    membershipsListDispatch({
      type: BudgetPlanMembershipsListActionType.CloseLinkMembershipModal,
    });
    searchMembershipDispatch({
      type: SearchBudgetPlanMembershipActionType.ResetSearchMembership,
    });
  }
};

export interface SearchMemberFormValues {
  memberEmail: string;
}

export type SearchBudgetPlanMembershipHandler = (values: SearchMemberFormValues) => void;
