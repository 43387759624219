import { createReducer } from '@reduxjs/toolkit';

import { LoadingStatus } from '../../models';

import {
  addBudgetPlanAsyncThunkAction,
  listBudgetPlansAsyncThunkAction,
  resetErrorAction,
  setBudgetPlanActionModeAction,
  updateBudgetPlanAsyncThunkAction,
  updateBudgetPlanStatusAsyncThunkAction,
} from './actions';
import { BudgetPlanActionMode, BudgetPlanReduxState } from './models';

const initialState: BudgetPlanReduxState = {
  entities: [],
  apiStatus: {
    listPlans: LoadingStatus.Idle,
    addPlan: LoadingStatus.Idle,
    updatePlan: LoadingStatus.Idle,
    transitionFailureRetry: LoadingStatus.Idle,
  },
  apiError: {
    listPlans: null,
    addPlan: null,
    updatePlan: null,
    transitionFailureRetry: null,
  },
  ui: {
    planActionMode: BudgetPlanActionMode.Idle,
  },
};

const budgetPlanReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(listBudgetPlansAsyncThunkAction.pending, (state) => {
      state.apiStatus.listPlans = LoadingStatus.Pending;
      state.apiError.listPlans = null;
    })
    .addCase(listBudgetPlansAsyncThunkAction.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.apiStatus.listPlans = LoadingStatus.Succeeded;
    })
    .addCase(listBudgetPlansAsyncThunkAction.rejected, (state, action) => {
      state.apiError.listPlans = action.payload! || action.error;
      state.apiStatus.listPlans = LoadingStatus.Failed;
      state.entities = [];
    })
    .addCase(addBudgetPlanAsyncThunkAction.pending, (state) => {
      state.apiStatus.addPlan = LoadingStatus.Pending;
      state.apiError.addPlan = null;
    })
    .addCase(addBudgetPlanAsyncThunkAction.fulfilled, (state, action) => {
      state.apiStatus.addPlan = LoadingStatus.Succeeded;
      state.entities.push(action.payload);
    })
    .addCase(addBudgetPlanAsyncThunkAction.rejected, (state, action) => {
      state.apiStatus.addPlan = LoadingStatus.Failed;
      state.apiError.addPlan = action.payload! || action.error;
    })
    .addCase(resetErrorAction, (state, action) => {
      state.apiError[action.payload] = null;
    })
    .addCase(setBudgetPlanActionModeAction, (state, action) => {
      state.ui.planActionMode = action.payload;
    })
    .addCase(updateBudgetPlanAsyncThunkAction.pending, (state) => {
      state.apiStatus.updatePlan = LoadingStatus.Pending;
      state.apiError.updatePlan = null;
    })
    .addCase(updateBudgetPlanAsyncThunkAction.fulfilled, (state, action) => {
      state.apiStatus.updatePlan = LoadingStatus.Succeeded;
      const updatedPlanIndex = state.entities.findIndex((budgetPlan) => budgetPlan.id === action.payload.id);
      state.entities[updatedPlanIndex] = action.payload;
    })
    .addCase(updateBudgetPlanAsyncThunkAction.rejected, (state, action) => {
      state.apiStatus.updatePlan = LoadingStatus.Failed;
      state.apiError.updatePlan = action.payload! || action.error;
    })
    .addCase(updateBudgetPlanStatusAsyncThunkAction.pending, (state) => {
      state.apiStatus.transitionFailureRetry = LoadingStatus.Pending;
      state.apiError.transitionFailureRetry = null;
    })
    .addCase(updateBudgetPlanStatusAsyncThunkAction.fulfilled, (state, action) => {
      state.apiStatus.transitionFailureRetry = LoadingStatus.Succeeded;
      const updatedPlanIndex = state.entities.findIndex((budgetPlan) => budgetPlan.id === action.payload.id);
      state.entities[updatedPlanIndex] = action.payload;
    })
    .addCase(updateBudgetPlanStatusAsyncThunkAction.rejected, (state, action) => {
      state.apiStatus.transitionFailureRetry = LoadingStatus.Failed;
      state.apiError.transitionFailureRetry = action.payload! || action.error;
    });
});

export default budgetPlanReducer;
