import { array, boolean, date, number, object, string } from 'yup';

// TODO: Check whether we should expose a barrel file for utils or not and what kind of utils can be grouped and separated out
import { MIN_DAYS_IN_A_MONTH, NAME_35_CHARS_LENGTH } from '../../../../utils/constants';
import { intl } from '../../../../utils/intl';
import { regexPatterns } from '../../../../utils/regex-patterns';
import { validateName } from '../../../../utils/ValidationSchemas';
import { budgetPlanFormInputKeys } from '../helpers';

export const budgetPlanDetailsForm = () => {
  const { formatMessage } = intl;

  return object().shape({
    [budgetPlanFormInputKeys.name]: validateName(intl, NAME_35_CHARS_LENGTH),
    [budgetPlanFormInputKeys.depositFrequency]: string().required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
    [budgetPlanFormInputKeys.depositAmount]: string()
      .matches(
        regexPatterns.numerberOnly,
        formatMessage(
          { id: 'form.validation.shouldBePositiveInteger' },
          { label: formatMessage({ id: 'operator.budgetPlan.form.input.depositAmount.title' }) },
        ),
      )
      .test({
        name: 'minValueCheck',
        test(value, ctx) {
          if (Number(value) < 1) {
            return ctx.createError({
              message: formatMessage({ id: 'form.validation.mustBeGreaterThanOrEqualToOne' }),
            });
          }
          return true;
        },
      })
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
    [budgetPlanFormInputKeys.depositDayOfMonth]: number()
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
      .positive(formatMessage({ id: 'form.validation.mustBePositiveNumber' }))
      .typeError(formatMessage({ id: 'form.validation.invalidValue' }))
      .max(
        MIN_DAYS_IN_A_MONTH,
        formatMessage(
          { id: 'form.validation.maxAllowedValue' },
          { label: formatMessage({ id: 'operator.budgetPlan.form.input.depositDayOfMonth.label' }), maxValue: MIN_DAYS_IN_A_MONTH },
        ),
      ),
    [budgetPlanFormInputKeys.amountPerTripSpendConstraintExists]: boolean().required(
      formatMessage({ id: 'form.validation.selectEitherOfTheOption' }),
    ),
    [budgetPlanFormInputKeys.depositTime]: date()
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
      .typeError(formatMessage({ id: 'form.validation.invalidTime' })),
    [budgetPlanFormInputKeys.amountPerTripSpendConstraint]: string().when(budgetPlanFormInputKeys.amountPerTripSpendConstraintExists, {
      is: true,
      then: function () {
        return string()
          .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
          .test({
            name: 'minAndMaxValueCheck',
            test(value, ctx) {
              const depositAmount = this.parent[budgetPlanFormInputKeys.depositAmount];
              if (depositAmount && Number(depositAmount) < Number(value)) {
                return ctx.createError({
                  message: formatMessage(
                    { id: 'form.validation.mustBeEqualOrLess' },
                    { label: formatMessage({ id: 'operator.budgetPlan.form.input.depositAmount.title' }) },
                  ),
                });
              }
              if (Number(value) < 1) {
                return ctx.createError({
                  message: formatMessage({ id: 'form.validation.mustBeGreaterThanOrEqualToOne' }),
                });
              }
              return true;
            },
          })
          .matches(
            regexPatterns.numerberOnly,
            formatMessage(
              { id: 'form.validation.shouldBePositiveInteger' },
              { label: formatMessage({ id: 'operator.budgetPlan.form.input.depositAmount.title' }) },
            ),
          );
      },
      otherwise: () => number().nullable().optional(),
    }),
    [budgetPlanFormInputKeys.vehicleTypesSpendConstraintExists]: boolean().required(
      formatMessage({ id: 'form.validation.selectEitherOfTheOption' }),
    ),
    [budgetPlanFormInputKeys.vehicleTypesSpendConstraint]: array().when(budgetPlanFormInputKeys.vehicleTypesSpendConstraintExists, {
      is: true,
      then: function () {
        return array()
          .min(1, formatMessage({ id: 'form.validation.errorFieldRequired' }))
          .required(formatMessage({ id: 'form.validation.errorFieldRequired' }));
      },
      otherwise: () => array().optional(),
    }),
  });
};
