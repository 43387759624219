import type { Theme } from '@mui/material/styles';

// LinkedProfilesTabContent
export const linkedProfilesContainerStyles = (theme: Theme) => ({
  marginTop: theme.spacing(5),
});

// SlidingConfirmationButton
export const slidingConfirmationContainerSx = (theme: Theme) => ({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row-reverse',
});

// LinkedMembersList
export const tableContainerStyles = {
  '.MuiTableRow-root > td:nth-last-of-type(1) > div > span': {
    flex: 1,
  },
};

export const unsetBgStyles = {
  backgroundColor: 'unset',
};
