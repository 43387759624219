import { Grid, IconButton, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';

export const VehicleModal = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  width: '80%',
  height: '90%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px 8px 8px 8px',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.white.main,
}));

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '-40px',
  right: '-40px',
}));

export const VehicleMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  paddingTop: '0',
  paddingBottom: '0',
  color: theme.palette.dark.shade60,
  '&:hover': {
    backgroundColor: theme.palette.dark.shade4,
    color: theme.palette.secondary.dark,
  },
  '&.Mui-selected': {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.dark.shade4,
  },
  ...theme.typography.bodyLargeBold,
}));

// Modal -> Left Content
export const leftContainerSx = (theme: Theme) => ({
  backgroundColor: theme.palette.dark.shade3,
  padding: '16px 8px',
  gap: theme.spacing(2),
  margin: '0 8px 0 8px',
  width: 'fit-content',
});

export const leftContentSx = (theme: Theme) => ({
  padding: '24px 16px',
  gap: theme.spacing(1),
  backgroundColor: theme.palette.white.main,
  borderRadius: theme.spacing(1),
  flexWrap: 'nowrap',
});

export const ellipsisTextSx = (theme: Theme) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const leftContentDividerSx = (theme: Theme) => ({
  borderColor: theme.palette.dark.shade8,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
});

export const rightContainerSx = (theme: Theme) => ({
  padding: '16px 16px 24px 16px',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(1),
  margin: '0 8px 0 8px',
  overflow: 'auto',
});
