import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Form, useFormikContext, Formik } from 'formik';
import { useIntl } from 'react-intl';

import { useSearchBudgetPlanMembership } from '../contexts';
import { searchPlanMemberFormValidationSchema } from '../validation-schemas/searchPlanMemberForm';

import { searchBudgetPlanMemberFormKeys } from './helpers';
import { SearchBudgetPlanMembershipHandler, SearchMemberFormValues } from './SearchAndLinkNewBudgetPlanMembershipModal';
import { SearchInput } from './SearchInput';

const searchBudgetPlanMembershipFormInitialValues: SearchMemberFormValues = {
  memberEmail: '',
};

export const SearchBudgetPlanMemberFormWithFormik = ({ onSearchBudgetPlanMembershipClick }: SearchBudgetPlanMemberFormWithFormikProps) => {
  return (
    <Formik
      validationSchema={searchPlanMemberFormValidationSchema}
      initialValues={searchBudgetPlanMembershipFormInitialValues}
      onSubmit={onSearchBudgetPlanMembershipClick}
    >
      {(props) => <SearchBudgetPlanMemberForm />}
    </Formik>
  );
};

const SearchBudgetPlanMemberForm = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const { values, setFieldValue, errors, touched, handleSubmit } = useFormikContext<SearchMemberFormValues>();
  const { searchMembershipApiStatus } = useSearchBudgetPlanMembership();

  return (
    <Form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="row" height="40px" gap={theme.spacing(1)}>
        <SearchInput
          hasError={!!errors.memberEmail && touched.memberEmail}
          helperText={!!errors.memberEmail && touched.memberEmail ? errors.memberEmail : null}
          value={values.memberEmail}
          onChange={handleMemberEmailChange}
          variant="outlined"
          label={formatMessage({ id: 'common.emailAddress' })}
          placeholder={formatMessage({ id: 'budgetPlan.memberSearch.placeholder' })}
          size="small"
          sx={{ flex: 1 }}
          showSearchIcon={false}
          showClearIcon
        />
        <Button type="submit" size="small" variant="secondaryLight" disabled={searchMembershipApiStatus.isPending()}>
          {formatMessage({ id: 'common.search' })}
        </Button>
      </Box>
    </Form>
  );

  async function handleMemberEmailChange(email: string) {
    await setFieldValue(searchBudgetPlanMemberFormKeys.memberEmail, email);
  }
};

interface SearchBudgetPlanMemberFormWithFormikProps {
  onSearchBudgetPlanMembershipClick: SearchBudgetPlanMembershipHandler;
}
