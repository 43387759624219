import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';
import { ListItemConfig } from '../common';

export enum CustomerBudgetInformationTab {
  ActiveBudget = 'ACTIVE_BUDGET',
  LinkedProfiles = 'LINKED_PROFILES',
  BudgetHistory = 'BUDGET_HISTORY',
}

interface CustomerBudgetInformationTabMetadata {
  displayText: string;
}

const metaData = new Map<CustomerBudgetInformationTab, CustomerBudgetInformationTabMetadata>([
  [
    CustomerBudgetInformationTab.ActiveBudget,
    {
      displayText: intl.formatMessage({ id: 'common.activeBudget' }),
    },
  ],
  [
    CustomerBudgetInformationTab.LinkedProfiles,
    {
      displayText: intl.formatMessage({ id: 'common.linkedProfiles' }),
    },
  ],
  [
    CustomerBudgetInformationTab.BudgetHistory,
    {
      displayText: intl.formatMessage({ id: 'common.budgetHistory' }),
    },
  ],
]);

export namespace CustomerBudgetInformationTabHelper {
  export function displayText(tab: CustomerBudgetInformationTab) {
    return metaData.get(tab)?.displayText;
  }

  export function allValues(): CustomerBudgetInformationTab[] {
    return EnumWithStringValue.enumToValues(CustomerBudgetInformationTab);
  }

  export function allLabelAndValues(): ListItemConfig<CustomerBudgetInformationTab>[] {
    return allValues().map((customerBudgetInformationTab) => {
      return {
        value: customerBudgetInformationTab,
        label: displayText(customerBudgetInformationTab)!,
      };
    });
  }
}
