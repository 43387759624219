import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorMessage } from '../../../../components/FormFields/ErrorMessage';
import {
  AppDispatch,
  BudgetPlanActionMode,
  budgetPlanActionModeSelector,
  budgetPlanApiErrorSelector,
  budgetPlanApiStatusSelector,
  resetErrorAction,
  setBudgetPlanActionModeAction,
} from '../../../../state';
import { TranslatedText, ProgressAwareButton } from '../../../../wmv-components';
import { actionButtonStyles, contentHeadingStyles } from '../styles';

import { BudgetPlanDetailsFormValues } from './BudgetPlanDetailsView';
import { BUDGET_PLAN_ALREADY_EXIST_ERROR_TITLE } from './helpers';

export const BudgetPlanFormHeadingAndActionButtons = ({
  onCancelClick,
  currentBudgetPlanFormValues,
}: BudgetPlanFormHeadingAndActionButtonsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const { isSubmitting, setValues } = useFormikContext<BudgetPlanDetailsFormValues>();

  const budgetPlanApiStatus = useSelector(budgetPlanApiStatusSelector);
  const budgetPlansError = useSelector(budgetPlanApiErrorSelector);
  const budgetPlanActionMode = useSelector(budgetPlanActionModeSelector);
  const isEditingBudgetPlanDetails = budgetPlanActionMode === BudgetPlanActionMode.Edit;
  // TODO: This check needs to be removed as we are comparing the hardcoded text. Discuss with BE team to send some kind of code.
  const isSameNameError =
    budgetPlansError['addPlan']?.title === BUDGET_PLAN_ALREADY_EXIST_ERROR_TITLE ||
    budgetPlansError['updatePlan']?.title === BUDGET_PLAN_ALREADY_EXIST_ERROR_TITLE;

  const handleEditPlanClick = () => {
    dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.Edit));
  };

  const handleCancelClick = async () => {
    if (isEditingBudgetPlanDetails) {
      if (budgetPlanApiStatus['updatePlan']) dispatch(resetErrorAction('updatePlan'));
      dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.View));
      await setValues(currentBudgetPlanFormValues!);
    } else {
      if (budgetPlanApiStatus['addPlan']) dispatch(resetErrorAction('addPlan'));
      onCancelClick();
    }
  };

  return (
    <Box sx={contentHeadingStyles}>
      <Box marginLeft="auto">
        <Button variant="outlined" onClick={handleCancelClick} size="small" disabled={isSubmitting}>
          <TranslatedText translationKey="actions.cancel" variant="bodySmallBold" />
        </Button>
        {budgetPlanActionMode === BudgetPlanActionMode.Add && (
          <ProgressAwareButton loading={isSubmitting} loadingButtonProps={{ sx: actionButtonStyles }} size="small">
            {formatMessage({ id: 'actions.save' })}
          </ProgressAwareButton>
        )}
        {budgetPlanActionMode === BudgetPlanActionMode.View && (
          <Button onClick={handleEditPlanClick} sx={actionButtonStyles} variant="contained" size="small">
            <TranslatedText translationKey="actions.edit" variant="bodySmallBold" />
          </Button>
        )}
        {budgetPlanActionMode === BudgetPlanActionMode.Edit && (
          <ProgressAwareButton loading={isSubmitting} loadingButtonProps={{ sx: actionButtonStyles }} size="small">
            <TranslatedText translationKey="actions.update" variant="bodySmallBold" />
          </ProgressAwareButton>
        )}
      </Box>
      {(budgetPlansError['addPlan'] || budgetPlansError['updatePlan']) && (
        <ErrorMessage
          error={
            isSameNameError ? formatMessage({ id: 'budgetPlan.samePlanNameExists' }) : formatMessage({ id: 'systemStatus.error.caption' })
          }
        />
      )}
    </Box>
  );
};

interface BudgetPlanFormHeadingAndActionButtonsProps {
  onCancelClick: () => void;
  currentBudgetPlanFormValues?: BudgetPlanDetailsFormValues;
}
