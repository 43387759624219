import React, { useState } from 'react';

import { Box, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  AggregatedVehicle,
  AggregatedVehicleMetadata,
  AggregatedVehicleUsageStatus,
  AggregatedVehicleUsageStatusHelper,
} from '../../../../models';

import StatusChangeModal from './StatusChangeModal';

function StatusChangePopover({
  vehicle,
  anchorElement,
  handleCloseCellAnchor,
  parentWidth,
  handleStatusChangeConfirm,
  futureStatus,
  onCurrentStatusPopoverClick,
}: StatusChangePopoverProps) {
  const theme = useTheme();

  const handleClose = () => {
    handleCloseCellAnchor();
  };
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  const [openChangeModal, setOpenChangeModal] = useState(false);
  const handleOpenChangeModal = (futureStatusProp: AggregatedVehicleUsageStatus) => {
    onCurrentStatusPopoverClick(futureStatusProp);
    setOpenChangeModal(true);
  };
  const handleCloseChangeModal = () => setOpenChangeModal(false);

  const popoverContent = (options: AggregatedVehicleMetadata[] | undefined) => {
    return options?.map((option, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: theme.spacing(2),
          '&:hover': {
            backgroundColor: theme.palette.dark.shade8,
            width: '100%',
          },
          padding: theme.spacing(1),
        }}
        onClick={() => handleOpenChangeModal(option.futureStatus)}
      >
        <Box
          sx={{
            width: '16px',
            height: '16px',
            borderRadius: '16px',
            backgroundColor: option.color,
            marginLeft: theme.spacing(2),
          }}
        />
        <Typography variant="bodyMedium" color={theme.palette.dark.main}>
          {option.usageStatusTranslation}
        </Typography>
      </Box>
    ));
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            width:
              parentWidth > 253
                ? `calc(${parentWidth}px)`
                : parentWidth > 200
                ? `calc(${parentWidth}px - ${theme.spacing(1.25)}): `
                : `calc(${parentWidth}px - ${theme.spacing(2)})`,
            //This custom calculation will be removed by the implementation of a select with issue 1533
            margin: `8px 0 0 ${theme.spacing(1.75)}`,
          },
          '& .MuiPaper-root .MuiBox-root': {
            padding: `${theme.spacing(1)} 0`,
          },
          overflow: 'auto',
        }}
        disableScrollLock
      >
        <Box sx={{ padding: theme.spacing(1) }}>
          {popoverContent(AggregatedVehicleUsageStatusHelper.metadata(vehicle.operationalStatusDetails!.lastKnownStatusDetails.status))}
        </Box>
      </Popover>
      <StatusChangeModal
        isOpen={openChangeModal}
        vehicleId={vehicle.id}
        vehicleVersion={vehicle.version}
        vehicleQrCode={vehicle.qrCode}
        currentStatus={vehicle.operationalStatusDetails!.lastKnownStatusDetails.status}
        futureStatus={futureStatus}
        onClose={handleCloseChangeModal}
        handleStatusChangeConfirm={handleStatusChangeConfirm}
      />
    </>
  );
}

interface StatusChangePopoverProps {
  vehicle: AggregatedVehicle;
  anchorElement: HTMLButtonElement | null;
  handleCloseCellAnchor: () => void;
  parentWidth: number;
  handleStatusChangeConfirm: () => void;
  futureStatus: AggregatedVehicleUsageStatus;
  onCurrentStatusPopoverClick: (futureStatus: AggregatedVehicleUsageStatus) => void;
}

export default StatusChangePopover;
