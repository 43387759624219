import Placeholder from '../../components/Placeholder/Placeholder';

const Notifications: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 90px)',
        width: '100vw)',
      }}
    >
      <Placeholder />
    </div>
  );
};

export default Notifications;
