import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { themeOptions as theme } from '../../init-setup/ThemeOptions';
import {
  UserTripDetailsResponse,
  UserTripDurationBreakdownDetails,
  UserTripDurationBreakdownType,
  UserTripPaymentAmountSubType,
  UserTripPaymentAmountType,
  UserTripStatus,
} from '../../models';
import { DEFAULT_CURRENCY } from '../../utils/constants';

import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';

const StyledTableCell = ({ children, variant = 'bodySmallBold' }: { children: React.ReactNode; variant?: any }) => (
  <TableCell sx={{ border: 'none', padding: 1 }}>
    <Typography variant={variant}>{children}</Typography>
  </TableCell>
);

interface TripDetailsAccordionProps {
  tripDetails: UserTripDetailsResponse;
}
interface DurationBreakdownSecondsProps {
  index: number;
  duration: UserTripDurationBreakdownDetails;
  durationSecondsValue: number;
}

const TripDetailsAccordion = ({ tripDetails }: TripDetailsAccordionProps) => {
  const intl = useIntl();
  const {
    duration: { breakdown: durationBreakdown },
    payment: {
      amount: { breakdown: amountBreakdown },
    },
  } = tripDetails;

  const compareStartTime = (a: UserTripDurationBreakdownDetails, b: UserTripDurationBreakdownDetails) => a.startedAt - b.startedAt;
  const chronologicalDurationBreakdown = durationBreakdown.sort(compareStartTime);

  const totalAmount = amountBreakdown.reduce((acc, curr) => acc + curr.amount, 0);
  const totalTax = amountBreakdown
    .filter((amount) => amount.type === UserTripPaymentAmountType.Tax)
    .reduce((acc, curr) => acc + curr.amount, 0);
  const totalActiveCharges = amountBreakdown
    .filter((amount) => amount.subType === UserTripPaymentAmountSubType.ActiveDuration)
    .reduce((acc, curr) => acc + curr.amount, 0);
  const totalPausedCharges = amountBreakdown
    .filter((amount) => amount.subType === UserTripPaymentAmountSubType.PausedDuration)
    .reduce((acc, curr) => acc + curr.amount, 0);

  const totalActiveDuration = durationBreakdown
    .filter((duration) => duration.type === UserTripDurationBreakdownType.Active)
    .reduce((acc, curr) => acc + curr.actualDurationSeconds, 0);

  const totalActiveChargedDuration = durationBreakdown
    .filter((duration) => duration.type === UserTripDurationBreakdownType.Active)
    .reduce((acc, curr) => acc + curr.billableDurationSeconds, 0);

  const totalPausedDuration = durationBreakdown
    .filter((duration) => duration.type === UserTripDurationBreakdownType.Paused)
    .reduce((acc, curr) => acc + curr.actualDurationSeconds, 0);

  const totalPausedChargedDuration = durationBreakdown
    .filter((duration) => duration.type === UserTripDurationBreakdownType.Paused)
    .reduce((acc, curr) => acc + curr.billableDurationSeconds, 0);

  const DurationBreakdownSeconds = ({ index, duration, durationSecondsValue }: DurationBreakdownSecondsProps) => {
    return (
      <StyledTableCell variant="bodySmall">
        {index === durationBreakdown.length - 1 &&
        duration.type === UserTripDurationBreakdownType.Active &&
        tripDetails.status === UserTripStatus.Started ? (
          <Typography sx={{ color: theme.palette.alert.main }}>
            {intl.formatMessage({ id: 'userTripDetails.activeTrip.placeholder' })}
          </Typography>
        ) : (
          durationSecondsValue.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()
        )}
      </StyledTableCell>
    );
  };

  return (
    <>
      <Accordion id="panel-1">
        <AccordionSummary>
          <Typography variant="bodySmallBold">{intl.formatMessage({ id: 'userTripDetails.duration' })}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.activity' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.time' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.actualDuration' })}</StyledTableCell>
              <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.chargeDuration' })}</StyledTableCell>
            </TableHead>
            <TableBody>
              {chronologicalDurationBreakdown.map((duration, index) => (
                <TableRow key={duration.startedAt}>
                  <StyledTableCell variant="bodySmall">
                    {intl.formatMessage({ id: `userTripDetails.billing.status.${duration.type.toLowerCase()}` })}
                  </StyledTableCell>
                  <StyledTableCell variant="bodySmall">{duration?.startedAt?.parseEpochMillis()?.formatAsDateTimeString()}</StyledTableCell>
                  <DurationBreakdownSeconds index={index} duration={duration} durationSecondsValue={duration.actualDurationSeconds} />
                  <DurationBreakdownSeconds index={index} duration={duration} durationSecondsValue={duration.billableDurationSeconds} />
                </TableRow>
              ))}
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.totalActiveDuration' })}</StyledTableCell>
                <StyledTableCell>{totalActiveDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}</StyledTableCell>
                <StyledTableCell>
                  {totalActiveChargedDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.totalPausedDuration' })}</StyledTableCell>
                <StyledTableCell>{totalPausedDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}</StyledTableCell>
                <StyledTableCell>
                  {totalPausedChargedDuration.parseSecondsSinceBeginningOfDay().formatAsTimeWithoutMilliSeconds()}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion id="panel-2">
        <AccordionSummary>
          <Typography variant="bodySmallBold" flexGrow={2}>
            {intl.formatMessage({ id: 'userTripDetails.tripCosts' })}
          </Typography>
          <Typography variant="bodySmallBold" flex={1} pl="1rem">
            {intl.formatNumber(totalAmount, {
              style: 'currency',
              currency: 'EUR',
            })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.activeTripCost' })}</StyledTableCell>
                <StyledTableCell>
                  {intl.formatNumber(totalActiveCharges, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>{intl.formatMessage({ id: 'userTripDetails.pausedTripCost' })}</StyledTableCell>
                <StyledTableCell>
                  {intl.formatNumber(totalPausedCharges, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {intl.formatMessage({ id: 'userTripDetails.tax' })} {DEFAULT_CURRENCY}
                </StyledTableCell>
                <StyledTableCell>
                  {intl.formatNumber(totalTax, {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TripDetailsAccordion;
