import { useState } from 'react';
import { MouseEvent as ReactMouseEvent } from 'react';

import { Box, Modal, Grid, Typography, MenuList, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import camelCase from 'lodash/camelCase';
import { useIntl } from 'react-intl';

import { LiveVehicle } from '../../state/common/vehicle.model';
import CustomIcon from '../../utils/CustomIcon';
import { ProviderLogo } from '../../wmv-components';

import {
  CloseButton,
  ellipsisTextSx,
  leftContentDividerSx,
  leftContentSx,
  rightContainerSx,
  leftContainerSx,
  VehicleMenuItem,
  VehicleModal,
} from './styles';
import GeneralInformation from './VehicleInfoModalPanels/GeneralInformation/index';
import VehicleTripHistory from './VehicleInfoModalPanels/VehicleTripHistory/index';

const options = {
  generalInformation: 'GeneralInformation',
  tripHistory: 'TripHistory',
};

const VehicleInformationModal = ({ vehicle, isOpen, onClose }: VehicleInformationModalProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [option, setOption] = useState(options.generalInformation);

  return (
    <Box>
      <Modal open={isOpen} aria-labelledby="modal-title">
        <VehicleModal container direction="row" wrap="nowrap" justifyContent="center" alignItems="stretch">
          <Grid container item direction="column" sx={leftContainerSx}>
            <Grid container direction="row" sx={leftContentSx}>
              <ProviderLogo provider={vehicle.mspName.toLowerCase()} />
              <Grid container direction="column" sx={{ gap: theme.spacing(0.5) }}>
                <Typography variant="bodyLargeBold" color={theme.palette.dark.main} sx={{ whiteSpace: 'nowrap' }}>
                  {vehicle.mspName} {vehicle.formFactor}
                </Typography>
                <Typography variant="bodyMedium" color={theme.palette.dark.shade76} sx={ellipsisTextSx}>
                  {vehicle.stableVehicleId ? vehicle.stableVehicleId : `${formatMessage({ id: 'common.dash' })}`}
                </Typography>
              </Grid>
            </Grid>
            <Divider orientation="horizontal" flexItem sx={leftContentDividerSx}></Divider>
            <MenuList sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
              {Object.keys(options).map((item, index) => (
                <VehicleMenuItem
                  key={index}
                  disableRipple
                  selected={option === options[item as keyof typeof options]}
                  onClick={() => setOption(options[item as keyof typeof options])}
                >
                  {formatMessage({ id: `map.detailedModal.${camelCase(options[item as keyof typeof options])}` })}
                </VehicleMenuItem>
              ))}
            </MenuList>
          </Grid>
          <Divider orientation="vertical" flexItem sx={leftContentDividerSx}></Divider>
          <Grid container direction="column" sx={rightContainerSx}>
            {returnDetailsPanel(option, vehicle)}
          </Grid>
          <CloseButton aria-label="close" onClick={onClose} disableRipple>
            {CustomIcon('CloseIcon', theme.palette.white.main, theme.spacing(4))}
          </CloseButton>
        </VehicleModal>
      </Modal>
    </Box>
  );
};

function returnDetailsPanel(option: string, vehicle: LiveVehicle) {
  switch (option) {
    case options.generalInformation:
      return <GeneralInformation vehicle={vehicle} />;
    case options.tripHistory:
      return <VehicleTripHistory vehicleId={vehicle.stableVehicleId} />;
    default:
      return <GeneralInformation vehicle={vehicle} />;
  }
}

interface VehicleInformationModalProps {
  vehicle: LiveVehicle;
  isOpen: boolean;
  onClose: (() => void) | ((event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void);
}

export default VehicleInformationModal;
