/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import 'leaflet/dist/leaflet.css';
import L, { LatLngBounds, LatLngExpression } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import { GraphqlVehicleUsageStatusHelper } from '../../models';
import { LiveVehicle } from '../../state/common/vehicle.model';
import { BAMBERG_COORDINATES } from '../../utils/constants';
import Index from '../VehicleInformationModal';

import CustomLayersViewComponent from './MapModes/PlanningMode/LayersView/CustomLayers';
import VehicleIcons from './VehicleIcons';
import VehicleInfoModal from './VehicleInfoModal';
import '../../../src/assets/styles/components/_map.scss';
import { getVehiclesInsideZone } from './ZoneMethods';

declare global {
  interface Window {
    mapInstance: L.Map; // Assuming L.Map is the type you're assigning
  }
}

interface MarkerComponentProps {
  vehicle: LiveVehicle;
  openMarkerId: string | null | undefined;
  selected?: boolean;
  handleOpenDetailedVehicleModal: (vehicle: LiveVehicle) => void;
}

interface MapProps {
  zoomBtn?: boolean;
  showLayers?: boolean;
  children?: React.ReactNode;
  openMarkerId?: string | null;
  setPopupTableData?: (vehicles: LiveVehicle[]) => void;
  vehicles?: LiveVehicle[]; // TODO: Fix this option type
}

const generateUniqueID = (vehicle: LiveVehicle) => {
  return [vehicle.vehicleTypeId, vehicle.vehicleId, vehicle.qrCodeId].map((v) => String(v)).join('#');
};

const MarkerComponent = ({ vehicle, openMarkerId, selected = false, handleOpenDetailedVehicleModal }: MarkerComponentProps) => {
  const markerRef = useRef<L.Marker>(null);

  useEffect(() => {
    if (markerRef && openMarkerId === generateUniqueID(vehicle)) {
      markerRef.current?.openPopup();
    }
  }, [openMarkerId, vehicle]);

  return (
    <Marker
      key={generateUniqueID(vehicle)}
      icon={VehicleIcons(
        GraphqlVehicleUsageStatusHelper.metadata(vehicle.usageStatus).iconClassName,
        vehicle.formFactor.toLowerCase(),
        selected,
      )}
      position={[vehicle.lat, vehicle.lon]}
      ref={markerRef}
    >
      <Popup autoClose>
        <VehicleInfoModal status={vehicle.usageStatus} vehicle={vehicle} handleOpenModal={handleOpenDetailedVehicleModal} />
      </Popup>
    </Marker>
  );
};

const isSelectedVehicle = (vehicle: LiveVehicle, selectedVehicles: LiveVehicle[]) => {
  return selectedVehicles.some((v) => generateUniqueID(v) === generateUniqueID(vehicle));
};

function WikimoveMap({ zoomBtn = true, showLayers = true, children, openMarkerId, setPopupTableData, vehicles }: MapProps) {
  const center = BAMBERG_COORDINATES as LatLngExpression;

  const [selectedVehicles, setSelectedVehicles] = useState<LiveVehicle[]>([]);
  const [selectedVehicleForDetailedModal, setSelectedVehicleForDetailedModal] = useState<LiveVehicle | null>(null);
  const [detailedVehicleModalIsOpen, setDetailedVehicleModalIsOpen] = useState(false);

  const [zoombox, setZoombox] = useState<LatLngBounds[] | null>(null);
  const [selectedView, setSelectedView] = useState('Street');

  const tileLayers = {
    Hybrid: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
    Satellite: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga',
    Street: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga',
  };

  //Extend functionality of leaflet method boxzoom
  (L.Map as any).BoxPrinter = (L.Map as any).BoxZoom.extend({
    _onMouseUp: function (e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      this._finish();
      let map = this._map,
        startPoint = map.containerPointToLatLng(this._startPoint),
        layerPoint = map.mouseEventToLatLng(e);

      if (this._startPoint.equals(layerPoint)) {
        return;
      }

      let bounds = new L.LatLngBounds(startPoint, layerPoint);

      setZoombox([bounds]);
    },
  });
  L.Map.mergeOptions({ boxPrinter: true });
  L.Map.mergeOptions({ boxZoom: false });
  L.Map.addInitHook('addHandler', 'boxPrinter', (L.Map as any).BoxPrinter);

  useEffect(() => {
    if (zoombox && zoombox.length > 0 && vehicles) {
      const vehiclesInsideZoombox = getVehiclesInsideZone(null, vehicles, zoombox);
      setPopupTableData && setPopupTableData(vehiclesInsideZoombox || []);
      setSelectedVehicles(vehiclesInsideZoombox || []);
    }
  }, [zoombox]);

  function MapInstanceForTesting() {
    const map = useMap();
    window.mapInstance = map;
    return null;
  }

  const handleCloseDetailedVehicleModal = useCallback(() => {
    setDetailedVehicleModalIsOpen(false);
    selectedVehicleForDetailedModal && setSelectedVehicleForDetailedModal(null);
  }, []);

  const handleOpenDetailedVehicleModal = useCallback((vehicle: LiveVehicle) => {
    setDetailedVehicleModalIsOpen(true);
    vehicle && setSelectedVehicleForDetailedModal(vehicle);
  }, []);

  return (
    <>
      <MapContainer
        id="map-container"
        style={{ height: '100%', width: '100%' }}
        center={center}
        zoom={13}
        zoomControl={false}
        attributionControl={false}
        doubleClickZoom={false}
      >
        {vehicles &&
          vehicles.map((vehicle, index) => (
            <MarkerComponent
              key={vehicle.vehicleId || vehicle.stableVehicleId}
              vehicle={vehicle}
              openMarkerId={openMarkerId}
              selected={isSelectedVehicle(vehicle, selectedVehicles)}
              handleOpenDetailedVehicleModal={handleOpenDetailedVehicleModal}
            />
          ))}

        {zoomBtn && <ZoomControl position={'bottomright'} />}
        <CustomLayersViewComponent selectedView={selectedView} setSelectedView={setSelectedView} />
        {showLayers ? (
          <TileLayer
            url={selectedView === 'Hybrid' ? tileLayers.Hybrid : selectedView === 'Satellite' ? tileLayers.Satellite : tileLayers.Street}
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />
        ) : (
          <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" maxZoom={20} subdomains={['mt1', 'mt2', 'mt3']} />
        )}
        <MapInstanceForTesting />
        {children}
      </MapContainer>
      {selectedVehicleForDetailedModal && (
        <Index vehicle={selectedVehicleForDetailedModal} isOpen={detailedVehicleModalIsOpen} onClose={handleCloseDetailedVehicleModal} />
      )}
    </>
  );
}

export default WikimoveMap;
