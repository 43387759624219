import React from 'react';

import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as CargoBicycleIcon } from '../../../../assets/illustrations/cargoBike.svg';
import { ReactComponent as EBikeIcon } from '../../../../assets/illustrations/eBike.svg';
import { ReactComponent as MopedIcon } from '../../../../assets/illustrations/eMoped.svg';
import { ReactComponent as EScooterIcon } from '../../../../assets/illustrations/eScooter.svg';
import { ReactComponent as CarIcon } from '../../../../assets/illustrations/meiaudoCar.svg';

const iconMap = {
  cargo_bicycle: CargoBicycleIcon,
  scooter: EScooterIcon,
  ebike: EBikeIcon,
  moped: MopedIcon,
  car: CarIcon,
};

interface VehicleIllustrationsProps {
  formFactor: string;
}

const VehicleIllustrations: React.FC<VehicleIllustrationsProps> = ({ formFactor }) => {
  const IconComponent = iconMap[formFactor.toLowerCase() as keyof typeof iconMap];

  const theme = useTheme();

  if (!IconComponent) return null;
  return (
    <div>
      {IconComponent && (
        <Box
          sx={{
            backgroundColor: theme.palette.dark.shade3,
            borderRadius: '8px',
            width: '268px',
            height: '190px',
          }}
        >
          <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <IconComponent width="174px" height="171px" />
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default VehicleIllustrations;
