import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { TranslatedText } from '../../../../wmv-components';
import { formTranslationKey } from '../helpers';
import { inputContainerStyles, inputTitleAndDescriptionContainerStyles } from '../styles';

const formInputTranslationKey = `${formTranslationKey}.input`;

export const BudgetPlanInputContainer = ({ translationGroupKeyPrefix, children, required = false }: BudgetPlanInputContainerProps) => {
  const theme = useTheme();

  return (
    <Box sx={inputContainerStyles}>
      <Box sx={inputTitleAndDescriptionContainerStyles}>
        <Box display="inline">
          <TranslatedText variant="bodyMediumBold" translationKey={`${formInputTranslationKey}.${translationGroupKeyPrefix}.title`} />
          {required && (
            <Box component="span" color={theme.palette.alert.main}>
              *
            </Box>
          )}
        </Box>
        <TranslatedText
          variant="bodySmall"
          translationKey={`${formInputTranslationKey}.${translationGroupKeyPrefix}.description`}
          color={theme.palette.baseLight.base76}
          typographyProps={{ component: 'p' }}
        />
      </Box>
      {children}
    </Box>
  );
};

interface BudgetPlanInputContainerProps {
  translationGroupKeyPrefix: string;
  children: ReactNode;
  required?: boolean;
}
