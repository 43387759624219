import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from 'react';

import { LoadingStatus } from '../../../../models';
import { ApiStatusService } from '../../../../services/ui';
import { VehiclePricingDto } from '../../../../services/vehicle-fee-pricing';

import { useSelectedVehiclePricingPricingId, useVehiclePricingState } from './VehiclePricing';

const selectedVehicleFeePricingInitialState: SelectedVehicleFeePricingState = {
  data: null,
  updatePricingApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
  discardFailedTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
};

const SelectedVehicleFeePricingContext = createContext<SelectedVehicleFeePricingState>(selectedVehicleFeePricingInitialState);
const SelectedVehicleFeePricingDispatchContext = createContext<Dispatch<SelectedVehicleFeePricingAction> | null>(null);

export function SelectedVehiclePricingStateProvider({ children }: VehicleFeePricingStateProviderProps) {
  const selectedVehiclePricingId = useSelectedVehiclePricingPricingId();
  const vehiclePricingState = useVehiclePricingState();
  const [selectedVehicleFeePricingState, dispatch] = useReducer(selectedVehicleFeePricingReducer, selectedVehicleFeePricingInitialState);

  useEffect(() => {
    if (selectedVehiclePricingId) {
      const selectedPricingDetails = vehiclePricingState.list.find(
        (vehicleFeePricing) => vehicleFeePricing.id === selectedVehiclePricingId,
      );
      if (selectedPricingDetails) {
        dispatch({
          type: SelectedVehicleFeePricingActionType.UpdateSelectedPlanDetails,
          payload: selectedPricingDetails!,
        });
      }
    }
  }, [selectedVehiclePricingId, dispatch, vehiclePricingState.list]);

  return (
    <SelectedVehicleFeePricingContext.Provider value={selectedVehicleFeePricingState!}>
      <SelectedVehicleFeePricingDispatchContext.Provider value={dispatch}>{children}</SelectedVehicleFeePricingDispatchContext.Provider>
    </SelectedVehicleFeePricingContext.Provider>
  );
}

export function selectedVehicleFeePricingReducer(
  selectedVehicleFeePricingState: SelectedVehicleFeePricingState,
  action: SelectedVehicleFeePricingAction,
) {
  switch (action.type) {
    case SelectedVehicleFeePricingActionType.UpdateSelectedPlanDetails:
      return {
        ...selectedVehicleFeePricingState,
        data: action.payload,
      };
    case SelectedVehicleFeePricingActionType.UpdateVehiclePricingStart:
      return {
        ...selectedVehicleFeePricingState,
        updatePricingApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
      };
    case SelectedVehicleFeePricingActionType.UpdateVehiclePricingSuccess:
      return {
        ...selectedVehicleFeePricingState,
        updatePricingApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
        data: action.payload,
      };
    case SelectedVehicleFeePricingActionType.UpdateVehiclePricingFailure:
      return {
        ...selectedVehicleFeePricingState,
        updatePricingApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
      };

    case SelectedVehicleFeePricingActionType.DiscardFailedTransitionStart:
      return {
        ...selectedVehicleFeePricingState,
        discardFailedTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
      };
    case SelectedVehicleFeePricingActionType.DiscardFailedTransitionSuccess:
      return {
        ...selectedVehicleFeePricingState,
        discardFailedTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
      };
    case SelectedVehicleFeePricingActionType.DiscardFailedTransitionFailure:
      return {
        ...selectedVehicleFeePricingState,
        discardFailedTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
      };
    case SelectedVehicleFeePricingActionType.ResetToInitialState:
      return selectedVehicleFeePricingInitialState;
    default:
      return selectedVehicleFeePricingState;
  }
}

export function useSelectedVehiclePricingState() {
  return useContext(SelectedVehicleFeePricingContext);
}

export function useSelectedVehiclePricingDispatch() {
  return useContext(SelectedVehicleFeePricingDispatchContext);
}

export function useSelectedVehiclePricingDetails() {
  const selectedFeePricingState = useSelectedVehiclePricingState();
  return selectedFeePricingState.data;
}

export function useUpdatePricingApiStatus() {
  const selectedFeePricingState = useSelectedVehiclePricingState();
  return selectedFeePricingState.updatePricingApiStatus;
}

export function useDiscardFailedTransitionApiStatus() {
  const selectedFeePricingState = useSelectedVehiclePricingState();
  return selectedFeePricingState.discardFailedTransitionApiStatus;
}

interface VehicleFeePricingStateProviderProps {
  children: ReactNode;
}

interface SelectedVehicleFeePricingState {
  data: VehiclePricingDto | null;
  updatePricingApiStatus: ApiStatusService;
  discardFailedTransitionApiStatus: ApiStatusService;
}

export enum SelectedVehicleFeePricingActionType {
  UpdateSelectedPlanDetails = 'UPDATE_SELECTED_PLAN_DETAILS',
  UpdateVehiclePricingStart = 'UPDATE_VEHICLE_PRICING_START',
  UpdateVehiclePricingSuccess = 'UPDATE_VEHICLE_PRICING_SUCCESS',
  UpdateVehiclePricingFailure = 'UPDATE_VEHICLE_PRICING_FAILURE',
  ResetToInitialState = 'RESET_VEHICLE_PRICING_STATE',
  DiscardFailedTransitionStart = 'DISCARD_FAILED_TRANSITION_START',
  DiscardFailedTransitionSuccess = 'DISCARD_FAILED_TRANSITION_SUCCESS',
  DiscardFailedTransitionFailure = 'DISCARD_FAILED_TRANSITION_FAILURE',
}

export type SelectedVehicleFeePricingAction =
  | {
      type: SelectedVehicleFeePricingActionType.UpdateSelectedPlanDetails;
      payload: VehiclePricingDto | null;
    }
  | {
      type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingStart;
    }
  | {
      type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingSuccess;
      payload: VehiclePricingDto | null;
    }
  | {
      type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingFailure;
    }
  | {
      type: SelectedVehicleFeePricingActionType.ResetToInitialState;
    }
  | {
      type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionStart;
    }
  | {
      type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionSuccess;
    }
  | {
      type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionFailure;
    };
