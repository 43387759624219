import type { ReactNode } from 'react';
import React from 'react';

import { Typography } from '@mui/material';
import type { SxProps, Theme } from '@mui/material/styles';
import type { TypographyProps } from '@mui/material/Typography';
import { useIntl } from 'react-intl';

interface TranslatedTextProps {
  translationKey: string;
  color?: TypographyProps['color'];
  variant?: TypographyProps['variant'];
  typographyProps?: TypographyProps;
  sx?: SxProps<Theme>;
}

export const TranslatedText = ({ translationKey, variant, color, sx, typographyProps }: TranslatedTextProps): ReactNode => {
  const intl = useIntl();
  const translationKeyExists = intl.messages[translationKey];

  if (!translationKeyExists) {
    throw new Error(`Translation key: ${translationKey}, does not exist`);
  }

  return (
    <Typography variant={variant} color={color} sx={sx} {...typographyProps}>
      {intl.formatMessage({ id: translationKey })}
    </Typography>
  );
};
