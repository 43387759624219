import { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton, styled, Typography } from '@mui/material';
// eslint-disable-next-line import/default
import formik, { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { FilterProfileType, TableColumnType } from '../../../models';
import { applyFilterProfileAction, updateFilterProfilesAction, filterProfilesSelector } from '../../../state';
import { analyticsTableAllColumns, operatorOptions, valueTypeOptions } from '../../../utils/constants';
import { GenericLabel } from '../../../wmv-components';
import CustomSelectField from '../../FormFields/CustomSelectField';
import CustomTextField from '../../FormFields/CustomTextField';
import DropDownField from '../../FormFields/DropDownField';
import SingleSelectField from '../../FormFields/SingleSelectField';

// Reference: Icon import in tsx.
// import Icon from '../../../assets/illustrations/404.svg';

const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '24px 16px',
}));

type PropsType = {
  handleClose: () => void;
  filterSetName: string | null;
};

const NewFilterProfile = ({ filterSetName, handleClose }: PropsType) => {
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const filters: FilterProfileType[] = useSelector(filterProfilesSelector);
  const [selectedColFilter, setSelectedColFilter] = useState<TableColumnType>(analyticsTableAllColumns[0]);

  const filterProfile = useMemo(() => {
    return {
      filterName: '',
      visibleColumns: analyticsTableAllColumns,
      columnFilters: analyticsTableAllColumns.map((col) => ({
        column: col,
        valueType: valueTypeOptions[0],
        operator: operatorOptions(intl)[0],
        value: '',
      })),
      favorite: false,
    };
  }, [intl]);

  const [initialValues, setInitialValues] = useState<FilterProfileType>(filterProfile);

  useEffect(() => {
    const filterSet = filters.filter((f: FilterProfileType) => f.filterName === filterSetName);
    if (filterSet && filterSet.length > 0) {
      setInitialValues(filterSet[0]);
    } else {
      setInitialValues(filterProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSetName, filters]);

  const validationFn = (values: FilterProfileType) => {
    const errors: any = {};
    // Check for the filtername.
    if (values.filterName !== undefined) {
      const name = values.filterName.trim();
      if (name === '') {
        errors.filterName = `${formatMessage({ id: 'analytics.filter.validation.name.required' })}`;
      } else if (
        name &&
        filters
          .filter((f: FilterProfileType) => filterSetName !== f.filterName)
          .map((f: FilterProfileType) => f.filterName.toLowerCase())
          .includes(name.toLowerCase())
      ) {
        errors.filterName = `${formatMessage({ id: 'analytics.filter.validation.name.used' })}`;
      } else if (name && name.length > 20) {
        errors.filterName = `${formatMessage({ id: 'analytics.filter.validation.length.max' })}`;
      }
    } else {
      errors.filterName = `${formatMessage({ id: 'analytics.filter.validation.name.required' })}`;
    }

    // Check for the visible columns.
    if (values.visibleColumns.length < 1) {
      errors.visibleColumns = `${formatMessage({ id: 'analytics.filter.validation.column.min' })}`;
    }
    // Check for the filter values.
    // if (
    //   values.columnFilters.filter((cf) => cf.value === "").length ===
    //   values.columnFilters.length
    // ) {
    //   errors.columnFilters = `${formatMessage({ id: 'analytics.filter.validation.column.min' })}`;
    // }

    return errors;
  };

  const submitFn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values: FilterProfileType,
    resetForm: (nextState?: Partial<formik.FormikState<FilterProfileType>> | undefined) => void,
  ) => {
    // submit the form to save the Filter profile.
    reduxDispatch(updateFilterProfilesAction([{ ...values }]));

    // apply the filter profile, if selected.
    if (e.currentTarget.id === 'saveAndApply') {
      reduxDispatch(applyFilterProfileAction(values.filterName));
    }
    console.log('Re initializing!');
    resetForm({ values: initialValues });
  };

  const errorMessage = {
    marginTop: 5,
    color: 'red',
    fontSize: 14,
    height: 20,
  };

  const { formatMessage } = useIntl();

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <Header>
        <IconButton sx={{ marginRight: '24px' }} onClick={handleClose} disableRipple>
          <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1">{formatMessage({ id: 'filter.filterSets' })}</Typography>
      </Header>

      <Formik initialValues={initialValues} validate={validationFn} onSubmit={(v) => {}} enableReinitialize>
        {({ values, isValid, isValidating, resetForm, setFieldValue }) => (
          <Form>
            <Grid container spacing={3} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs="auto">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <GenericLabel htmlFor="filterName">{formatMessage({ id: 'filter.filterName' })}</GenericLabel>
                  <Field
                    // check if the update is being made to the previous profile.
                    disabled={!!filterSetName}
                    name="filterName"
                    as={CustomTextField}
                    placeholder={formatMessage({ id: 'analytics.filter.filterSets.placeholder.name' })}
                    style={{ width: '300px' }}
                    inputProps={{ maxLength: 20 }}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      await setFieldValue('filterName', e.target.value.trim());
                    }}
                  />
                </div>
                <div style={errorMessage}>
                  <ErrorMessage name="filterName" />
                </div>
              </Grid>
              <Grid item xs="auto">
                <Field
                  name="visibleColumns"
                  as={CustomSelectField}
                  options={analyticsTableAllColumns}
                  outlineLabel={formatMessage({ id: 'analytics.filter.filterSets.columnVisibility' })}
                  multiple
                  style={{ width: '300px' }}
                />
                <div style={errorMessage}>
                  <ErrorMessage name="visibleColumns" />
                </div>
              </Grid>
              <Grid item xs="auto">
                <SingleSelectField
                  value={selectedColFilter}
                  name="Property"
                  onOptionSelect={(selectedOp: TableColumnType) => {
                    setSelectedColFilter(selectedOp);
                  }}
                  options={analyticsTableAllColumns}
                  outlineLabel={formatMessage({ id: 'analytics.filter.filterSets.property' })}
                  width="300px"
                />
              </Grid>
              <Grid item xs="auto">
                <FieldArray
                  name="columnFilters"
                  render={(arrayHelpers) => (
                    <div>
                      {values.columnFilters.map((cf, index) => (
                        <div
                          key={index}
                          style={{
                            display: cf.column.accessor === selectedColFilter.accessor ? '' : 'none',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                margin: '0px 0px 20px 0px',
                              }}
                            >
                              <Grid item xs="auto">
                                <Field
                                  name={`columnFilters[${index}].valueType`}
                                  as={DropDownField}
                                  options={valueTypeOptions}
                                  outlineLabel={formatMessage({ id: 'analytics.filter.filterSets.valueType' })}
                                  width="100px"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                <Field
                                  name={`columnFilters[${index}].operator`}
                                  as={DropDownField}
                                  options={operatorOptions(intl)}
                                  outlineLabel={formatMessage({ id: 'analytics.filter.filterSets.operator' })}
                                  width="200px"
                                />
                              </Grid>
                            </div>
                            <Grid item xs="auto">
                              <Field
                                placeholder={formatMessage({ id: 'analytics.filter.filterSets.placeholder.value' })}
                                name={`columnFilters[${index}].value`}
                                as={CustomTextField}
                                style={{ width: '300px' }}
                                inputProps={{ maxLength: 50 }}
                              />
                            </Grid>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
                <div style={errorMessage}>
                  <ErrorMessage name="columnFilters" />
                </div>
              </Grid>
              <Grid item xs={10} style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  type="submit"
                  id="saveAndApply"
                  disabled={isValidating || !isValid || values.filterName === ''}
                  onClick={(e) => submitFn(e, values, resetForm)}
                  sx={{
                    marginTop: '10px',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                  variant="contained"
                >
                  <Typography variant="subtitle2">{formatMessage({ id: 'filter.filterApply' })}</Typography>
                </Button>
                <Button
                  type="submit"
                  id="save"
                  disabled={isValidating || !isValid || values.filterName === ''}
                  onClick={(e) => submitFn(e, values, resetForm)}
                  sx={{
                    marginTop: '10px',
                    width: '100%',
                    marginBottom: '20px',
                  }}
                  variant="outlined"
                >
                  <Typography variant="subtitle2">{formatMessage({ id: 'filter.filterSave' })}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewFilterProfile;
