import React from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { LoadingStatus } from '../../../../models';
import { budgetPlanApiStatusSelector } from '../../../../state';
import { ConfirmationModal } from '../../../../wmv-components';

export const RetryBudgetStatusModal = ({ open, onClose, planName, onRetry }: RetryBudgetStatusModalProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { palette } = theme;
  const transitionFailureRetryApiStatus = useSelector(budgetPlanApiStatusSelector)['transitionFailureRetry'];

  return (
    <ConfirmationModal
      open={open}
      title={formatMessage({ id: 'common.updatedFailed' })}
      handleClose={onClose}
      handleConfirm={onRetry}
      loading={transitionFailureRetryApiStatus === LoadingStatus.Pending}
    >
      <Box>
        <Typography variant="bodySmall">{planName} </Typography>
      </Box>
      <Box>
        <Typography variant="bodySmall" color={palette.baseLight.base60}>
          {formatMessage({ id: 'common.error.retryMessage' })}
        </Typography>
      </Box>
    </ConfirmationModal>
  );
};

interface RetryBudgetStatusModalProps {
  open: boolean;
  onClose: () => void;
  planName: string;
  onRetry: () => void;
}
