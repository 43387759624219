import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
// eslint-disable-next-line import/named
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// eslint-disable-next-line import/named
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';

interface StyledAccordionProps extends AccordionProps {
  children: NonNullable<React.ReactNode>;
}

export const Accordion = styled(({ children, ...props }: StyledAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props}>
    {children}
  </MuiAccordion>
))(() => ({
  border: 0,
  background: 'transparent',
  '&:before': {
    display: 'none',
  },
}));

interface StyledAccordionSummaryProps extends AccordionSummaryProps {
  children?: React.ReactNode;
}

export const AccordionSummary = styled(({ children, ...props }: StyledAccordionSummaryProps) => {
  const theme = useTheme();
  return (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.8rem', color: theme.palette.secondary.dark }} />}
      {...props}
    >
      {children}
    </MuiAccordionSummary>
  );
})(({ theme }) => ({
  flexDirection: 'row-reverse',
  minHeight: '25px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(2),
}));
