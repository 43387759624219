import Changelog from '../assets/illustrations/Changelog.svg';
import ContactCustomerSupport from '../assets/illustrations/ContactCustomerSupport.svg';
import FAQ from '../assets/illustrations/FAQ.svg';
import SupportingDocuments from '../assets/illustrations/SupportingDocuments.svg';
import TroubleShootingGuides from '../assets/illustrations/TroubleShootingGuides.svg';
import VideoTutorials from '../assets/illustrations/VideoTutorials.svg';

interface Illustrations {
  [key: string]: string;
}

export const illustrations: Illustrations = {
  SupportingDocuments,
  VideoTutorials,
  FAQ,
  ContactCustomerSupport,
  TroubleShootingGuides,
  Changelog,
};
