import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { UserTrip, UserTripStatus } from '../../../models';
import { ReactTable } from '../../../wmv-components';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';
import UserTripDetails, { EndDate } from '../../UserTripHistory/UserTripDetails';

import { makeUserTripTableColumns } from './makeTripTableColumns';
import useFetchAllTrips from './useFetchAllTrips';

interface UserTripsProps {
  userId: string;
}

const UserTrips = ({ userId }: UserTripsProps) => {
  const intl = useIntl();
  const tripTableColumns = makeUserTripTableColumns(intl);

  const { allTrips, loading, error, refetch: refetchAllTripsData, setAllTrips } = useFetchAllTrips({ userId });

  const handleUpdateEndDate = (updatedEndDate: EndDate) => {
    const updatedTrips = allTrips.map((trip) => {
      if (trip.id === updatedEndDate.id) {
        return {
          ...trip,
          status: UserTripStatus.Stopped,
          duration: {
            ...trip.duration,
            finishedAt: updatedEndDate.endEpochMillis,
          },
        };
      }
      return trip;
    });
    setAllTrips(updatedTrips);
  };

  const EXPANDER_COLUMN = {
    id: 'expander',
    Header: () => <></>,
    Cell: ({ row }: any) => {
      return (
        <Box
          {...row.getToggleRowExpandedProps({})}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {row.isExpanded ? (
            <ExpandLessOutlinedIcon sx={{ fontSize: 16, color: '#009881' }} />
          ) : (
            <ExpandMoreOutlinedIcon sx={{ fontSize: 16, color: '#009881' }} />
          )}
        </Box>
      );
    },
    width: '25px',
  };

  const handleRetry = async () => {
    refetchAllTripsData(userId);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'stretch', height: '100%' }}>
      {allTrips.length === 0 || error || loading ? (
        <LoadingAndErrorWithRetryAndNoResults
          style={{ height: '100%', backgroundColor: theme.palette.white.main }}
          error={error}
          loading={loading}
          onRetry={handleRetry}
          baseTranslationKey="tripHistory"
          noResults={!!allTrips.length}
        />
      ) : (
        <ReactTable
          loading={loading}
          columns={[...tripTableColumns, EXPANDER_COLUMN]}
          tableContainerStyle={{
            border: '1px solid #f0f0f0',
            borderRadius: '8px',
            height: '100%',
          }}
          renderDetailsComponent={({ row }: { row: UserTrip }) => {
            const detailsQueryParams = {
              startedAt: row.duration.startedAt,
              finishedAt: row.duration.finishedAt,
              id: row.id,
            };
            return <UserTripDetails trip={detailsQueryParams} enableForceStop={true} onUpdateEndDate={handleUpdateEndDate} />;
          }}
          onRowClickHandler={() => {}}
          data={allTrips}
          pagination={false}
        />
      )}
    </Box>
  );
};

export default UserTrips;
