import React, { useState } from 'react';

import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import camelCase from 'lodash/camelCase';
import { useIntl } from 'react-intl';
import { Column, Row } from 'react-table';

import { generateUniqueID } from '../../../components/MapTableView/ZonesPopupDataTable';
import { AggregatedVehicle, AggregatedVehicleUsageStatus, VehicleTypeHelper } from '../../../models';
import { LiveVehicle } from '../../../state/common/vehicle.model';
import CustomIcon from '../../../utils/CustomIcon';
import { changeVehicleStatus } from '../../../utils/queries';

import StatusChangeFailModal from './StatusChange/StatusChangeFailModal';
import StatusChangePopover from './StatusChange/StatusChangePopover';

type CustomColumn<T extends object = {}> = Column<T> & {
  justifyContent?: string;
  fullWidth?: boolean;
  backgroundColor?: string;
  hoverLastSecondary?: boolean;
};

function OperatorColumns({
  processingStatuses,
  setProcessingStatuses,
  failedStatuses,
  setFailedStatuses,
  onVehicleLocationClick,
}: OperatorColumnsProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const OperatorColumns: CustomColumn<AggregatedVehicle>[] = [
    {
      Header: formatMessage({ id: 'operator.fleets.table.heading.qrCodeId' }),
      accessor: 'qrCode',
      Cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
        return (
          <Typography variant="bodySmall" px={1}>
            {row.original.qrCode}
          </Typography>
        );
      },
    },
    {
      Header: formatMessage({ id: 'operator.fleets.table.heading.vehicleId' }),
      accessor: (row) => row.id,
      Cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
        return (
          <Typography variant="bodySmall" px={1}>
            {row.original.id}
          </Typography>
        );
      },
    },
    {
      Header: formatMessage({ id: 'operator.fleets.table.heading.vehicleType' }),
      accessor: (row) => `${row.model.vehicleType}`,
      Cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
        const vehicleTypeIcons = {
          electric_bike: CustomIcon('ebikeTooltipIcon', '', '22px'),
          electric_moped: CustomIcon('mopedTooltipIcon', '', '22px'),
          car: CustomIcon('carTooltipIcon', '', '22px'),
          cargo_bicycle: CustomIcon('cargobikeTooltipIcon', '', '22px'),
          electric_scooter: CustomIcon('scooterTooltipIcon', '', '22px'),
        };
        const IconComponent = vehicleTypeIcons[row.original.model.vehicleType.toLocaleLowerCase() as keyof typeof vehicleTypeIcons] || null;
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '4px',
              padding: '4px',
            }}
          >
            {IconComponent}
            <Typography variant="bodySmall" px={1}>
              {formatMessage({ id: `mspVehicleType.${row.original.model.vehicleType.toLowerCase()}` })}
            </Typography>
          </Box>
        );
      },
    },
    {
      Header: formatMessage({ id: 'operator.fleets.table.heading.lastEventType' }),
      accessor: (row) => row.operationalStatusDetails?.lastKnownStatusDetails.status,
      Cell: ({ row }: { row: Row<AggregatedVehicle> }) => (
        <Typography variant="bodySmall" px={1}>
          {formatMessage({
            id: row.original.operationalStatusDetails?.lastKnownStatusDetails.status
              ? `operator.statusChange.vehicleStatus.${camelCase(row.original.operationalStatusDetails.lastKnownStatusDetails.status)}`
              : 'common.all',
          })}
        </Typography>
      ),
    },
    {
      Header: formatMessage({ id: 'operator.fleets.table.heading.position' }),
      accessor: (row) =>
        row.operationalStatusDetails?.location?.lat &&
        row.operationalStatusDetails.location.lng &&
        row.operationalStatusDetails.lastKnownStatusDetails.status !== AggregatedVehicleUsageStatus.OnTrip,
      justifyContent: 'flex-start',
      Cell: ({ row: { original: vehicle } }: { row: Row<AggregatedVehicle> }) => {
        const liveVehicle = {
          vehicleTypeId: VehicleTypeHelper.toGraphqlVehicleType(vehicle.model.vehicleType),
          vehicleId: vehicle.qrCode,
          qrCodeId: vehicle.qrCode,
        };
        return !vehicle.operationalStatusDetails?.location?.lat ||
          !vehicle.operationalStatusDetails.location?.lng ||
          vehicle.operationalStatusDetails.lastKnownStatusDetails.status === AggregatedVehicleUsageStatus.OnTrip ? (
          <IconButton disabled>{CustomIcon('CurrentLocationIcon', theme.palette.dark.shade60, theme.spacing(4))}</IconButton>
        ) : (
          <IconButton
            disabled
            onClick={() => {
              onVehicleLocationClick(generateUniqueID(liveVehicle as unknown as LiveVehicle));
            }}
          >
            {CustomIcon('CurrentLocationIcon', theme.palette.dark.main, theme.spacing(4))}
          </IconButton>
        );
      },
    },
    {
      Header: formatMessage({ id: 'operator.fleets.table.heading.currentStatus' }),
      accessor: (row) => row.operationalStatusDetails?.lastKnownStatusDetails.status,
      Cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
        const statusColorMap = {
          [AggregatedVehicleUsageStatus.Available]: theme.palette.success.main,
          [AggregatedVehicleUsageStatus.NonOperational]: theme.palette.warning.main,
          [AggregatedVehicleUsageStatus.OnTrip]: theme.palette.blue.tint,
        };
        const currentStatusBgColor =
          row.original.operationalStatusDetails?.lastKnownStatusDetails.status != null
            ? statusColorMap[row.original.operationalStatusDetails.lastKnownStatusDetails.status]
            : null;

        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: theme.spacing(1),
              paddingLeft: theme.spacing(0.5),
            }}
          >
            <Box
              sx={{
                width: theme.spacing(2),
                height: theme.spacing(2),
                borderRadius: theme.spacing(2),
                backgroundColor: currentStatusBgColor,
              }}
            />
            {formatMessage({
              id: row.original.operationalStatusDetails?.lastKnownStatusDetails.status
                ? `operator.statusChange.vehicleStatus.${camelCase(row.original.operationalStatusDetails.lastKnownStatusDetails.status)}`
                : 'common.all',
            })}
          </Box>
        );
      },
    },
    {
      Header: formatMessage({ id: 'operator.fleets.table.heading.changeStatus' }),
      justifyContent: 'space-between',
      fullWidth: true,
      backgroundColor: theme.palette.secondary.main,
      hoverLastSecondary: true,
      Cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
        const theme = useTheme();

        const isProcessing = processingStatuses[row.original.qrCode] || false;
        const hasFailedProcessing = failedStatuses[row.original.qrCode] || false;

        const fittingStatus =
          row.original.operationalStatusDetails?.lastKnownStatusDetails.status === AggregatedVehicleUsageStatus.Available ||
          row.original.operationalStatusDetails?.lastKnownStatusDetails.status === AggregatedVehicleUsageStatus.NonOperational;

        const [parentWidth, setParentWidth] = React.useState<number>(0);
        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
        const [futureStatus, setFutureStatus] = useState<AggregatedVehicleUsageStatus>(AggregatedVehicleUsageStatus.Available);
        const [failModalOpen, setFailModalOpen] = useState(false);

        const handleFailModalOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
          setFailModalOpen(true);
        };
        const handleFailModalClose = () => {
          setFailModalOpen(false);
          setAnchorEl(null);
        };

        const handleClickCell = (event: React.MouseEvent<HTMLButtonElement>) => {
          const parentCell = event.currentTarget.parentElement?.closest('td');
          const parentWidth = parentCell ? parentCell.offsetWidth : 0;
          event.currentTarget.parentElement?.closest('tr')?.classList.add('keepHoverState');
          setAnchorEl(event.currentTarget);
          setParentWidth(parentWidth);
        };
        const handleCloseCell = () => {
          anchorEl?.parentElement?.closest('tr')?.classList.remove('keepHoverState');
          setAnchorEl(null);
        };

        const handleStatusChangeConfirm = () => {
          anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.remove('isFailed');
          anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.add('isProcessing');
          setProcessingStatuses((prevStatuses) => ({
            ...prevStatuses,
            [row.original.qrCode]: true,
          }));
          setFailedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [row.original.qrCode]: false,
          }));
          handleCloseCell();
        };

        //Will be called when the request to change the status fails from the vehicle side.
        const handleStatusChangeFail = () => {
          anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.add('isFailed');
          anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.remove('isProcessing');
          setProcessingStatuses((prevStatuses) => ({
            ...prevStatuses,
            [row.original.qrCode]: false,
          }));
          setFailedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [row.original.qrCode]: true,
          }));
          handleCloseCell();
        };

        const handleStatusChangeCancel = () => {
          anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.remove('isFailed');
          anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.remove('isProcessing');
          anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.remove('keepHoverState');
          setProcessingStatuses((prevStatuses) => ({
            ...prevStatuses,
            [row.original.qrCode]: false,
          }));
          setFailedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [row.original.qrCode]: false,
          }));
          handleCloseCell();
        };

        const handleStatusChangeRetry = async () => {
          try {
            await changeVehicleStatus({ version: row.original.version, status: futureStatus }, row.original.id);
            anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.remove('isFailed');
            anchorEl?.parentElement?.parentElement?.closest('tr')?.classList.add('isProcessing');
            setProcessingStatuses((prevStatuses) => ({
              ...prevStatuses,
              [row.original.qrCode]: true,
            }));
            setFailedStatuses((prevStatuses) => ({
              ...prevStatuses,
              [row.original.qrCode]: false,
            }));
          } catch (error) {
            handleStatusChangeFail();
            return false;
          }
        };

        return (
          <>
            <Tooltip
              title={
                isProcessing && (
                  <Typography variant="bodyMedium">{formatMessage({ id: 'operator.statusChange.tooltip.cancel' })}</Typography>
                )
              }
              arrow
              componentsProps={{
                tooltip: {
                  sx: { borderRadius: theme.spacing(1), padding: `${theme.spacing(1)} ${theme.spacing(3)}` },
                },
              }}
            >
              <Box>
                <Button
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '4px',
                    height: '100%',
                    '&:hover *': theme.typography.bodyMediumBold,
                  }}
                  onClick={
                    hasFailedProcessing && !isProcessing
                      ? handleFailModalOpen
                      : fittingStatus && !isProcessing
                      ? handleClickCell
                      : fittingStatus && isProcessing
                      ? () => console.log('Open Cancel Modal') //Will be implemented by #1479
                      : !fittingStatus
                      ? handleFailModalOpen
                      : () => {}
                  }
                >
                  <Typography
                    variant="bodySmall"
                    px={1}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    {formatMessage({
                      id: hasFailedProcessing
                        ? 'operator.statusChange.request.failed'
                        : isProcessing
                        ? 'operator.statusChange.processing'
                        : 'operator.statusChange',
                    })}
                  </Typography>
                  {CustomIcon('ChevronDownIcon', theme.palette.dark.shade76, '24px')}
                </Button>
              </Box>
            </Tooltip>

            {hasFailedProcessing ? (
              <StatusChangeFailModal
                isOpen={failModalOpen}
                vehicleId={row.original.id}
                onClose={handleFailModalClose}
                modalType={'retry'}
                handleStatusChangeCancel={handleStatusChangeCancel}
                handleStatusChangeRetry={handleStatusChangeRetry}
              />
            ) : fittingStatus && !isProcessing ? (
              <StatusChangePopover
                vehicle={row.original}
                anchorElement={anchorEl}
                handleCloseCellAnchor={handleCloseCell}
                parentWidth={parentWidth}
                handleStatusChangeConfirm={handleStatusChangeConfirm}
                futureStatus={futureStatus}
                onCurrentStatusPopoverClick={(futureStatusProp: AggregatedVehicleUsageStatus) => {
                  setFutureStatus(futureStatusProp);
                  handleCloseCell();
                }}
              />
            ) : null}
            {!fittingStatus ? (
              <StatusChangeFailModal isOpen={failModalOpen} vehicleId={row.original.id} onClose={handleFailModalClose} modalType={'fail'} />
            ) : null}
          </>
        );
      },
    },
  ];
  return OperatorColumns;
}

interface OperatorColumnsProps {
  processingStatuses: { [key: string]: boolean };
  setProcessingStatuses: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }> | {}>;
  failedStatuses: { [key: string]: boolean };
  setFailedStatuses: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }> | {}>;
  onVehicleLocationClick: (latlng: string) => void;
}

export default OperatorColumns;
