import type { Dayjs, PluginFunc } from 'dayjs';
import dayjs from 'dayjs';

import {
  DEFAULT_TIME_FORMAT,
  DEFAULT_TIME_FORMAT_WITH_SECONDS,
  DEFAULT_DATE_FORMAT_DAYJS,
  ISO_DATE_FORMAT_DAYJS,
  DEFAULT_DATETIME_FORMAT_DAYJS,
} from '../../utils/constants';

declare module 'dayjs' {
  interface Dayjs {
    formatAsTimeWithoutSeconds(): string;
    formatAsTimeWithoutMilliSeconds(): string;
    formatAsDateString(): string;
    formatAsDateTimeString(): string;
    formatAsISODateString(): string;
  }
}

declare global {
  interface Number {
    parseSecondsSinceBeginningOfDay(): Dayjs;
  }
}

const formatTimestampPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.formatAsTimeWithoutSeconds = function (): string {
    return this.format(DEFAULT_TIME_FORMAT);
  };

  dayjsClass.prototype.formatAsTimeWithoutMilliSeconds = function (): string {
    return this.format(DEFAULT_TIME_FORMAT_WITH_SECONDS);
  };

  dayjsClass.prototype.formatAsDateString = function (): string {
    return this.format(DEFAULT_DATE_FORMAT_DAYJS);
  };

  dayjsClass.prototype.formatAsDateTimeString = function (): string {
    return this.format(DEFAULT_DATETIME_FORMAT_DAYJS);
  };

  dayjsClass.prototype.formatAsISODateString = function (): string {
    return this.format(ISO_DATE_FORMAT_DAYJS);
  };

  // eslint-disable-next-line no-extend-native
  Number.prototype.parseSecondsSinceBeginningOfDay = function (this: number): Dayjs {
    return dayjs().toBeginningOfDay().plusSeconds(this);
  };
};

export default formatTimestampPlugin;
