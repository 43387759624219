import Box from '@mui/material/Box';
import { Field } from 'formik';

import CustomTextField from './CustomTextField';
import FormikErrorMessage from './ErrorMessage';

export interface FormFieldWithLabelInfo {
  name: string;
  label: string;
  type: string;
  isMandatory?: boolean;
  error?: boolean;
  format?: string;
}

export const FormFieldWithLabel = ({ name, label, type, isMandatory, error, format }: FormFieldWithLabelInfo) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Field as={CustomTextField} name={name} type={type} error={error} format={format} label={label} required={isMandatory} />
      <FormikErrorMessage name={name} />
    </Box>
  );
};
