import { useEffect } from 'react';

import type { AsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch, useAppDispatch } from '../../../../state';

export const pollActionCreator = (actionToDispatch: AsyncThunk<any, any, any>, payload: any, interval: number) => {
  let intervalId: ReturnType<typeof setTimeout> | null = null;
  return {
    start: (dispatch: AppDispatch) => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
      dispatch(actionToDispatch(payload));

      intervalId = setInterval(() => dispatch(actionToDispatch(payload)), interval);
    },
    stop: () => {
      clearInterval(intervalId!);
      intervalId = null;
    },
  };
};

export function usePolling(asyncPollAction: AsyncThunk<any, any, any>, payload: any, interval: number) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const pollingActions = pollActionCreator(asyncPollAction, payload, interval);
    pollingActions.start(dispatch);

    return pollingActions.stop;
  }, [dispatch, asyncPollAction, interval, payload]);
}
