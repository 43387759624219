import { useCallback, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { deleteMembership } from '../../../../utils/queries';
import HelperText, { HelperTextType } from '../../../../wmv-components/HelperText';
import {
  MembershipDetailsActionType,
  useBudgetPlanMembershipsListState,
  useSelectedBudgetPlanId,
  useMembershipDetailsState,
  useMembershipDetailsDispatch,
} from '../contexts';
import { useGetMembershipDetails } from '../hooks';
import { useGetBudgetPlanMemberships } from '../hooks/useGetBudgetPlanMemberships';

import { MembershipDetailsActionButtons } from './MembershipDetailsActionButtons';
import { MembershipInfo } from './MembershipInfo';
import { UnlinkMembershipConfirmationModal } from './UnlinkMembershipConfirmationModal';

export const MembershipDetailsModalContent = ({ onUnlinkMembershipModalClose }: UnlinkMembershipModalContentProps) => {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();

  const getBudgetPlanMemberships = useGetBudgetPlanMemberships();

  const selectedPlanId = useSelectedBudgetPlanId();
  const membershipDetailsDispatch = useMembershipDetailsDispatch();
  const membershipDetailsState = useMembershipDetailsState();
  const { membership, membershipDetailsApiStatus } = membershipDetailsState;

  const membershipsListState = useBudgetPlanMembershipsListState();
  const fetchAndUpdateMembershipDetails = useGetMembershipDetails();
  const { selectedMembershipId, memberships } = membershipsListState;

  const helperTextRef = useRef<string>('');
  const helperTextTypeRef = useRef<HelperTextType>('info');

  const getMembershipDetails = useCallback(async () => {
    if (selectedPlanId && selectedMembershipId) {
      await fetchAndUpdateMembershipDetails(selectedPlanId, selectedMembershipId);
    }
  }, [selectedPlanId, selectedMembershipId, fetchAndUpdateMembershipDetails]);

  useEffect(() => {
    (async () => {
      await getMembershipDetails();
    })();
  }, [getMembershipDetails]);

  if (membership?.isUnlinkingFailed()) {
    helperTextRef.current = fm({ id: 'budgetPlanMembership.unlinkingFailed' });
    helperTextTypeRef.current = 'error';
  }

  if (membership?.isLinkingFailed()) {
    helperTextRef.current = fm({ id: 'budgetPlanMembership.linkingFailed' });
    helperTextTypeRef.current = 'error';
  }

  // If details in the list has been updated, silently update the membership details for modal as well
  const selectedMembershipDetailsFromList = memberships.find((membership) => membership.id === selectedMembershipId);
  if (
    !isEmpty(selectedMembershipDetailsFromList) &&
    !isEqual(selectedMembershipDetailsFromList?.getMembership(), membership?.getMembership()) &&
    membershipDetailsApiStatus.isSucceeded()
  ) {
    membershipDetailsDispatch({
      type: MembershipDetailsActionType.UpdateMembershipDetails,
      payload: selectedMembershipDetailsFromList,
    });
  }

  const { retryStateTransitionApiStatus, discardFailedStateTransitionApiStatus } = membershipDetailsState;
  if (!membership) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        error={membershipDetailsApiStatus.isFailed()}
        loading={membershipDetailsApiStatus.isPending()}
        noResults={isEmpty(membership) && membershipDetailsApiStatus.isSucceeded()}
        onRetry={getMembershipDetails}
        baseTranslationKey="membershipDetails"
        style={{ backgroundColor: palette.white.main, flex: 1 }}
      />
    );
  }

  return (
    <Box display="flex" flex="1" flexDirection="column">
      <MembershipInfo />
      <MembershipDetailsActionButtons onUnlinkMembershipModalClose={onUnlinkMembershipModalClose} />
      <HelperText
        showAttentionIcon={helperTextTypeRef.current === 'error'}
        showCheckIcon={helperTextTypeRef.current === 'success'}
        type={
          retryStateTransitionApiStatus.isPending() || discardFailedStateTransitionApiStatus.isPending()
            ? 'info'
            : helperTextTypeRef.current
        }
        value={helperTextRef.current}
      />
      <UnlinkMembershipConfirmationModal onUnlinkConfirmClick={handleUnlinkConfirmClick} />
    </Box>
  );

  async function handleUnlinkConfirmClick() {
    try {
      membershipDetailsDispatch({
        type: MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestStart,
      });
      const newMembership = await deleteMembership(selectedPlanId!, membership!.id);
      const newMembershipDto = new BudgetPlanMembershipDto(newMembership);
      membershipDetailsDispatch({
        type: MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestSuccess,
        payload: newMembershipDto,
      });
      membershipDetailsDispatch({
        type: MembershipDetailsActionType.CloseUnlinkConfirmationModal,
      });
      getBudgetPlanMemberships(selectedPlanId!);
      updateHelperTextAndType(newMembershipDto);
    } catch {
      membershipDetailsDispatch({
        type: MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestFailure,
      });
    }
  }

  function updateHelperTextAndType(membership: BudgetPlanMembershipDto) {
    if (membership.isInactive()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.successfullyUnlinked' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isUnlinkingInProgress()) {
      helperTextRef.current = fm({ id: 'common.requestSuccessfullySubmitted' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isUnlinkingFailed()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.unlinkingFailed' });
      helperTextTypeRef.current = 'error';
    }
    if (membership.isActive()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.successfullyLinked' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isLinkingInProgress()) {
      helperTextRef.current = fm({ id: 'common.requestSuccessfullySubmitted' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isLinkingFailed()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.linkingFailed' });
      helperTextTypeRef.current = 'error';
    }
  }
};

interface UnlinkMembershipModalContentProps {
  onUnlinkMembershipModalClose: () => void;
}
