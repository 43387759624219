import { MouseEvent as ReactMouseEvent, PropsWithChildren, useState } from 'react';

import { Button, Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { isAfter, isBefore, isEqual } from 'date-fns';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import { UserTripActivityLogDetails, UserTripFieldValue, UserTripStatus, VehicleTypeHelper } from '../../models';
import { splitDateTimeFormat } from '../../utils/DateMethods';
import { forceStopTrip } from '../../utils/queries';
import CustomTextField from '../FormFields/CustomTextField';
import FormikErrorMessage, { ErrorMessage } from '../FormFields/ErrorMessage';
import { LoadingAndErrorWithRetryAndNoResults } from '../LoadingAndErrorWithRetryAndNoResults';

import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';
import TripDetailsAccordion from './TripDetailsAccordion';
import { useUserTripDetails } from './useUserTripDetails';

const UserTripDetails = ({ trip, onUpdateEndDate, enableForceStop = false }: UserTripDetailsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [savedEvent, setSavedEvent] = useState<ReactMouseEvent<HTMLButtonElement> | null>(null);
  const [savedValues, setSavedValues] = useState<UserTripFieldValue | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [endDataToUpdate, setEndDataToUpdate] = useState<EndDate | null>(null);

  const { formatMessage } = useIntl();
  const [errorMessage, setErrorMessage] = useState(false);
  const theme = useTheme();

  const noTripFinishDateExist = !trip.finishedAt;
  const defaultValues: UserTripFieldValue = {
    startDate: trip.startedAt.parseEpochMillis().formatAsISODateString(),
    startTime: trip.startedAt.parseEpochMillis().formatAsTimeWithoutSeconds(),
    endDate: noTripFinishDateExist ? '' : trip.finishedAt!.parseEpochMillis().formatAsISODateString(),
    endTime: noTripFinishDateExist ? '' : trip.finishedAt!.parseEpochMillis().formatAsTimeWithoutSeconds(),
    latestDate: noTripFinishDateExist ? '' : trip.finishedAt!.parseEpochMillis().formatAsISODateString(),
    latestTime: noTripFinishDateExist ? '' : trip.finishedAt!.parseEpochMillis().formatAsTimeWithoutSeconds(),
  };
  const [initialValues] = useState<UserTripFieldValue>(defaultValues);

  const handleClose = (event: ReactMouseEvent<Document | HTMLDivElement>, reason: string) => {
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const {
    tripDetailsData: tripDetails,
    loading: tripDetailsLoading,
    error: tripDetailsError,
    refetch: refetchTripDetails,
  } = useUserTripDetails({ tripId: trip.id });

  const handleForceStop = async (event: ReactMouseEvent<HTMLButtonElement>, values: UserTripFieldValue) => {
    setSavedEvent(event);
    setSavedValues(values);
    setIsEditMode(true);
    setLoading(true);
    setError(false);
    setErrorMessage(false);
    const combinedDateTime = `${values.endDate}T${values.endTime}`;
    const endDateTime = new Date(combinedDateTime).getTime();

    const endDate: EndDate = {
      id: trip.id,
      endEpochMillis: endDateTime,
      active: false,
    };

    try {
      await forceStopTrip(trip.id, { endTimestamp: endDateTime });
      setLoading(false);
      await refetchTripDetails();
      setShowModal(true);
      setEndDataToUpdate(endDate);
    } catch (err) {
      setErrorMessage(true);
      setLoading(false);
      setError(true);
      console.error(err);
    }
  };

  const handleModalConfirm = () => {
    if (endDataToUpdate) {
      if (onUpdateEndDate) onUpdateEndDate(endDataToUpdate);
      setEndDataToUpdate(null);
    }
    setShowModal(false);
  };

  const validate = (values: UserTripFieldValue) => {
    const errors: any = {};
    if (!values.endDate) {
      errors.endDate = formatMessage({ id: 'form.validation.errorFieldRequired' });
    } else {
      const date = dayjs().diff(values.endDate, 'year');
      if (date < 0 || date > 100) {
        errors.endDate = formatMessage({ id: 'form.validation.invalidDate' });
      }
    }

    if (!values.endTime) {
      errors.endTime = formatMessage({ id: 'form.validation.errorFieldRequired' });
    }

    if (values.endDate && values.endTime) {
      const endDatTime = new Date(values.endDate);
      const endTimeParts = values.endTime.split(':').map(Number);
      endDatTime.setHours(endTimeParts[0] || 0, endTimeParts[1] || 0, 0, 0);

      const { date: eD } = splitDateTimeFormat(endDatTime);
      if (isAfter(endDatTime, new Date())) {
        errors.endDate = formatMessage({ id: 'form.validation.errorNoFutureDateTime' });
      } else {
        const lastActivityUpdateDateTime = new Date(
          tripDetails?.activityLog[tripDetails.activityLog.length - 1].timestamp || values.startDate,
        );
        const { date: lD } = splitDateTimeFormat(lastActivityUpdateDateTime);
        if (isBefore(new Date(eD), new Date(lD))) {
          errors.endDate = formatMessage({ id: 'form.validation.cannotBeBeforeRecentActivityDate' });
        } else {
          if (isEqual(new Date(eD), new Date(lD))) {
            if (isBefore(endDatTime, lastActivityUpdateDateTime)) {
              errors.endTime = formatMessage({ id: 'form.validation.cannotBeBeforeRecentActivityTime' });
            }
          }
        }
      }
    }

    return errors;
  };

  const determineRetryFunction = (editMode: boolean) => {
    if (editMode && enableForceStop && savedEvent && savedValues) {
      return () => handleForceStop(savedEvent, savedValues);
    }
    return () => refetchTripDetails();
  };

  const incorrectStatus = loading || tripDetailsLoading || tripDetailsError || error;
  const isTripInActive = tripDetails?.status === UserTripStatus.Stopped || tripDetails?.status === UserTripStatus.ForceStopped;
  const isTripActive = !isTripInActive;

  const compareTimestamps = (a: UserTripActivityLogDetails, b: UserTripActivityLogDetails) => a.timestamp - b.timestamp;
  const chronologicalActivityLog = tripDetails?.activityLog.sort(compareTimestamps);
  return (
    <>
      {incorrectStatus ? (
        <LoadingAndErrorWithRetryAndNoResults
          style={{ height: '150px' }}
          error={error || tripDetailsError}
          loading={loading || tripDetailsLoading}
          onRetry={determineRetryFunction(isEditMode)}
          baseTranslationKey="tripDetails"
        />
      ) : (
        <Box sx={{ boxShadow: theme.shadowOptions.belowSmall }}>
          <Formik initialValues={initialValues} validate={validate} onSubmit={() => {}} validateOnMount={true}>
            {({ isValid, values }) => (
              <Form>
                <Box display="flex" flexDirection="column" gap="1rem" padding="1rem">
                  <Box display="flex" gap="2.5rem">
                    <Box display="flex" gap="0.5rem">
                      <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.trip.provider' })} </Typography>
                      {tripDetails && (
                        <Typography variant="bodySmall">
                          {formatMessage({ id: `account.tripHistory.provider.${tripDetails.vehicle.provider.name.toLowerCase()}` })}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" gap="0.5rem">
                      <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.trip.vehicleType' })}</Typography>
                      {tripDetails && (
                        <Typography variant="bodySmall">{VehicleTypeHelper.metadata(tripDetails.vehicle.type).displayText}</Typography>
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={2} padding={1}>
                    <Box alignSelf="flex-start">
                      <Typography variant="bodySmall">{formatMessage({ id: 'maas.customer.trip.start' })}</Typography>
                      <Box display="flex" gap={2}>
                        <Box width={170}>
                          <Field type="date" name="startDate" component={CustomTextField} disabled />
                        </Box>
                        <Box width={140}>
                          <Field type="time" name="startTime" component={CustomTextField} disabled />
                        </Box>
                      </Box>
                    </Box>

                    <Box alignSelf="flex-start">
                      <Typography variant="bodySmall">{formatMessage({ id: 'maas.customer.trip.end' })}</Typography>
                      <Box display="flex" gap={2}>
                        <Box width={170}>
                          <Field type="date" name="endDate" disabled={isTripInActive} component={CustomTextField} />
                          <FormikErrorMessage name="endDate" />
                        </Box>
                        <Box width={140}>
                          <Field type="time" name="endTime" disabled={isTripInActive} component={CustomTextField} />
                          <FormikErrorMessage name="endTime" />
                        </Box>
                        <Box>
                          {isTripActive && enableForceStop && (
                            <div>
                              <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                name="buttonForceStop"
                                disabled={!isValid}
                                disableElevation
                                sx={{ height: 40, width: 140 }}
                                onClick={(e) => handleForceStop(e, values)}
                              >
                                <Typography variant="bodySmallBold">
                                  {formatMessage({ id: 'maas.customer.tripHistory.forceStop' })}
                                </Typography>
                              </Button>

                              {errorMessage ? (
                                <Box mt="4px" color={theme.palette.alert.main} height={theme.typography.caption.lineHeight}>
                                  <ErrorMessage error={formatMessage({ id: 'form.validation.updateFailed' })} />
                                </Box>
                              ) : null}
                            </div>
                          )}

                          <Modal open={showModal} onClose={handleClose}>
                            <Box sx={modalStyles}>
                              <Box sx={modalContentStyles}>
                                <Typography variant="bodyMediumBold" color={theme.palette.dark.main}>
                                  {formatMessage({ id: 'maas.customer.dl.dialog.successTitle' })}
                                </Typography>
                                <Typography variant="bodySmall" color={theme.palette.dark.shade76} style={{ textAlign: 'center' }}>
                                  {formatMessage({ id: 'maas.customer.dl.dialog.trip' })} {trip.id.toString()}{' '}
                                  {formatMessage({ id: 'maas.customer.dl.dialog.trip.modified' })}
                                </Typography>
                              </Box>
                              <div style={{ textAlign: 'center', margin: '10px' }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{ fontSize: '14px', width: '190px', height: '32px' }}
                                  onClick={handleModalConfirm}
                                >
                                  {formatMessage({ id: 'common.dialog.confirm' })}
                                </Button>
                              </div>
                            </Box>
                          </Modal>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ mx: 2 }} />
                {tripDetails && tripDetails.payment && (
                  <Box display="flex" flexDirection="column" padding="1rem">
                    <Typography variant="bodyMediumBold" mb="12px">
                      {formatMessage({ id: 'maas.customer.tripHistory.detailedTripHistory' })}
                    </Typography>
                    <TripDetailsAccordion tripDetails={tripDetails} />
                  </Box>
                )}
                <Box display="flex" flexDirection="column" padding="1rem">
                  <Typography variant="bodyMediumBold" mb="12px">
                    {formatMessage({ id: 'maas.customer.tripHistory.auditLog' })}
                  </Typography>
                  <Accordion id="detail-panel">
                    <AccordionSummary>
                      <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.tripHistory.details' })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableHead>
                          <TableCellBoldStyled>{formatMessage({ id: 'maas.customer.tripHistory.modifiedAt' })}</TableCellBoldStyled>
                          <TableCellBoldStyled>{formatMessage({ id: 'maas.customer.tripHistory.modifiedBy' })}</TableCellBoldStyled>
                          <TableCellBoldStyled>{formatMessage({ id: 'maas.customer.tripHistory.change' })}</TableCellBoldStyled>
                        </TableHead>
                        <TableBody>
                          {tripDetails &&
                            chronologicalActivityLog?.map((log: UserTripActivityLogDetails, index) => (
                              <TableRow key={index}>
                                <TableCellStyled>{log.timestamp.parseEpochMillis().formatAsDateTimeString()}</TableCellStyled>
                                <TableCellStyled>{log.updatedBy.name}</TableCellStyled>
                                <TableCellStyled>
                                  {formatMessage({ id: `account.tripHistory.activity.${log.event.toLowerCase()}` })}
                                </TableCellStyled>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </>
  );
};

const TableCellBoldStyled = (props: PropsWithChildren<{}>) => (
  <TableCell sx={{ border: 'none', padding: 1, borderBottom: '1px solid #ccc' }}>
    <Typography variant="bodySmallBold" {...props} />
  </TableCell>
);

const TableCellStyled = (props: PropsWithChildren<{}>) => (
  <TableCell sx={{ border: 'none', padding: 1 }}>
    <Typography variant="bodySmall" {...props} />
  </TableCell>
);

const modalStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.white.main,
  position: 'absolute',
  display: 'flex',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '8px',
  width: '350px',
  height: 'auto',
  padding: '10px',
});

const modalContentStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
});

interface UserTripDetailsProps {
  trip: UserTripDetailsQueryParams;
  enableForceStop?: boolean;
  onUpdateEndDate?: (endDate: EndDate) => void;
}

interface UserTripDetailsQueryParams {
  startedAt: number;
  finishedAt?: number;
  id: BigInt;
}

export interface EndDate {
  id: BigInt;
  endEpochMillis: number;
  active: boolean;
}

export default UserTripDetails;
