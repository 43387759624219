import { useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Column, Row } from 'react-table';

import { CustomerDetailsType, SearchUsersQueryParams } from '../../../models';
import { TABLE_FOOTER_HEIGHT } from '../../../utils/constants';
import ReactTable from '../../../wmv-components/ReactTable';
import CustomerInformationModal from '../../CustomerInformationModal';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';

interface CustomerDetailsTableProps {
  customerDetails: CustomerDetailsType[];
  loading: boolean;
  error: boolean;
  onRetry: (searchParameters: SearchUsersQueryParams) => void;
  lastSearchParameters: SearchUsersQueryParams | null;
  noSearchResults: boolean;
}

const getFormattedColumns = (formatMessage: any): Column<CustomerDetailsType>[] => [
  {
    Header: formatMessage({ id: 'maas.customer.details.firstName' }),
    accessor: 'firstName',
  },
  {
    Header: formatMessage({ id: 'maas.customer.details.lastName' }),
    accessor: 'lastName',
  },
  {
    Header: formatMessage({ id: 'maas.customer.details.birthdateShort' }),
    accessor: 'birthDate',
    Cell: ({ row }: { row: Row<CustomerDetailsType> }) => {
      return <Typography>{dayjs(row.original.birthDate).formatAsDateString()}</Typography>;
    },
  },
  { Header: formatMessage({ id: 'maas.customer.details.email' }), accessor: 'email' },
];

export const CustomerDetailsTable = (props: CustomerDetailsTableProps) => {
  const { customerDetails, loading, error, onRetry, lastSearchParameters, noSearchResults } = props;
  const { formatMessage } = useIntl();
  const { palette } = useTheme();

  const columns = useMemo(() => getFormattedColumns(formatMessage), [formatMessage]);

  const [openModal, setOpenModal] = useState(false);
  const [userInfo, setUserInfo] = useState<CustomerDetailsType>();

  const handleOpenModal = (user: CustomerDetailsType) => {
    setOpenModal(true);
    setUserInfo(user);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setUserInfo(undefined);
  };
  return (
    <>
      {customerDetails.length === 0 ? (
        // 215px depends on the preceding elements; needed to center the text vertically
        <LoadingAndErrorWithRetryAndNoResults
          style={{ minHeight: `calc(100vh - 192px)`, backgroundColor: palette.white.main }}
          error={error}
          loading={loading}
          onRetry={() => {
            if (lastSearchParameters) onRetry(lastSearchParameters);
          }}
          baseTranslationKey="customerDatabase"
          noResults={noSearchResults}
        />
      ) : (
        <ReactTable
          columns={columns}
          data={customerDetails}
          onRowClickHandler={(user: CustomerDetailsType) => handleOpenModal(user)}
          tableContainerStyle={{
            height: `calc(100vh - (${TABLE_FOOTER_HEIGHT} + 194px))`,
          }}
          pagination={false}
          loading={loading}
        />
      )}
      {openModal && userInfo && <CustomerInformationModal isOpen={openModal} onClose={handleModalClose} userInfo={userInfo} />}
    </>
  );
};
