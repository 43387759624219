import { FormControl, MenuItem, Checkbox, InputLabel, OutlinedInput } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// eslint-disable-next-line import/named
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useIntl } from 'react-intl';

import { TableColumnType } from '../../models';

const MultipleSelectField = ({ ...props }) => {
  const { outlineLabel, value, options, style, setValue } = props;

  const handleChange = (event: SelectChangeEvent<TableColumnType[]>) => {
    const selectedOptions = event.target.value as TableColumnType[];
    if (selectedOptions.map((o) => o.value).indexOf('all') > -1) {
      setValue(selectedOptions.length === options.length + 1 ? [] : options);
      return;
    }
    setValue(selectedOptions);
  };

  const getRenderValue = (selected: TableColumnType[]) => {
    if (value.length === options.length) return formatMessage({ id: 'common.all' });
    return selected
      .filter((s) => s.value !== 'all')
      .map((op) => op.label)
      .join(', ');
  };

  const { formatMessage } = useIntl();

  return (
    <>
      <FormControl sx={{ width: 275, ...style }}>
        <InputLabel size="small" id={outlineLabel}>
          {outlineLabel}
        </InputLabel>
        <Select
          multiple
          fullWidth
          size="small"
          labelId={outlineLabel}
          id={outlineLabel}
          value={value}
          label={outlineLabel}
          onChange={handleChange}
          sx={{
            '& fieldset': { top: 0 },
          }}
          renderValue={getRenderValue}
          input={<OutlinedInput label={outlineLabel} />}
        >
          <MenuItem key="012" value={{ value: 'all', label: formatMessage({ id: 'common.all' }) } as any}>
            <ListItemIcon>
              <Checkbox checked={value.length === options.length} indeterminate={value.length > 0 && value.length < options.length} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'common.all' })}</ListItemText>
          </MenuItem>
          {options.map((op: TableColumnType, index: number) => (
            <MenuItem key={index} value={op as any}>
              <ListItemIcon>
                <Checkbox checked={value.map((v: TableColumnType) => v.value).indexOf(op.value) > -1} />
              </ListItemIcon>
              <ListItemText>{op.label}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MultipleSelectField;
