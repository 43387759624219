import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { FormControl, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useFormikContext } from 'formik';

import { FormikFieldProps, DropDownOption } from '../../models';

const DropDownField: React.FC<FormikFieldProps> = ({ field, form, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { outlineLabel, value, name, options, width = '100%', disabled, defaultValue } = props;
  const onOptionSelect = async (op: DropDownOption) => {
    await setFieldValue(name, op);
  };

  return (
    <>
      <FormControl sx={{ width: width }}>
        <InputLabel size="small" id={outlineLabel}>
          {outlineLabel}
        </InputLabel>
        <Select
          fullWidth
          size="small"
          labelId={outlineLabel}
          id={outlineLabel}
          value={value}
          label={name}
          disabled={disabled}
          defaultValue={defaultValue}
          // onChange={(e) => {
          //   await setFieldValue(name, e.target.value);
          // }}
          onBlur={async () => {
            await setFieldTouched(name, true);
          }}
          sx={{
            '& fieldset': { top: 0 },
          }}
          renderValue={(selected: DropDownOption) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selected.color && (
                <ListItemIcon>
                  <SquareRoundedIcon style={{ color: selected.color }} />
                </ListItemIcon>
              )}
              <ListItemText>{selected.label}</ListItemText>
            </div>
          )}
          input={<OutlinedInput label={outlineLabel} />}
        >
          {options.map((op: DropDownOption, index: number) => (
            <MenuItem key={index} value={op.value} onClick={() => onOptionSelect(op)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {op.color && (
                  <ListItemIcon>
                    <SquareRoundedIcon style={{ color: op.color }} />
                  </ListItemIcon>
                )}
                <ListItemText>{op.label}</ListItemText>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default DropDownField;
