import { FC } from 'react';

// eslint-disable-next-line import/named
import { Button } from '@mui/material';

interface SaveButtonProps {
  onClick?: () => void;
  disabled: boolean;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'text' | 'outlined' | 'contained';
  disabledElevation?: boolean;
}

const SaveButton: FC<SaveButtonProps> = ({ onClick, disabled, variant, disabledElevation, ...props }) => {
  return (
    <Button
      sx={{
        margin: '3px 0',
        width: '100%',
      }}
      variant={variant}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      disableElevation={disabledElevation}
      {...props}
    />
  );
};

export default SaveButton;
