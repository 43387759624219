import { intl } from './intl';

export const splitDateTime = (dateObj: Date) => {
  const d = dateObj.getDate();
  const m = dateObj.getMonth();
  const y = dateObj.getFullYear();
  const hr = dateObj.getHours();
  const min = dateObj.getMinutes();
  return {
    date: `${d}/${m}/${y}`,
    time: `${hr}:${min}`,
  };
};

export const splitDateTimeFormat = (dateObj: Date) => {
  const [date, time] = new Date(dateObj).toISOString().split('T');
  return {
    date,
    time,
  };
};

export const localDateTime = (addedMinutes = 0) => {
  const offsetMinutes = new Date().getTimezoneOffset() * 60000;
  return new Date(Date.now() - offsetMinutes + addedMinutes * 60000);
};

export const formatDistanceFromMeters = (meters: number) => {
  if (meters === 0) return intl.formatNumber(0, { style: 'unit', unit: 'meter' });
  if (meters < 1000) return intl.formatNumber(meters, { style: 'unit', unit: 'meter' });
  return intl.formatNumber(meters / 1000, { style: 'unit', unit: 'kilometer' });
};
