import {
  CustomerDetailsType,
  DashboardShortcut,
  GetTripsQueryParams,
  LawEnforcementTripSummaryResponse,
  SearchUsersQueryParams,
  VehicleTripType,
  ZoneResponse,
  ZoneRequest,
  ZoneStatus,
  UserTripsListResponse,
  UserTripDetailsResponse,
  CreateBudgetPlanRequest,
  BudgetPlanSummaryCumDetailsResponse,
  UpdateBudgetPlanStatusRequest,
  UpdateBudgetPlanRequest,
  BudgetPlanMembershipSummaryResponse,
  BudgetPlanMembershipDetailsResponse,
  AggregatedVehicle,
  ChangeStatusRequest,
  ChangeRequestResponse,
  ForceStopTripDetailsResponse,
  CreateBudgetPlanMembershipRequest,
  BudgetPlanMembershipSearchResponse,
  UnlinkMembershipResponse,
  CreateBudgetPlanMembershipResponse,
} from '../models';
import { RetryFailedMembershipTransitionResponse } from '../models/budget-plan/retry-failed-membership-transition/response';
import { UpdateVehiclePricingRequest, UpdateVehiclePricingResponse, VehicleFeePricingListResponse } from '../models/vehicle-fee-pricing';
import axiosInstance from '../services/AxiosClient';
import UserService from '../services/UserService';

export const searchUsers = ({ firstName, lastName, birthDate }: SearchUsersQueryParams): Promise<CustomerDetailsType[]> => {
  return axiosInstance.get(`/users/?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}`);
};

export const getTripsByVehicleId = ({
  vehicleId,
  fromInclusiveEpochMillis,
  toInclusiveEpochMillis,
}: GetTripsQueryParams): Promise<VehicleTripType[]> => {
  return axiosInstance.get(
    `/operation/vehicles/${vehicleId}/trips?fromInclusiveEpochMillis=${fromInclusiveEpochMillis}&toInclusiveEpochMillis=${toInclusiveEpochMillis}`,
  );
};

export const putCustomerDetails = (
  payload: Partial<CustomerDetailsType>,
  subjectClaim: string,
): Promise<CustomerDetailsType | undefined | null> => {
  return axiosInstance.put(`/users/${subjectClaim}/updateBySupport`, payload);
};

export const getCustomerDetails = (subjectClaim: string): Promise<CustomerDetailsType> => {
  return axiosInstance.get(`/users/${subjectClaim}`);
};

export const getUserTripsList = (userSubjectClaim: string): Promise<UserTripsListResponse> => {
  return axiosInstance.get(`operation/mock/users/${UserService.subjectClaim()}/trips`);
};

export const getUserTripDetails = (tripId: BigInt): Promise<UserTripDetailsResponse> => {
  return axiosInstance.get(`/operation/mock/users/${UserService.subjectClaim()}/trips/1`);
};

export const getDashboardWidgetsData = (): Promise<DashboardShortcut[]> => {
  return axiosInstance.get(`/users/mobility-manager/dashboard-shortcuts`);
};

export const getAllDashboardWidgetsData = (): Promise<DashboardShortcut[]> => {
  return axiosInstance.get(`/users/mobility-manager/metadata/dashboard-shortcut-categories`);
};

export const fetchZonesByStatus = (status: ZoneStatus): Promise<ZoneResponse[]> => {
  return axiosInstance.get(`/mobility-manager/zones?status=${status}`);
};

export const fetchAllZones = (): Promise<ZoneResponse[]> => {
  return axiosInstance.get(`/mobility-manager/zones`);
};

export const createZone = (payload: ZoneRequest): Promise<ZoneResponse> => {
  return axiosInstance.post(`/mobility-manager/zones`, payload);
};

export const updateZone = (zoneId: number, payload: ZoneRequest): Promise<ZoneResponse> => {
  return axiosInstance.put(`/mobility-manager/zones/${zoneId}`, payload);
};

export const deleteZone = (zoneId: number): Promise<ZoneResponse> => {
  return axiosInstance.delete(`/mobility-manager/zones/${zoneId}`);
};

export const forceStopTrip = (tripId: BigInt, payload: any): Promise<ForceStopTripDetailsResponse> => {
  return axiosInstance.post(`/operation/mock/users/${UserService.subjectClaim()}/trips/1/force-stop`, payload);
};

export const fetchTripSummariesForLawEnforcement = (
  fromInclusiveEpochMillis: number,
  toInclusiveEpochMillis: number,
): Promise<LawEnforcementTripSummaryResponse[]> => {
  return axiosInstance.get(
    `/operation/lookup/trips-for-law-enforcement?fromInclusiveEpochMillis=${fromInclusiveEpochMillis}&toInclusiveEpochMillis=${toInclusiveEpochMillis}`,
  );
};

export const listBudgetPlans = (): Promise<BudgetPlanSummaryCumDetailsResponse[]> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans`);
};

export const addBudgetPlan = (payload: CreateBudgetPlanRequest): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.post(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans`, payload);
};

export const updateBudgetPlan = (payload: UpdateBudgetPlanRequest, planId: number): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.put(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}`, payload);
};

export const updateBudgetPlanStatus = (
  payload: UpdateBudgetPlanStatusRequest,
  planId: number,
): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.put(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}`, payload);
};

export const fetchBudgetPlanByIdApi = (id: number): Promise<BudgetPlanSummaryCumDetailsResponse> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${id}`);
};

export const fetchBudgetPlanMembershipSummaries = (budgetPlanId: number): Promise<BudgetPlanMembershipSummaryResponse[]> => {
  return axiosInstance.get(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${budgetPlanId}/memberships`,
  );
};

export const fetchBudgetPlanMembershipDetails = (
  budgetPlanId: number,
  budgetPlanMembershipId: number,
): Promise<BudgetPlanMembershipDetailsResponse> => {
  return axiosInstance.get(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${budgetPlanId}/memberships/${budgetPlanMembershipId}`,
  );
};

// NOTE: Here budgetPlanId is temporary with mock api, with real api budgetPlanId param will get removed
export const searchMembership = (email: string, budgetPlanId: number): Promise<BudgetPlanMembershipSearchResponse> => {
  return axiosInstance.get('/mobility-manager/mock/mobility-users', {
    params: {
      email,
      budgetPlanId,
    },
    headers: {
      accept: 'application/json+vnd.budget-plan.membership-linkage',
    },
  });
};

export const createMembership = (
  planId: number,
  payload: CreateBudgetPlanMembershipRequest,
): Promise<CreateBudgetPlanMembershipResponse> => {
  return axiosInstance.post(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships`,
    payload,
  );
};

export const deleteMembership = (planId: number, membershipId: number): Promise<UnlinkMembershipResponse> => {
  return axiosInstance.delete(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships/${membershipId}`,
  );
};

export const retryFailedMembershipStateTransition = (
  planId: number,
  membershipId: number,
): Promise<RetryFailedMembershipTransitionResponse> => {
  return axiosInstance.post(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships/${membershipId}/retry-failed-attempt`,
  );
};

export const cancelLastFailedAttempt = (planId: number, membershipId: number): Promise<''> => {
  return axiosInstance.post(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships/${membershipId}/mark-last-failed-attempt-acknowledged`,
  );
};

export const fetchAggregatedVehicles = (): Promise<AggregatedVehicle[]> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/vehicles`);
};

export const changeVehicleStatus = (payload: ChangeStatusRequest, vehicleId: number): Promise<ChangeRequestResponse> => {
  return axiosInstance.patch(
    `mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/vehicles/${vehicleId}/status`,
    payload,
  );
};

export const fetchVehicleFeeList = (): Promise<VehicleFeePricingListResponse> => {
  return axiosInstance.get('/mobility-manager/mobility-service-providers/aggregated/vehicle-models/aggregated/fee-schedules');
};

export const updateVehicleFeePricing = (
  request: UpdateVehiclePricingRequest,
  mspSubjectClaim: string,
  vehicleModelId: number,
): Promise<UpdateVehiclePricingResponse> => {
  return axiosInstance.put(
    `/mobility-manager/mobility-service-providers/${mspSubjectClaim}/vehicle-models/${vehicleModelId}/fee-schedule`,
    request,
  );
};

type NO_CONTENT = '';
export const discardFailedVehiclePricingStatusTransition = (mspSubjectClaim: string, vehicleModelId: number): Promise<NO_CONTENT> => {
  return axiosInstance.post(
    `/mobility-manager/mock/mobility-service-providers/${mspSubjectClaim}/vehicle-models/${vehicleModelId}/fee-schedule/mark-last-failed-attempt-acknowledged`,
  );
};
