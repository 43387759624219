import type { FormatXMLElementFn } from 'intl-messageformat';
import type { PrimitiveType } from 'react-intl';

import { intl } from '../../utils/intl';

declare global {
  interface String {
    i18nText(values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string;
  }
}

declare global {
  interface Number {
    i18nNumber(values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.i18nText = function (values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string {
  let key = String(this);
  return intl.formatMessage({ id: key }, values);
};

// eslint-disable-next-line no-extend-native
Number.prototype.i18nNumber = function (values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string {
  let value = Number(this);
  return intl.formatNumber(value, values);
};
