import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { LastChangeAttemptStatus, LastChangeAttemptStatusHelper } from '../../models';

import { dotIndicatorStyles } from './styles';

export function LastChangeAttemptStatusInfo({ status }: LastChangeAttemptStatusInfoProps) {
  const theme = useTheme();
  const LastChangeAttemptStatusMetadata = LastChangeAttemptStatusHelper.metadata(status);
  const lastAttemptedStateChangeFailed = status === LastChangeAttemptStatus.Failed;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box sx={dotIndicatorStyles(theme, LastChangeAttemptStatusMetadata.color)} />
      <Typography
        component="span"
        ml={1}
        color={lastAttemptedStateChangeFailed ? LastChangeAttemptStatusMetadata.color : theme.palette.baseLight.base76}
      >
        {LastChangeAttemptStatusMetadata.displayText}
      </Typography>
    </Box>
  );
}

interface LastChangeAttemptStatusInfoProps {
  status: LastChangeAttemptStatus;
}
