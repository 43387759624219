import type { ChangeEvent } from 'react';

import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import type { SelectChangeEvent } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ZoneTypeHelper } from '../../../models';
import {
  updateZoneServerViolationsFieldToErrorSelector,
  createZoneServerViolationsFieldToErrorSelector,
} from '../../../state/zones-management';
import { SelectionMenu, Value } from '../../../wmv-components';

import { ZoneRuleFormFields, ZoneRulesFormValues } from './ZoneRulesForm';

interface ZoneTypeAndNameFormGroupProps {
  type: string;
  name: string;
  readOnlyZoneRuleForm: boolean;
}

export const ZoneTypeAndNameFormGroup = ({ type, name, readOnlyZoneRuleForm }: ZoneTypeAndNameFormGroupProps): JSX.Element => {
  const { errors, touched, setFieldValue, handleBlur } = useFormikContext<ZoneRulesFormValues>();
  const { formatMessage } = useIntl();
  const updateZoneServerViolationsFieldToError = useSelector(updateZoneServerViolationsFieldToErrorSelector);
  const createZoneServerViolationsFieldToError = useSelector(createZoneServerViolationsFieldToErrorSelector);
  const zoneServerViolationsFieldToError = updateZoneServerViolationsFieldToError || createZoneServerViolationsFieldToError;

  const zoneTypesMenuConfig = ZoneTypeHelper.allTypes().map((zoneType) => ({
    value: zoneType,
    label: formatMessage({ id: ZoneTypeHelper.metadata(zoneType).translationKey }),
    startIcon: <SquareRoundedIcon style={{ color: ZoneTypeHelper.metadata(zoneType).color }} />,
  }));

  const handleNameChange = async (event: ChangeEvent<HTMLInputElement>) => {
    await setFieldValue(ZoneRuleFormFields.name, event.target.value);
  };

  const handleTypeChange = async (event: SelectChangeEvent<Value>) => {
    await setFieldValue(ZoneRuleFormFields.type, event.target.value);
  };

  return (
    <Stack spacing={3}>
      <Typography variant="bodyMediumBold" component="h4">
        {formatMessage({ id: 'map.forms.section.zoneInformation' })}
      </Typography>
      <SelectionMenu
        options={zoneTypesMenuConfig}
        onChange={handleTypeChange}
        value={type}
        label={formatMessage({ id: 'map.forms.zoneType.label' })}
        disabled={readOnlyZoneRuleForm}
        name={ZoneRuleFormFields.type}
      />
      <TextField
        label={formatMessage({ id: 'map.forms.zoneName.label' })}
        onChange={handleNameChange}
        onBlur={handleBlur}
        value={name}
        error={(!!errors.name && touched.name) || !!zoneServerViolationsFieldToError?.['name']}
        name={ZoneRuleFormFields.name}
        size="small"
        placeholder={formatMessage({ id: 'map.forms.zoneName.placeholder' })}
        helperText={(!!errors.name && touched.name && errors.name) || zoneServerViolationsFieldToError?.['name']?.message}
        disabled={readOnlyZoneRuleForm}
      />
    </Stack>
  );
};
