import { Typography, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { ReactComponent as Checkmark } from '../../../../assets/icons/outline/checkmark.svg';
import { ReactComponent as Close } from '../../../../assets/icons/outline/close.svg';
import { intl } from '../../../../utils/intl';
import { useMembershipDetailsState } from '../contexts';

import { MembershipDetailsRow } from './MembershipDetailsRow';
import { MembershipStatus } from './MembershipStatus';

export const MembershipInfo = () => {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { membership } = useMembershipDetailsState();

  return (
    <Box bgcolor={palette.baseLight.base0} p={2} flex={1}>
      {renderGeneralInformation()}
      {renderPersonalInformation()}
      {!isEmpty(membership?.activeState.sharingMembers) && renderLinkedMembersInformation()}
    </Box>
  );

  function renderGeneralInformation() {
    return (
      <Box borderRadius={1}>
        <Typography variant="bodyMediumBold">{fm({ id: 'common.generalInformation' })}</Typography>
        <MembershipDetailsRow
          title={<MembershipStatus membership={membership!} />}
          labelTranslationKey="common.lastStatusUpdate"
          toolTipText={fm({ id: 'budgetPlanMembership.lastStatusUpdateTooltip' })}
          titleVariant="bodyMedium"
        />
        <MembershipDetailsRow title={membershipStatus()} titleVariant="bodyMedium" labelTranslationKey="common.membershipStatus" />
        {membership!.isExistingMembership() && (
          <MembershipDetailsRow
            title={membership!.activationDate!.formatAsDateString()}
            labelTranslationKey="common.budgetPlanActivationDate"
            toolTipText={fm({ id: 'budgetPlanMembership.planActivationDateTooltip' })}
            titleVariant="bodyMedium"
          />
        )}
      </Box>
    );
  }

  function renderPersonalInformation() {
    return (
      <Box mt={3}>
        <Typography variant="bodyMediumBold">{fm({ id: 'common.personalInfo' })}</Typography>
        <MembershipDetailsRow title={membership!.firstName} titleVariant="bodyMedium" labelTranslationKey="common.firstName" />
        <MembershipDetailsRow title={membership!.lastName} titleVariant="bodyMedium" labelTranslationKey="common.lastName" />
        <MembershipDetailsRow title={membership!.email} titleVariant="bodyMedium" labelTranslationKey="common.emailAddress" />
      </Box>
    );
  }
  function renderLinkedMembersInformation() {
    return (
      <Box mt={3}>
        <Typography variant="bodyMediumBold">{intl.formatMessage({ id: 'common.linkedMembers' })}</Typography>
        <Stack spacing={1} mt={1}>
          {membership?.activeState.sharingMembers!.map((sharingMember) => (
            <Typography key={sharingMember.email} variant="bodyMedium">
              {sharingMember.email}
            </Typography>
          ))}
        </Stack>
      </Box>
    );
  }

  function membershipStatus() {
    return membership?.isLinked() ? (
      <Box display="flex" alignItems="center">
        <Checkmark fill={palette.success.main} height="16px" width="16px" />
        <Typography ml={1}>{fm({ id: 'budgetPlanMembership.linked' })}</Typography>
      </Box>
    ) : (
      <Box display="flex" alignItems="center">
        <Close fill={palette.alert.main} height="16px" width="16px" />
        <Typography ml={1}>{fm({ id: 'budgetPlanMembership.notLinked' })}</Typography>
      </Box>
    );
  }
};
