import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { FormControl, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { TableColumnType } from '../../models';

interface SingleSelectFieldProps {
  outlineLabel: string;
  value: TableColumnType;
  name: string;
  options: TableColumnType[];
  width?: string;
  sx?: React.CSSProperties;
  onChange?: any;
  disabled?: boolean;
  onOptionSelect: (option: TableColumnType) => void;
}

const SingleSelectField: React.FC<SingleSelectFieldProps> = ({ ...props }) => {
  const { outlineLabel, value, name, options, width = '100%', sx, onChange, disabled, onOptionSelect } = props;
  return (
    <>
      <FormControl sx={{ width: width }}>
        <InputLabel size="small" id={outlineLabel}>
          {outlineLabel}
        </InputLabel>
        <Select
          fullWidth
          size={'small'}
          sx={sx}
          labelId={outlineLabel}
          id={outlineLabel}
          value={value}
          label={name}
          onChange={onChange}
          disabled={disabled}
          renderValue={(selected) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selected.color && (
                <ListItemIcon>
                  <SquareRoundedIcon style={{ color: selected.color }} />
                </ListItemIcon>
              )}
              <ListItemText>{selected.label}</ListItemText>
            </div>
          )}
          input={<OutlinedInput label={outlineLabel} />}
        >
          {options.map((op: TableColumnType, index: number) => (
            <MenuItem key={index} value={op.value} onClick={() => onOptionSelect(op)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {op.color && (
                  <ListItemIcon>
                    <SquareRoundedIcon style={{ color: op.color }} />
                  </ListItemIcon>
                )}
                <ListItemText>{op.label}</ListItemText>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SingleSelectField;
