import { VehicleType } from '../common';
import { LastChangeAttemptStatus } from '../common/LastChangeAttemptStatus';

import { DepositUnit } from './details/DepositUnit';
import { VehicleSpendConstraintCategory } from './details/VehicleSpendConstraintCategory';
import { BudgetPlanMembershipStatus, BudgetPlanMembershipTransition } from './memberships';

export interface MspBudgetSpendConstraint {
  type: VehicleSpendConstraintCategory.Msp;
  ids: string[];
}

export interface VehicleTypeBudgetSpendConstraint {
  type: VehicleSpendConstraintCategory.VehicleType;
  values: VehicleType[];
}

export type VehicleSpendConstraint = MspBudgetSpendConstraint | VehicleTypeBudgetSpendConstraint;

export interface BudgetSpendConstraint {
  type: BudgetSpendConstraintType;
  value: number;
}

export interface SpendConstraints {
  vehicle: VehicleSpendConstraint[];
  budget?: BudgetSpendConstraint[];
}

export interface Fragments {
  amount: number;
  amountMultiplier: number;
  unit: DepositUnit;
  spendConstraints?: SpendConstraints[];
}

export enum BudgetSpendConstraintType {
  LimitPerTrip = 'LIMIT_PER_TRIP',
}

export interface BudgetPlanMembershipActiveState {
  status: BudgetPlanMembershipStatus;
  budgetAccountMembers?: BudgetAccountSharingMember[];
  subscribedAtEpochMillis?: number;
  unsubscribedAtEpochMillis?: number;
  createdAtEpochMillis: number;
}

export interface BudgetAccountSharingMember {
  subjectClaim: string;
  email: string;
  firstName: string;
  lastName: string;
  sharingSinceEpochMillis: number;
}

export interface BudgetPlanMembershipLastAttemptedStateTransition {
  transition: BudgetPlanMembershipTransition;
  status: LastChangeAttemptStatus;
  triggeredViaRetry: boolean;
  acknowledged: boolean;
  createdAtEpochMillis: number;
  subscribedAtEpochMillis?: number;
  unsubscribedAtEpochMillis?: number;
  updatedAtEpochMillis: number;
}

export interface BudgetPlanMembership {
  id: number;
  version: number;
  subjectClaim: string;
  firstName: string;
  lastName: string;
  email: string;
  activeState: BudgetPlanMembershipActiveState;
  lastAttemptedStateTransition: BudgetPlanMembershipLastAttemptedStateTransition;
}
