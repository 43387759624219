import { Form, useFormikContext, withFormik } from 'formik';

import { BudgetPlanModalCloseHandler } from '../BudgetPlanModal';
import { budgetPlanDetailsForm } from '../validation-schemas';

import { BudgetPlanDetailsFormValues, BudgetPlanFormSubmitHandler } from './BudgetPlanDetailsView';
import { BudgetPlanFormHeadingAndActionButtons } from './BudgetPlanFormHeadingAndActionButtons';
import { BudgetPlanFormInputs } from './BudgetPlanFormInputs';

const BudgetPlanDetailsForm = ({ onBudgetPlanModalClose }: BudgetPlanDetailsFormProps) => {
  const { handleSubmit, initialValues } = useFormikContext<BudgetPlanDetailsFormValues>();

  return (
    <Form onSubmit={handleSubmit}>
      <BudgetPlanFormHeadingAndActionButtons onCancelClick={onBudgetPlanModalClose} currentBudgetPlanFormValues={initialValues} />
      <BudgetPlanFormInputs />
    </Form>
  );
};

export const BudgetPlanDetailsFormWithFormik = withFormik<BudgetPlanDetailsFormProps, BudgetPlanDetailsFormValues>({
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: ({ initialValues }) => initialValues,
  handleSubmit: async (values, { props: { onBudgetPlanFormSubmit }, setSubmitting }) => {
    setSubmitting(true);
    try {
      await onBudgetPlanFormSubmit(values);
    } finally {
      setSubmitting(false);
    }
  },
  validationSchema: budgetPlanDetailsForm,
})(BudgetPlanDetailsForm);

interface BudgetPlanDetailsFormProps {
  initialValues: BudgetPlanDetailsFormValues;
  onBudgetPlanModalClose: BudgetPlanModalCloseHandler;
  onBudgetPlanFormSubmit: BudgetPlanFormSubmitHandler;
}
