import type { CSSProperties, ReactNode } from 'react';

interface FloatingBoxProps {
  boxStyling: CSSProperties;
  children: ReactNode;
}

export const FloatingBox = ({ boxStyling, children }: FloatingBoxProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'absolute',
        top: '24px',
        left: '24px',
        justifyContent: 'center',
        zIndex: 999,
        borderRadius: '8px',
        borderLeft: 'none',
        ...boxStyling,
      }}
    >
      {children}
    </div>
  );
};
