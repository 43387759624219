import { ReactNode } from 'react';

import { Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

import { InfoIconWithTooltip, TranslatedText } from '../../../../wmv-components';

export const MembershipDetailsRow = ({
  labelTranslationKey,
  title,
  titleVariant = 'bodyMediumBold',
  extraMessage = null,
  applyLabelStyles = true,
  toolTipText = '',
}: MembershipDetailsRowProps) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" mt={1}>
      <Box display="flex" alignItems="center" gap={0.5} sx={applyLabelStyles ? { minWidth: '220px', mr: 3 } : { mr: 1 }}>
        <TranslatedText
          translationKey={labelTranslationKey}
          variant="bodyMedium"
          color={theme.palette.baseLight.base76}
          typographyProps={{ component: 'p' }}
        />
        {toolTipText && <InfoIconWithTooltip title={toolTipText} placement="bottom-start" arrow />}
      </Box>
      <Box>
        <Typography variant={titleVariant}>{title}</Typography>
        {isString(extraMessage) && !isEmpty(extraMessage) ? (
          <Typography color={theme.palette.alert.main}>{extraMessage}</Typography>
        ) : (
          extraMessage
        )}
      </Box>
    </Box>
  );
};

interface MembershipDetailsRowProps {
  labelTranslationKey: string;
  extraMessage?: ReactNode;
  title: ReactNode;
  titleVariant?: TypographyProps['variant'];
  applyLabelStyles?: boolean;
  toolTipText?: string;
}
