import { IntlProvider } from 'react-intl';
// eslint-disable-next-line import/named
import { ConnectedProps, connect } from 'react-redux';

import { RootState } from '../state';
import { AvailableLanguage } from '../state/common/common.model';

import messages_de from './translations/de.json';
import messages_en from './translations/en.json';

interface Messages {
  [key: string]: Record<string, string>;
}

function mapStateToProps(state: RootState) {
  const lang = state.common.language;

  const messages: Messages = {
    de: messages_de,
    en: messages_en,
  };

  // TODO: check if language is available
  return { locale: lang || AvailableLanguage.EN, messages };
}

interface OwnProps {
  children: React.ReactNode;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps;

const ConnectedIntlProvider: React.FC<Props> = ({ locale, messages, children }) => {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

const connector = connect(mapStateToProps);

export default connector(ConnectedIntlProvider);
