import { useState } from 'react';
import type { MouseEvent } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { useMapFilterChange } from '../../hooks';
import { FloatingButtonType } from '../../models';
import { WmvButtonGroup, FloatingBox } from '../../wmv-components';

import { getSwitchButtonConfig } from './helpers';
import PlanningModeLayout from './PlanningModeLayout';
import ViewModeLayout from './ViewModeLayout';

const ZonesPage = () => {
  const intl = useIntl();
  const theme = useTheme();

  const [activeView, setActiveView] = useState(FloatingButtonType.ViewMode);
  const switchButtonConfig = getSwitchButtonConfig(intl);
  const { filters, anyFilterApplied, handleFilterChange, resetFilters } = useMapFilterChange();

  const handleSwitchModeButtonClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, switchModeButtonId: string) => {
    setActiveView(switchModeButtonId as FloatingButtonType);
  };

  return (
    <Box position="relative">
      <FloatingBox boxStyling={{ top: theme.spacing(2) }}>
        <WmvButtonGroup config={switchButtonConfig} activeButtonId={activeView} onClick={handleSwitchModeButtonClick} />
      </FloatingBox>
      {activeView === FloatingButtonType.ViewMode && (
        <ViewModeLayout
          filters={filters}
          onFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          anyFilterApplied={anyFilterApplied}
        />
      )}
      {activeView === FloatingButtonType.PlanningMode && (
        <PlanningModeLayout filters={filters} onFilterChange={handleFilterChange} anyFilterApplied={anyFilterApplied} />
      )}
    </Box>
  );
};

export default ZonesPage;
