import { ReactElement } from 'react';

import { Stack, Typography, Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { LastChangeAttemptStatusInfo } from '../../../../components/LastChangeAttemptStatusInfo';
import { VehicleTypeHelper } from '../../../../models';
import { VehiclePricingDto } from '../../../../services/vehicle-fee-pricing';
import { VehicleFeePricingActiveStateDto } from '../../../../services/vehicle-fee-pricing/VehicleFeePricingActiveState';
import { VehicleFeePricingLastChangeAttemptDto } from '../../../../services/vehicle-fee-pricing/VehicleFeePricingLastChangeAttemptDto';
import { changingLanguageSelector } from '../../../../state';

export function useMakeVehiclePricingTableColumns() {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const langCode = useSelector(changingLanguageSelector) as 'en' | 'de';

  return [
    {
      Header: formatMessage({ id: `map.vehicleInfo.provider` }),
      accessor: 'msp.name[langCode]',
      Cell: ({ row: { original: vehicleFeePricing } }: RowData) => (
        <Typography variant="bodySmall" px={0.5}>
          {vehicleFeePricing.msp.name[langCode]}
        </Typography>
      ),
    },
    {
      Header: formatMessage({ id: `zoneControls.filters.vehicleType` }),
      accessor: 'vehicleType.length',
      Cell: ({ row: { original: vehicleFeePricing } }: RowData) => (
        <Typography variant="bodySmall" px={0.5}>
          {VehicleTypeHelper.metadata(vehicleFeePricing.vehicleType).displayText}
        </Typography>
      ),
    },
    {
      Header: formatMessage({ id: `common.vehicleModel` }),
      accessor: 'modelName',
      Cell: ({ row: { original: vehicleFeePricing } }: RowData) => {
        return (
          <Typography variant="bodySmall" color={theme.palette.baseLight.base76} px={0.5}>
            {vehicleFeePricing.modelName}
          </Typography>
        );
      },
    },
    {
      Header: formatMessage({ id: `common.pricingBreakDown` }),
      accessor: 'activeState.activationFeeAmount()?.amount.value',
      Cell: ({ row: { original: vehicleFeePricing } }: RowData) => {
        const pricingActiveState = vehicleFeePricing.activeState
          ? vehicleFeePricing.activeState
          : vehicleFeePricing.lastAttemptedStateChange;

        return renderChips(pricingActiveState);
      },
    },
    {
      Header: formatMessage({ id: `common.lastStatusUpdate` }),
      accessor: 'lastAttemptedStateChange.status',
      Cell: ({ row: { original: vehicleFeePricing } }: RowData) => {
        return <LastChangeAttemptStatusInfo status={vehicleFeePricing.lastAttemptedStateChange.status} />;
      },
    },
  ];

  function renderChips(pricingActiveState: VehicleFeePricingActiveStateDto | VehicleFeePricingLastChangeAttemptDto) {
    return (
      <Stack direction="row" spacing={1}>
        {renderChipWithTooltip(
          formatMessage({ id: 'common.activation' }),
          pricingActiveState.activationFeeAmount()!.amount!.value!.toCents()!,
          formatMessage({ id: 'common.activation' }),
          <Chip
            label={pricingActiveState.activationFeeAmount()!.amount.value!.toCents()}
            variant="outlined"
            color="purple"
            sx={{
              '&.MuiChip-root': {
                '&&:hover': {
                  backgroundColor: theme.palette.purple.dark,
                  color: theme.palette.baseLight.base0,
                  borderColor: 'transparent',
                },
              },
            }}
          />,
        )}
        {renderChipWithTooltip(
          formatMessage({ id: 'common.activeMinutes' }),
          pricingActiveState.activeDurationFeeAmount()!.amount.value!.toCents(),
          formatMessage({ id: 'common.minute' }),
          <Chip
            label={pricingActiveState.activeDurationFeeAmount()?.amount.value!.toCents()}
            variant="outlined"
            sx={{
              color: theme.palette.blue.dark,
              borderColor: theme.palette.blue.dark,
              '&.MuiChip-root': {
                '&&:hover': {
                  backgroundColor: theme.palette.blue.main,
                  color: theme.palette.white.main,
                  borderColor: 'transparent',
                },
              },
            }}
          />,
        )}
        {renderChipWithTooltip(
          formatMessage({ id: 'common.pausedMinutes' }),
          pricingActiveState.pausedDurationFeeAmount()!.amount.value!.toCents(),
          formatMessage({ id: 'common.minute' }),
          <Chip
            label={pricingActiveState.pausedDurationFeeAmount()!.amount.value!.toCents()}
            variant="outlined"
            color="blue"
            sx={{
              '&.MuiChip-root': {
                '&&:hover': {
                  backgroundColor: theme.palette.blue.dark,
                  color: theme.palette.baseLight.base0,
                  borderColor: 'transparent',
                },
              },
            }}
          />,
        )}
      </Stack>
    );
  }

  function renderChipWithTooltip(feeName: string, feeAmount: string, feeUnit: string, children: ReactElement) {
    return (
      <Tooltip
        title={
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="bodySmall">{feeName}</Typography>
            <Typography variant="bodySmall">
              {feeAmount}/ {feeUnit}
            </Typography>
          </Box>
        }
        slotProps={{ tooltip: { style: { borderRadius: theme.spacing(1), padding: theme.spacing(1) } } }}
        placement="bottom"
        arrow
      >
        {children}
      </Tooltip>
    );
  }
}

interface RowData {
  row: {
    original: VehiclePricingDto;
  };
}
