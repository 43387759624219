import type { Theme } from '@mui/material/styles';

export const containerStyles = (theme: Theme) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: theme.palette.white.main,
  borderRadius: theme.spacing(0.5),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const contentStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(2),
});

export const actionButtonStyles = (theme: Theme) => ({
  textTransform: 'capitalize',
  width: 140,
});
