import { AggregatedVehicleMetadata } from '.';

import { themeOptions } from '../../init-setup/ThemeOptions';
import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum AggregatedVehicleUsageStatus {
  OnTrip = 'ON_TRIP',
  Available = 'AVAILABLE',
  NonOperational = 'NON_OPERATIONAL',
}

export namespace AggregatedVehicleUsageStatusHelper {
  const { formatMessage } = intl;
  const statusChangeUnderivedMetadata = new Map<AggregatedVehicleUsageStatus, AggregatedVehicleMetadata[]>([
    [
      AggregatedVehicleUsageStatus.Available,
      [
        {
          color: themeOptions.palette.warning.main,
          usageStatusTranslation: formatMessage({ id: 'operator.statusChange.vehicleStatus.nonOperational' }),
          futureStatus: AggregatedVehicleUsageStatus.NonOperational,
        },
      ],
    ],
    [
      AggregatedVehicleUsageStatus.NonOperational,
      [
        {
          color: themeOptions.palette.success.main,
          usageStatusTranslation: formatMessage({ id: 'operator.statusChange.vehicleStatus.available' }),
          futureStatus: AggregatedVehicleUsageStatus.Available,
        },
      ],
    ],
  ]);

  export function metadata(status: AggregatedVehicleUsageStatus): AggregatedVehicleMetadata[] {
    const underivedMetadata = statusChangeUnderivedMetadata.get(status);
    if (!underivedMetadata) {
      throw Error(`
            Specified Status: ${status} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return [...underivedMetadata];
  }
  export function toString(status: AggregatedVehicleUsageStatus): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(AggregatedVehicleUsageStatus, status);
  }
}
