import { useState } from 'react';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Divider, IconButton, List } from '@mui/material';

import FilterProfiles from '../../components/FilterProfiles';
import NewFilterProfile from '../../components/FilterProfiles/NewFilterProfile';

export enum FilterView {
  SAVED_FILTERS = 'SAVED_FILTERS',
  CREATE_NEW_FILTER = 'CREATE_NEW_FILTER',
}

export interface TableFiltersProps {
  open: boolean;
  handleOpenDrawer: (open: boolean) => void;
  style: React.CSSProperties | undefined;
}

const TableFilters: React.FC<TableFiltersProps> = ({ open, handleOpenDrawer, style }) => {
  const [filterView, setFilterView] = useState<FilterView | null>(null);
  const [filterSetName, setFilterSetName] = useState<string | null>(null);

  const changeFilterView = (newView: FilterView, filterSetName: string | null = null) => {
    if (filterSetName) {
      setFilterSetName(filterSetName);
    } else {
      setFilterSetName(null);
    }
    setFilterView(newView);
  };

  const controls = [
    {
      value: FilterView.SAVED_FILTERS, //filter
      icon: <FilterAltOutlinedIcon style={{ width: '32px', height: '32px' }} />,
      // did not work therefore commented out
      onClick: () => {
        setFilterView(FilterView.SAVED_FILTERS);
        if (!open) handleOpenDrawer(true);
      },
    },
    {
      value: FilterView.CREATE_NEW_FILTER,
      icon: <TuneOutlinedIcon style={{ width: '32px', height: '32px' }} />,
      // did not work therefore commented out
      onClick: () => {
        setFilterSetName(null);
        setFilterView(FilterView.CREATE_NEW_FILTER);
        if (!open) handleOpenDrawer(true);
      },
    },
  ];
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        border: '1px solid #2F2E4114',
        ...style,
      }}
    >
      {open &&
        (filterView === FilterView.SAVED_FILTERS ? (
          <FilterProfiles
            handleClose={() => {
              handleOpenDrawer(false);
            }}
            changeFilterView={changeFilterView}
          />
        ) : (
          <NewFilterProfile
            filterSetName={filterSetName}
            handleClose={() => {
              handleOpenDrawer(false);
            }}
          />
        ))}
      <List style={{ borderLeft: '1px solid #2F2E4114', padding: 0 }}>
        {controls.map(({ value, icon, onClick }) => (
          <div key={value}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: 100,
              }}
            >
              {filterView === value ? (
                <IconButton key={value} onClick={onClick} disableRipple sx={{ color: '#6DB286' }}>
                  {icon}
                </IconButton>
              ) : (
                <IconButton key={value} onClick={onClick} disableRipple>
                  {icon}
                </IconButton>
              )}
            </div>
            <Divider />
          </div>
        ))}
      </List>
    </div>
  );
};

export default TableFilters;
