import type { Theme } from '@mui/material/styles';

export const sidebarHeaderStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.white.main,
  padding: '16px 0px',
  width: '268px',
  minWidth: '268px',
});

export const sidebarContentStyles = (theme: Theme) => ({
  padding: '16px',
  gap: '5px',
});

export const tabPanelContainerStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.white.main,
  flex: 1,
  width: '718px',
  height: '100%',
  overflow: 'auto',
});

export const dividerStyles = (theme: Theme) => ({
  borderColor: theme.palette.dark.shade8,
  marginLeft: '8px',
  marginRight: '8px',
});

export const separtorStyles = (theme: Theme) => ({
  marginRight: '20px',
});

export const customerInfoTabPanelStyles = (theme: Theme) => ({
  height: '100%',
  padding: theme.spacing(2),
});
