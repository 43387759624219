import { createContext, Dispatch, ReactNode, useReducer, useContext } from 'react';

import { useSelector } from 'react-redux';

import { BudgetPlanTab } from '../../../../models';
import { BudgetPlanDto } from '../../../../services/budget-plan';
import { budgetPlansSelector } from '../../../../state';

const initialBudgetPlanState: BudgetPlanState = {
  selectedPlanId: null,
  budgetPlanModalVisible: false,
  selectedFailedPlanId: null,
  activeTab: BudgetPlanTab.PlanDetails,
};

const BudgetPlanContext = createContext<BudgetPlanState>(initialBudgetPlanState);
const BudgetPlanDispatchContext = createContext<Dispatch<BudgetPlanAction> | null>(null);

export function BudgetPlanProvider({ children }: SelectedBudgetPlanProviderProps) {
  const [budgetPlanState, dispatch] = useReducer(budgetPlanReducer, initialBudgetPlanState);
  return (
    <BudgetPlanContext.Provider value={budgetPlanState!}>
      <BudgetPlanDispatchContext.Provider value={dispatch}>{children}</BudgetPlanDispatchContext.Provider>
    </BudgetPlanContext.Provider>
  );
}

export function useBudgetPlanState() {
  return useContext(BudgetPlanContext)!;
}

export function useBudgetPlanDispatch() {
  return useContext(BudgetPlanDispatchContext)!;
}

export function useSelectedBudgetPlanDetails() {
  const budgetPlans: BudgetPlanDto[] = useSelector(budgetPlansSelector);
  const budgetPlanState = useBudgetPlanState();
  return budgetPlans.find((budgetPlan) => budgetPlan.id === budgetPlanState.selectedPlanId);
}

export function useSelectedBudgetPlanId() {
  const budgetPlanState = useBudgetPlanState();
  return budgetPlanState.selectedPlanId;
}

function budgetPlanReducer(budgetPlanState: BudgetPlanState, action: BudgetPlanAction): BudgetPlanState {
  switch (action.type) {
    case BudgetPlanActionType.UpdateSelectedBudgetPlanId:
      return {
        ...budgetPlanState,
        selectedPlanId: action.payload,
      };
    case BudgetPlanActionType.UpdateSelectedFailedPlanId:
      return {
        ...budgetPlanState,
        selectedFailedPlanId: action.payload,
      };
    case BudgetPlanActionType.UpdateBudgetPlanFormModalVisibility:
      return {
        ...budgetPlanState,
        budgetPlanModalVisible: action.payload,
      };
    case BudgetPlanActionType.UpdateActiveTab:
      return {
        ...budgetPlanState,
        activeTab: action.payload,
      };
    default:
      return budgetPlanState;
  }
}

interface SelectedBudgetPlanProviderProps {
  children: ReactNode;
}

interface BudgetPlanState {
  selectedPlanId: number | null;
  budgetPlanModalVisible: boolean;
  selectedFailedPlanId: number | null;
  activeTab: BudgetPlanTab;
}

export enum BudgetPlanActionType {
  UpdateSelectedBudgetPlanId = 'UPDATE_SELECTED_BUDGET_PLAN_ID',
  UpdateSelectedFailedPlanId = 'UPDATE_SELECTED__FAILED_BUDGET_PLAN_ID',
  UpdateBudgetPlanFormModalVisibility = 'UPDATE_BUDGET_PLAN_FORM_VISIBILITY',
  UpdateActiveTab = 'UPDATE_ACTIVE_TAB',
}

export type BudgetPlanAction =
  | { type: BudgetPlanActionType.UpdateSelectedBudgetPlanId; payload: number | null }
  | { type: BudgetPlanActionType.UpdateSelectedFailedPlanId; payload: number | null }
  | { type: BudgetPlanActionType.UpdateBudgetPlanFormModalVisibility; payload: boolean }
  | { type: BudgetPlanActionType.UpdateActiveTab; payload: BudgetPlanTab };
