import { useRef } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { cancelLastFailedAttempt, retryFailedMembershipStateTransition } from '../../../../utils/queries';
import { ProgressAwareButton } from '../../../../wmv-components';
import { HelperTextType } from '../../../../wmv-components/HelperText';
import { MembershipDetailsActionType, useMembershipDetailsDispatch, useMembershipDetailsState, useSelectedBudgetPlanId } from '../contexts';
import { useSnackbarWhileTransition } from '../hooks';
import { useGetBudgetPlanMemberships } from '../hooks/useGetBudgetPlanMemberships';

export const MembershipDetailsActionButtons = ({ onUnlinkMembershipModalClose }: UnlinkingActionButtonsProps) => {
  const { formatMessage: fm } = useIntl();

  const helperTextRef = useRef<string>('');
  const helperTextTypeRef = useRef<HelperTextType>('info');

  const selectedPlanId = useSelectedBudgetPlanId();
  const membershipDetailsDispatch = useMembershipDetailsDispatch();
  const membershipDetailsState = useMembershipDetailsState();

  const getBudgetPlanMemberships = useGetBudgetPlanMemberships();
  const { snackbarOnTransitionRetryApiSuccess, snackbarOnDiscardFailedTransitionApiSuccess } = useSnackbarWhileTransition();
  const { membership, retryStateTransitionApiStatus, discardFailedStateTransitionApiStatus } = membershipDetailsState;

  const enableTransitionRetry = membership?.isLinkingFailed() || membership?.isUnlinkingFailed() || membership?.isActive();
  return (
    <Box display="flex" mt={3} gap={4}>
      <ProgressAwareButton
        size="large"
        variant="secondaryLight"
        onClick={onCancelOrCloseClick}
        loading={discardFailedStateTransitionApiStatus.isPending()}
        loadingButtonProps={{ sx: { flex: 1 } }}
        disabled={discardFailedStateTransitionApiStatus.isPending() || retryStateTransitionApiStatus.isPending()}
      >
        <Typography variant="bodyMediumBold">
          {membership?.isLastAttemptedStateTransitionFailed() ? fm({ id: 'actions.cancel' }) : fm({ id: 'common.close' })}
        </Typography>
      </ProgressAwareButton>
      <ProgressAwareButton
        size="large"
        variant={membership?.isLinked() ? 'alert' : 'contained'}
        onClick={handleFailedTransitionRetry}
        disabled={!enableTransitionRetry}
        loading={retryStateTransitionApiStatus.isPending()}
        loadingButtonProps={{ sx: { flex: 1 } }}
      >
        <Typography variant="bodyMediumBold">
          {membership?.isLinked() ? fm({ id: 'budgetPlanMembership.unlinkUser' }) : fm({ id: 'common.retryToLink' })}
        </Typography>
      </ProgressAwareButton>
    </Box>
  );

  async function onCancelOrCloseClick() {
    await closeModalOrDiscardFailedTransitionRequest(membership?.isLastAttemptedStateTransitionFailed() ? 'cancelRequest' : 'closeModal');
  }

  async function closeModalOrDiscardFailedTransitionRequest(cancelClickReason: UnlinkModalCancelClickReason) {
    if (cancelClickReason === 'closeModal') {
      helperTextRef.current = '';
      helperTextTypeRef.current = 'info';
      onUnlinkMembershipModalClose();
    }
    if (cancelClickReason === 'cancelRequest') {
      try {
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.CancelLastFailedAttemptRequestStart,
        });
        await cancelLastFailedAttempt(selectedPlanId!, membership!.id);
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.CancelLastFailedAttemptRequestSuccess,
        });
        onUnlinkMembershipModalClose();
        getBudgetPlanMemberships(selectedPlanId!);
        snackbarOnDiscardFailedTransitionApiSuccess(membership!);
      } catch (e) {
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.CancelLastFailedAttemptRequestFailure,
        });
      }
    }
  }

  async function handleFailedTransitionRetry() {
    if (membership!.isUnlinkingFailed() || membership!.isLinkingFailed()) {
      try {
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.FailedMembershipTransitionRetryStart,
        });
        const newMembership = await retryFailedMembershipStateTransition(selectedPlanId!, membership!.id);
        const newMembershipDto = new BudgetPlanMembershipDto(newMembership);
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.FailedMembershipTransitionRetrySuccess,
          payload: newMembershipDto,
        });
        onUnlinkMembershipModalClose();
        snackbarOnTransitionRetryApiSuccess(newMembershipDto);
        getBudgetPlanMemberships(selectedPlanId!);
      } catch (e) {
        membershipDetailsDispatch({
          type: MembershipDetailsActionType.FailedMembershipTransitionRetryFailure,
        });
      }
    } else {
      membershipDetailsDispatch({
        type: MembershipDetailsActionType.OpenUnlinkConfirmationModal,
      });
    }
  }
};

export type UnlinkModalCancelClickReason = 'cancelRequest' | 'closeModal';
interface UnlinkingActionButtonsProps {
  onUnlinkMembershipModalClose: () => void;
}
