import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';

import { LoadingStatus } from '../../../../models';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { ApiStatusService } from '../../../../services/ui';

const initialMembershipDetailsState: MembershipDetailsState = {
  membershipDetailsApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
  membership: null,
  error: '',
  showUnlinkConfirmationModal: false,
  unlinkingApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
  retryStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
  discardFailedStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Idle),
};

const MembershipDetailsContext = createContext<MembershipDetailsState>(initialMembershipDetailsState);
const MembershipDetailsContextDispatchContext = createContext<Dispatch<MembershipDetailsAction> | null>(null);

export function MembershipDetailsProvider({ children }: MembershipDetailsProviderProps) {
  const [membershipDetailsState, dispatch] = useReducer(membershipDetailsReducer, initialMembershipDetailsState);

  return (
    <MembershipDetailsContextDispatchContext.Provider value={dispatch!}>
      <MembershipDetailsContext.Provider value={membershipDetailsState}>{children}</MembershipDetailsContext.Provider>
    </MembershipDetailsContextDispatchContext.Provider>
  );
}

export function useMembershipDetailsState() {
  return useContext(MembershipDetailsContext)!;
}

export function useMembershipDetailsDispatch() {
  return useContext(MembershipDetailsContextDispatchContext)!;
}

function membershipDetailsReducer(membershipDetailsState: MembershipDetailsState, action: MembershipDetailsAction): MembershipDetailsState {
  switch (action.type) {
    case MembershipDetailsActionType.MembershipDetailsFetchStart:
      return {
        ...membershipDetailsState,
        membershipDetailsApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
        membership: null,
        error: '',
      };
    case MembershipDetailsActionType.MembershipDetailsFetchSuccess:
      return {
        ...membershipDetailsState,
        membershipDetailsApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
        membership: action.payload,
      };
    case MembershipDetailsActionType.MembershipDetailsFetchFailure:
      return {
        ...membershipDetailsState,
        membershipDetailsApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
        membership: null,
      };
    case MembershipDetailsActionType.UpdateMembershipDetails:
      return {
        ...membershipDetailsState,
        membership: action.payload,
      };
    case MembershipDetailsActionType.OpenUnlinkConfirmationModal:
      return {
        ...membershipDetailsState,
        showUnlinkConfirmationModal: true,
      };
    case MembershipDetailsActionType.CloseUnlinkConfirmationModal:
      return {
        ...membershipDetailsState,
        showUnlinkConfirmationModal: false,
      };
    case MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestStart:
      return {
        ...membershipDetailsState,
        unlinkingApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
      };
    case MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestSuccess:
      return {
        ...membershipDetailsState,
        unlinkingApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
        membership: action.payload,
      };
    case MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestFailure:
      return {
        ...membershipDetailsState,
        unlinkingApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
      };
    case MembershipDetailsActionType.FailedMembershipTransitionRetryStart:
      return {
        ...membershipDetailsState,
        retryStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
      };
    case MembershipDetailsActionType.FailedMembershipTransitionRetrySuccess:
      return {
        ...membershipDetailsState,
        retryStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
        membership: action.payload,
      };
    case MembershipDetailsActionType.FailedMembershipTransitionRetryFailure:
      return {
        ...membershipDetailsState,
        retryStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
      };
    case MembershipDetailsActionType.CancelLastFailedAttemptRequestStart:
      return {
        ...membershipDetailsState,
        discardFailedStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Pending),
      };
    case MembershipDetailsActionType.CancelLastFailedAttemptRequestSuccess:
      return {
        ...membershipDetailsState,
        discardFailedStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Succeeded),
      };
    case MembershipDetailsActionType.CancelLastFailedAttemptRequestFailure:
      return {
        ...membershipDetailsState,
        discardFailedStateTransitionApiStatus: ApiStatusService.getInstance(LoadingStatus.Failed),
      };
    case MembershipDetailsActionType.ResetMembershipState:
      return initialMembershipDetailsState;
    default:
      return initialMembershipDetailsState;
  }
}

interface MembershipDetailsProviderProps {
  children: ReactNode;
}

export interface MembershipDetailsState {
  membershipDetailsApiStatus: ApiStatusService;
  membership: BudgetPlanMembershipDto | null;
  error: string;
  showUnlinkConfirmationModal: boolean;
  unlinkingApiStatus: ApiStatusService;
  retryStateTransitionApiStatus: ApiStatusService;
  discardFailedStateTransitionApiStatus: ApiStatusService;
}

export enum MembershipDetailsActionType {
  MembershipDetailsFetchStart = 'MEMBERSHIP_DETAILS_FETCH_START',
  MembershipDetailsFetchSuccess = 'MEMBERSHIP_DETAILS_FETCH_SUCCESS',
  MembershipDetailsFetchFailure = 'MEMBERSHIP_DETAILS_FETCH_FAILURE',
  UpdateMembershipDetails = 'UPDATE_MEMBERSHIP_DETAILS',
  ResetMembershipState = 'RESET_MEMBERSHIP_STATE',
  OpenUnlinkConfirmationModal = 'OPEN_UNLINK_CONFIRMATION_MODAL',
  CloseUnlinkConfirmationModal = 'CLOSE_UNLINK_CONFIRMATION_MODAL',
  UnlinkMembershipTransitionApiRequestStart = 'UNLINK_MEMBERSHIP_API_REQUEST_START',
  UnlinkMembershipTransitionApiRequestSuccess = 'UNLINK_MEMBERSHIP_API_REQUEST_SUCCESS',
  UnlinkMembershipTransitionApiRequestFailure = 'UNLINK_MEMBERSHIP_API_REQUEST_FAILURE',
  FailedMembershipTransitionRetryStart = 'FAILED_MEMBERSHIP_TRANSITION_RETRY_START',
  FailedMembershipTransitionRetrySuccess = 'FAILED_MEMBERSHIP_TRANSITION_RETRY_SUCCESS',
  FailedMembershipTransitionRetryFailure = 'FAILED_MEMBERSHIP_TRANSITION_RETRY_FAILURE',
  CancelLastFailedAttemptRequestStart = 'CANCEL_LAST_FAILED_ATTEMPT_REQUEST_START',
  CancelLastFailedAttemptRequestSuccess = 'CANCEL_LAST_FAILED_ATTEMPT_REQUEST_SUCCESS',
  CancelLastFailedAttemptRequestFailure = 'CANCEL_LAST_FAILED_ATTEMPT_REQUEST_FAILURE',
}

export type MembershipDetailsAction =
  | { type: MembershipDetailsActionType.MembershipDetailsFetchStart }
  | { type: MembershipDetailsActionType.MembershipDetailsFetchSuccess; payload: BudgetPlanMembershipDto | null }
  | { type: MembershipDetailsActionType.MembershipDetailsFetchFailure }
  | { type: MembershipDetailsActionType.UpdateMembershipDetails; payload: BudgetPlanMembershipDto | null }
  | { type: MembershipDetailsActionType.ResetMembershipState }
  | { type: MembershipDetailsActionType.OpenUnlinkConfirmationModal }
  | { type: MembershipDetailsActionType.CloseUnlinkConfirmationModal }
  | { type: MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestStart }
  | { type: MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestSuccess; payload: BudgetPlanMembershipDto }
  | { type: MembershipDetailsActionType.UnlinkMembershipTransitionApiRequestFailure }
  | { type: MembershipDetailsActionType.FailedMembershipTransitionRetryStart }
  | { type: MembershipDetailsActionType.FailedMembershipTransitionRetrySuccess; payload: BudgetPlanMembershipDto }
  | { type: MembershipDetailsActionType.FailedMembershipTransitionRetryFailure }
  | { type: MembershipDetailsActionType.CancelLastFailedAttemptRequestStart }
  | { type: MembershipDetailsActionType.CancelLastFailedAttemptRequestSuccess }
  | { type: MembershipDetailsActionType.CancelLastFailedAttemptRequestFailure };
