import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';
import { ListItemConfig } from '../common';

export enum CustomerInformationTab {
  CustomerDetails = 'CUSTOMER_DETAILS',
  TripHistory = 'TRIP_HISTORY',
  Budget = 'BUDGET',
  DriverLicence = 'DRIVER_LICENCE',
  Promotions = 'PROMOTIONS',
  Tickets = 'TICKETS',
}

interface CustomerInformationTabMetadata {
  displayText: string;
}

const metaData = new Map<CustomerInformationTab, CustomerInformationTabMetadata>([
  [
    CustomerInformationTab.CustomerDetails,
    {
      displayText: intl.formatMessage({ id: 'customerInformationModal.tab.customerDetails' }),
    },
  ],
  [
    CustomerInformationTab.TripHistory,
    {
      displayText: intl.formatMessage({ id: 'customerInformationModal.tab.tripHistory' }),
    },
  ],
  [
    CustomerInformationTab.Promotions,
    {
      displayText: intl.formatMessage({ id: 'customerInformationModal.tab.promotions' }),
    },
  ],
  [
    CustomerInformationTab.Budget,
    {
      displayText: intl.formatMessage({ id: 'common.budget' }),
    },
  ],
  [
    CustomerInformationTab.Tickets,
    {
      displayText: intl.formatMessage({ id: 'customerInformationModal.tab.tickets' }),
    },
  ],
  [
    CustomerInformationTab.DriverLicence,
    {
      displayText: intl.formatMessage({ id: 'customerInformationModal.tab.driversLicence' }),
    },
  ],
]);

export namespace CustomerInformationTabHelper {
  export function displayText(tab: CustomerInformationTab) {
    return metaData.get(tab)?.displayText;
  }

  export function allValues(): CustomerInformationTab[] {
    return EnumWithStringValue.enumToValues(CustomerInformationTab);
  }

  export function allLabelAndValues(): ListItemConfig<CustomerInformationTab>[] {
    return allValues().map((customerInfoTab) => {
      return {
        value: customerInfoTab,
        label: displayText(customerInfoTab)!,
      };
    });
  }
}
