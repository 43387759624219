import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ApiRequestStatus } from '../../../models';
import { useAppDispatch } from '../../../state';
import { showAlertAction } from '../../../state/app';
import {
  deleteZoneApiStatusSelector,
  deleteZoneAsyncThunkAction,
  deleteZoneErrorSelector,
  resetErrorAction,
} from '../../../state/zones-management';
import { ConfirmationModal, ConfirmationModalCloseReason } from '../../../wmv-components';

interface DeleteZoneConfirmationModalProps {
  open: boolean;
  handleClose: (reason: DeleteZoneConfirmationModalCloseReason) => void;
  zoneId: number;
  name: string;
  startDate: Dayjs;
  endDate: Dayjs | null;
}
export type DeleteZoneConfirmationModalCloseReason = ConfirmationModalCloseReason | 'deleteZoneSuccess';

export const DeleteZoneConfirmationModal = ({ open, handleClose, zoneId, name, startDate, endDate }: DeleteZoneConfirmationModalProps) => {
  const { palette } = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const deleteZoneApiStatus = useSelector(deleteZoneApiStatusSelector);
  const deleteZoneError = useSelector(deleteZoneErrorSelector);

  const handleConfirmClick = async () => {
    try {
      await dispatch(deleteZoneAsyncThunkAction(zoneId as number)).unwrap();
      handleClose('deleteZoneSuccess');
      dispatch(showAlertAction({ message: formatMessage({ id: 'alert.zones.delete.success' }) }));
    } catch (error) {
      // TODO: Dont eat exception
      console.log('Delete zone', error);
    }
  };

  const handleCancelClick = (reason: ConfirmationModalCloseReason) => {
    if (deleteZoneError) {
      dispatch(resetErrorAction('deleteZone'));
    }
    handleClose(reason);
  };

  let endDateString = '';
  if (endDate) {
    endDateString = endDate.formatAsDateString();
  } else {
    endDateString = formatMessage({ id: 'deleteZone.indefinite' });
  }

  return (
    <ConfirmationModal
      open={open}
      handleClose={handleCancelClick}
      handleConfirm={handleConfirmClick}
      loading={deleteZoneApiStatus === ApiRequestStatus.Pending}
      title={formatMessage({ id: 'deleteZone.title' })}
      confirmBtnText={formatMessage({ id: 'dialog.confirmDelete.apply' })}
      confirmBtnColor="error"
      error={deleteZoneError && formatMessage({ id: 'systemStatus.error.zoneDelete' })}
    >
      <Box>
        <Typography variant="bodySmall" color={palette.baseLight.base60}>
          {formatMessage({ id: 'zone.name' })}:{' '}
        </Typography>
        <Typography variant="bodySmall">{name}</Typography>
      </Box>
      <Box>
        <Typography variant="bodySmall" color={palette.baseLight.base60}>
          {formatMessage({ id: 'common.valid' })}:{' '}
        </Typography>
        <Typography variant="bodySmall">
          {startDate!.formatAsDateString()} – {endDateString}
        </Typography>
      </Box>
    </ConfirmationModal>
  );
};
