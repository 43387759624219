import { PaymentStatus, Unit, VehicleType, Currency, UsageStatus } from '../common';

export interface UserTripVehicle {
  qrCode: string;
  type: VehicleType;
  provider: UserTripVehicleProvider;
  model: UserTripVehicleModel;
}

export interface UserTripVehicleProvider {
  id: number;
  name: string;
}

export interface UserTripVehicleModel {
  id: number;
  name: string;
}

export interface UserTripInvoice {
  status: UsageStatus;
  url: string;
}

export interface UserTripActivityLogDetails {
  event: UserTripActivityLogEventType;
  terminalState: boolean;
  timestamp: number;
  updatedBy: UpdatedBy;
}

export interface UserTripDurationDetails {
  breakdown: UserTripDurationBreakdownDetails[];
}

export interface UserTripPaymentDetails {
  id: number;
  status: PaymentStatus;
  amount: UserTripPaymentAmountDetails;
}

export interface UserTripPaymentAmountDetails {
  budget: UserTripPaymentAmountBudgetDetails;
  money: UserTripPaymentAmountMoneyDetails;
  breakdown: UserTripPaymentAmountBreakdownDetails[];
}

export interface UserTripPaymentAmountBudgetDetails {
  status: PaymentStatus;
  amount: number;
  unit: Unit;
  capturedAt: number;
  linkedAccountSubjectClaim: string;
  linkedAccountOwnerName: string;
}

export interface UserTripPaymentAmountMoneyDetails {
  status: PaymentStatus;
  amount: number;
  currency: Currency;
  paidAt: number;
  paymentMethod: PaymentMethod;
}

export interface UserTripPaymentAmountBreakdownDetails {
  type: UserTripPaymentAmountType;
  subType?: UserTripPaymentAmountSubType;
  amount: number;
  metadata: UserTripPaymentAmountBreakdownMetadata;
}

export interface UserTripPaymentAmountBreakdownMetadata {
  schedule?: UserTripPaymentAmountSchedule;
  discountCode?: string;
}

export interface UserTripDurationBreakdownDetails {
  startedAt: number;
  actualDurationSeconds: number;
  billableDurationSeconds: number;
  type: UserTripDurationBreakdownType;
}

export interface UpdatedBy {
  id: string;
  name: string;
}

export interface PaymentMethod {
  type: string;
  brand: string;
  cardDetails: string;
}

export interface UserTripPaymentAmountSchedule {
  type: UserTripPaymentAmountScheduleType;
  value: number;
}

export enum UserTripPaymentAmountScheduleType {
  Fixed = 'FIXED',
  PerMinuteRate = 'PER_MINUTE_RATE',
}

export enum UserTripStatus {
  Init = 'INIT',
  StartRequested = 'START_REQUESTED',
  Started = 'STARTED',
  PauseRequested = 'PAUSE_REQUESTED',
  Paused = 'PAUSED',
  ResumeRequested = 'PAUSE_REQUESTED',
  Resumed = 'RESUMED',
  StopRequested = 'STOP_REQUESTED',
  Stopped = 'STOPPED',
  ForceStopRequested = 'FORCE_STOP_REQUESTED',
  ForceStopped = 'FORCE_STOPPED',
}

export enum UserTripPaymentAmountType {
  UsageCharges = 'USAGE_CHARGES',
  Discount = 'DISCOUNT',
  Tax = 'TAX',
}

export enum UserTripPaymentAmountSubType {
  ActivationFee = 'ACTIVATION_FEE',
  ActiveDuration = 'ACTIVE_DURATION',
  PausedDuration = 'PAUSED_DURATION',
  Vat = 'VAT',
}

export enum UserTripDurationBreakdownType {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
}

export enum UserTripActivityLogEventType {
  StartRequested = 'START_REQUESTED',
  Started = 'STARTED',
  PauseRequested = 'PAUSE_REQUESTED',
  Paused = 'PAUSED',
  ResumeRequested = 'PAUSE_REQUESTED',
  Resumed = 'RESUMED',
  StopRequested = 'STOP_REQUESTED',
  Stopped = 'STOPPED',
  ForceStopRequested = 'FORCE_STOP_REQUESTED',
  ForceStopped = 'FORCE_STOPPED',
  PaymentRequired = 'PAYMENT_REQUIRED',
  PaymentReceived = 'PAYMENT_RECEIVED',
}
