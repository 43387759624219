import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { BudgetPlanTab, BudgetPlanTabHelper } from '../../../models';
import { AppDispatch, BudgetPlanActionMode, budgetPlansByIdSelector, setBudgetPlanActionModeAction } from '../../../state';
import CustomIcon from '../../../utils/CustomIcon';
import { Tabs } from '../../../wmv-components';

// TODO: Check whether to expose all the export for redux state from a single file is a good practice or not
import { BudgetPlanPopupTabContent } from './BudgetPlanPopupTabContent';
import { BudgetPlanActionType, useBudgetPlanDispatch, useBudgetPlanState } from './contexts';
import { closeIconButtonStyles, contentBodyStyles, modalContentContainerStyles } from './styles';

export const BudgetPlanModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();

  const budgetPlanState = useBudgetPlanState();
  const budgetPlanDispatch = useBudgetPlanDispatch();
  const selectedBudgetPlanDetails = useSelector(budgetPlansByIdSelector)[budgetPlanState.selectedPlanId!];
  const { budgetPlanModalVisible, selectedPlanId, activeTab } = budgetPlanState;

  const handleBudgetPlanModalClose: BudgetPlanModalCloseHandler = () => {
    dispatch(setBudgetPlanActionModeAction(BudgetPlanActionMode.Idle));
    budgetPlanDispatch({
      type: BudgetPlanActionType.UpdateBudgetPlanFormModalVisibility,
      payload: false,
    });
    budgetPlanDispatch({
      type: BudgetPlanActionType.UpdateSelectedBudgetPlanId,
      payload: null,
    });
  };

  return (
    <Modal open={budgetPlanModalVisible} onClose={handleBudgetPlanModalClose}>
      <Box sx={modalContentContainerStyles}>
        <Box width="240px">
          <Tabs
            items={BudgetPlanTabHelper.allTabLabelAndValues()}
            disabledItems={selectedPlanId ? [] : [BudgetPlanTab.Members]}
            onChange={(tab) =>
              budgetPlanDispatch({
                type: BudgetPlanActionType.UpdateActiveTab,
                payload: tab,
              })
            }
            active={activeTab}
            title={selectedBudgetPlanDetails?.activeState.name}
          />
        </Box>
        <Box sx={contentBodyStyles}>
          <BudgetPlanPopupTabContent activeTab={activeTab} onBudgetPlanModalClose={handleBudgetPlanModalClose} />
          <IconButton
            onClick={handleBudgetPlanModalClose}
            sx={closeIconButtonStyles}
            aria-label={formatMessage({ id: 'dialog.confirmDelete.close' })}
          >
            {CustomIcon('CloseIcon', theme.palette.white.main, '32px')}
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export type BudgetPlanModalCloseHandler = () => void;
