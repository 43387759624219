import { ReactTable } from '../../../../wmv-components';

import { unsetBgStyles } from './styles';

export function BudgetHistoryTable() {
  return (
    <ReactTable
      data={budgetActivityHistoryList}
      columns={rows}
      customRowHoverStyle={unsetBgStyles}
      tableContainerStyle={{ height: '100%' }}
    />
  );
}

const rows = [
  {
    Header: 'common.name'.i18nText(),
    accessor: 'memberName',
  },
  {
    Header: 'tripHistory.table.date'.i18nText(),
    accessor: 'date',
  },
  {
    Header: 'account.profile.role'.i18nText(),
    accessor: 'role',
  },
  {
    Header: 'common.budget'.i18nText(),
    accessor: 'budgetName',
  },
  {
    Header: 'customerTable.row.active'.i18nText(),
    accessor: 'active',
  },
  {
    Header: 'operator.budgetPlan.form.input.depositAmount.label'.i18nText(),
    accessor: 'amount',
  },
  {
    Header: 'informationTable.header.tripId'.i18nText(),
    accessor: 'tripId',
  },
];

const budgetActivityHistoryList = [
  {
    memberName: 'Frank, Schopkuli',
    date: '22.04.2024 16:00',
    role: 'Owner',
    budgetName: 'Lagarde Budget 600',
    active: 'Deduction',
    amount: '12mins',
    tripId: 800123456,
  },
  {
    memberName: 'Frank, Schopkuli 2',
    date: '22.04.2024 16:00',
    role: 'Owner',
    budgetName: 'Lagarde Budget 600',
    active: 'Deduction',
    amount: '12mins',
    tripId: 800123456,
  },
  {
    memberName: 'Frank, Schopkuli 3',
    date: '22.04.2024 16:00',
    role: 'Owner',
    budgetName: 'Lagarde Budget 600',
    active: 'Deduction',
    amount: '12mins',
    tripId: 800123456,
  },
];
