import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import { ErrorCode } from '../../../../models';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { createMembership } from '../../../../utils/queries';
import { ProgressAwareButton } from '../../../../wmv-components';
import HelperText from '../../../../wmv-components/HelperText';
import {
  SearchBudgetPlanMembershipActionType,
  useBudgetPlanState,
  useSearchBudgetPlanMembership,
  useSearchBudgetPlanMembershipDispatch,
} from '../contexts';
import { useGetBudgetPlanMemberships } from '../hooks/useGetBudgetPlanMemberships';

import { MembershipDetailsRow } from './MembershipDetailsRow';
import { MembershipStatus } from './MembershipStatus';
import { SearchedBudgetPlanMembershipLinkingDetails } from './SearchedMembershipLinkingDetails';
import { SearchedBudgetPlanMembershipUserDetails } from './SearchedMembershipUserDetails';

export const SearchedMembershipDetailsAndLinkNewUserButton = () => {
  const { formatMessage: fm } = useIntl();
  const searchMembershipState = useSearchBudgetPlanMembership();
  const searchMembershipDispatch = useSearchBudgetPlanMembershipDispatch();
  const budgetPlanState = useBudgetPlanState();
  const { selectedPlanId } = budgetPlanState;

  const getBudgetPlanMemberships = useGetBudgetPlanMemberships();

  const { status: linkingApiStatus, membership, message, messageType } = searchMembershipState.linkMembership;
  const disableLinking = !searchMembershipState.searchedMembership?.isEligibleToBeLinked() || membership?.isStatusTransitionPending();
  return (
    <Box mt={2}>
      <SearchedBudgetPlanMembershipUserDetails />
      {membership?.isStatusTransitionPending() ? (
        <MembershipDetailsRow
          title={<MembershipStatus membership={membership!} />}
          labelTranslationKey="common.lastStatusUpdate"
          toolTipText={fm({ id: 'budgetPlanMembership.lastStatusUpdateTooltip' })}
          titleVariant="bodyMedium"
        />
      ) : (
        <SearchedBudgetPlanMembershipLinkingDetails />
      )}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={3}>
        <ProgressAwareButton
          loading={linkingApiStatus.isPending()}
          onClick={handleLinkNewMembershipClick}
          type="button"
          variant="contained"
          size="large"
          loadingButtonProps={{
            sx: { width: 'fit-content', minWidth: '240px' },
          }}
          disabled={disableLinking}
        >
          <Typography variant="bodyMediumBold">{fm({ id: 'budgetPlanMembership.linkUser' })}</Typography>
        </ProgressAwareButton>
        <HelperText
          value={message}
          type={messageType}
          showCheckIcon={messageType === 'success'}
          showAttentionIcon={messageType === 'error'}
        />
      </Box>
    </Box>
  );

  async function handleLinkNewMembershipClick() {
    try {
      searchMembershipDispatch({
        type: SearchBudgetPlanMembershipActionType.LinkingUserStart,
      });
      const resp = await createMembership(selectedPlanId!, {
        subjectClaim: searchMembershipState.searchedMembershipEmail.split('@')[0], // TODO: Remove it while integrating the real api. This will be the searched user email
      });
      const newMembershipDto = new BudgetPlanMembershipDto(resp);
      searchMembershipDispatch({
        type: SearchBudgetPlanMembershipActionType.LinkingUserSuccess,
        payload: {
          membership: newMembershipDto,
          message: linkingApiSuccessMessage(newMembershipDto)!,
          messageType: newMembershipDto.isLinkingFailed() ? 'error' : 'success',
        },
      });
      getBudgetPlanMemberships(selectedPlanId!);
    } catch (error: any) {
      const { errorCode } = error || {};
      searchMembershipDispatch({
        type: SearchBudgetPlanMembershipActionType.LinkingUserFailure,
        payload: linkingApiFailureMessage(errorCode),
      });
    }
  }

  function linkingApiSuccessMessage(membership: BudgetPlanMembershipDto) {
    if (membership.isLinkingInProgress()) return fm({ id: 'budgetPlanMemberships.linkingRequestSubmitted' });
    if (membership.isActive()) return fm({ id: 'common.linked' });
    if (membership.isLinkingFailed()) return fm({ id: 'budgetPlanMemberships.linkingFailed' });
  }

  function linkingApiFailureMessage(errorCode?: ErrorCode) {
    if (errorCode === ErrorCode.MobilityUserAlreadyLinkedToPlan) return fm({ id: 'budgetPlanMembership.alreadyLinkedToThisPlan' });
    else if (errorCode === ErrorCode.MobilityUserSharingOthersBudget) return fm({ id: 'budgetPlanMembership.linkedToOtherPlan' });
    else return fm({ id: 'budgetPlanMemberships.linkingFailed' });
  }
};
