/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';

import { Checkbox, FormControl, IconButton, InputAdornment, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { FilteredRow, FilterProfileType } from '../models';
import CustomIcon from '../utils/CustomIcon';

interface DefaultColumnFilterProps {
  column: any;
  getPreFilter?: (columnId: string) => string;
  filterChangeDependency?: FilterProfileType[];
}

interface MultipleChoiceFilterProps {
  column: any;
  getPreFilter?: (columnId: string) => string;
  filterChangeDependency?: FilterProfileType[] | undefined;
}

export function DefaultColumnFilter({
  column: { filterValue, setFilter, value: columnAccessor },
  getPreFilter = (columnId) => '',
  filterChangeDependency = [],
}: DefaultColumnFilterProps) {
  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setFilter('');
  };

  useEffect(() => {
    const preFilterValue = getPreFilter(columnAccessor);
    setFilter(preFilterValue);
    // TODO: update the filter with all remaining options.
    // if(!preFilterSet) setFilter(options);
  }, filterChangeDependency);

  const theme = useTheme();

  return (
    <TextField
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor: 'white',
          borderRadius: '8px',
        },
        '& .MuiInputBase-input': {
          // border: '2px solid rgba(47, 46, 65, 0.32)',
        },
        '& .css-1ysei5v-MuiInputBase-root-MuiOutlinedInput-root': {
          background: 'white',
          height: '32px',
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="delete column input" onClick={handleIconClick} edge="end">
              {filterValue ? CustomIcon('RemoveTextIcon', theme.palette.dark.main, '24px') : null}
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="small"
      fullWidth
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

export function MultipleChoiceFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id, value: columnAccessor },
  getPreFilter = (columnId) => '',
  filterChangeDependency,
}: MultipleChoiceFilterProps) {
  // The filterValue will be an array.
  const options = useMemo(() => {
    const optionsSet: Set<string> = new Set();
    preFilteredRows.forEach((row: FilteredRow) => {
      const value = row.values[id];
      if (typeof value === 'string') {
        optionsSet.add(value);
      }
    });
    return [...optionsSet.values()];
  }, [id, preFilteredRows, filterChangeDependency]);

  useEffect(() => {
    // get and set pre filters, if available.
    // else we will set all the options as selected values.
    console.log('Setting pre filter for: ', columnAccessor);
    const preFilterValue = getPreFilter(columnAccessor);
    if (preFilterValue) {
      // preFilter value can be a single value or commma separated multiple values.
      // e.g meiaudo, zeus, STWB
      const preArray = preFilterValue.split(',');
      if (preArray.length > 0) {
        const verifiedValues = preArray.map((p) => p.trim()).filter((p) => options.includes(p));
        console.log(verifiedValues);
        if (verifiedValues.length === 0) {
          // No pre filters are available.
          // In this case, we push all the options as those are selected.
          setFilter(options);
        } else {
          setFilter(verifiedValues);
        }
      }
    } else {
      // No pre filters are available.
      // In this case, we push all the options as those are selected.
      setFilter(options);
    }
  }, [id, filterChangeDependency]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedOptions = event.target.value;
    if (selectedOptions.indexOf('all') > -1) {
      const filter = selectedOptions.length === options.length + 1 ? [] : [...options];
      setFilter(filter);
      return;
    }
    setFilter([...selectedOptions]);
  };

  const getRenderValue = (selected: string[]) => {
    if (selected.length >= options.length) return formatMessage({ id: 'common.all' });
    return selected.join(', ');
  };

  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <FormControl sx={{ width: '100%' }}>
      <Select
        sx={{
          width: '100%',
          backgroundColor: '#fff',
          height: '32px',
          borderRadius: '8px',
          position: 'relative',
        }}
        renderValue={getRenderValue}
        value={filterValue}
        onChange={handleChange}
        IconComponent={() => (
          <Box
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              right: '5%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {CustomIcon('DropdownIcon', theme.palette.dark.main)}
          </Box>
        )}
        multiple
      >
        {options.map((op, index) => (
          <MenuItem key={index} value={op as any}>
            <ListItemIcon>
              <Checkbox checked={filterValue.indexOf(op) > -1} />
            </ListItemIcon>
            <ListItemText>{op}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
