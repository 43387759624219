import { useCallback } from 'react';

import { Typography } from '@mui/material';

import {
  BudgetPlanMembershipsListActionType,
  MembershipDetailsActionType,
  useBudgetPlanMembershipsListDispatch,
  useSelectedBudgetPlanDetails,
  useSelectedMembershipId,
  useMembershipDetailsState,
  useMembershipDetailsDispatch,
} from '../contexts';

import { MembershipDetailsModalContent } from './MembershipDetailsModalContent';
import { Modal } from './Modal';
import { unlinkContentStyles } from './styles';

export const MembershipDetailsModal = () => {
  const membershipsListDispatch = useBudgetPlanMembershipsListDispatch();
  const membershipDetailsDispatch = useMembershipDetailsDispatch();
  const membershipDetailsState = useMembershipDetailsState();
  const selectedBudgetPlanDetails = useSelectedBudgetPlanDetails();
  const selectedMembershipId = useSelectedMembershipId();

  const handleUnlinkMembershipModalClose = useCallback(() => {
    membershipDetailsDispatch({
      type: MembershipDetailsActionType.ResetMembershipState,
    });
    membershipsListDispatch({
      type: BudgetPlanMembershipsListActionType.UpdateSelectedMembership,
      payload: null,
    });
  }, [membershipDetailsDispatch, membershipsListDispatch]);

  const { discardFailedStateTransitionApiStatus, retryStateTransitionApiStatus } = membershipDetailsState;
  return (
    <Modal
      open={!!selectedMembershipId}
      onClose={handleUnlinkMembershipModalClose}
      closeable={!retryStateTransitionApiStatus.isPending() || !discardFailedStateTransitionApiStatus.isPending()}
    >
      <Modal.Content sx={unlinkContentStyles}>
        <Typography variant="bodyLargeBold">{selectedBudgetPlanDetails?.activeState.name}</Typography>
        <MembershipDetailsModalContent onUnlinkMembershipModalClose={handleUnlinkMembershipModalClose} />
      </Modal.Content>
    </Modal>
  );
};
