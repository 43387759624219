import { FloatingButtonType } from '../../models';
import { intl } from '../../utils/intl';
import { ButtonGroupItemConfig } from '../../wmv-components';

export const switchButtonConfig: ButtonGroupItemConfig[] = [
  {
    text: intl.formatMessage({ id: 'map.mapMode' }),
    switchModeButtonId: FloatingButtonType.MapMode,
  },
  {
    text: intl.formatMessage({ id: 'map.listMode' }),
    switchModeButtonId: FloatingButtonType.ListMode,
  },
];
