import L from 'leaflet';

import car from '../../assets/map-illustrations/car.svg';
import cargo_bicycle from '../../assets/map-illustrations/cargo_bicycle.svg';
import ebike from '../../assets/map-illustrations/ebike.svg';
import moped from '../../assets/map-illustrations/moped.svg';
import scooter from '../../assets/map-illustrations/scooter.svg';

const icons = {
  moped,
  scooter,
  cargo_bicycle,
  car,
  ebike,
};

const VehicleIcons = (className = '', icon: string, selected = false) => {
  return L.divIcon({
    iconSize: [34, 34],
    className: `my-div-icon ${className} ${selected ? 'selected' : ''}`,
    html: `<img src=${icons[icon as keyof typeof icons]} alt='pic'/>`,
  });
};

export default VehicleIcons;
