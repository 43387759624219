import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

import diffPlugin from './diffPlugin';
import formatAsPlugin from './formatAsPlugin';
import getterPlugin from './getterPlugin';
import miscPlugin from './miscPlugin';
import numberToDayjsConverter from './numberToDayjsConverter';
import stringToDayjsConverter from './stringToDayjsConverter';
import timeUnitAdjustmentPlugin from './timeUnitAdjustmentPlugin';
import timeUtilsPlugin from './timeUtilsPlugin';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(isBetweenPlugin);
dayjs.extend(duration);
dayjs.extend(getterPlugin);

// Custom Plugins
dayjs.extend(formatAsPlugin);
dayjs.extend(timeUnitAdjustmentPlugin);
dayjs.extend(miscPlugin);
dayjs.extend(stringToDayjsConverter);
dayjs.extend(numberToDayjsConverter);
dayjs.extend(diffPlugin);
dayjs.extend(timeUtilsPlugin);
